import styled from "styled-components/macro";
import classnames from "classnames";
import { useP } from "../../../../../services/i18n";

import { HiTooltip } from "@hipay/design-system/components";
import {
    darken,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    SvgIconProps,
    Typography,
} from "@mui/material";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import StarOutlined from "@mui/icons-material/StarOutlined";

import { Link } from "react-router-dom";
import { MenuItemType, MenuSubItemType } from "../menu-items";

export type MenuItemProps = {
    /* HTML ID */
    id?: string;
    /* CSS Classname */
    className?: string;
    /* Menu item config */
    item: MenuItemType | MenuSubItemType;
    /* Item type (main = first level item, secondary = sub-level item) */
    type: "main" | "secondary";
    /* Item label text */
    label: string;
    /* Callback when item is clicked */
    onItemClick: () => void;
    /* Item main background color */
    color?: string;
    /* If true, display smaller height item for sub-items */
    dense?: boolean;
    /* Icon on the left */
    leftIcon?: React.ComponentType<SvgIconProps>;
    /* If true, add a Star icon on the right */
    withFavorites?: boolean;
    /* If true, favorite is not clickable and show a tooltip */
    maxFavoritesReached?: boolean;
    /* If true, the Star icon is displayed as checked */
    favoriteChecked?: boolean;
    /* Callback when the Star icon si clicked */
    onFavoriteClick?: () => void;
    /* If true, add an Expand icon on the right */
    expandable?: boolean;
    /* Set the position of the Expand icon */
    expanded?: boolean;
    /* If true, color the background as active item */
    active?: boolean;
    /* Url prefix to add before target url */
    consoleUrlPrefix?: string;
    /* TPP url to redirect */
    oldInterfaceUrl?: string;
};

/**
 * Final item displayed in the Menu.
 * The item can be:
 * - a div if expandable item, or logout
 * - a react-router link if internal navigation
 * - a link if external navigation
 * The item can have:
 * - a left icon, display a dot instead
 * - a favorite star
 * - an expand icon
 */
const MenuItem = ({
    id,
    className,
    item,
    type,
    label,
    leftIcon: IconComponent,
    dense = false,
    withFavorites = false,
    maxFavoritesReached = false,
    favoriteChecked = false,
    expandable = false,
    expanded = false,
    onItemClick,
    onFavoriteClick,
    consoleUrlPrefix,
    oldInterfaceUrl,
}: MenuItemProps) => {
    const p = useP();

    const handleFavoriteClick = (e: React.MouseEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
        // Clickable only if not max favorites or if max & checked
        if (!maxFavoritesReached || favoriteChecked) {
            onFavoriteClick && onFavoriteClick();
        }
    };

    const itemContent = (
        <>
            <ListItemIcon className="MenuItem-leftIcon">
                {IconComponent ? (
                    <IconComponent fontSize="small" />
                ) : (
                    <div className="MenuItem-dot" />
                )}
            </ListItemIcon>
            <ListItemText className="MenuItem-text">
                <Typography
                    variant={type === "main" ? "subtitle2" : "body2"}
                    color="white"
                    sx={{ ...(type === "secondary" && { fontWeight: "light" }) }}
                >
                    {label}
                </Typography>
            </ListItemText>
            {withFavorites && (
                <ListItemIcon className="MenuItem-rightIcon" onClick={handleFavoriteClick}>
                    {maxFavoritesReached && !favoriteChecked ? (
                        <HiTooltip
                            title={p.t("app.menu.favorites.maxFavoritesReached")}
                            disableInteractive
                        >
                            <StarOutlined
                                fontSize="small"
                                className="MenuItem-favoriteIcon MenuItem-favoriteIcon-disabled"
                            />
                        </HiTooltip>
                    ) : (
                        <StarOutlined
                            fontSize="small"
                            className={classnames("MenuItem-favoriteIcon", {
                                "MenuItem-favoriteIcon-checked": favoriteChecked,
                            })}
                        />
                    )}
                </ListItemIcon>
            )}
            {expandable && (
                <ListItemIcon className="MenuItem-rightIcon MenuItem-expandIcon">
                    {expanded ? <ExpandLess /> : <ExpandMore />}
                </ListItemIcon>
            )}
        </>
    );

    // Add target url to component and display it as a link if final element
    let itemContainerProps = {};
    if (item.type === "console") {
        // Add selected accounts in url
        // accounts-123/target?targetParams
        const targetUrl = consoleUrlPrefix + item.target + (item.targetParams || "");
        itemContainerProps = {
            component: Link,
            to: targetUrl,
        };
    } else if (item.type === "tpp") {
        itemContainerProps = { href: oldInterfaceUrl };
    }

    return (
        <ListItemButton
            {...itemContainerProps}
            id={id}
            className={className}
            dense={dense}
            onClick={onItemClick}
        >
            {itemContent}
        </ListItemButton>
    );
};

const StyledMenuItem = styled(MenuItem).attrs(({ color, theme }) => ({
    color: color || theme.palette.info.main,
}))`
    height: ${({ dense }: MenuItemProps) => (dense ? "35px" : "40px")};

    background-color: ${({ active, color }: { active?: boolean; color: string }) =>
        active ? darken(color, 0.35) : color};

    &:hover {
        background-color: ${({ color }: { color: string }) => darken(color, 0.2)};

        .MenuItem-favoriteIcon {
            display: inline-flex !important;
        }
    }

    .MenuItem-text {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .MenuItem-leftIcon {
        min-width: 28px;
        color: white;

        .MenuItem-dot {
            width: 3px;
            height: 3px;
            border-radius: 30px;
            background-color: white;
        }
    }

    .MenuItem-rightIcon {
        margin-left: 4px;
        margin-right: -4px;
        min-width: 20px;
        color: white;

        &.MenuItem-expandIcon {
            opacity: 0.6;
            min-width: 0;
            margin-right: -6px;
        }

        .MenuItem-favoriteIcon {
            display: none;
            opacity: 0.3;

            &:hover {
                opacity: 0.7;
            }

            &-disabled:hover {
                opacity: 0.3;
            }

            &-checked {
                display: inline-flex;
                opacity: 1;
                &:hover {
                    opacity: 1;
                }
            }
        }
    }
`;

export default StyledMenuItem;
