const timezoneNames = {
    id: "timezones_names",
    data: [
        {
            id: "Pacific/Midway",
            label: "ref.user.timezones.Pacific/Midway",
        },
        {
            id: "Pacific/Samoa",
            label: "ref.user.timezones.Pacific/Samoa",
        },
        {
            id: "US/Hawaii",
            label: "ref.user.timezones.US/Hawaii",
        },
        {
            id: "US/Alaska",
            label: "ref.user.timezones.US/Alaska",
        },
        {
            id: "US/Pacific",
            label: "ref.user.timezones.US/Pacific",
        },
        {
            id: "US/Arizona",
            label: "ref.user.timezones.US/Arizona",
        },
        {
            id: "America/Tijuana",
            label: "ref.user.timezones.America/Tijuana",
        },
        {
            id: "US/Mountain",
            label: "ref.user.timezones.US/Mountain",
        },
        {
            id: "America/Chihuahua",
            label: "ref.user.timezones.America/Chihuahua",
        },
        {
            id: "America/Mazatlan",
            label: "ref.user.timezones.America/Mazatlan",
        },
        {
            id: "Canada/Saskatchewan",
            label: "ref.user.timezones.Canada/Saskatchewan",
        },
        {
            id: "Canada/Central",
            label: "ref.user.timezones.Canada/Central",
        },
        {
            id: "US/Central",
            label: "ref.user.timezones.US/Central",
        },
        {
            id: "America/Mexico_City",
            label: "ref.user.timezones.America/Mexico_City",
        },
        {
            id: "America/Monterrey",
            label: "ref.user.timezones.America/Monterrey",
        },
        {
            id: "America/Bogota",
            label: "ref.user.timezones.America/Bogota",
        },
        {
            id: "America/Lima",
            label: "ref.user.timezones.America/Lima",
        },
        {
            id: "America/Guayaquil",
            label: "ref.user.timezones.America/Guayaquil",
        },
        {
            id: "US/Eastern",
            label: "ref.user.timezones.US/Eastern",
        },
        {
            id: "US/East-Indiana",
            label: "ref.user.timezones.US/East-Indiana",
        },
        {
            id: "America/Caracas",
            label: "ref.user.timezones.America/Caracas",
        },
        {
            id: "America/La_Paz",
            label: "ref.user.timezones.America/La_Paz",
        },
        {
            id: "America/Santiago",
            label: "ref.user.timezones.America/Santiago",
        },
        {
            id: "Canada/Atlantic",
            label: "ref.user.timezones.Canada/Atlantic",
        },
        {
            id: "America/Sao_Paulo",
            label: "ref.user.timezones.America/Sao_Paulo",
        },
        {
            id: "America/Buenos_Aires",
            label: "ref.user.timezones.America/Buenos_Aires",
        },
        {
            id: "Canada/Newfoundland",
            label: "ref.user.timezones.Canada/Newfoundland",
        },
        {
            id: "America/Godthab",
            label: "ref.user.timezones.America/Godthab",
        },
        {
            id: "Atlantic/Cape_Verde",
            label: "ref.user.timezones.Atlantic/Cape_Verde",
        },
        {
            id: "Atlantic/Azores",
            label: "ref.user.timezones.Atlantic/Azores",
        },
        {
            id: "Africa/Monrovia",
            label: "ref.user.timezones.Africa/Monrovia",
        },
        {
            id: "UTC",
            label: "ref.user.timezones.UTC",
        },
        {
            id: "Africa/Casablanca",
            label: "ref.user.timezones.Africa/Casablanca",
        },
        {
            id: "Europe/Dublin",
            label: "ref.user.timezones.Europe/Dublin",
        },
        {
            id: "Europe/Isle_of_Man",
            label: "ref.user.timezones.Europe/Isle_of_Man",
        },
        {
            id: "Europe/Lisbon",
            label: "ref.user.timezones.Europe/Lisbon",
        },
        {
            id: "Europe/London",
            label: "ref.user.timezones.Europe/London",
        },
        {
            id: "Africa/Libreville",
            label: "ref.user.timezones.Africa/Libreville",
        },
        {
            id: "Europe/Amsterdam",
            label: "ref.user.timezones.Europe/Amsterdam",
        },
        {
            id: "Europe/Belgrade",
            label: "ref.user.timezones.Europe/Belgrade",
        },
        {
            id: "Europe/Berlin",
            label: "ref.user.timezones.Europe/Berlin",
        },
        {
            id: "Europe/Zurich",
            label: "ref.user.timezones.Europe/Zurich",
        },
        {
            id: "Europe/Bratislava",
            label: "ref.user.timezones.Europe/Bratislava",
        },
        {
            id: "Europe/Brussels",
            label: "ref.user.timezones.Europe/Brussels",
        },
        {
            id: "Europe/Budapest",
            label: "ref.user.timezones.Europe/Budapest",
        },
        {
            id: "Europe/Copenhagen",
            label: "ref.user.timezones.Europe/Copenhagen",
        },
        {
            id: "Europe/Ljubljana",
            label: "ref.user.timezones.Europe/Ljubljana",
        },
        {
            id: "Europe/Madrid",
            label: "ref.user.timezones.Europe/Madrid",
        },
        {
            id: "Europe/Paris",
            label: "ref.user.timezones.Europe/Paris",
        },
        {
            id: "Europe/Prague",
            label: "ref.user.timezones.Europe/Prague",
        },
        {
            id: "Europe/Rome",
            label: "ref.user.timezones.Europe/Rome",
        },
        {
            id: "Europe/Sarajevo",
            label: "ref.user.timezones.Europe/Sarajevo",
        },
        {
            id: "Europe/Skopje",
            label: "ref.user.timezones.Europe/Skopje",
        },
        {
            id: "Europe/Stockholm",
            label: "ref.user.timezones.Europe/Stockholm",
        },
        {
            id: "Europe/Vienna",
            label: "ref.user.timezones.Europe/Vienna",
        },
        {
            id: "Europe/Warsaw",
            label: "ref.user.timezones.Europe/Warsaw",
        },
        {
            id: "Europe/Zagreb",
            label: "ref.user.timezones.Europe/Zagreb",
        },
        {
            id: "Africa/Cairo",
            label: "ref.user.timezones.Africa/Cairo",
        },
        {
            id: "Africa/Harare",
            label: "ref.user.timezones.Africa/Harare",
        },
        {
            id: "Africa/Johannesburg",
            label: "ref.user.timezones.Africa/Johannesburg",
        },
        {
            id: "Europe/Athens",
            label: "ref.user.timezones.Europe/Athens",
        },
        {
            id: "Europe/Bucharest",
            label: "ref.user.timezones.Europe/Bucharest",
        },
        {
            id: "Europe/Helsinki",
            label: "ref.user.timezones.Europe/Helsinki",
        },
        {
            id: "Europe/Istanbul",
            label: "ref.user.timezones.Europe/Istanbul",
        },
        {
            id: "Asia/Jerusalem",
            label: "ref.user.timezones.Asia/Jerusalem",
        },
        {
            id: "Europe/Kiev",
            label: "ref.user.timezones.Europe/Kiev",
        },
        {
            id: "Europe/Minsk",
            label: "ref.user.timezones.Europe/Minsk",
        },
        {
            id: "Europe/Riga",
            label: "ref.user.timezones.Europe/Riga",
        },
        {
            id: "Europe/Sofia",
            label: "ref.user.timezones.Europe/Sofia",
        },
        {
            id: "Europe/Tallinn",
            label: "ref.user.timezones.Europe/Tallinn",
        },
        {
            id: "Europe/Vilnius",
            label: "ref.user.timezones.Europe/Vilnius",
        },
        {
            id: "Asia/Baghdad",
            label: "ref.user.timezones.Asia/Baghdad",
        },
        {
            id: "Asia/Kuwait",
            label: "ref.user.timezones.Asia/Kuwait",
        },
        {
            id: "Europe/Moscow",
            label: "ref.user.timezones.Europe/Moscow",
        },
        {
            id: "Africa/Nairobi",
            label: "ref.user.timezones.Africa/Nairobi",
        },
        {
            id: "Asia/Riyadh",
            label: "ref.user.timezones.Asia/Riyadh",
        },
        {
            id: "Europe/Volgograd",
            label: "ref.user.timezones.Europe/Volgograd",
        },
        {
            id: "Asia/Tehran",
            label: "ref.user.timezones.Asia/Tehran",
        },
        {
            id: "Asia/Dubai",
            label: "ref.user.timezones.Asia/Dubai",
        },
        {
            id: "Asia/Baku",
            label: "ref.user.timezones.Asia/Baku",
        },
        {
            id: "Asia/Muscat",
            label: "ref.user.timezones.Asia/Muscat",
        },
        {
            id: "Asia/Tbilisi",
            label: "ref.user.timezones.Asia/Tbilisi",
        },
        {
            id: "Asia/Yerevan",
            label: "ref.user.timezones.Asia/Yerevan",
        },
        {
            id: "Asia/Kabul",
            label: "ref.user.timezones.Asia/Kabul",
        },
        {
            id: "Asia/Yekaterinburg",
            label: "ref.user.timezones.Asia/Yekaterinburg",
        },
        {
            id: "Asia/Karachi",
            label: "ref.user.timezones.Asia/Karachi",
        },
        {
            id: "Asia/Tashkent",
            label: "ref.user.timezones.Asia/Tashkent",
        },
        {
            id: "Asia/Calcutta",
            label: "ref.user.timezones.Asia/Calcutta",
        },
        {
            id: "Asia/Kolkata",
            label: "ref.user.timezones.Asia/Kolkata",
        },
        {
            id: "Asia/Colombo",
            label: "ref.user.timezones.Asia/Colombo",
        },
        {
            id: "Asia/Almaty",
            label: "ref.user.timezones.Asia/Almaty",
        },
        {
            id: "Asia/Dhaka",
            label: "ref.user.timezones.Asia/Dhaka",
        },
        {
            id: "Asia/Novosibirsk",
            label: "ref.user.timezones.Asia/Novosibirsk",
        },
        {
            id: "Asia/Rangoon",
            label: "ref.user.timezones.Asia/Rangoon",
        },
        {
            id: "Asia/Urumqi",
            label: "ref.user.timezones.Asia/Urumqi",
        },
        {
            id: "Asia/Bangkok",
            label: "ref.user.timezones.Asia/Bangkok",
        },
        {
            id: "Asia/Saigon",
            label: "ref.user.timezones.Asia/Saigon",
        },
        {
            id: "Asia/Jakarta",
            label: "ref.user.timezones.Asia/Jakarta",
        },
        {
            id: "Asia/Krasnoyarsk",
            label: "ref.user.timezones.Asia/Krasnoyarsk",
        },
        {
            id: "Asia/Taipei",
            label: "ref.user.timezones.Asia/Taipei",
        },
        {
            id: "Asia/Chongqing",
            label: "ref.user.timezones.Asia/Chongqing",
        },
        {
            id: "Asia/Hong_Kong",
            label: "ref.user.timezones.Asia/Hong_Kong",
        },
        {
            id: "Asia/Irkutsk",
            label: "ref.user.timezones.Asia/Irkutsk",
        },
        {
            id: "Asia/Kuala_Lumpur",
            label: "ref.user.timezones.Asia/Kuala_Lumpur",
        },
        {
            id: "Australia/Perth",
            label: "ref.user.timezones.Australia/Perth",
        },
        {
            id: "Asia/Singapore",
            label: "ref.user.timezones.Asia/Singapore",
        },
        {
            id: "Asia/Ulaanbaatar",
            label: "ref.user.timezones.Asia/Ulaanbaatar",
        },
        {
            id: "Asia/Seoul",
            label: "ref.user.timezones.Asia/Seoul",
        },
        {
            id: "Asia/Tokyo",
            label: "ref.user.timezones.Asia/Tokyo",
        },
        {
            id: "Asia/Yakutsk",
            label: "ref.user.timezones.Asia/Yakutsk",
        },
        {
            id: "Australia/Adelaide",
            label: "ref.user.timezones.Australia/Adelaide",
        },
        {
            id: "Australia/Darwin",
            label: "ref.user.timezones.Australia/Darwin",
        },
        {
            id: "Australia/Brisbane",
            label: "ref.user.timezones.Australia/Brisbane",
        },
        {
            id: "Australia/Canberra",
            label: "ref.user.timezones.Australia/Canberra",
        },
        {
            id: "Pacific/Guam",
            label: "ref.user.timezones.Pacific/Guam",
        },
        {
            id: "Australia/Hobart",
            label: "ref.user.timezones.Australia/Hobart",
        },
        {
            id: "Australia/Melbourne",
            label: "ref.user.timezones.Australia/Melbourne",
        },
        {
            id: "Pacific/Port_Moresby",
            label: "ref.user.timezones.Pacific/Port_Moresby",
        },
        {
            id: "Australia/Sydney",
            label: "ref.user.timezones.Australia/Sydney",
        },
        {
            id: "Asia/Vladivostok",
            label: "ref.user.timezones.Asia/Vladivostok",
        },
        {
            id: "Asia/Magadan",
            label: "ref.user.timezones.Asia/Magadan",
        },
        {
            id: "Pacific/Noumea",
            label: "ref.user.timezones.Pacific/Noumea",
        },
        {
            id: "Pacific/Guadalcanal",
            label: "ref.user.timezones.Pacific/Guadalcanal",
        },
        {
            id: "Pacific/Auckland",
            label: "ref.user.timezones.Pacific/Auckland",
        },
        {
            id: "Pacific/Fiji",
            label: "ref.user.timezones.Pacific/Fiji",
        },
        {
            id: "Asia/Kamchatka",
            label: "ref.user.timezones.Asia/Kamchatka",
        },
        {
            id: "Pacific/Majuro",
            label: "ref.user.timezones.Pacific/Majuro",
        },
        {
            id: "Pacific/Tongatapu",
            label: "ref.user.timezones.Pacific/Tongatapu",
        },
    ],
    translate: true,
};

export default timezoneNames;
