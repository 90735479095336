import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles({
    root: {
        width: 40,
        height: 40,
        borderRadius: "100%",
        cursor: "pointer",
    },
});
