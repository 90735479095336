import { createStore, applyMiddleware, compose, combineReducers } from "redux";
import { apiMiddleware } from "redux-api-middleware";
import promise from "redux-promise";
import thunk from "redux-thunk";
import { authMiddleware } from "./middlewares/authMiddleware";
import { e401Middleware } from "./middlewares/e401Middleware";
import { e403Middleware } from "./middlewares/e403Middleware";
import { gaMiddleware } from "./middlewares/gaMiddleware";
import { queryMiddleware } from "./middlewares/queryMiddleware";
import { timeoutScheduler } from "./middlewares/timeoutScheduler";
import { zendeskMiddleware } from "./middlewares/zendeskMiddleware";

/**
 * /!\ WARNING /!\
 * As the store is used by Console Master,
 * you should not introduce breaking changes !
 *
 * If updates are required, please tag old functions as deprecated.
 *
 * @param initialState
 * @returns {Store<any> | Store<StoreEnhancer<S>> | Store<S> | *}
 */
export const configureStore = (initialState) => {
    const finalCreateStore = compose(
        applyMiddleware(
            promise,
            timeoutScheduler,
            authMiddleware,
            queryMiddleware,
            apiMiddleware,
            e401Middleware,
            e403Middleware,
            gaMiddleware,
            zendeskMiddleware,
            thunk
        ),
        window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : (f) => f
    )(createStore);

    const store = finalCreateStore(baseReducer, initialState);

    // Add a dictionary to keep track of the registered async reducers
    store.asyncReducers = {};

    // Create an inject reducer function
    // This function adds the async reducer, and creates a new combined reducer
    store.injectReducer = (key, asyncReducer) => {
        store.asyncReducers[key] = asyncReducer;
        store.replaceReducer(createReducer(store.asyncReducers));
    };

    return store;
};

// Base reducer - you could add static reducers here
function baseReducer(state = {}) {
    return state;
}

function createReducer(asyncReducers) {
    return combineReducers({
        // you could add static reducers here
        ...asyncReducers,
    });
}
