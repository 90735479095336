import attributes from "./attributes";
import AppAccount from "../AppAccount";
import { defaultFields } from "./form";
import references from "../references";
import { formatExportColumns } from "../services/search/Search";
import { getGuideTourSteps } from "./guidetour";
import SellOutlined from "@mui/icons-material/SellOutlined";

/**
 * Config
 */
export const moduleConfig = {
    id: "account", // module key
    attributes: attributes, // module attributes for search
    defaultFormFields: defaultFields, // default attributes on search
    icon: SellOutlined, // module icon on module selector (see https://materialdesignicons.com/)
    label: "menu.account.title", // translation path
    path: "accounts", // module uri from search
    route: "[prefix]/accounts", // module uri from quicksearch
    features: ["form", "export"], // module features enabled
    exportColumnsFormatter: formatExportColumns,
    references: references, // module references
    apiUrl: process.env.NX_ACCOUNT_API_URL, // module api url
    suggestRoute: "/accounts/suggest?", // module suggest endpoint,
    acl: {
        // List ACL that give access to account module
        module: [
            {
                module: "account",
                controller: "details",
            },
        ],
        // ACL that give access to accounts export feature
        export: {
            module: "account",
            controller: "export",
        },
    },
    getGuideTourSteps: getGuideTourSteps,
};

export const moduleRouting = [
    {
        key: "account", // module key
        path: ["/accounts", "/accounts-:ids/accounts"], // module matching uri
        component: AppAccount, // module parent component
    },
];
