import { lazy } from "react";
import { AppCommon } from "@console/core";

// https://reactjs.org/docs/code-splitting.html#route-based-code-splitting
const Root = lazy(() => import("./Root"));

const AppPaymentMethods = (props: any) => (
    <AppCommon ModuleRoot={Root} module="payment_methods_settings" {...props} />
);

export { AppPaymentMethods };
