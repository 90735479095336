import makeStyles from "@mui/styles/makeStyles";

// Can't use the HiTheme because this style is going to be used in Okta component
export const useStyles = makeStyles(() => ({
    root: {
        height: 48,
        width: "100%",
        marginBottom: 12,
        color: "#FFFFFF",
        fontSize: 14,
        background: "#a50979",
        border: "none",
        borderRadius: 4,
        boxShadow:
            "0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)",
        "&:hover": {
            cursor: "pointer",
            //background: "#007CA9",
        },
    },
}));
