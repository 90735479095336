import * as actionTypes from "../actions/actionTypes";

// initial state of your module store partition
export const initialStateSettings = {
    // default settings
    data: {
        currency: "EUR",
        dateFormat: "MMMM dd, YYYY HH:mm:ss",
        dateFormatShort: "YYYY-MM-DD",
        decimalSymbol: ".",
        encoding: "UTF-8",
        fileFormat: "CSV-SEMICOLON",
        language: "fr_FR",
        lexicon: "DEFAULT",
        timezone: {
            id: 49,
            abbrev: "CET",
            description: "Paris",
            gmtOffset: "GMT +01:00",
            groupname: null,
            isDst: false,
            name: "Europe/Paris",
            utcOffset: "01:00:00",
        },
        searchResultsConfig: {},
        noticeConfig: {},
    },
    loading: false,
    error: null,
};

/**
 * Hydrate store.app.settings from
 * - FETCH_CONNECTED_USER
 * - UPDATE_SETTINGS
 */
export const settings = (state = initialStateSettings, action) => {
    switch (action.type) {
        case actionTypes.UPDATE_SETTINGS_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case actionTypes.SET_USER:
            return {
                data: {
                    ...state.data,
                    ...action.payload.settings,
                },
                loading: false,
                error: null,
            };

        case actionTypes.UPDATE_SETTINGS_SUCCESS:
            return {
                data: {
                    ...state.data,
                    ...action.payload,
                },
                loading: false,
                error: null,
            };

        case actionTypes.UPDATE_SETTINGS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case actionTypes.LOGOUT:
            return initialStateSettings;

        default:
            return state;
    }
};
