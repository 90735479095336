import { useCallback, useMemo, useEffect, useState } from "react";
import { Form } from "../../../../../common/components/Search/Form";
import { stringifySearchParams } from "../../../../../utils/urls";
import { filterModules } from "../../../../../utils/modules";
import { HiSelectField } from "@hipay/hipay-material-ui/HiSelect";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { useModuleConfig, useModuleList } from "../../../../../services/config";
import { useP } from "../../../../../services/i18n";
import makeStyles from "@mui/styles/makeStyles";
import { useHistory } from "react-router-dom";
import { useAccount } from "../../../../../services/account";
import { useCan } from "../../../../../services/ability";
import { ErrorBoundary } from "../../../common/ErrorBoundary";
import { setEventTracker } from "../../../../../services/tracker";
import { useHeaderHeight } from "../../../../../hooks/useHeaderHeight";

const useStylesQuickForm = makeStyles((theme) => ({
    form: {
        zIndex: 1200,
        position: "fixed",
        backgroundColor: theme.palette.background3,
        boxShadow: "0px 1px 4px 0px rgba(0, 0, 0, 0.16)",
        overflowY: "auto",
        overflowX: "hidden",
        maxHeight: ({ headerHeight }) => `calc(100% - ${headerHeight}px)`,
        margin: "auto",
        top: ({ headerHeight }) => headerHeight - 8,
        color: theme.palette.neutral.dark,
        paddingTop: "26px",
    },
    searchTypeForm: {
        width: "100%",
        maxWidth: 500,
        margin: "auto",
        padding: "0 8px",
        textAlign: "left",
    },
}));

export function QuickForm(props) {
    const {
        handleCloseInnerPage,
        onSubmit,
        selectedModule,
        onBlur,
        containerWidth,
        connectedUser,
        selectedAccountIdList,
        accountEntities,
    } = props;

    const p = useP();
    const headerHeight = useHeaderHeight();
    const classes = useStylesQuickForm({ headerHeight });
    const history = useHistory();
    const { urlPrefix } = useAccount();

    const [module, setModule] = useState(selectedModule);

    const { getModuleConfigListByFeature } = useModuleList();
    const can = useCan();
    const moduleConfig = useModuleConfig(module);
    const defaultFormFields = useMemo(() => moduleConfig.defaultFormFields, [moduleConfig]);
    const references = useMemo(() => moduleConfig.references, [moduleConfig]);
    const attributes = useMemo(() => moduleConfig.attributes, [moduleConfig]);
    const path = useMemo(() => moduleConfig.path, [moduleConfig]);
    const [_formParams, setFormParams] = useState({});

    useEffect(() => {
        setFormParams(moduleConfig.defaultFormFields);
    }, [moduleConfig]);

    /**
     * Build iso url from state object
     * Add prefix to param name
     *
     * @param nextState
     */
    const buildUrlFromState = useCallback(({ formParams, ...params }) => {
        return [stringifySearchParams(params), stringifySearchParams({ ...formParams }, "fp_")]
            .filter((param) => param && param.length > 0)
            .join("&");
    }, []);

    const handleRedirectToSearch = useCallback(
        (submit) => (formParams) => {
            setEventTracker("filter", {
                event_category: "qsearch",
                event_action: submit
                    ? "qsearch_validate_search"
                    : "qsearch_shortcut_advanced_search_button",
            });

            let urlFormParams = { ...formParams };

            // Remove useless multifield like status_period if no period
            Object.keys(formParams).forEach((paramName) => {
                let attribute = attributes[paramName];
                if (
                    attribute &&
                    attribute.formFieldOptions.multiFields &&
                    !urlFormParams[paramName]
                ) {
                    Object.values(attribute.formFieldOptions.multiFields).forEach((field) => {
                        if (urlFormParams[field.id]) {
                            delete urlFormParams[field.id];
                        }
                    });
                }
            });

            handleCloseInnerPage();
            onSubmit();

            history.push({
                pathname: `${urlPrefix}/${path}`,
                search: buildUrlFromState({
                    formParams: urlFormParams,
                    noSubmit: submit ? undefined : true,
                }),
            });
        },
        [attributes, buildUrlFromState, handleCloseInnerPage, history, onSubmit, path, urlPrefix]
    );

    const handleChangeModule = useCallback((event, value) => {
        setModule(value);
    }, []);

    const handleChangeFormField = useCallback(
        (name, value) => {
            // Format data properly to put in state object
            let valToInsert;
            if (typeof value === "object" && !Array.isArray(value)) {
                valToInsert = {
                    ..._formParams[name],
                    ...value,
                    navigate: false,
                };
            } else {
                valToInsert = value;
            }

            _formParams[name] = valToInsert;

            setFormParams(_formParams);
        },
        [_formParams]
    );

    const handleClickAway = useCallback(
        (event) => {
            if (
                document.getElementById("quick-form") &&
                !document.getElementById("quick-form").contains(event.srcElement) &&
                document.getElementById("quick-form-button") &&
                !document.getElementById("quick-form-button").contains(event.srcElement)
            ) {
                onBlur();
            }
        },
        [onBlur]
    );

    const moduleList = useMemo(
        () =>
            filterModules(
                getModuleConfigListByFeature("form"),
                can,
                connectedUser.internalstaff
            ).map((m) => {
                const IconComponent = m.icon;
                return {
                    ...m,
                    icon: <IconComponent fontSize="small" />,
                    label: p.t(m.label),
                    color: "primary",
                };
            }),
        [getModuleConfigListByFeature, can, p]
    );

    return (
        <ClickAwayListener onClickAway={handleClickAway}>
            <div style={{ width: containerWidth }} className={classes.form} id="quick-form">
                <div id="search-type-form" className={classes.searchTypeForm}>
                    <HiSelectField
                        id="search_type"
                        name="search_type"
                        onChange={handleChangeModule}
                        options={moduleList}
                        value={module}
                        label={p.t("common.search.search_type")}
                        type={"icon"}
                        hiSelectableListProps={{ hideCheckbox: true }}
                        disabled={module !== null && moduleList.length < 2}
                    />
                </div>
                <ErrorBoundary>
                    <Form
                        key={`form-${module}`}
                        isQuickForm
                        deletable={false}
                        newFilters={false}
                        formParams={_formParams}
                        attributes={attributes}
                        moduleReferences={references}
                        defaultForm={defaultFormFields}
                        connectedUser={connectedUser}
                        onSubmit={handleRedirectToSearch(true)}
                        onRedirectToSearch={handleRedirectToSearch(false)}
                        onChange={handleChangeFormField}
                        selectedAccountIdList={selectedAccountIdList}
                        module={module}
                        accountEntities={accountEntities}
                    />
                </ErrorBoundary>
            </div>
        </ClickAwayListener>
    );
}
