import React from "react";
import makeStyles from "@mui/styles/makeStyles";

import CampaignOutlined from "@mui/icons-material/CampaignOutlined";
import WarningAmberOutlined from "@mui/icons-material/WarningAmberOutlined";
import CloseIcon from "mdi-material-ui/Close";
import { Message } from "./Message";
import { useP } from "../../../../../services/i18n";
import classNames from "classnames";
import ButtonBase from "@mui/material/ButtonBase";
import { useMessageBar } from "../../../../../services/messageBar";
import { MESSAGE_BAR_HEIGHT } from "../../../../../constants";

const useStylesMessageBar = makeStyles((theme) => ({
    root: {
        width: "100%",
        zIndex: 99999,
        marginTop: -MESSAGE_BAR_HEIGHT,
        height: MESSAGE_BAR_HEIGHT,
        display: "flex",
        justifyContent: "space-between",
        "& div:nth-child(2) div:first-child": { minWidth: 70 },
    },
    displayBar: { marginTop: 0 },
    closeBtn: {
        display: "flex",
        height: MESSAGE_BAR_HEIGHT,
        minWidth: MESSAGE_BAR_HEIGHT,
        maxWidth: MESSAGE_BAR_HEIGHT,
        background: theme.palette.background1,
        color: theme.palette.neutral.dark,
        justifyContent: "center",
        alignItems: "center",
    },
    warnMessage: {
        background: theme.palette.warning.main,
        color: theme.palette.warning.contrastText,
    },
    infoMessage: {
        background: theme.palette.secondary.main,
        position: "relative",
        color: theme.palette.secondary.contrastText,
        "&::after": {
            content: "''",
            width: 0,
            borderStyle: "solid",
            borderWidth: "50px 20px 0 0",
            borderColor: theme.palette.secondary.main + " transparent transparent transparent",
            position: "absolute",
            left: "100%",
            top: 0,
        },
    },
    messageHalfWidth: { width: "calc(50% - 25px)" },
}));

export const MessageBar = () => {
    const classes = useStylesMessageBar();
    const p = useP();

    const { infoMessage, infoMessageViewed, warnMessage, warnMessageViewed, isVisible, dismiss } =
        useMessageBar();

    return (
        <div className={classNames(classes.root, { [classes.displayBar]: isVisible })}>
            {infoMessage && !infoMessageViewed && (
                <Message
                    content={infoMessage.content}
                    link={infoMessage.link}
                    icon={<CampaignOutlined />}
                    customClass={classNames(classes.infoMessage, {
                        [classes.messageHalfWidth]: warnMessage && !warnMessageViewed,
                    })}
                />
            )}
            {warnMessage && !warnMessageViewed && (
                <Message
                    content={warnMessage.content}
                    link={warnMessage.link}
                    icon={<WarningAmberOutlined />}
                    customClass={classNames(classes.warnMessage, {
                        [classes.messageHalfWidth]: infoMessage && !infoMessageViewed,
                    })}
                />
            )}
            {isVisible && (
                <ButtonBase
                    className={classes.closeBtn}
                    title={p.t("app.message_bar.hide_message")}
                    onClick={dismiss}
                >
                    <CloseIcon color="info" />
                </ButtonBase>
            )}
        </div>
    );
};
