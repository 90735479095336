import privilege_description from "./references/privilege_description";
import privilege_type from "./references/privilege_type";
import privilege_right from "./references/privilege_right";
import privilege_category from "./references/privilege_category";
import privilege_resource from "./references/privilege_resource";

const references = {
    privilege_description,
    privilege_type,
    privilege_right,
    privilege_category,
    privilege_resource,
};

export default references;
