import Popover from "@mui/material/Popover";
import makeStyles from "@mui/styles/makeStyles";
import Close from "mdi-material-ui/Close";
import HiIconButton from "@hipay/hipay-material-ui/HiIconButton";
import { ErrorBoundary } from "../../../app/components/common/ErrorBoundary";
import { ChooseAndOrganize } from "./ChooseAndOrganize";

export const useStylesOrderItemsPanel = makeStyles((theme) => ({
    menu: {
        width: 300,
        paddingTop: 12,
        paddingBottom: 12,
    },
    menuTitle: {
        fontSize: 20,
        fontWeight: 300,
        paddingLeft: 12,
        fontFamily: "Roboto, Helvetica, Arial, sans-serif",
        marginBottom: 16,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
    closeMenu: {
        width: 24,
        height: 24,
        float: "right",
        marginRight: 10,
        padding: "unset",
        marginTop: "-5px",
    },
}));

export const OrderItemsPanel = (props) => {
    const {
        anchorEl,
        updateOrderedItems,
        orderedItems,
        persistOrderedItems,
        filterSuggestions,
        defaultItems,
        itemsLabels,
        onClose,
        title,
    } = props;

    const classes = useStylesOrderItemsPanel();

    return (
        <Popover
            id="order-kpi-menu"
            anchorEl={anchorEl}
            anchorReference={"anchorEl"}
            anchorOrigin={{
                vertical: "center",
                horizontal: "center",
            }}
            transformOrigin={{
                vertical: "top",
                horizontal: "right",
            }}
            open={Boolean(anchorEl)}
            classes={{ paper: classes.menu }}
            onClose={onClose}
        >
            <div className={classes.menuTitle} id="order-kpi-title">
                <span>{title}</span>
                <HiIconButton className={classes.closeMenu} onClick={onClose} color="neutral">
                    <Close />
                </HiIconButton>
            </div>
            <ErrorBoundary>
                <ChooseAndOrganize
                    name="analytics"
                    updateOrderedItems={updateOrderedItems}
                    orderedItems={orderedItems}
                    persistOrderedItems={persistOrderedItems}
                    filterSuggestionsFn={filterSuggestions}
                    defaultItems={defaultItems}
                    itemsLabels={itemsLabels}
                    onClose={onClose}
                />
            </ErrorBoundary>
        </Popover>
    );
};
