import { Route } from "react-router-dom";
import { ErrorBoundary } from "./ErrorBoundary";

/**
 * Render Route with error boundary on child
 *
 * @param RouteComponent
 * @param render
 * @param contexts
 * @param routeProps
 * @returns {*}
 */
export const ErrorBoundaryRoute = ({
    component: RouteComponent,
    render,
    contexts,
    ...routeProps
}) => {
    let _render;
    if (RouteComponent) {
        _render = (props) => (
            <ErrorBoundary>
                <RouteComponent contexts={contexts} {...props} />
            </ErrorBoundary>
        );
    }
    if (render) {
        _render = (props) => (
            <ErrorBoundary>
                {render({
                    contexts,
                    ...props,
                })}
            </ErrorBoundary>
        );
    }

    return <Route {...routeProps} render={_render} />;
};
