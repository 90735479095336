export default {
    id: "invoice_currencies",
    endpoint: `${process.env.NX_INVOICE_API_URL}/invoice-currencies`,
    withSelectedAccounts: true,
    translate: true,
    reduceFunc: (previous, current) =>
        current["isocode"]
            ? [
                  ...previous,
                  {
                      id: current["isocode"],
                      label: "ref.invoice.currencies." + current["isocode"],
                  },
              ]
            : previous,
};
