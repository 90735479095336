import React from "react";
import PropTypes from "prop-types";
import { capitalize } from "@mui/material/utils";
import { HiTextField } from "@hipay/hipay-material-ui/HiForm";
import HiButton from "@hipay/hipay-material-ui/HiButton";
import moment from "moment-timezone";
import { withRouterStylesAndTranslate } from "../../../../../hoc/withRouterStylesAndTranslate";
import { withConfig } from "../../../../../services/config";
import { drySearchUrl } from "../../../../../utils/urls";
import { withInnerPage } from "../../../../../services/innerPage";

export const stylesFavoriteForm = (theme) => ({
    root: { paddingTop: 70 },
    headerItem: {
        display: "flex",
        alignItems: "center",
        padding: "10px 0",
    },
    headerLabel: {
        ...theme.typography.body2,
        color: theme.palette.text.secondary,
        marginRight: 4,
        width: "50%",
        textAlign: "right",
    },
    headerValue: {
        ...theme.typography.body2,
        color: theme.palette.text.primary,
        marginLeft: 4,
        width: "50%",
        textAlign: "left",
        display: "flex",
        alignItems: "center",
    },
    separator: {
        width: 36,
        backgroundColor: theme.palette.input.bottomLine,
        height: 1,
        margin: "auto",
    },
    form: {
        marginTop: 24,
        maxWidth: 500,
        margin: "auto",
        "@media(max-width: 532px)": { width: "calc(100% - 32px)" },
    },
    formField: {
        paddingBottom: 22,
        textAlign: "left",
    },
    submitButton: { width: "100%" },
});

class FavoriteFormClass extends React.Component {
    static propTypes = {
        /**
         * Le module de la recherche en cours
         */
        module: PropTypes.string.isRequired,
        /**
         * Le favori si il y en a un de selectionné
         */
        favoriteItem: PropTypes.object,
        /**
         * Fonction permettant d'appelé l'action correspondante (création, modification, suppression) pour mettre à jour le favori
         */
        onSubmitForm: PropTypes.func,
    };

    static defaultProps = { favoriteItem: null };

    maxFavoriteNameLength = 45;

    constructor(props) {
        super(props);

        this.state = {
            favoriteName: props.favoriteItem ? props.favoriteItem.name : "",
            favoriteNameError: false,
        };

        this.favoriteNameRef = React.createRef();
    }

    componentDidMount() {
        document.addEventListener("keydown", this.handleKeyDown);
        if (this.favoriteNameRef.current) {
            setTimeout(() => {
                this.favoriteNameRef.current.focus();
            }, 1);
        }
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.handleKeyDown);
    }

    handleKeyDown = (event) => {
        if (event.key === "Escape") {
            // close inner page
            this.props.closeInnerPage();
        } else if (event.key === "Enter") {
            if (event.currentTarget.id === "favorite-data-submit") {
                this.handleClickSubmit(event);
            }
        }
    };

    handleChange = (name) => (event) => {
        this.setState({ [name]: event.target.value });
    };

    handleResetFavoriteName = () => {
        this.setState({ favoriteName: "" });
    };

    handleBlurFavoriteName = () => {
        this.setState({ favoriteNameError: this.checkFavoriteNameError(this.state.favoriteName) });
    };

    checkFavoriteNameError = (favoriteName) => {
        return (
            favoriteName.length > this.maxFavoriteNameLength ||
            favoriteName.length === 0 ||
            (this.props.favoriteItem && this.props.favoriteItem.name === favoriteName)
        );
    };

    handleClickSubmit = (e) => {
        const { favoriteItem, location, module } = this.props;
        const { favoriteName } = this.state;

        e.stopPropagation();
        e.preventDefault();

        if (!this.checkFavoriteNameError(favoriteName)) {
            if (favoriteItem) {
                this.props.onSubmitForm({
                    ...favoriteItem,
                    name: favoriteName,
                });
            } else {
                this.props.onSubmitForm({
                    name: favoriteName,
                    module: module,
                    urlSearch: drySearchUrl(location.search),
                });
            }

            // close inner page
            this.props.closeInnerPage();
        }
    };

    render() {
        const { classes, p, favoriteItem, module, getConfigByModule } = this.props;
        const { favoriteName } = this.state;

        const configModule = getConfigByModule(module);

        let today;
        if (favoriteItem) {
            today = moment(favoriteItem.dateCreated);
        } else {
            today = moment();
        }

        const ModuleIcon = configModule.icon;

        return (
            <div className={classes.root}>
                <div id={"favorite-form-header"}>
                    <div id={"favorite-creation-date"} className={classes.headerItem}>
                        <div className={classes.headerLabel}>
                            {p.t("common.search.favorite.favorite_form.creation_date")}
                        </div>
                        <div className={classes.headerValue}>
                            {capitalize(today.format("dddd D MMMM YYYY"))}
                        </div>
                    </div>
                    <div id={"favorite-module"} className={classes.headerItem}>
                        <div className={classes.headerLabel}>
                            {p.t("common.search.favorite.favorite_form.module")}
                        </div>
                        <div className={classes.headerValue}>
                            <ModuleIcon fontSize="small" color="primary" />
                            {p.t(`menu.${module}.title`, { smart_count: 1 })}
                        </div>
                    </div>
                </div>
                <div className={classes.separator} />
                <div id="favorite-form" className={classes.form}>
                    <div className={classes.formField} id="favorite-favoriteName-field">
                        <HiTextField
                            ref={this.favoriteNameRef}
                            id="favorite-favoriteName"
                            name="favoriteName"
                            label={p.t("common.search.favorite.favorite_form.form.favoriteName")}
                            helperText={favoriteName.length + "/" + this.maxFavoriteNameLength}
                            onChange={this.handleChange("favoriteName")}
                            onReset={this.handleResetFavoriteName}
                            value={favoriteName}
                            HiInputProps={{
                                onSubmit: this.handleClickSubmit,
                                onBlur: this.handleBlurFavoriteName,
                                inputProps: { maxLength: this.maxFavoriteNameLength },
                            }}
                        />
                    </div>
                    <div className={classes.formField}>
                        <HiButton
                            id="favorite-data-submit"
                            color="primary"
                            variant="contained"
                            className={classes.submitButton}
                            onClick={this.handleClickSubmit}
                            disabled={this.checkFavoriteNameError(favoriteName)}
                        >
                            {favoriteItem
                                ? p.t("common.search.favorite.favorite_form.form.edit")
                                : p.t("common.search.favorite.favorite_form.form.save")}
                        </HiButton>
                    </div>
                </div>
            </div>
        );
    }
}

export const FavoriteForm = withRouterStylesAndTranslate(stylesFavoriteForm)(
    withConfig(withInnerPage(FavoriteFormClass))
);
