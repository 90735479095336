import { useMemo, useEffect, useState } from "react";
import PropTypes from "prop-types";
import makeStyles from "@mui/styles/makeStyles";
import { useUser } from "../../../services/user";
import qs from "query-string";
import { useSelector, useDispatch } from "react-redux";
import Filter from "mdi-material-ui/Filter";
import HiDynamicSelectField from "@hipay/hipay-material-ui/HiSelect/HiDynamicSelectField";
import HiNestedSelectField from "@hipay/hipay-material-ui/HiSelect/HiNestedSelectField";
import HiIcon from "@hipay/hipay-material-ui/HiIcon";
import HiPin from "@hipay/hipay-material-ui/HiPin";
import HiButton from "@hipay/hipay-material-ui/HiButton";
import * as appActions from "../../actions/appActions";
import { hasOneValue, hasSameValues, removeDuplicatedArrayElement } from "../../../utils/arrays";
import {
    buildAccountDisplayPropsFromEntities,
    buildAccountAndBusinessMapFromAccountDatas,
    filterEntitiesByIdList,
    filterBusinessEntitiesByAccountIdList,
    getSelectedBusinessIdMapFromSelectedAccountIdList,
    buildHierarchicAccountList,
} from "../../../utils/entities";
import { foldAccents } from "../../../utils/strings";
import { useApi } from "../../../services/api";
import { useAsync } from "react-async";
import { useP } from "../../../services/i18n";

const useStylesAccountField = makeStyles((theme) => ({
    accountFilterBlock: { textAlign: "center" },
    accountFilterSpan: { margin: "0 4px" },
    labelIcon: { marginRight: 10 },
    selectIconLabel: {
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        paddingRight: 16,
        ...theme.typography.body2,
        display: "inline-flex",
        width: "100%",
    },
    filterIcon: { fontSize: 16 },
}));

let fetchAccountsTimeout;

export const AccountField = (props) => {
    const {
        id,
        name,
        formField,
        helperIcon,
        helperText,
        onChange,
        onSubmit,
        selectedAccountIdList,
        restrictedFilters,
        staticPosition,
    } = props;

    const accountEntities = new Map(
        Object.entries(useSelector((state) => state.app.global.entities.account))
    );
    const businessEntities = new Map(
        Object.entries(useSelector((state) => state.app.global.entities.business))
    );
    const user = useUser();

    const showAllAccounts = useMemo(() => {
        return accountEntities.size <= 40;
    }, [accountEntities]);

    const [page, setPage] = useState(1);
    const [nextPage, setNextPage] = useState();
    const [onlySelected, setOnlySelected] = useState(false);
    const [loading, setLoading] = useState(false);
    const [searchValue, setSearchValue] = useState("");
    const [selectedAccountIds, setSelectedAccountIds] = useState(selectedAccountIdList);
    const [selectedBusinessIdList, setSelectedBusinessIdList] = useState(
        getSelectedBusinessIdMapFromSelectedAccountIdList(selectedAccountIdList, businessEntities)
    );
    const [accountList, setAccountList] = useState(
        user.internalstaff || !showAllAccounts
            ? filterEntitiesByIdList(accountEntities, selectedAccountIdList)
            : accountEntities
    );
    const [businessList, setBusinessList] = useState(
        user.internalstaff || !showAllAccounts
            ? filterBusinessEntitiesByAccountIdList(businessEntities, selectedAccountIdList)
            : businessEntities
    );

    useEffect(() => {
        if (!hasSameValues(selectedAccountIdList, selectedAccountIds)) {
            setSelectedAccountIds(selectedAccountIdList);
            setSelectedBusinessIdList(
                filterBusinessEntitiesByAccountIdList(businessEntities, selectedAccountIdList)
            );
            if (user.internalstaff || showAllAccounts) {
                setAccountList(filterEntitiesByIdList(accountEntities, selectedAccountIdList));
                setBusinessList(
                    filterBusinessEntitiesByAccountIdList(businessEntities, selectedAccountIdList)
                );
            }
        }
    }, []);

    const dispatch = useDispatch();

    const persistAccountAndBusinessEntities = (_accountEntities, _businessEntities) => {
        dispatch(
            appActions.persistSearchAccountAndBusinessEntities(_accountEntities, _businessEntities)
        );
    };
    const fetchSelectedAccountList = (accountIdList) =>
        dispatch(appActions.fetchSelectedAccountList(accountIdList));

    const classes = useStylesAccountField(props);
    const p = useP();

    const { get } = useApi();
    const {
        data: accountData,
        isPending: accountPending,
        error: accountError,
        run: fetchAccounts,
        cancel: cancelFetchAccounts,
    } = useAsync({
        deferFn: get,
        url: (search) => `/accounts?${search}`,
    });

    useEffect(() => {
        if (
            !loading &&
            user.internalstaff &&
            selectedAccountIds.length > 0 &&
            !hasOneValue(selectedAccountIds, "_every")
        ) {
            // check if some selected accounts in aren't loaded in account entities then request them
            if (!selectedAccountIds.every((accountId) => accountId in accountEntities)) {
                fetchSelectedAccountList(selectedAccountIds).then((response) => {
                    const { account, business } = buildAccountAndBusinessMapFromAccountDatas(
                        response.payload
                    );
                    setAccountList(account);
                    setBusinessList(business);
                    setSelectedBusinessIdList(
                        getSelectedBusinessIdMapFromSelectedAccountIdList(
                            selectedAccountIds,
                            business
                        )
                    );
                    setLoading(false);
                });
                setLoading(true);
            } else {
                setLoading(false);
            }
        }
    }, []);

    // Update selected accounts directly from props
    useEffect(() => {
        setSelectedAccountIds(selectedAccountIdList);
    }, [selectedAccountIdList]);

    const handleMoreResults = () => {
        if (!onlySelected && searchValue.length >= 2 && user.internalstaff) {
            requestAccountsFromSearch(searchValue, page);
        }
    };

    useEffect(() => {
        if (accountData) {
            let _accounts = [];
            let hasNew = false;
            if (!page || page === 1) {
                _accounts = accountData;
                hasNew = true;
            } else {
                // On vérifie que la recherche retourne de nouveaux résultats, pour éviter de recharger x pages pour rien si le business est selectionné
                for (let i = 0; i < accountData.length; i++) {
                    if (!accountList.has(accountData[i].accountId.toString())) {
                        hasNew = true;
                        break;
                    }
                }
                _accounts = Array.from(accountList.values()).concat(accountData);
            }
            const { account, business } = buildAccountAndBusinessMapFromAccountDatas(_accounts);
            setAccountList(account);
            setBusinessList(business);
            setLoading(false);
            setNextPage(accountData.length >= 10 && hasNew ? "auto" : "none");
            setPage(page + 1);
        }
    }, [accountData, accountError]);

    /**
     * Internal User
     * Request accounts from search query
     * cancel previous request when a new one is thrown
     */
    const requestAccountsFromSearch = (query, _page) => {
        // Cancel previous pending request
        if (accountPending) {
            cancelFetchAccounts();
        }

        clearTimeout(fetchAccountsTimeout);
        fetchAccountsTimeout = setTimeout(() => {
            setLoading(true);
            let search = "";

            if (query && query !== "") {
                search += qs.stringify({ name: query });
            }
            if (_page && _page > 1) {
                search += `&p=${_page}`;
            } else {
                setAccountList(new Map());
                setBusinessList(new Map());
            }

            fetchAccounts(search);

            setNextPage("none");
            setPage(page + 1);
        }, 400);
    };

    /**
     * Show only selected Businesses / Accounts
     */
    const handleOnlySelectFilterClick = () => {
        setOnlySelected(!onlySelected);
    };

    /**
     * No query -> reset search
     * 3+ char query
     *    -> (internal user) request for accounts
     *    -> (merchant user) filter account list
     * @param e
     * @param query
     */
    const handleSearch = (e, query) => {
        if (query === undefined || query.length < 2) {
            handleSearchReset();
        } else if (user.internalstaff) {
            requestAccountsFromSearch(query, 1);
        } else {
            const searchedAccounts = filterAccountsFromSearch(query);
            setAccountList(searchedAccounts.accountList);
            setBusinessList(searchedAccounts.businessList);
        }
        if (page > 1) {
            setPage(1);
        }
        setSearchValue(query);
    };

    /**
     * Filter account list and business list on account name and business denomination
     * @param query
     */
    const filterAccountsFromSearch = (query) => {
        let businessEntityList = new Map();
        for (let [businessId, business] of businessEntities) {
            if (
                foldAccents(business["denomination"].toString().toLowerCase()).search(
                    foldAccents(query.toLowerCase())
                ) !== -1
            ) {
                businessEntityList.set(businessId, business);
            }
        }

        let accountEntityList = new Map();
        let businessEntityListForAccount = new Map();
        for (let [accountId, account] of accountEntities) {
            if (businessEntityList.has(account.businessId)) {
                accountEntityList.set(accountId, account);
            } else if (
                foldAccents(account.name.toString().toLowerCase()).search(
                    foldAccents(query.toLowerCase())
                ) !== -1
            ) {
                accountEntityList.set(accountId, account);
                businessEntityListForAccount.set(
                    account.businessId,
                    businessEntities.get(account.businessId.toString())
                );
            }
        }
        return {
            accountList: accountEntityList,
            businessList: new Map([...businessEntityList, ...businessEntityListForAccount]),
        };
    };

    /**
     * INTERNAL USER
     * Reset state but keep selected id list
     * only get currently selected account and relative business from redux
     */
    const handleSearchReset = () => {
        setAccountList(
            user.internalstaff || !showAllAccounts
                ? filterEntitiesByIdList(accountEntities, selectedAccountIdList)
                : accountEntities
        );
        setBusinessList(
            user.internalstaff || !showAllAccounts
                ? filterBusinessEntitiesByAccountIdList(businessEntities, selectedAccountIdList)
                : businessEntities
        );
        setSearchValue("");
        setLoading(false);
        setNextPage("auto");
    };

    const handleChange = (event, value, item) => {
        if (item.id === "_every") {
            deselectAllAccount();
        } else if (item.isBusiness) {
            handleSelectBusiness(item.id);
        } else {
            handleSelectAccount(item.id);
        }
    };

    /**
     * Deselect all account (equivalent to select "All accounts")
     */
    const deselectAllAccount = () => {
        setSelectedAccountIds(["_every"]);
        setSelectedBusinessIdList([]);
        onChange(["_every"]);
    };

    /**
     * Add or remove all relative accounts from selected list
     * -- if business was selected, remove all relative accounts from selected list
     * -- if business was unselected, add all relative accounts to selected list and deduplicate them
     *
     * @param businessId
     */
    const handleSelectBusiness = (businessId) => {
        let business = businessList.get(businessId);
        if (!business) {
            // Get from store
            business = businessEntities.get(businessId);
        }
        if (business !== undefined) {
            if (selectedBusinessIdList.includes(business.businessId)) {
                // DESELECT BUSINESS AND RELATIVE ACCOUNT
                onChange(selectedAccountIds.filter((aId) => !business.accountIdList.includes(aId)));
                setSelectedAccountIds(
                    selectedAccountIds.filter((aId) => !business.accountIdList.includes(aId))
                );
                setSelectedBusinessIdList(
                    selectedBusinessIdList.filter((bId) => bId !== business.businessId)
                );
                setOnlySelected(
                    selectedAccountIds.filter((aId) => !business.accountIdList.includes(aId))
                        .length > 0
                        ? onlySelected
                        : false
                );
            } else {
                // SELECT BUSINESS AND RELATIVE ACCOUNT
                let accountIdsToFetch = business.accountIdList.filter(
                    (accountId) => !accountEntities.has(accountId)
                );
                let newAccountList = accountList;
                if (accountIdsToFetch.length) {
                    setLoading(true);
                    fetchSelectedAccountList(business.accountIdList)
                        .then((response) => {
                            for (let i = 0; i < response.payload.length; i++) {
                                response.payload[i].businessId = business.businessId;
                                newAccountList.set(
                                    response.payload[i].accountId.toString(),
                                    response.payload[i]
                                );
                            }
                            setAccountList(newAccountList);
                            setLoading(false);
                        })
                        .catch((error) => {
                            //console.log(error);
                        });
                } else {
                    for (let i = 0; i < business.accountIdList.length; i++) {
                        newAccountList.set(
                            business.accountIdList[i].toString(),
                            accountEntities.get(business.accountIdList[i].toString())
                        );
                    }
                    setAccountList(newAccountList);
                }
                onChange(
                    hasOneValue(selectedAccountIds, "_every")
                        ? business.accountIdList
                        : removeDuplicatedArrayElement([
                              ...selectedAccountIds,
                              ...business.accountIdList,
                          ])
                );
                setSelectedAccountIds(
                    hasOneValue(selectedAccountIds, "_every")
                        ? business.accountIdList
                        : removeDuplicatedArrayElement([
                              ...selectedAccountIds,
                              ...business.accountIdList,
                          ])
                );
                setSelectedBusinessIdList([...selectedBusinessIdList, business.businessId]);
            }
        }
    };

    /**
     * Add or remove accounts from selected list
     * -- if business selected accounts count is equal to all business accounts count then also add business id to selectedBusinessIdList
     * @param accountId
     */
    const handleSelectAccount = (accountId) => {
        // Get from state
        let account = accountList.get(accountId);
        if (!account) {
            // Get from store
            account = accountEntities.get(accountId);
        }
        if (account !== undefined) {
            if (selectedAccountIds.includes(account.accountId.toString())) {
                // DESELECT ACCOUNT
                onChange(
                    selectedAccountIds.filter(
                        (aId) => aId.toString() !== account.accountId.toString()
                    )
                );
                setSelectedAccountIds(
                    selectedAccountIds.filter(
                        (aId) => aId.toString() !== account.accountId.toString()
                    )
                );
                setSelectedBusinessIdList(
                    selectedBusinessIdList.filter(
                        (bId) => bId.toString() !== account.businessId.toString()
                    )
                );
                setOnlySelected(
                    selectedAccountIds.filter((aId) => aId !== account.accountId).length > 0
                        ? onlySelected
                        : false
                );
            } else {
                // SELECT ACCOUNT
                onChange(
                    hasOneValue(selectedAccountIds, "_every")
                        ? [accountId.toString()]
                        : [...selectedAccountIds, account.accountId.toString()]
                );

                let business = { ...businessList.get(account.businessId) };
                // Put account on store
                persistAccountAndBusinessEntities(
                    { [account.accountId]: account },
                    { [account.businessId]: business }
                );

                setSelectedAccountIds(
                    hasOneValue(selectedAccountIds, "_every")
                        ? [accountId.toString()]
                        : [...selectedAccountIds, account.accountId.toString()]
                );
                if (
                    business.accountIdList.every((_accountId) =>
                        [...selectedAccountIds, account.accountId.toString()].includes(
                            _accountId.toString()
                        )
                    )
                ) {
                    setSelectedBusinessIdList([...selectedBusinessIdList, account.businessId]);
                }
            }
        }
    };

    const handleClose = () => {
        if (onlySelected) {
            setOnlySelected(false);
        }
    };

    // Par défaut, on affiche les comptes du state selon la recherche effectuée
    let accountListDisplayed = accountList;
    let businessListDisplayed = businessList;

    // Si on souhaite afficher que les sélectionnés, on les prend du store
    if (onlySelected) {
        accountListDisplayed = filterEntitiesByIdList(accountEntities, selectedAccountIds);
        businessListDisplayed = filterBusinessEntitiesByAccountIdList(
            businessEntities,
            selectedAccountIds
        );
    }

    // build account options for select (with highlight on search value)
    let accountOptions = buildHierarchicAccountList(
        accountListDisplayed,
        businessListDisplayed,
        onlySelected ? "" : searchValue,
        selectedAccountIds,
        onlySelected
    );

    if (!onlySelected) {
        accountOptions.unshift({
            id: "_every",
            label: p.t("account_selector.everywhere"),
            type: "icon",
            icon: "fa-archive",
            ...(!user.internalstaff && { info: accountEntities.size.toString() }),
        });
    }

    let alert;
    if (restrictedFilters && restrictedFilters.length > 0) {
        alert = {
            title: p.t("alert.information"),
            content: p.t("alert.restricted_filters.form.body", {
                filters_name: restrictedFilters.join(", "),
                smart_count: restrictedFilters.length,
            }),
            submitButton: p.t("alert.action.ok"),
            cancelButton: p.t("alert.action.cancel"),
        };
    }

    // deduce selected id list from specific value, selected account & selected business
    const allSelectedIdList = hasOneValue(selectedAccountIds, "_every")
        ? ["_every"]
        : [...selectedAccountIds, ...selectedBusinessIdList];

    const onlySelectFilter = (
        <div className={classes.accountFilterBlock}>
            <HiButton
                id="only-selected"
                onClick={handleOnlySelectFilterClick}
                color={onlySelected ? "primary" : "neutral"}
                disabled={
                    allSelectedIdList.length === 0 || hasOneValue(allSelectedIdList, "_every")
                }
            >
                <Filter className={classes.filterIcon} />
                <span className={classes.accountFilterSpan}>
                    {p.t("account_selector.selected")}
                </span>
                {selectedAccountIds.length > 0 && !hasOneValue(selectedAccountIds, "_every") && (
                    <HiPin color="primary">{selectedAccountIds.length}</HiPin>
                )}
            </HiButton>
        </div>
    );

    const accountDisplayProps = buildAccountDisplayPropsFromEntities(
        p,
        selectedAccountIds,
        accountList,
        businessList
    );

    const inputValue = selectedAccountIds.length ? (
        <span className={classes.selectIconLabel}>
            <HiIcon className={classes.labelIcon} icon={accountDisplayProps.icon} />
            {accountDisplayProps.label}
        </span>
    ) : (
        <span />
    );

    const label = formField.label || p.t("attributes.transaction.account_name.label");

    const hideOptions = loading && (!page || page === 1);

    return user.internalstaff || !showAllAccounts ? (
        <HiDynamicSelectField
            id={id}
            name={name}
            classes={classes}
            type="text"
            multiple
            searchable
            loading={loading}
            value={allSelectedIdList}
            searchValue={searchValue}
            options={hideOptions ? [] : accountOptions}
            label={label}
            onChange={handleChange}
            startAdornment={onlySelectFilter}
            onSubmit={onSubmit}
            onSearch={handleSearch}
            staticPosition={staticPosition}
            nextPage={nextPage}
            onNextPage={handleMoreResults}
            translations={{
                // hack to show only account count in input
                one_item_selected: accountDisplayProps.label,
                n_items_selected: accountDisplayProps.label,
                search: p.t("account_selector.search"),
            }}
            hiSelectInputProps={{ value: inputValue }}
            hiSearchInputProps={{ disabled: onlySelected }}
            onClose={handleClose}
            alert={alert}
            helperIcon={helperIcon}
            helperText={helperText}
        />
    ) : (
        <HiNestedSelectField
            id={id}
            name={name}
            classes={classes}
            type="text"
            multiple
            searchable={accountList.size > 10}
            loading={loading}
            value={allSelectedIdList}
            searchValue={undefined}
            options={accountOptions}
            label={label}
            onChange={handleChange}
            startAdornment={onlySelectFilter}
            onSubmit={onSubmit}
            staticPosition={staticPosition}
            translations={{
                // hack to show only account count in input
                one_item_selected: accountDisplayProps.label,
                n_items_selected: accountDisplayProps.label,
                search: p.t("account_selector.search"),
            }}
            hiSelectInputProps={{ value: inputValue }}
            hiSearchInputProps={{ disabled: onlySelected }}
            onClose={handleClose}
            alert={alert}
            helperIcon={helperIcon}
            helperText={helperText}
        />
    );
};

AccountField.propTypes = {
    classes: PropTypes.object,
    id: PropTypes.string.isRequired,
    helperIcon: PropTypes.bool,
    helperText: PropTypes.string,
    name: PropTypes.string.isRequired,
    formField: PropTypes.object,
};

AccountField.defaultProps = {
    formField: {},
    staticPosition: false,
};
