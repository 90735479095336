import { MenuItemType, MenuSubItemType } from "./menu-items";

/**
 * Transform menuItems list to key/value object
 * It is used to access directly to items by key
 */
export const transformMenuItemsListToObject = (menuItems: MenuItemType[]) => {
    const itemsObject: Record<
        string,
        Omit<MenuItemType, "subItems"> & { subItems?: Record<string, MenuSubItemType> }
    > = {};

    menuItems.forEach(({ subItems, ...item }) => {
        const subItemsAsObject: Record<string, MenuSubItemType> = {};
        if (subItems) {
            subItems.forEach((subItem) => {
                subItemsAsObject[subItem.key] = { ...subItem };
            });

            itemsObject[item.key] = {
                ...item,
                subItems: subItemsAsObject,
            };
        } else {
            itemsObject[item.key] = { ...item };
        }
    });

    return itemsObject;
};
