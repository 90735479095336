import React from "react";
import PropTypes from "prop-types";
import Target from "mdi-material-ui/Target";
import { translate } from "../../../../../../services/i18n/translate";
import classNames from "classnames";
import { isMobile } from "react-device-detect";

class TargetFieldSuggestionItem extends React.Component {
    static propTypes = {
        suggestion: PropTypes.object.isRequired,
        module: PropTypes.string,
    };

    render() {
        const { module, suggestion, p } = this.props;

        return (
            <div className="target item">
                {!isMobile && <Target />}
                <div
                    className={classNames({
                        "text-div": !isMobile,
                        "text-div-mobile": isMobile,
                    })}
                >
                    {p.t(`attributes.${module}.${suggestion.value}.suggestLabel`)}
                </div>
            </div>
        );
    }
}

export default translate(TargetFieldSuggestionItem);
