import HiIconButton from "@hipay/hipay-material-ui/HiIconButton";
import HiIcon from "@hipay/hipay-material-ui/HiIcon";
import makeStyles from "@mui/styles/makeStyles";
import { useP } from "../../../../services/i18n";

export const useStylesActBtn = makeStyles((theme) => ({
    root: {
        height: "100%",
        lineHeight: "48px",
        display: "inline-block",
        zIndex: 1,
        textAlign: "right",
    },
    icon: (props) => {
        return {
            fontSize: 24,
            color: props.isActive ? theme.palette.primary.main : theme.palette.neutral.main,
        };
    },
}));

export const ActionBtn = (props) => {
    const classes = useStylesActBtn(props);
    const p = useP();
    const { icon, id, isActive, name, onClick, title, ...others } = props;

    let buttonIcon;
    if (icon) {
        const iconProps = { className: classes.icon };
        if (typeof icon === "string") {
            buttonIcon = <HiIcon icon={icon} {...iconProps} />;
        } else if (typeof icon === "object") {
            buttonIcon = { ...icon };
            buttonIcon.props = {
                ...buttonIcon.props,
                ...iconProps,
            };
        }
    }

    return (
        <div className={classes.root} {...(title && { title: p.t(title) })}>
            <HiIconButton
                id={id}
                name={name}
                onClick={onClick}
                {...others}
                color={isActive ? "primary" : "neutral"}
            >
                {buttonIcon}
            </HiIconButton>
        </div>
    );
};
