import attributes from "./attributes";
import { defaultFields } from "./form";
import references from "../references";
import AppInvoice from "../AppInvoice";
import { FEATURE_SEARCH_SELECTION } from "@console/core";
import { LeaderboardOutlined } from "@mui/icons-material";

/**
 * Config
 *
 * On retrouve ici la configuration du module.
 *  - Ses attributes
 *  - La config du module selector de la quicksearch
 *  - Les default fields presents dans le quickForm & par défaut sur le formulaire de recherche (s'il est activé)
 *    ainsi que leurs valeurs par défaut (ou null)
 *  - La liste des features qui sont activées pour ce module
 *
 */
export const moduleConfig = {
    id: "invoice", // module key
    attributes: attributes, // module attributes for search
    defaultFormFields: defaultFields, // default attributes on search
    icon: LeaderboardOutlined, // module icon on module selector
    label: "menu.invoice.title", // translation path
    path: "invoices", // module uri from search
    route: "[prefix]/invoices", // module uri from quicksearch
    features: ["form", FEATURE_SEARCH_SELECTION], // module features enabled
    references: references, // module references
    apiUrl: process.env.NX_INVOICE_API_URL,
    apiTableParams: true, // params must be send like status[]=1&status[]=2 instead of staus=1--2 in transaction api
    suggestRoute: "/_suggest?",
    acl: {
        module: [
            {
                module: "account",
                controller: "invoicing",
            },
        ],
    },
};

export const moduleRouting = [
    {
        key: "invoices", // module key
        path: ["/invoices", "/accounts-:ids/invoices"], // module matching uri
        component: AppInvoice, // module parent component
    },
];
