import React from "react";
import PropTypes from "prop-types";
import HiFormLabel from "@hipay/hipay-material-ui/HiForm/HiFormLabel";
import HiSelect from "@hipay/hipay-material-ui/HiSelect";
import HiFormControl from "@hipay/hipay-material-ui/HiForm/HiFormControl";
import HiRadio from "@hipay/hipay-material-ui/HiRadio";
import HiLoader from "@hipay/hipay-material-ui/HiLoader";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
    loader: {
        marginTop: "8px",
        marginLeft: "auto",
        marginRight: "auto",
        width: "1px",
    },
}));

export const FormFieldRadioToSelect = (props) => {
    const { legend, value, options, loading, onChange, radioColor = "primary" } = props;

    const classes = useStyles();

    return (
        <>
            <HiFormLabel component="legend">{legend}</HiFormLabel>

            {loading && <HiLoader className={classes.loader} />}
            {!loading && options.length >= 6 && (
                <HiSelect value={value} onChange={onChange} options={options} multiple={false} />
            )}
            {!loading && options.length < 6 && (
                <HiFormControl component="fieldset">
                    <RadioGroup value={value} onChange={onChange}>
                        {options.map((option) => (
                            <FormControlLabel
                                control={<HiRadio color={radioColor} />}
                                label={option.label}
                                value={option.value}
                            />
                        ))}
                    </RadioGroup>
                </HiFormControl>
            )}
        </>
    );
};

FormFieldRadioToSelect.propTypes = {
    legend: PropTypes.string,
    value: PropTypes.string,
    options: PropTypes.arrayOf(
        PropTypes.exact({
            id: PropTypes.string,
            label: PropTypes.string,
            value: PropTypes.string,
        })
    ),
    loading: PropTypes.bool,
    onChange: PropTypes.func,
};
