import locales from "./static_datas/verification_locales";
import onboardingStatuses from "./static_datas/onboardingStatuses";
import onboardingTypes from "./onboardingTypes";
import kycDecision from "./kycDecision";
import riskDecisionLevels from "./riskDecisionLevels";

export default {
    locales,
    onboardingStatuses,
    onboardingTypes,
    kycDecision,
    riskDecisionLevels,
};
