import { AppAuthentication } from "../index";

export const moduleConfig = {
    id: "authentication",
    attributes: {},
    defaultFormFields: {},
    icon: "hi_user",
    label: "modules.authentication.title",
    path: "authentication",
    route: "[prefix]/login",
    features: [],
    api: process.env.NX_USER_API_URL,
};

export const moduleRouting = [
    {
        key: "authentication",
        path: ["/login", "/logout", "/login/sso", "/tpp/redirect"],
        component: AppAuthentication,
    },
];
