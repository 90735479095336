import withStyles from "@mui/styles/withStyles";

const styles = (theme) => ({
    root: {
        lineHeight: "initial",
        textAlign: "justify",
        whiteSpace: "nowrap",
        padding: "4px",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
});

const NavLabelClass = (props) => {
    const { classes, label, title } = props;

    return (
        <div className={classes.root} title={title}>
            <span dangerouslySetInnerHTML={{ __html: label }} />
        </div>
    );
};

export const NavLabel = withStyles(styles)(NavLabelClass);
