import { AppConfig, AppVendors, AppPaymentOrders, AppBatches } from "../index";

import attributesVendors from "./mkp_vendors/attributes";
import { defaultFields as defaultFieldsVendors } from "./mkp_vendors/form";
import referencesVendors from "./mkp_vendors/references";

import attributesPaymentOrders from "./mkp_payment_orders/attributes";
import { defaultFields as defaultFieldsPaymentOrders } from "./mkp_payment_orders/form";
import referencesPaymentOrders from "./mkp_payment_orders/references";

import attributesBatches from "./mkp_batches/attributes";
import { defaultFields as defaultFieldsBatches } from "./mkp_batches/form";
import referencesBatches from "./mkp_batches/references";

import referencesConfig from "./mkp_config/references";

import Store from "@mui/icons-material/Store";

/**
 * Config
 *
 * On retrouve ici la configuration du module.
 *  - Ses attributes
 *  - La config du module selector de la quicksearch
 *  - Les default fields presents dans le quickForm & par défaut sur le formulaire de recherche (s'il est activé)
 *    ainsi que leurs valeurs par défaut (ou null)
 *  - La liste des features qui sont activées pour ce module
 *
 */
export const moduleConfig = {
    id: "mkp_config", // module key
    attributes: null,
    defaultFormFields: null, // default attributes on search
    icon: Store,
    label: "menu.mkp_config.title", // translation path
    path: "marketplaces/:mkp_id/config", // module uri from search
    route: "[prefix]/marketplaces/:mkp_id/config", // module uri from quicksearch
    features: [], // module features enabled
    featureFlags: "marketplace:enable-module",
    references: referencesConfig,
    apiUrl: null,
    suggestRoute: null,
    acl: {
        module: [
            {
                module: "marketplace",
                controller: "cashout",
                action: "view",
            },
        ],
    },
};

export const moduleConfigVendors = {
    id: "mkp_vendors", // module key
    attributes: attributesVendors, // module attributes for search
    defaultFormFields: defaultFieldsVendors, // default attributes on search
    icon: Store,
    label: "menu.mkp_vendors.title", // translation path
    route: "[prefix]/marketplaces/:mkp_id/vendors", // module uri from quicksearch
    features: [], // module features enabled
    featureFlags: "marketplace:enable-module",
    references: referencesVendors, // module references
    apiUrl: process.env.NX_MARKETPLACE_API_URL,
    suggestRoute: null,
    acl: {
        module: [
            {
                module: "marketplace",
                controller: "cashout",
                action: "view",
            },
        ],
    },
};

export const moduleConfigPaymentOrders = {
    id: "mkp_payment_orders", // module key
    attributes: attributesPaymentOrders, // module attributes for search
    defaultFormFields: defaultFieldsPaymentOrders, // default attributes on search
    icon: Store,
    label: "menu.mkp_payment_orders.title", // translation path
    route: "[prefix]/marketplaces/:mkp_id/payment-orders", // module uri from quicksearch
    features: [], // module features enabled
    featureFlags: "marketplace:enable-module",
    references: referencesPaymentOrders, // module references
    apiUrl: process.env.NX_MARKETPLACE_API_URL,
    suggestRoute: null,
    acl: {
        module: [
            {
                module: "marketplace",
                controller: "cashout",
                action: "view",
            },
        ],
    },
};

export const moduleConfigBatches = {
    id: "mkp_batches", // module key
    attributes: attributesBatches, // module attributes for search
    defaultFormFields: defaultFieldsBatches, // default attributes on search
    icon: Store,
    label: "menu.mkp_batches.title", // translation path
    route: "[prefix]/marketplaces/:mkp_id/batches", // module uri from quicksearch
    features: [], // module features enabled
    featureFlags: "marketplace:enable-module",
    references: referencesBatches, // module references
    apiUrl: process.env.NX_MARKETPLACE_API_URL,
    suggestRoute: null,
    acl: {
        module: [
            {
                module: "marketplace",
                controller: "cashout",
                action: "view",
            },
        ],
    },
};

export const moduleRouting = [
    {
        key: "mkp_vendors",
        path: [
            "/marketplaces/:mkp_id/vendors",
            "/accounts-:ids/marketplaces/:mkp_id/vendors",
            "/marketplaces/:mkp_id/vendors/:vendor_id",
            "/accounts-:ids/marketplaces/:mkp_id/vendors/:vendor_id",
        ],
        component: AppVendors,
    },
    {
        key: "mkp_payment_orders",
        path: [
            "/marketplaces/:mkp_id/payment-orders",
            "/marketplaces/:mkp_id/payment-orders/:payment_order_id",
            "/accounts-:ids/marketplaces/:mkp_id/payment-orders",
            "/accounts-:ids/marketplaces/:mkp_id/payment-orders/:payment_order_id",
        ],
        component: AppPaymentOrders,
    },
    {
        key: "mkp_batches",
        path: [
            "/marketplaces/:mkp_id/batches",
            "/marketplaces/:mkp_id/batches/:batch_id",
            "/accounts-:ids/marketplaces/:mkp_id/batches",
            "/accounts-:ids/marketplaces/:mkp_id/batches/:batch_id",
        ],
        component: AppBatches,
    },
    {
        key: "mkp_config",
        path: [
            "/marketplaces",
            "/marketplaces/:mkp_id/config",
            "/accounts-:ids/marketplaces",
            "/accounts-:ids/marketplaces/:mkp_id/config",
        ],
        component: AppConfig,
    },
];
