const three_ds_is_exempted = {
    id: "three_ds_is_exempted",
    data: [
        {
            id: "1",
            label: "ref.transaction.three_ds_is_exempted.true",
        },
    ],
    translate: true,
};

export default three_ds_is_exempted;
