import React from "react";
import PropTypes from "prop-types";
import ImageFilterCenterFocusWeak from "mdi-material-ui/ImageFilterCenterFocusWeak";
import { translate } from "../../../../../../services/i18n/translate";
import classNames from "classnames";
import { isMobile } from "react-device-detect";

class OperatorSuggestionItem extends React.Component {
    static propTypes = { suggestion: PropTypes.object.isRequired };

    render() {
        const { suggestion, p } = this.props;

        return (
            <div className="operator item">
                {!isMobile && <ImageFilterCenterFocusWeak />}
                <div
                    className={classNames({
                        "text-div": !isMobile,
                        "text-div-mobile": isMobile,
                    })}
                >
                    {p.t(`app.qsearch.operators.${suggestion.value}`)}
                </div>
            </div>
        );
    }
}

export default translate(OperatorSuggestionItem);
