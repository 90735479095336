import { useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { useInterval } from "../../hooks";
import { HiLogo } from "@hipay/design-system/components";

const useStylesAppLoading = makeStyles((theme) => ({
    root: {
        backgroundColor: "#F5F5F5",
        width: "100vw",
        height: "100vh",
        "&>div": { transition: "opacity 1400ms ease-in-out" },
        display: "flex",
        "flex-direction": "column",
        "align-items": "center",
        "justify-content": "center",
    },
    fadeIn: {
        opacity: "0.16",
        width: 250,
        maxWidth: "80%",
    },
    fadeOut: {
        opacity: "1",
        width: 250,
        maxWidth: "80%",
    },
    logo: {
        width: "100%",
    },
    message: {
        color: "#2C0A64",
        width: "fit-content",
    },
}));

/**
 * AppLoading
 */
export function AppLoading({ message = "" }) {
    const classes = useStylesAppLoading();

    const [toggle, setToggle] = useState(false);

    useInterval(() => {
        setToggle(!toggle);
    }, 1000);

    return (
        <div className={classes.root}>
            <div className={toggle ? classes.fadeIn : classes.fadeOut}>
                <HiLogo className={classes.logo} />
            </div>
            <div className={classes.message}>{message}</div>
        </div>
    );
}
