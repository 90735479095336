import { Typography } from "@mui/material";

/**
 * Block that display a subtitle in the menu
 */
const MenuBlockTitle = ({ className, label }: { className?: string; label: string }) => {
    return (
        <div className={className}>
            <Typography
                variant="body3"
                fontWeight="600"
                component="div"
                color="white"
                sx={{
                    mb: 2,
                    textTransform: "uppercase",
                }}
            >
                {label}
            </Typography>
        </div>
    );
};

export default MenuBlockTitle;
