import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles({
    root: {
        "@media (max-width: 1023px)": { margin: "64px auto" },
        "@media (min-width: 1023px)": { "&>img": { display: "none" } },
        width: 360,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
});
