import React from "react";
import PropTypes from "prop-types";
import withStyles from "@mui/styles/withStyles";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import keycode from "keycode";
import { translate } from "../../../../../services/i18n/translate";
import classnames from "classnames";
import HiCheckbox from "@hipay/hipay-material-ui/HiCheckbox";
import HiPin from "@hipay/hipay-material-ui/HiPin";
import { escapeHTML } from "@hipay/hipay-material-ui/utils/helpers";
import { highlightString } from "../../../../../utils/strings";

const styleSheet = (theme) => ({
    listItem: {
        paddingTop: 8,
        paddingBottom: 8,
        cursor: "default",
    },
    selected: { backgroundColor: theme.palette.background2 },
    subListItem: { paddingLeft: 50 },
    listItemIconChecked: { color: theme.palette.primary.main },
    listItemSecondaryTextAccount: {
        ...theme.typography.body3,
        color: theme.palette.neutral.main,
        marginLeft: 6,
        textAlign: "left",
    },
    listItemText: {
        ...theme.typography.body2,
        color: theme.palette.neutral.dark,
        textAlign: "left",
        marginLeft: 4,
        padding: 0,
        "& strong": { fontWeight: theme.typography.fontWeightMedium },
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    listItemSecondaryTextBusiness: {
        ...theme.typography.body3,
        color: theme.palette.neutral.main,
        textAlign: "right",
        marginLeft: 4,
        verticalAlign: "middle",
    },
    hovered: {
        backgroundColor: theme.palette.action.hover,
        cursor: "pointer",
    },
});

class ListBusinessItem extends React.PureComponent {
    static propTypes = {
        classes: PropTypes.object,
        selected: PropTypes.bool.isRequired,
        business: PropTypes.object.isRequired,
        onItemClick: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = { hover: false };

        this.handleMouseEnter = this.handleMouseEnter.bind(this);
        this.handleMouseLeave = this.handleMouseLeave.bind(this);
        this.handleItemClick = this.handleItemClick.bind(this);
    }

    handleMouseEnter(event) {
        this.setState({ hover: true });
    }

    handleMouseLeave(event) {
        this.setState({ hover: false });
    }

    handleItemClick() {
        this.props.onItemClick(this.props.business);
    }

    render() {
        const { classes, p, selected, business, highlight, onSubmit } = this.props;

        let label = business["denomination"];
        if (highlight) {
            label = highlightString(label, highlight);
        }

        return (
            <ListItem
                key={`b-${business.businessId}`}
                onMouseEnter={this.handleMouseEnter}
                onMouseLeave={this.handleMouseLeave}
                onClick={this.handleItemClick}
                className={classnames(
                    classes.listItem,
                    { [classes.selected]: selected },
                    this.state.hover && classes.hovered
                )}
                onKeyDown={(event) => {
                    if (keycode(event) === "enter") {
                        event.preventDefault();
                        onSubmit();
                    } else if (keycode(event) === "space") {
                        event.preventDefault();
                        this.handleItemClick();
                    }
                }}
                tabIndex={0}
            >
                <HiCheckbox
                    checked={selected}
                    checkedIcon="check_box"
                    classes={{ checked: classes.listItemIconChecked }}
                    icon={this.state.hover ? "check_box_outline_blank" : "fa-tags"}
                    value={`b-${business.businessId}`}
                    key={`b-${business.businessId}`}
                    style={{
                        width: "24px",
                        height: "24px",
                    }}
                    color="primary"
                    tabIndex={-1}
                />
                <ListItemText disableTypography className={classes.listItemText}>
                    <span
                        // eslint-disable-next-line react/no-danger
                        dangerouslySetInnerHTML={{ __html: escapeHTML(label) }}
                    />
                </ListItemText>

                <div>
                    <HiPin
                        color={
                            selected || business.selectedAccountCount > 0 ? "primary" : "neutral"
                        }
                    >
                        {selected
                            ? business["accountIdList"].length
                            : business.selectedAccountCount}
                    </HiPin>
                    <span className={classes.listItemSecondaryTextBusiness}>
                        {p.t("account_selector.n_accounts", business["accountIdList"].length)}
                    </span>
                </div>
            </ListItem>
        );
    }
}
export default withStyles(styleSheet)(translate(ListBusinessItem));
