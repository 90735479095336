import React from "react";
import PropTypes from "prop-types";
import moment from "moment-timezone";
import { withStylesAndTranslate } from "../../../../../hoc/withStylesAndTranslate";
import Collapse from "@mui/material/Collapse";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import MenuDown from "mdi-material-ui/MenuDown";
import MenuRight from "mdi-material-ui/MenuRight";
import TableItem from "./TableItem";
import { getDayLabelFromRelativeDate } from "../../../../../utils/dates";

export const stylesTable = (theme) => ({
    list: {
        overflow: "auto",
        marginBottom: 32,
    },
    listItemHeader: {
        ...theme.typography.body2,
        padding: "3px 0",
    },
});

class Table extends React.Component {
    static propTypes = {
        classes: PropTypes.object,
        deleteSearchHistory: PropTypes.func.isRequired,
        searchHistory: PropTypes.array.isRequired,
        timezoneName: PropTypes.string.isRequired,
        phrases: PropTypes.object,
    };

    constructor(props) {
        super(props);

        let searchHistoryByDay = props.searchHistory
            ? this.formatSearchHistoryByDay(Object.values(props.searchHistory))
            : [];

        this.state = {
            openFields: Object.keys(searchHistoryByDay),
            deletedSearchHistoryIdList: [],
        };

        this.handleCollapse = this.handleCollapse.bind(this);
        this.handleHistoryDelete = this.handleHistoryDelete.bind(this);
    }

    formatSearchHistoryByDay(searchHistory) {
        return searchHistory.reduce((a, s) => {
            let yyyymmdd = moment(s["createdAt"]).format("YYYYMMDD");

            if (typeof a[yyyymmdd] === "undefined") {
                a[yyyymmdd] = [];
            }

            a[yyyymmdd].push(s);
            return a;
        }, {});
    }

    handleCollapse = (field) => {
        if (this.state.openFields.includes(field)) {
            this.setState((prevState) => ({
                openFields: prevState.openFields.filter(function (a) {
                    return a !== field;
                }),
            }));
        } else {
            this.setState((prevState) => ({ openFields: prevState.openFields.concat([field]) }));
        }
    };

    handleHistoryDelete = (history) => (event) => {
        event.stopPropagation();
        this.setState((prevState) => ({
            deletedSearchHistoryIdList: prevState.deletedSearchHistoryIdList.concat([
                history["historyId"],
            ]),
        }));
        this.props.deleteSearchHistory(history);
    };

    render() {
        const {
            classes,
            p,
            phrases,
            history,
            searchHistory,
            authentication,
            addSearchHistory,
            onClose,
            selectedAccountIdList,
            accountEntities,
            businessEntities,
        } = this.props;

        const { openFields, deletedSearchHistoryIdList } = this.state;

        let filteredSearchHistory = searchHistory
            ? searchHistory.filter(
                  (item) => !deletedSearchHistoryIdList.includes(item["historyId"])
              )
            : [];

        let searchHistoryByDay = this.formatSearchHistoryByDay(filteredSearchHistory);

        return (
            <List className={classes.list}>
                {Object.keys(searchHistoryByDay)
                    .sort()
                    .reverse()
                    .map((yyyymmdd, index) => {
                        let dateLabel = getDayLabelFromRelativeDate(p, yyyymmdd);

                        return (
                            <div key={index}>
                                <ListItem button onClick={() => this.handleCollapse(yyyymmdd)}>
                                    <ListItemIcon>
                                        {openFields.includes(yyyymmdd) ? (
                                            <MenuDown />
                                        ) : (
                                            <MenuRight />
                                        )}
                                    </ListItemIcon>
                                    <ListItemText
                                        classes={{ root: classes.listItemHeader }}
                                        disableTypography
                                        primary={dateLabel}
                                    />
                                </ListItem>
                                <Collapse in={openFields.includes(yyyymmdd)} unmountOnExit>
                                    {Object.values(searchHistoryByDay[yyyymmdd])
                                        .sort((a, b) => a["createdAt"] < b["createdAt"])
                                        .map((searchRecord, indexRecord) => {
                                            return (
                                                <TableItem
                                                    key={indexRecord}
                                                    searchRecord={searchRecord}
                                                    deleteItem={this.handleHistoryDelete}
                                                    authentication={authentication}
                                                    selectedAccountIdList={selectedAccountIdList}
                                                    accountEntities={accountEntities}
                                                    businessEntities={businessEntities}
                                                    onClose={onClose}
                                                    addSearchHistory={addSearchHistory}
                                                    history={history}
                                                    phrases={phrases}
                                                />
                                            );
                                        })}
                                </Collapse>
                            </div>
                        );
                    })}
            </List>
        );
    }
}

export default withStylesAndTranslate(stylesTable)(Table);
