import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { getReferenceAsEntities } from "../../../../services/references/utils";
import { useModule } from "../../../../services/module";
import { useP } from "../../../../services/i18n";
import { AttributeRowBuilder } from "./AttributeTableBuilder/AttributeRowBuilder";

export const AttributeTable = (props) => {
    const { id, attributes, data, hideLabel } = props;

    const p = useP();
    const references = useSelector((state) => state.ref);
    const accountIdList = useSelector((state) => state.app.global.selectedAccountIdList);
    const { references: moduleReferences } = useModule();
    const args = { accountIdList };

    const mapAttributesEntities = (_attributes, _references, _args, _p) => {
        let _attributesWtRef = {};
        Object.values(_attributes).forEach((attr) => {
            _attributesWtRef = {
                ..._attributesWtRef,
                [attr.id]: {
                    ...attr,
                    dataList: getReferenceAsEntities(
                        _references,
                        attr.reference,
                        _args,
                        _p,
                        moduleReferences
                    ),
                },
            };
        });

        return _attributesWtRef;
    };

    const attributesWtRef = mapAttributesEntities(attributes, references, args, p);

    return (
        <div id={`attribute-table-${id}`}>
            {Object.values(attributesWtRef).map(
                (attr) =>
                    data[attr.id] && (
                        <AttributeRowBuilder
                            key={`attribute-row-${attr.id}`}
                            attribute={attr}
                            data={data[attr.id]}
                            hideLabel={hideLabel}
                        />
                    )
            )}
        </div>
    );
};

AttributeTable.propTypes = {
    /**
     * Unique identifier
     */
    id: PropTypes.string.isRequired,
    /**
     * Attributs du paragraphe
     */
    attributes: PropTypes.object.isRequired,
    /**
     * Données du paragraphe
     */
    data: PropTypes.object.isRequired,
    module: PropTypes.string,
};
