import { lazy } from "react";
import { AppCommon } from "@console/core";

// https://reactjs.org/docs/code-splitting.html#route-based-code-splitting
const Root = lazy(() => import("./Root"));

const AppIntegration = (props: any) => (
    <AppCommon ModuleRoot={Root} module="integration" {...props} />
);

const AppEterminal = (props: any) => <AppCommon ModuleRoot={Root} module="eterminal" {...props} />;

export { AppIntegration, AppEterminal };
