import { Provider as ReduxProvider } from "react-redux";
import { BrowserRouter } from "react-router-dom";

import { store } from "./store";
import { staticReducersManager } from "./staticReducersManager";

import {
    AbilityProvider,
    AccountProvider,
    ConfigProvider,
    ApiProvider,
    ConsoleContextThemeProvider,
    DatePickerProvider,
    I18nProvider,
    OktaAuthentication,
    SnackbarProvider,
    ServiceRouter,
    UserProvider,
} from "./services";

import { ReactQueryConfigProvider } from "react-query";

import {
    moduleConfig as authenticationConfig,
    moduleRouting as moduleAuthenticationRouting,
} from "./authentication/constants/config";

/**
 * Master Router
 * This component setup the global application.
 * Configuration props are the following :
 * @param menu              - [], options
 * @param routing           - [], routes config
 * @param modules           - {}, modules description
 * @param userMenu          - {}, menu user additional options
 * @returns {*}
 */
export const MasterRouter = ({ menu = [], modules = {}, routing = [], userMenu = [] }) => {
    // manage static reducers
    const ref = Object.values(modules).reduce((acc, m) => {
        const { references = {} } = m;
        return {
            ...acc,
            ...references,
        };
    }, {});
    const staticReducers = staticReducersManager({ references: ref });

    // inject static reducers
    Object.keys(staticReducers).forEach((key) => {
        store.injectReducer(key, staticReducers[key]);
    });

    const reactQueryConfig = {
        queries: {
            retry: false,
            refetchOnWindowFocus: false,
            refetchOnMount: false,
        },
    };

    //Si le module authentication n'est pas présent on affiche un message d'erreur
    if (!modules.authentication || !routing.authentication) {
        try {
            modules = {
                ...modules,
                authentication: authenticationConfig,
            };

            routing = [...routing, ...moduleAuthenticationRouting];
        } catch (err) {
            throw new Error(
                "Module authentication-console not found. This module is required to log into console."
            );
        }
    }

    return (
        <ReduxProvider store={store}>
            <ConfigProvider menu={menu} modules={modules}>
                <BrowserRouter>
                    <OktaAuthentication>
                        <UserProvider>
                            <AbilityProvider>
                                <I18nProvider>
                                    <ConsoleContextThemeProvider>
                                        <DatePickerProvider>
                                            <SnackbarProvider>
                                                <ApiProvider>
                                                    <ReactQueryConfigProvider
                                                        config={reactQueryConfig}
                                                    >
                                                        <AccountProvider>
                                                            <ServiceRouter routing={routing} />
                                                        </AccountProvider>
                                                    </ReactQueryConfigProvider>
                                                </ApiProvider>
                                            </SnackbarProvider>
                                        </DatePickerProvider>
                                    </ConsoleContextThemeProvider>
                                </I18nProvider>
                            </AbilityProvider>
                        </UserProvider>
                    </OktaAuthentication>
                </BrowserRouter>
            </ConfigProvider>
        </ReduxProvider>
    );
};
