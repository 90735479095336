import { LOGOUT, ERROR_401 } from "../app/actions/actionTypes";

/**
 * Custom Zendesk Middleware to close the widget when the user log out of Console
 * It'll prevent the opening of the widget in the state of the last user connected on the same session
 * @returns {function(*): function(*=): *}
 */
export function zendeskMiddleware() {
    return (next) => (action) => {
        if (window.zE && [LOGOUT, ERROR_401].includes(action.type)) {
            window.zE("webWidget", "close");
            window.zE("webWidget", "reset");
            if (window.zE.logout) {
                window.zE.logout();
            } else {
                window.zE("webWidget", "logout");
            }
            window.zE("webWidget", "hide");
        }

        return next(action);
    };
}
