import React from "react";
import PropTypes from "prop-types";
import HiIcon from "@hipay/hipay-material-ui/HiIcon";
import { translate } from "../../../../../../services";
import { buildAccountDisplayPropsFromEntities } from "../../../../../../utils";
import HiColoredLabel from "@hipay/hipay-material-ui/HiColoredLabel";
import classNames from "classnames";
import { isMobile } from "react-device-detect";
import Typography from "@mui/material/Typography";

class HistorySuggestionItem extends React.Component {
    static propTypes = { suggestion: PropTypes.object.isRequired };

    render() {
        const { p, suggestion, accountEntities, businessEntities } = this.props;

        let accountDisplay = buildAccountDisplayPropsFromEntities(
            p,
            suggestion.accounts,
            accountEntities,
            businessEntities,
            0,
            false
        );

        return (
            <div
                className={classNames("history", {
                    "mobile-item": isMobile,
                    item: !isMobile,
                    "history-item": !isMobile,
                })}
            >
                <div className="left-item">
                    {!isMobile && <HiIcon icon="history" size={24} />}
                    <div
                        className={classNames({
                            "text-div": !isMobile,
                            "text-div-mobile": isMobile,
                        })}
                    >
                        {suggestion.value}
                    </div>
                </div>
                {!isMobile && (
                    <Typography variant="body3" className="right-item" title={accountDisplay.title}>
                        {suggestion.accounts.length === 1 ? (
                            <HiColoredLabel
                                label={accountDisplay.label}
                                color={accountDisplay.color}
                                fontSize={13}
                            />
                        ) : (
                            accountDisplay.label
                        )}
                        <i
                            className={"suggestion-item-right-icon fa fa-lg " + accountDisplay.icon}
                        />
                    </Typography>
                )}
            </div>
        );
    }
}

export default translate(HistorySuggestionItem);
