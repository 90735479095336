import React from "react";
import PropTypes from "prop-types";
import SetNone from "mdi-material-ui/SetNone";
import { translate } from "../../../../../../services/i18n/translate";
import classNames from "classnames";
import { isMobile } from "react-device-detect";

class KeywordSuggestionItem extends React.Component {
    static propTypes = { suggestion: PropTypes.object.isRequired };

    render() {
        const { suggestion, p } = this.props;

        return (
            <div className="keyword item">
                {!isMobile && <SetNone />}
                <div
                    className={classNames({
                        "text-div": !isMobile,
                        "text-div-mobile": isMobile,
                    })}
                >
                    {p.t(`app.qsearch.keywords.${suggestion.value}`)}
                </div>
            </div>
        );
    }
}

export default translate(KeywordSuggestionItem);
