import { TitleContent } from "../TitleContent/TitleContent";
import { useStyles } from "./stylesheet";

export const Content = (props) => {
    const classes = useStyles();
    const { titleKey, children } = props;

    return (
        <div className={classes.root}>
            {titleKey && <TitleContent titleKey={titleKey} />}
            <div className={classes.body}>{children}</div>
        </div>
    );
};
