import PropTypes from "prop-types";
import { ErrorBoundary as SentryErrorBoundary } from "@sentry/react";
import { useP } from "../../../services/i18n";
import makeStyles from "@mui/styles/makeStyles";

const useStylesError = makeStyles((theme) => ({
    root: {
        ...theme.typography.body2,
        width: "100%",
        height: "100%",
        display: "table",
        textAlign: "center",
    },
    center: {
        display: "table-cell",
        verticalAlign: "middle",
    },
}));

function Error() {
    const p = useP();
    const classes = useStylesError();
    return (
        <div className={classes.root}>
            <span className={classes.center}>{p.t("app.error_boundary")}</span>
        </div>
    );
}

export function ErrorBoundary({ children, error }) {
    return <SentryErrorBoundary fallback={error}>{children}</SentryErrorBoundary>;
}

ErrorBoundary.propTypes = { error: PropTypes.any };

ErrorBoundary.defaultProps = { error: <Error /> };
