import React from "react";
import { connect } from "react-redux";
import { translate } from "../../../services/i18n/translate";
import CloseIcon from "mdi-material-ui/WindowClose";
import withStyles from "@mui/styles/withStyles";
import HiButton from "@hipay/hipay-material-ui/HiButton";
import HiIconButton from "@hipay/hipay-material-ui/HiIconButton";
import * as actionTypes from "../../actions/actionTypes";
import * as appActions from "../../actions/appActions";
import { isDefined } from "../../../utils/objects";
import { withSnackbar } from "notistack";
import * as ReactDOM from "react-dom";
import { withInnerPage } from "../../../services/innerPage";
import { AccountSelector } from "../../components";

export const styleSheet = (theme) => ({
    root: {
        "& div": { flexWrap: "unset" },
        "& div span": { display: "flex" },
        textAlign: "left",
        zIndex: 999999,
    },
});

/**
 * SnackBarContainer
 *
 * Le composant/service SnackBarContainer gère les snackbars à afficher.
 * ( ex:
 * - après selection de comptes
 * ...
 * Une seule snackbar peut être ouverte à la fois.
 * On enregistre l'état de ce composant dans le store
 * app : {
 *      snackbar: {
 *          isOpen: (bool),
 *          message: (string),
 *          undo: (string)
 *      }
 * }
 * Avec
 * - isOpen : true pour ouvrir la snackbar, false pour la fermer
 * - message : message à afficher dans la snackbar
 * - undo : action à réaliser au click du bouton "undo" (à rajouter dans le switch ci-dessous)
 *
 * Utilisation;
 * - Inclure la fonction addSnackBar dans le composant nécessitant l'affichage d'une snackbar
 * - Ouvrir la snackbar:
 *      this.props.addSnackBar({
 *           isOpen: true,
 *           message: "Message à afficher",
 *           undo: actionTypes.SET_SELECTED_ACCOUNT_ID_LIST
 *       });
 */
class SnackBarContainer extends React.Component {
    undoButton;
    sideEffectActions;
    isUndoAction;

    componentDidUpdate(prevProps, prevState, prevContext) {
        const { classes, p, snackbar } = this.props;

        if (snackbar.isOpen && snackbar.id !== prevProps.snackbar.id) {
            this.isUndoAction = false;
            let action = [];
            if (snackbar.undo) {
                action.push(
                    <HiButton
                        id="undo-button"
                        key="undo"
                        ref={(el) => {
                            this.undoButton = el;
                        }}
                        action={(actions) => {
                            this.sideEffectActions = actions;
                        }}
                        className={classes.undoButton}
                        color={"inherit"}
                        onClick={this.handleUndo(snackbar)}
                    >
                        {snackbar.undoMessage || p.t("undo")}
                    </HiButton>
                );
                setTimeout(() => {
                    const undoButton = ReactDOM.findDOMNode(this.undoButton);
                    if (undoButton) {
                        undoButton.focus();
                    }
                    this.sideEffectActions.focusVisible();
                }, 100);
            }
            action.push(
                <HiIconButton
                    key="close"
                    aria-label="Close"
                    color="inherit"
                    onClick={this.handleCloseButtonClick(snackbar)}
                >
                    <CloseIcon />
                </HiIconButton>
            );

            this.props.enqueueSnackbar(p.t(snackbar.message, snackbar.messageParams), {
                action,
                key: snackbar.id.toString(),
                message: (
                    <span id="message-id">
                        {snackbar.message ? p.t(snackbar.message, snackbar.messageParams) : null}
                    </span>
                ),
                className: classes.root,
                open: snackbar.isOpen,
                autoHideDuration: snackbar.duration || 3000,
                disableWindowBlurListener: true,
                onClose: this.handleCloseSnackbar(snackbar),
                variant: snackbar.error ? "warning" : "default",
            });
        }
    }

    timerId;

    handleCloseButtonClick = (snackbar) => () => {
        this.props.closeSnackbar(snackbar.id.toString());
        this.handleOnCloseActions(snackbar)();
    };

    handleCloseSnackbar = (snackbar) => (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        this.handleOnCloseActions(snackbar)();
    };

    handleOnCloseActions = (snackbar) => () => {
        const { handleDo } = this.props;

        this.props.handleClose();

        //Delay call to cancel it if handleUndo was called.
        if (!this.isUndoAction && snackbar.confirm) {
            this.timerId = setTimeout(() => {
                handleDo(snackbar.confirm);
            }, 1);
        }

        if (!this.isUndoAction && snackbar.onCloseAction) {
            snackbar.onCloseAction();
        }
    };

    handleUndo = (snackbar) => () => {
        const { openInnerPage, handleUndo } = this.props;

        this.isUndoAction = true;

        this.props.closeSnackbar(snackbar.id.toString());

        setTimeout(() => {
            if (this.timerId) {
                clearTimeout(this.timerId);
            }

            switch (snackbar.undo) {
                //TODO : Set undo action in action intent.
                case actionTypes.SET_SELECTED_ACCOUNT_ID_LIST:
                    openInnerPage(
                        <AccountSelector />,
                        {
                            fromTopBar: true,
                            titleKey: "modules.dashboard.account_selector.title",
                        },
                        "AccountSelector"
                    );
                    break;
                case actionTypes.REDIRECT_TO_ZENDESK_TICKET:
                    if (isDefined(snackbar.redirectLink)) {
                        window.open(snackbar.redirectLink);
                    }
                    return null;
                case actionTypes.SNACKBAR_UNDO_ACTION_REFUSE:
                    if (snackbar.refuse) {
                        handleUndo(snackbar.refuse);
                    }
                    break;
                default:
                    return null;
            }
        }, 1);
    };

    render() {
        return null;
    }
}

const mapStateToProps = (state) => {
    return { snackbar: state.app.snackbar };
};

const mapDispatchToProps = (dispatch) => {
    return {
        handleClose: () => dispatch(appActions.closeSnackBar()),
        handleUndo: (refuse) => {
            return dispatch(refuse());
        },
        handleDo: (confirm) => {
            return dispatch(confirm());
        },
    };
};

export default withSnackbar(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(withStyles(styleSheet)(translate(withInnerPage(SnackBarContainer))))
);
