import React, { useState } from "react";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Collapse from "@mui/material/Collapse";
import styled from "styled-components/macro";
import Typography from "@mui/material/Typography";
import HiButton from "@hipay/design-system/components/atoms/HiButton";

/**
 * This component is a custom row for the CustomTable component.
 * It can display a list a data in cells.
 * A collapse view can be expanded underneath the row.
 */
const CustomCollapseRow = (props) => {
    const { key, cells, collapseConfig, collapseContent } = props;

    const [collapseOpen, setCollapseOpen] = useState(false);

    const renderCellContent = (content) => {
        switch (typeof content) {
            case "string":
            case "number":
                return <Typography variant="body3">{content}</Typography>;
            default:
                return content;
        }
    };

    return (
        <React.Fragment>
            {/* CELL CONTENT */}
            <TableRow className={props.className}>
                {cells.map((cell, index) => (
                    <TableCell key={`${key}-${index}-cell`} variant="body" className="cell-content">
                        {renderCellContent(cell.content)}
                    </TableCell>
                ))}

                {collapseConfig && collapseConfig.showToggle && (
                    <TableCell className="collapse-cell-toggle">
                        <HiButton
                            color="info"
                            onClick={() => setCollapseOpen(!collapseOpen)}
                            size="small"
                            variant="text"
                        >
                            {collapseOpen ? collapseConfig.openedText : collapseConfig.closedText}
                        </HiButton>
                    </TableCell>
                )}
            </TableRow>

            {/* COLLAPSE CONTENT */}
            {collapseContent && (
                <TableRow className={props.className}>
                    <TableCell className="collapse-cell-container" colSpan={cells.length + 1}>
                        <Collapse in={collapseOpen}>{collapseContent}</Collapse>
                    </TableCell>
                </TableRow>
            )}
        </React.Fragment>
    );
};

const StyledCustomCollapseRow = styled(CustomCollapseRow)`
    background-color: ${(props) => (props.error ? "#F9D9D9" : null)};

    .cell-content {
        font-weight: normal;
    }

    .collapse-cell-container {
        padding-top: 0;
        padding-bottom: 0;
        background-color: ${({ theme }) => theme.palette.background2};
    }

    .collapse-cell-toggle {
        text-align: right;
        padding: 12px 11px;
    }
`;

export default StyledCustomCollapseRow;
