import React from "react";
import PropTypes from "prop-types";
import { withStylesAndTranslate } from "../../../../hoc";
import HiIcon from "@hipay/hipay-material-ui/HiIcon";
import { HiLogo } from "@hipay/design-system/components";

const stylesQuickSearchBackdrop = (theme) => ({
    activeBackdrop: {
        position: "fixed",
        zIndex: 100,
        height: "calc(100% - 64px)",
        width: "100%",
        backgroundColor: theme.palette.background2,
        top: 64,
    },
    activeBackdropLogo: {
        marginTop: 16,
        height: 80,
        opacity: 0.3,
    },
    centeredContent: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        textAlign: "center",
    },
    noResultsTextInfo: {
        ...theme.typography.body2,
        textAlign: "left",
        color: theme.palette.neutral.main,
        lineHeight: "24px",
        maxWidth: 340,
    },
    noResultsTextInfoTitle: {
        textAlign: "center",
        marginBottom: 10,
    },
    blackText: { color: theme.palette.neutral.dark },
    InfoMessageText: {
        color: theme.palette.info.main,
        borderLeftColor: theme.palette.info.main,
        visibility: "visible",
    },
});

class QuickSearchBackdrop extends React.Component {
    static propTypes = {
        classes: PropTypes.object,
        query: PropTypes.string,
        errors: PropTypes.array,
    };

    constructor(props) {
        super(props);

        this.state = { activeInfoMessage: false };

        /**
         * Timeout
         * Display helper message on backdrop after 5s of inactivity
         */
        this.backdropTimeout = null;
    }

    componentDidMount() {
        this.resetBackdropTimeout();
    }

    componentWillUnmount() {
        clearTimeout(this.backdropTimeout);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.query !== this.props.query) {
            // add timeout to display helper message on backdrop
            this.setState({ activeInfoMessage: false });
            this.resetBackdropTimeout();
        }
    }

    resetBackdropTimeout = () => {
        clearTimeout(this.backdropTimeout);
        this.backdropTimeout = setTimeout(() => {
            this.setState({ activeInfoMessage: true });
        }, 1000);
    };

    renderErrorMessage = (error) => {
        const { p } = this.props;
        const { code, meta } = error;

        let options = {};
        if (meta) {
            if (meta.value) {
                options.value = meta.value;
            }
            if (meta.field && meta.module) {
                options.field = p.t(`attributes.${meta.module}.${meta.field}.suggestLabel`);
            }
            if (meta.operator) {
                options.operator = p.t(`app.qsearch.operators.${meta.operator}`);
            }
            if (meta.operator_list) {
                options.operator_list = meta.operator_list
                    .map((operator) => p.t(`app.qsearch.operators.${operator.value}`))
                    .join(", ");
            }
        }

        return (
            <span
                dangerouslySetInnerHTML={{
                    __html: p.t(`app.qsearch.errors.error_${code}`, options),
                }}
            />
        );
    };

    render() {
        const { classes, p, query, errors } = this.props;

        const { activeInfoMessage } = this.state;

        return (
            <div className={classes.activeBackdrop}>
                <div className={classes.centeredContent}>
                    {activeInfoMessage && (!errors || errors.length === 0) && (
                        <p
                            id="backdropInfoMessage"
                            className={classes.InfoMessageText}
                            dangerouslySetInnerHTML={{
                                __html: p.t("app.qsearch.backdrop.query_is_valid"),
                            }}
                        />
                    )}

                    {activeInfoMessage && errors && errors.length > 0 && (
                        <>
                            <HiIcon icon="search" size={175} color="neutral" />
                            <div className={classes.noResultsTextInfo}>
                                {this.renderErrorMessage(errors[0])}
                            </div>
                        </>
                    )}

                    {(query === "" || !activeInfoMessage || errors === null) && (
                        <HiLogo className={classes.activeBackdropLogo} />
                    )}
                </div>
            </div>
        );
    }
}

export default withStylesAndTranslate(stylesQuickSearchBackdrop)(QuickSearchBackdrop);
