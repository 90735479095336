// DB table : onboarding.verification_statuses

export default {
    id: "onboardingStatuses",
    translate: true,
    data: [
        {
            id: "draft",
            label: "ref.onboarding.onboarding_statuses.draft",
            color: "info",
        },
        {
            id: "deleted",
            label: "ref.onboarding.onboarding_statuses.deleted",
            color: "neutral",
        },
        {
            id: "form_sent",
            label: "ref.onboarding.onboarding_statuses.form_sent",
            color: "primary",
        },
        {
            id: "data_in_progress",
            label: "ref.onboarding.onboarding_statuses.data_in_progress",
            color: "primary",
        },
        {
            id: "data_to_validate",
            label: "ref.onboarding.onboarding_statuses.data_to_validate",
            color: "warning",
        },
        {
            id: "data_review",
            label: "ref.onboarding.onboarding_statuses.data_review",
            color: "secondary",
        },
        {
            id: "data_to_modify",
            label: "ref.onboarding.onboarding_statuses.data_to_modify",
            color: "error",
        },
        {
            id: "risk_review_in_progress",
            label: "ref.onboarding.onboarding_statuses.risk_review_in_progress",
            color: "secondary",
        },
        {
            id: "risk_review_completed",
            label: "ref.onboarding.onboarding_statuses.risk_review_completed",
            color: "success",
        },
        {
            id: "review_out_of_console",
            label: "ref.onboarding.onboarding_statuses.review_out_of_console",
            color: "neutral",
        },
    ],
};
