import { useContext, useEffect } from "react";
import { ShepherdTourContext } from "react-shepherd";
import { useDispatch, useSelector } from "react-redux";
import { closeTour, openTour } from "../../../app/actions/guidetourActions";
import { setEventTracker } from "../../../services/tracker";
import { useModule } from "../../../services/module";
import { HiButton } from "@hipay/design-system/components";
import FlagOutlinedIcon from "@mui/icons-material/FlagOutlined";

export const GuideTourButton = (props) => {
    const { unfocusInput, disabled, classes, p, tourDone, setTourDone, initCallback } = props;

    const tour = useContext(ShepherdTourContext);
    const module = useModule();

    const dispatch = useDispatch();
    const focused = useSelector((state) => state.app.guidetour.focusedInput);
    const guidetourActive = useSelector((state) => state.app.guidetour.active);

    const handleOpenTour = () => {
        dispatch(openTour());
    };

    const handleCloseTour = () => {
        if (focused) {
            unfocusInput();
        }
        dispatch(closeTour());
    };

    let buttonGuideTourProps;
    let guideTourTooltip;

    if (!disabled) {
        buttonGuideTourProps = {
            className: guidetourActive ? classes.guideTourButtonActive : classes.guideTourButton,
            disabled: false,
        };
        guideTourTooltip = guidetourActive ? "" : p.t("guidetour.tooltip.ready");
    } else {
        buttonGuideTourProps = {
            className: classes.guideTourButton,
            disabled: true,
        };
        guideTourTooltip = p.t("guidetour.tooltip.disabled");
    }

    const onClickGuideTour = () => {
        setEventTracker("guide_tour", {
            event_category: "guide_tour",
            event_action: "guide_tour_" + module.id,
        });

        if (guidetourActive) {
            tour.complete();
        } else {
            handleOpenTour();
            if (initCallback.callback) {
                initCallback.callback(tour);
            }
            tour.start();
        }
    };

    tour.on("start", () => {
        let launcher = document.getElementById("launcher");

        if (!tourDone) {
            // Because of the verification redirection, there is 2 loading of Console
            // The load of the main guidetour is asynchronous but there is a redirection in between
            // To solve this, a solution would be to only load Console when the accounts are fetched
            // It means adding a loading screen in AppLogged
            // This can have a big impact on Console user experience
            // A solution for now is to deactivate the main guidetour and to not set tourDone in the user's local storage
            //
            setTourDone(true);
        }

        // Close quicksearch
        let backButton = document.getElementById("back-btn");
        if (backButton) {
            backButton.click();
        }

        // Close innerpage
        let closeInnerButton = document.getElementById("close-innerpage");
        if (closeInnerButton) {
            closeInnerButton.click();
        }

        if (launcher) {
            launcher.style.zIndex = 995;
        } else {
            let interval = setInterval(() => {
                launcher = document.getElementById("launcher");
                if (launcher) {
                    launcher.style.zIndex = 995;
                    clearInterval(interval);
                }
            }, 100);
        }

        // Make sure "shepherd-modal-overlay-container" is on the DOM
        setTimeout(() => {
            let overlayContainer = document.getElementsByClassName(
                "shepherd-modal-overlay-container"
            );
            if (overlayContainer.length > 0) {
                let overlay = overlayContainer[0];
                overlay.addEventListener("click", () => tour.cancel());
            }
        }, 100);
    });

    ["cancel", "complete"].forEach((event) =>
        tour.on(event, () => {
            if (focused) {
                unfocusInput();
            }
            handleCloseTour();
        })
    );

    useEffect(() => {
        if (!tourDone && !guidetourActive) {
            // Wait page loading before start tour
            // & use click on btn => not working properly with tour.start() (PSYCHE-4416)
            setTimeout(() => {
                const btn = document.getElementById("guide-tour-button");
                btn.click();
            }, 1000);
        }
    }, []);

    return (
        <HiButton
            id="guide-tour-button"
            color="light"
            variant="contained"
            onClick={onClickGuideTour}
            {...buttonGuideTourProps}
            title={guideTourTooltip}
            startIcon={<FlagOutlinedIcon />}
        >
            {p.t("guidetour.guide")}
        </HiButton>
    );
};
