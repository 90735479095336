// *********************************************************************************************************************
// AUTHENTICATION
export const SET_AUTHENTICATION = "SET_AUTHENTICATION";

// TODO : change when transition form redux to hook requests is over
export const SET_AUTHENTICATION_NEW = "SET_AUTHENTICATION_NEW";

export const SET_CONNECTED_USER = "SET_CONNECTED_USER";

export const LOGOUT = "LOGOUT";

export const ERROR_401 = "ERROR_401";

export const REFRESH_TOKEN_REQUEST = "REFRESH_TOKEN_REQUEST";
export const REFRESH_TOKEN_SUCCESS = "REFRESH_TOKEN_SUCCESS";
export const REFRESH_TOKEN_FAILURE = "REFRESH_TOKEN_FAILURE";

// *********************************************************************************************************************
// GLOBAL
export const SET_REDIRECT_LOCATION = "SET_REDIRECT_LOCATION";
export const SET_SELECTED_ACCOUNT_ID_LIST = "SET_SELECTED_ACCOUNT_ID_LIST";
export const SET_SELECTED_BUSINESS_ID_LIST = "SET_SELECTED_BUSINESS_ID_LIST";
export const PERSIST_SEARCH_ACCOUNT_AND_BUSINESS_ENTITIES =
    "PERSIST_SEARCH_ACCOUNT_AND_BUSINESS_ENTITIES";

export const FETCH_SEARCH_HISTORY_REQUEST = "FETCH_SEARCH_HISTORY_REQUEST";
export const FETCH_SEARCH_HISTORY_SUCCESS = "FETCH_SEARCH_HISTORY_SUCCESS";
export const FETCH_SEARCH_HISTORY_FAILURE = "FETCH_SEARCH_HISTORY_FAILURE";

export const ADD_SEARCH_HISTORY_REQUEST = "ADD_SEARCH_HISTORY_REQUEST";
export const ADD_SEARCH_HISTORY_SUCCESS = "ADD_SEARCH_HISTORY_SUCCESS";
export const ADD_SEARCH_HISTORY_FAILURE = "ADD_SEARCH_HISTORY_FAILURE";

export const DELETE_SEARCH_HISTORY_REQUEST = "DELETE_SEARCH_HISTORY_REQUEST";
export const DELETE_SEARCH_HISTORY_SUCCESS = "DELETE_SEARCH_HISTORY_SUCCESS";
export const DELETE_SEARCH_HISTORY_FAILURE = "DELETE_SEARCH_HISTORY_FAILURE";

export const FETCH_ACCOUNT_LIST_REQUEST = "FETCH_ACCOUNT_LIST_REQUEST";
export const FETCH_ACCOUNT_LIST_SUCCESS = "FETCH_ACCOUNT_LIST_SUCCESS";
export const FETCH_ACCOUNT_LIST_FAILURE = "FETCH_ACCOUNT_LIST_FAILURE";

export const RELOAD_SEARCH_HISTORY_REQUEST = "RELOAD_SEARCH_HISTORY_REQUEST";
export const RELOAD_SEARCH_HISTORY_SUCCESS = "RELOAD_SEARCH_HISTORY_SUCCESS";
export const RELOAD_SEARCH_HISTORY_FAILURE = "RELOAD_SEARCH_HISTORY_FAILURE";

export const FETCH_FEATURES_REQUEST = "FETCH_FEATURES_REQUEST";
export const FETCH_FEATURES_SUCCESS = "FETCH_FEATURES_SUCCESS";
export const FETCH_FEATURES_FAILURE = "FETCH_FEATURES_FAILURE";

export const DISPATCH_403 = "DISPATCH_403";
export const HIDE_403 = "HIDE_403";

export const SET_LOCATION = "SET_LOCATION";
export const FETCH_DOMAIN_ERROR = "FETCH_DOMAIN_ERROR";

export const FETCH_DATA_EXPORT_REQUEST = "FETCH_DATA_EXPORT_REQUEST";
export const FETCH_DATA_EXPORT_SUCCESS = "FETCH_DATA_EXPORT_SUCCESS";
export const FETCH_DATA_EXPORT_FAILURE = "FETCH_DATA_EXPORT_FAILURE";

export const ADD_EXPORT_SUCCESS = "ADD_EXPORT_SUCCESS";

export const FETCH_RECURRING_EXPORT_LIST_REQUEST = "FETCH_RECURRING_EXPORT_LIST_REQUEST";
export const FETCH_RECURRING_EXPORT_LIST_SUCCESS = "FETCH_RECURRING_EXPORT_LIST_SUCCESS";
export const FETCH_RECURRING_EXPORT_LIST_FAILURE = "FETCH_RECURRING_EXPORT_LIST_FAILURE";

export const REMOVE_RECURRING_EXPORT_INTENT = "REMOVE_RECURRING_EXPORT_INTENT";
export const REMOVE_RECURRING_EXPORT_CANCELED = "REMOVE_RECURRING_EXPORT_CANCELED";
export const REMOVE_RECURRING_EXPORT_REQUEST = "REMOVE_RECURRING_EXPORT_REQUEST";
export const REMOVE_RECURRING_EXPORT_SUCCESS = "REMOVE_RECURRING_EXPORT_SUCCESS";
export const REMOVE_RECURRING_EXPORT_FAILURE = "REMOVE_RECURRING_EXPORT_FAILURE";

export const ADD_OR_REPLACE_EXPORT_FILE = "ADD_OR_REPLACE_EXPORT_FILE";

// *********************************************************************************************************************
// USER
export const SET_USER = "SET_USER";

// *********************************************************************************************************************
// SETTINGS
export const UPDATE_SETTINGS_REQUEST = "UPDATE_SETTINGS_REQUEST";
export const UPDATE_SETTINGS_SUCCESS = "UPDATE_SETTINGS_SUCCESS";
export const UPDATE_SETTINGS_FAILURE = "UPDATE_SETTINGS_FAILURE";

// *********************************************************************************************************************
// SNACKBAR
// /!\ Prefer to trigger snackbar via success actions (see reducer) /!\
export const SET_SNACKBAR = "SET_SNACKBAR";
export const CLOSE_SNACKBAR = "CLOSE_SNACKBAR";
export const SNACKBAR_UNDO_ACTION_REFUSE = "SNACKBAR_UNDO_ACTION_REFUSE";

// *********************************************************************************************************************
// ALERT
export const SET_ALERT = "SET_ALERT";
export const CLOSE_ALERT = "CLOSE_ALERT";

export const REDIRECT_TO_ZENDESK_TICKET = "REDIRECT_TO_ZENDESK_TICKET";
export const SET_LOADING_MESSAGE = "SET_LOADING_MESSAGE";

// *********************************************************************************************************************
// TOPBAR
export const ACTIVE_TOP_BAR = "ACTIVE_TOP_BAR";
export const INACTIVE_TOP_BAR = "INACTIVE_TOP_BAR";

// *********************************************************************************************************************
// GUIDETOUR
export const START_TOUR = "START_TOUR";
export const FINISH_TOUR = "FINISH_TOUR";
export const SET_TOUR_LOADING = "SET_TOUR_LOADING";
export const SET_TOUR_READY = "SET_TOUR_READY";
export const FOCUSED_INPUT = "FOCUSED_INPUT";
export const UNFOCUSED_INPUT = "UNFOCUSED_INPUT";

export const appActionTypes = {
    SET_AUTHENTICATION,
    SET_AUTHENTICATION_NEW,
    SET_CONNECTED_USER,
    LOGOUT,
    ERROR_401,
    REFRESH_TOKEN_REQUEST,
    REFRESH_TOKEN_SUCCESS,
    REFRESH_TOKEN_FAILURE,
    SET_REDIRECT_LOCATION,
    SET_SELECTED_ACCOUNT_ID_LIST,
    SET_SELECTED_BUSINESS_ID_LIST,
    PERSIST_SEARCH_ACCOUNT_AND_BUSINESS_ENTITIES,
    FETCH_SEARCH_HISTORY_SUCCESS,
    FETCH_SEARCH_HISTORY_FAILURE,
    ADD_SEARCH_HISTORY_REQUEST,
    ADD_SEARCH_HISTORY_SUCCESS,
    ADD_SEARCH_HISTORY_FAILURE,
    DELETE_SEARCH_HISTORY_REQUEST,
    DELETE_SEARCH_HISTORY_SUCCESS,
    DELETE_SEARCH_HISTORY_FAILURE,
    FETCH_ACCOUNT_LIST_REQUEST,
    FETCH_ACCOUNT_LIST_SUCCESS,
    FETCH_ACCOUNT_LIST_FAILURE,
    RELOAD_SEARCH_HISTORY_REQUEST,
    RELOAD_SEARCH_HISTORY_SUCCESS,
    RELOAD_SEARCH_HISTORY_FAILURE,
    FETCH_FEATURES_REQUEST,
    FETCH_FEATURES_SUCCESS,
    FETCH_FEATURES_FAILURE,
    DISPATCH_403,
    HIDE_403,
    SET_LOCATION,
    FETCH_DOMAIN_ERROR,
    FETCH_DATA_EXPORT_REQUEST,
    FETCH_DATA_EXPORT_SUCCESS,
    FETCH_DATA_EXPORT_FAILURE,
    ADD_EXPORT_SUCCESS,
    FETCH_RECURRING_EXPORT_LIST_REQUEST,
    FETCH_RECURRING_EXPORT_LIST_SUCCESS,
    FETCH_RECURRING_EXPORT_LIST_FAILURE,
    REMOVE_RECURRING_EXPORT_INTENT,
    REMOVE_RECURRING_EXPORT_CANCELED,
    REMOVE_RECURRING_EXPORT_REQUEST,
    REMOVE_RECURRING_EXPORT_SUCCESS,
    REMOVE_RECURRING_EXPORT_FAILURE,
    ADD_OR_REPLACE_EXPORT_FILE,
    SET_USER,
    UPDATE_SETTINGS_REQUEST,
    UPDATE_SETTINGS_SUCCESS,
    UPDATE_SETTINGS_FAILURE,
    SET_SNACKBAR,
    CLOSE_SNACKBAR,
    SNACKBAR_UNDO_ACTION_REFUSE,
    SET_ALERT,
    CLOSE_ALERT,
    REDIRECT_TO_ZENDESK_TICKET,
    SET_LOADING_MESSAGE,
    ACTIVE_TOP_BAR,
    INACTIVE_TOP_BAR,
    START_TOUR,
    SET_TOUR_LOADING,
    SET_TOUR_READY,
    FOCUSED_INPUT,
    UNFOCUSED_INPUT,
};
