import AppDashboard from "../index";
import { getGuideTourSteps } from "./guidetour";

/**
 * Config
 *
 * On retrouve ici la configuration du module.
 *  - Ses attributes
 *  - La config du module selector de la quicksearch
 *  - Les default fields presents dans le quickForm & par défaut sur le formulaire de recherche (s'il est activé)
 *    ainsi que leurs valeurs par défaut (ou null)
 *  - La liste des features qui sont activées pour ce module
 *
 */
export const moduleConfig = {
    id: "dashboard",
    attributes: {},
    defaultFormFields: {},
    icon: "",
    label: "modules.dashboard.title",
    path: "dashboard",
    route: "[prefix]/dashboard",
    features: [],
    getGuideTourSteps: getGuideTourSteps,
};

export const moduleRouting = [
    {
        key: "dashboard",
        path: ["/dashboard", "/accounts-:ids/dashboard"],
        component: AppDashboard,
    },
];
