import { useMemo, useState } from "react";
import styled from "styled-components/macro";
import { useUserInfo, useLogout } from "../../../../../services/user";

import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import PersonOutline from "@mui/icons-material/PersonOutline";
import {
    useTheme,
    darken,
    Collapse,
    Divider,
    List,
    Typography,
    ListItemButton,
    ListItemText,
    ListItemIcon,
} from "@mui/material";

import { MenuItemType, menuUserItems } from "../menu-items";
import { MenuBlockItem } from "./index";
import { transformMenuItemsListToObject } from "../menu-utils";
import { setEventTracker } from "../../../../../services/tracker";

export interface MenuBlockUserProps {
    /* CSS Classname */
    className?: string;
    /* Callback when an item is clicked */
    onItemClick?: any;
    /* Path of selected item in menu */
    activeItemPath?: string;
    /* Url prefix to add before target url */
    consoleUrlPrefix?: string;
}

/**
 * Block that display the user name and email.
 * It can extend to display additional menu items related to the user.
 */
const MenuBlockUser = ({ className, activeItemPath, consoleUrlPrefix }: MenuBlockUserProps) => {
    const theme = useTheme();
    const userInfo = useUserInfo();
    const logout = useLogout();

    const [expandUserMenu, setExpandUserMenu] = useState(false);

    const menuUserItemsAsObject = useMemo(() => {
        return transformMenuItemsListToObject(menuUserItems);
    }, []);

    const handleItemClick = (itemClicked: string) => {
        const itemObject = menuUserItemsAsObject[itemClicked] as MenuItemType;

        // Send analytics
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore (OK because only sub items are in favorites)
        setEventTracker("send", {
            event_category: "menu",
            event_action: "menu_click_" + itemObject.key,
        });

        if (itemObject.type === "logout") {
            logout();
        }
    };

    return (
        <div className={className}>
            <ListItemButton
                id="menuBlockUser"
                className="MenuBlockUser-userInfo"
                onClick={() => setExpandUserMenu(!expandUserMenu)}
            >
                <ListItemIcon className="MenuBlockUser-leftIcon">
                    <PersonOutline fontSize="small" />
                </ListItemIcon>

                <ListItemText className="MenuBlockUser-text">
                    <Typography
                        variant="body3"
                        component="div"
                        sx={{
                            color: "white",
                            fontWeight: 600,
                            textTransform: "uppercase",
                        }}
                    >
                        {userInfo.firstname + " " + userInfo.lastname}
                    </Typography>
                    <Typography variant="caption" component="div" color="white">
                        {userInfo.email}
                    </Typography>
                </ListItemText>
                <ListItemIcon className="MenuBlockUser-expandIcon">
                    {expandUserMenu ? <ExpandLess /> : <ExpandMore />}
                </ListItemIcon>
            </ListItemButton>
            <Collapse in={expandUserMenu} className="MenuBlockUser-items">
                <Divider sx={{ borderColor: "info.light" }} />
                <List className="MenuBlockUser-list">
                    {menuUserItems.map((item) => (
                        <MenuBlockItem
                            color={darken(theme.palette.info.main, 0.6)}
                            key={item.key}
                            item={item}
                            onItemClick={handleItemClick}
                            activeItemPath={activeItemPath}
                            consoleUrlPrefix={consoleUrlPrefix}
                        />
                    ))}
                </List>
            </Collapse>
        </div>
    );
};

const StyledMenuBlockUser = styled(MenuBlockUser)`
    .MenuBlockUser-userInfo {
        height: 80px;
        padding: ${({ theme }) => theme.spacing(4)};
        background-color: ${({ theme }) => darken(theme.palette.info.main, 0.6)};

        display: flex;
        justify-content: stretch;
        align-items: center;

        .MenuBlockUser-leftIcon {
            min-width: 28px;
            color: white;
        }

        .MenuBlockUser-expandIcon {
            margin-right: -6px;
            min-width: 20px;
            color: white;
        }
    }

    .MenuBlockUser-items {
        background-color: ${({ theme }) => darken(theme.palette.info.main, 0.6)};
    }

    .MenuBlockUser-list {
        padding-top: 0;
    }
`;

export default StyledMenuBlockUser;
