import { useRef } from "react";
import classNames from "classnames";
import { useKeyPressEvent, useIdle } from "react-use";
import makeStyles from "@mui/styles/makeStyles";
import ChevronLeft from "mdi-material-ui/ChevronLeft";

const useStylesNavBtnPrev = makeStyles((theme) => ({
    root: {
        display: "flex",
        position: "fixed",
        top: "calc(50vh - 32px)",
        left: 0,
        zIndex: 3,
        cursor: "pointer",
        boxShadow: "-2px 2px 3px rgba(0, 0, 0, .16)",
        borderTopRightRadius: "40px 80px",
        borderBottomRightRadius: "40px 80px",
        fontSize: 0, // Supprime le décalage du hover
        backgroundColor: "#fff",
        "&:hover>$contentContainer, &:focus>$contentContainer": {
            display: "flex",
            alignItems: "center",
        },
        "&:hover>$iconContainer, &:focus>$iconContainer": {
            "&$success": { backgroundColor: theme.palette.success.main },
            "&$error": { backgroundColor: theme.palette.error.main },
            "&$primary": { backgroundColor: theme.palette.primary.main },
            "&$secondary": { backgroundColor: theme.palette.secondary.main },
            "&$warning": { backgroundColor: theme.palette.warning.main },
        },
        transition: "opacity .2s ease-in-out, transform 0.4s",
        opacity: 1,
    },
    hidden: { "&:not(:hover):not(:focus)": { transform: "translateX(-100%)" } },
    contentContainer: {
        display: "none",
        height: 64,
        width: 152,
        padding: 8,
        lineHeight: "48px",
        textAlign: "left",
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.background3,
        fontSize: 18,
        fontWeight: theme.typography.fontWeightLight,
    },
    iconContainer: {
        display: "inline-block",
        borderTopRightRadius: "40px 80px",
        borderBottomRightRadius: "40px 80px",
        width: 35,
        height: 64,
        backgroundColor: theme.palette.neutral.light,
        color: theme.palette.common.white,
    },
    icon: {
        height: "100%",
        lineHeight: 1,
        color: "#fff",
    },
    success: {},
    error: {},
    primary: {},
    secondary: {},
    warning: {},
}));

export function NavBtnPrev(props) {
    const { children, color, onClick } = props;

    const classes = useStylesNavBtnPrev();
    const el = useRef();

    /**
     * Hide it if idle for 1s
     * @type {boolean}
     */
    const isIdle = useIdle(1000);

    /**
     * Handle arrow press
     * if focused, call onClick
     * else focus it
     * @param e
     */
    const handleArrowPress = (e) => {
        if (document.activeElement === el.current) {
            onClick();
        } else if (e) {
            el.current.focus();
        }
    };
    useKeyPressEvent("ArrowLeft", handleArrowPress);

    /**
     * Handle arrow press
     * if focused, call onClick
     * @param e
     */
    const handleEnterPress = (e) => {
        if (document.activeElement === el.current) {
            onClick();
        }
    };
    useKeyPressEvent("Enter", handleEnterPress);

    return (
        <div
            tabIndex="0"
            id="nav-previous"
            className={classNames(classes.root, { [classes.hidden]: isIdle })}
            onClick={onClick}
            ref={el}
        >
            <div className={classNames(classes.iconContainer, classes[color])}>
                <ChevronLeft className={classes.icon} />
            </div>
            <div className={classes.contentContainer}>{children}</div>
        </div>
    );
}
