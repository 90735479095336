import styled from "styled-components/macro";
import { HiLogo } from "@hipay/design-system/components";

/**
 * Block that display the HiPay logo in the menu
 */
const MenuBlockLogo = ({ className, onClick }: { className?: string; onClick?: () => void }) => {
    return (
        <div className={className}>
            <HiLogo color="white" className="MenuBlockUser-logo" onClick={onClick} />
        </div>
    );
};

const StyledMenuBlockLogo = styled(MenuBlockLogo)`
    padding: ${({ theme }) => theme.spacing(4)};

    height: 80px;
    display: flex;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;

    .MenuBlockUser-logo {
        cursor: pointer;
        width: 100px;
    }
`;

export default StyledMenuBlockLogo;
