import * as actionTypes from "./actionTypes";

export const openTour = () => {
    return { type: actionTypes.START_TOUR };
};

export const closeTour = () => {
    return { type: actionTypes.FINISH_TOUR };
};

export const focusedInput = () => {
    return { type: actionTypes.FOCUSED_INPUT };
};

export const unfocusedInput = () => {
    return { type: actionTypes.UNFOCUSED_INPUT };
};

export const setTourLoading = () => {
    return { type: actionTypes.SET_TOUR_LOADING };
};

export const setTourReady = () => {
    return { type: actionTypes.SET_TOUR_READY };
};
