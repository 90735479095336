import attributes from "./attributes";
import App from "../AppVerification";
import references from "../references";
import AssignmentOutlined from "@mui/icons-material/AssignmentOutlined";

const moduleLabelKey = `menu.onboarding.${
    process.env.NX_IS_INTERNAL === "true" ? "titleInternal" : "title"
}`;

/**
 * Config
 *
 * On retrouve ici la configuration du module.
 *  - Ses attributes
 *  - La config du module selector de la quicksearch
 *  - Les default fields presents dans le quickForm & par défaut sur le formulaire de recherche (s'il est activé)
 *    ainsi que leurs valeurs par défaut (ou null)
 *  - La liste des features qui sont activées pour ce module
 *
 */
export const moduleConfig = {
    id: "onboarding",
    attributes: attributes,
    defaultFormFields: {},
    icon: AssignmentOutlined,
    label: moduleLabelKey,
    path: "verifications",
    route: "[prefix]/verifications",
    features: ["form"],
    references: references,
    acl: {
        module: [
            {
                module: "business",
                controller: "verification-details",
            },
        ],
    },
};

export const moduleRouting = [
    {
        key: "onboarding",
        path: [
            "/:account/verifications",
            "/verifications",
            "/:account/verifications/:verificationId",
            "/verifications/:verificationId",
        ],
        component: App,
    },
];
