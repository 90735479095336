import { isInternal, stopPropagationEvent } from "../../utils";
import { setEventTracker } from "@console/core";

export function getNoticeSteps(tourOptions, p, accountId, setInitCallback) {
    let steps;

    let settingsBtn;
    let settingsMenu;
    let historyBtn;
    let historySection;
    let navArrowNext;
    let contentContainer;
    let iconContainer;
    let nextAccountColor;

    settingsMenu = document.getElementById(`settings-menu-${accountId}-menu`);
    settingsBtn = document.getElementById(`settings-menu-${accountId}-button`);
    historyBtn = document.getElementById(`action-open-status-history-${accountId}`);
    navArrowNext = document.getElementById("nav-next");

    if (navArrowNext) {
        // Target the content and the icon containers of the navigation arrow
        contentContainer = document.querySelector("#nav-next div:nth-of-type(1)");
        iconContainer = document.querySelector("#nav-next div:nth-of-type(2)");
    }

    setInitCallback({
        callback: (tour) => {
            // Remove the stopPropagationEvent() on click() allow the user to click again on settings-menu buttons
            ["close", "cancel", "complete"].forEach((event) =>
                tour.on(event, () => {
                    if (navArrowNext) {
                        navArrowNext.removeAttribute("style");
                        iconContainer.removeAttribute("style");
                        contentContainer.removeAttribute("style");
                        navArrowNext.tabIndex = 0;
                    }
                    if (settingsMenu) {
                        settingsMenu.removeEventListener("click", stopPropagationEvent);
                    }
                })
            );
        },
    });

    steps = [
        {
            id: "left-title",
            attachTo: {
                element: `#notice-title-${accountId}`,
                on: "auto",
            },
            title: p.t("modules.account.guidetour.notice.left-title.title"),
            text: p.t("modules.account.guidetour.notice.left-title.text"),
            buttons: [
                {
                    ...tourOptions.defaultStepOptions.buttons[0],
                    text: "",
                },
                tourOptions.defaultStepOptions.buttons[1],
            ],
            beforeShowPromise: () => {
                return new Promise((resolve) => {
                    setEventTracker("account_guide_tour_submission", {
                        event_category: "account_guide_tour_submission",
                        event_action: "account_guide_tour_submission",
                    });
                    if (document.getElementById("menu-item-order-paragraph")) {
                        document.querySelector(`#settings-menu-${accountId}-menu > div`).click();
                    }
                    resolve();
                });
            },
        },
    ];

    if (isInternal) {
        steps.push(
            {
                id: "edit-account",
                canClickTarget: false,
                attachTo: {
                    element: "#menu-item-edit-account",
                    on: "auto",
                },
                title: p.t("modules.account.guidetour.notice.edit.title"),
                text: p.t("modules.account.guidetour.notice.edit.text"),
                beforeShowPromise: () => {
                    return new Promise((resolve) => {
                        settingsBtn.click();
                        setTimeout(() => resolve(), 300);
                    });
                },
            },
            {
                id: "setup-account",
                attachTo: {
                    element: "#menu-item-setup-account",
                    on: "auto",
                },
                title: p.t("modules.account.guidetour.notice.setup.title"),
                text: p.t("modules.account.guidetour.notice.setup.text"),
            }
        );
    }

    steps.push({
        id: "settings",
        attachTo: {
            element: `#menu-item-order-paragraph`,
            on: "auto",
        },
        title: p.t("modules.account.guidetour.notice.settings.title"),
        text: p.t("modules.account.guidetour.notice.settings.text"),
        beforeShowPromise: () => {
            return new Promise((resolve) => {
                settingsBtn.click();
                setTimeout(() => resolve(), 200);
            });
        },
    });

    steps.push({
        id: "history",
        attachTo: {
            element: `#action-open-status-history-${accountId}`,
            on: "auto",
        },
        title: p.t("modules.account.guidetour.notice.history.title"),
        text: p.t("modules.account.guidetour.notice.history.text"),
        beforeShowPromise: () => {
            return new Promise((resolve) => {
                if (navArrowNext) {
                    navArrowNext.removeAttribute("style");
                    iconContainer.removeAttribute("style");
                    contentContainer.removeAttribute("style");
                }
                // Close settings menu
                if (document.getElementById("menu-item-order-paragraph")) {
                    document.querySelector(`#settings-menu-${accountId}-menu > div`).click();
                }

                if (document.getElementById("history")) {
                    let historyEl = document.getElementById("history");
                    historySection = historyEl.parentElement;
                    // Check if history section is shown
                    if (historySection.clientWidth === 0) {
                        historyBtn.click();
                    }
                }

                resolve();
            });
        },
    });

    // If navigation arrows are present, we add this steps to the guidetour
    if (navArrowNext) {
        steps.push({
            id: "navigation",
            attachTo: {
                element: "#nav-next",
                on: "auto",
            },
            title: p.t("modules.account.guidetour.notice.navigation.title"),
            text: p.t("modules.account.guidetour.notice.navigation.text"),
            beforeShowPromise: () => {
                return new Promise((resolve) => {
                    // target the nextAccount swipeable div index for extract its bg-color
                    let swipeableAccount = document.querySelector(
                        "div.react-swipeable-view-container"
                    ).children;
                    let currentAccount = document.querySelector(
                        "div.react-swipeable-view-container > div[aria-hidden='false']"
                    );
                    let index = [].indexOf.call(swipeableAccount, currentAccount) + 1;

                    nextAccountColor = window
                        .getComputedStyle(
                            swipeableAccount[index].querySelector("#notice-header"),
                            null
                        )
                        .getPropertyValue("background-color");

                    //show the navigation arrow
                    navArrowNext.style.transform = "translateX(0)";

                    iconContainer.style.backgroundColor = nextAccountColor;
                    contentContainer.style.display = "inline-block";

                    setTimeout(() => {
                        resolve();
                    }, 200);
                });
            },
        });
    }
    steps.push({
        id: "kpi",
        attachTo: {
            element: `#kpi-header-${accountId} >div>button`,
            on: "auto",
        },
        title: p.t("modules.account.guidetour.notice.kpi.title"),
        text: p.t("modules.account.guidetour.notice.kpi.text"),
        beforeShowPromise: () => {
            return new Promise((resolve) => {
                if (navArrowNext) {
                    navArrowNext.removeAttribute("style");
                    iconContainer.removeAttribute("style");
                    contentContainer.removeAttribute("style");
                }
                resolve();
            });
        },
    });

    steps.push.apply(steps, [
        {
            id: "help",
            attachTo: {
                element: "#launcher",
                on: "auto",
            },
            title: p.t("modules.account.guidetour.notice.help.title"),
            text: p.t("modules.account.guidetour.notice.help.text"),
            buttons: [
                tourOptions.defaultStepOptions.buttons[0],
                {
                    ...tourOptions.defaultStepOptions.buttons[1],
                    text: p.t("guidetour.buttons.done"),
                },
            ],
        },
    ]);

    return steps;
}
