import makeStyles from "@mui/styles/makeStyles";
import { useP } from "../../../../services";
import HiIcon from "@hipay/hipay-material-ui/HiIcon";

const useStylesError = makeStyles((theme) => ({
    noDataBackgroundIcon: {
        maxWidth: 160,
        opacity: 0.54,
        marginBottom: 30,
    },
    errorContainer: {
        position: "fixed",
        textAlign: "center",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        maxWidth: 400,
    },
    errorDiv: {
        ...theme.typography.body2,
        margin: "auto",
        lineHeight: 1.8,
    },
    errorSpan: { marginTop: 10 },
}));

export function Error() {
    const classes = useStylesError();
    const p = useP();

    return (
        <div className={classes.errorContainer}>
            <HiIcon
                icon={"hi_briefcase_rescue"}
                size={145}
                className={classes.noDataBackgroundIcon}
            />
            <div className={classes.errorDiv}>
                <div
                    className={classes.errorSpan}
                    dangerouslySetInnerHTML={{ __html: p.t("errors.message") }}
                />
            </div>
        </div>
    );
}
