import * as actionTypes from "../actions/actionTypes";
import {
    buildAccountAndBusinessListFromAccountDatas,
    getSelectedBusinessIdListFromSelectedAccountIdList,
} from "../../utils/entities";

// initial state of your module store partition
export const initialStateGlobal = {
    searchHistory: {},
    selectedAccountIdList: [],
    selectedBusinessIdList: [],
    entities: {
        loadingAccounts: false,
        account: {},
        business: {},
        export: {},
    },
    redirectLocation: null,
    display403: false,
};

export const globalReducer = (state = initialStateGlobal, action) => {
    switch (action.type) {
        case actionTypes.SET_REDIRECT_LOCATION:
            //Don't save logout
            if (action.redirectLocation && action.redirectLocation.indexOf("logout") !== -1) {
                return { ...state };
            }

            return {
                ...state,
                redirectLocation: action.redirectLocation,
            };

        case actionTypes.SET_SELECTED_ACCOUNT_ID_LIST:
            return {
                ...state,
                selectedAccountIdList: action.selectedAccountIdList,
            };

        case actionTypes.SET_SELECTED_BUSINESS_ID_LIST:
            return {
                ...state,
                selectedBusinessIdList: action.selectedBusinessIdList,
            };

        case actionTypes.FETCH_SEARCH_HISTORY_REQUEST:
            return {
                ...state,
                searchHistory: {
                    ...state.searchHistory,
                    [action.meta.module]: {
                        data: null,
                        loading: true,
                        error: null,
                    },
                },
            };

        case actionTypes.FETCH_SEARCH_HISTORY_SUCCESS:
            return {
                ...state,
                searchHistory: {
                    ...state.searchHistory,
                    [action.meta.module]: {
                        data: action.payload,
                        loading: false,
                        error: null,
                    },
                },
            };

        case actionTypes.FETCH_SEARCH_HISTORY_FAILURE:
            return {
                ...state,
                searchHistory: {
                    ...state.searchHistory,
                    [action.meta.module]: {
                        data: null,
                        loading: false,
                        error: action.payload,
                    },
                },
            };

        case actionTypes.ADD_SEARCH_HISTORY_SUCCESS:
            return {
                ...state,
                searchHistory: {
                    ...state.searchHistory,
                    [action.meta.module]: {
                        data: [
                            action.payload,
                            // Filter potential duplicates
                            ...(state.searchHistory[action.meta.module] &&
                            state.searchHistory[action.meta.module].data
                                ? state.searchHistory[action.meta.module].data.filter(
                                      (item) =>
                                          item.query !== action.payload.query ||
                                          item.selectedAccountIdList !==
                                              action.payload.selectedAccountIdList
                                  )
                                : []),
                        ],
                        loading: false,
                        error: null,
                    },
                },
            };

        /**
         * Remove specify history or all histories if no meta datas
         */
        case actionTypes.DELETE_SEARCH_HISTORY_SUCCESS:
            return {
                ...state,
                searchHistory: {
                    ...state.searchHistory,
                    [action.meta.module]: {
                        ...state.searchHistory[action.meta.module],
                        data:
                            !!action.meta && !!action.meta.history
                                ? state.searchHistory[action.meta.module].data.filter(
                                      (item) =>
                                          item["historyId"] !== action.meta.history["historyId"]
                                  )
                                : [],
                    },
                },
            };

        case actionTypes.FETCH_ACCOUNT_LIST_REQUEST:
            return {
                ...state,
                entities: {
                    ...state.entities,
                    accountFetched: false,
                    loadingAccounts:
                        action.meta && action.meta.setLoadingAccounts
                            ? true
                            : state.entities.loadingAccounts,
                },
            };

        /**
         * Persist account and business entities
         * deduce selectedBusinessIdList from selectedAccountIdList
         *
         * INTERNAL USER -> request SELECTED accounts
         * MERCHANT USER -> request ALL accounts
         */
        case actionTypes.FETCH_ACCOUNT_LIST_SUCCESS:
            let data = buildAccountAndBusinessListFromAccountDatas(action.payload);
            return {
                ...state,
                entities: {
                    ...state.entities,
                    accountFetched: true,
                    account: {
                        ...state.entities.account,
                        ...data.account,
                    },
                    business: {
                        ...state.entities.business,
                        ...data.business,
                    },
                    loadingAccounts: action?.meta?.setLoadingAccounts
                        ? false
                        : state.entities.loadingAccounts,
                },
                selectedBusinessIdList: getSelectedBusinessIdListFromSelectedAccountIdList(
                    state.selectedAccountIdList,
                    data.business
                ),
            };

        case actionTypes.FETCH_RECURRING_EXPORT_LIST_REQUEST:
            return {
                ...state,
                entities: {
                    ...state.entities,
                    export: {
                        data: [],
                        loading: true,
                        error: null,
                    },
                },
            };

        case actionTypes.FETCH_RECURRING_EXPORT_LIST_FAILURE:
            return {
                ...state,
                entities: {
                    ...state.entities,
                    export: {
                        data: [],
                        loading: false,
                        error: action.payload,
                    },
                },
            };

        case actionTypes.FETCH_RECURRING_EXPORT_LIST_SUCCESS:
            return {
                ...state,
                entities: {
                    ...state.entities,
                    export: {
                        data: action.payload,
                        loading: false,
                        error: null,
                    },
                },
            };

        case actionTypes.ADD_EXPORT_SUCCESS:
            return {
                ...state,
                entities: {
                    ...state.entities,
                    export: {
                        ...state.entities.export,
                        data: [
                            // remove duplicate (if update)
                            ...(state.entities.export.data
                                ? state.entities.export.data.filter(
                                      (e) => action.data.exportId !== e.exportId
                                  )
                                : []),
                            action.data,
                        ].sort((a, b) => {
                            if (a.filePrefix.toLowerCase() === b.filePrefix.toLowerCase()) {
                                return 0;
                            }
                            return a.filePrefix.toLowerCase() > b.filePrefix.toLowerCase() ? 1 : -1;
                        }),
                    },
                },
            };

        case actionTypes.REMOVE_RECURRING_EXPORT_INTENT:
            let exportList = [...state.entities.export.data];
            exportList = exportList.filter(
                (exprt) => action.meta.exportItem.exportId !== exprt.exportId
            );

            return {
                ...state,
                entities: {
                    ...state.entities,
                    export: {
                        ...state.entities.export,
                        data: [...exportList],
                    },
                },
            };

        case actionTypes.REMOVE_RECURRING_EXPORT_FAILURE:
        case actionTypes.REMOVE_RECURRING_EXPORT_CANCELED:
            return {
                ...state,
                entities: {
                    ...state.entities,
                    export: {
                        ...state.entities.export,
                        data: [...state.entities.export.data, action.meta.exportItem].sort(
                            (a, b) => {
                                // Sort alphabetically
                                if (a.filePrefix.toLowerCase() === b.filePrefix.toLowerCase()) {
                                    return 0;
                                }
                                return a.filePrefix.toLowerCase() > b.filePrefix.toLowerCase()
                                    ? 1
                                    : -1;
                            }
                        ),
                    },
                },
            };

        case actionTypes.ADD_OR_REPLACE_EXPORT_FILE:
            const _exportList = state.entities.export.data.map((exportItem) => {
                if (exportItem.exportId !== action.meta.exportId) {
                    return exportItem;
                }
                exportItem.exportFiles = exportItem.exportFiles.map((exportFile) => {
                    if (exportFile.fileId !== action.meta.exportFile.fileId) {
                        return exportFile;
                    }
                    return action.meta.exportFile;
                });
                return exportItem;
            });
            return {
                ...state,
                entities: {
                    ...state.entities,
                    export: {
                        ...state.entities.export,
                        data: _exportList,
                    },
                },
            };

        case actionTypes.FETCH_ACCOUNT_LIST_FAILURE:
            if (
                action?.payload?.response?.detail?.indexOf(
                    "Can not found linked accounts for user #"
                ) === 0
            ) {
                return {
                    ...state,
                    entities: {
                        ...state.entities,
                        accountFetched: false,
                        account: {},
                    },
                };
            }
            return state;

        /**
         * (INTERNAL USER)
         * Add current selected accounts and business entities to store
         */
        case actionTypes.PERSIST_SEARCH_ACCOUNT_AND_BUSINESS_ENTITIES:
            return {
                ...state,
                entities: {
                    ...state.entities,
                    account: {
                        ...state.entities.account,
                        ...action.data.accountEntities,
                    },
                    business: {
                        ...state.entities.business,
                        ...action.data.businessEntities,
                    },
                },
            };

        case actionTypes.SET_LOADING_MESSAGE:
            return {
                ...state,
                loadingMessage: action.loadingMessage,
            };

        case actionTypes.DISPATCH_403:
            return {
                ...state,
                display403: true,
            };

        case actionTypes.HIDE_403:
            return {
                ...state,
                display403: false,
            };

        case actionTypes.ERROR_401:
        case actionTypes.LOGOUT:
            return initialStateGlobal;

        default:
            return state;
    }
};
