import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { useP, useLocale } from "../../../services/i18n";
import { useApi } from "../../../services/api";
import { useOktaAuth } from "@okta/okta-react";
import { oktaSignInConfig, oktaSignInConfigInternal } from "../../../okta-config";

import { LoginPage } from "../LoginPage/LoginPage";
import { OktaWidget } from "../OktaWidget/OktaWidget";
import { ErrorMessage } from "../ErrorMessage";
import classnames from "classnames";
import { useStyles } from "./stylesheet";
import HiLoader from "@hipay/hipay-material-ui/HiLoader";
import { SET_REDIRECT_LOCATION } from "../../../app/actions/actionTypes";
import { getRedirectToUrl } from "../../../utils/redirectTo";

export const Login = () => {
    const classes = useStyles();
    const p = useP();
    const { oktaAuth } = useOktaAuth();
    const [userType, setUserType] = useState("loading");
    const [oktaWidgetStep, setOktaWidgetStep] = useState("primary-auth");
    const location = useLocation();
    const dispatch = useDispatch();
    const authError = useSelector((state) => state.app && state.app.authentication.error);

    const { fetchApi } = useApi();
    const [locale] = useLocale();

    useEffect(() => {
        const redirectTo = getRedirectToUrl(location);
        if (redirectTo) {
            dispatch({
                type: SET_REDIRECT_LOCATION,
                redirectLocation: redirectTo,
            });
        }
    }, []);

    useEffect(() => {
        fetchApi("GET", "/user/check-ip-address", "", {
            url: process.env.NX_CONSOLE_API_URL,
        }).then(
            (response) => {
                setUserType(response.isValidIp ? "internal" : "external");
            },
            () => {
                setUserType("external");
            }
        );
    }, []);

    const onSuccess = (tokens) => {
        return oktaAuth.handleLoginRedirect(tokens);
    };

    let config = {
        ...oktaSignInConfig,
        language: locale.substring(0, 2).toLowerCase(), // en_US => en
        i18n: {
            en: {
                "primaryauth.username.placeholder": p.t("modules.authentication.app.login.email"),
                "primaryauth.submit": p.t("modules.authentication.app.login.authenticate"),
            },
            fr: {
                "primaryauth.username.placeholder": p.t("modules.authentication.app.login.email"),
                "primaryauth.submit": p.t("modules.authentication.app.login.authenticate"),
            },
            it: {
                "primaryauth.username.placeholder": p.t("modules.authentication.app.login.email"),
            },
        },
    };

    if (userType === "internal") {
        config = {
            ...config,
            ...oktaSignInConfigInternal,
        };
    }

    return (
        <LoginPage
            titleKey={"modules.authentication.app.login.title"}
            showHeader={oktaWidgetStep === "primary-auth"}
            content={
                <div className={classes.root}>
                    {!!authError && <ErrorMessage message={p.t(authError)} />}
                    {userType === "loading" && <HiLoader style={{ marginLeft: 140 }} />}
                    {userType !== "loading" && (
                        <OktaWidget
                            config={config}
                            onSuccess={onSuccess}
                            userType={userType}
                            setOktaWidgetStep={setOktaWidgetStep}
                        />
                    )}
                    {oktaWidgetStep === "primary-auth" && (
                        <div className={classes.notYet}>
                            <span>
                                {p.t("modules.authentication.app.login.not_yet")}{" "}
                                <a
                                    className={classnames(classes.link, classes.notYetLink)}
                                    href={"https://hipay.com/"}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    {p.t("modules.authentication.app.login.join_us")}
                                </a>
                            </span>
                        </div>
                    )}
                </div>
            }
        />
    );
};
