import currencyCodes from "./currencyCodes";
import statusCodes from "./statusCodes";
import mccCodes from "./mccCodes";
import homeCountryCodes from "./homeCountryCodes";
import paymentMeanCodes from "./paymentMeanCodes";
import businessUnitCodes from "./businessUnitCodes";
import settlementDelayCodes from "./settlementDelayCodes";
import settlementFrequencyCodes from "./settlementFrequencyCodes";
import enableStatus from "./enableStatus";
import suspensionReasonCodes from "./suspensionReasonCodes";
import settlementDays from "./settlementDays";
import cardRestrictionCodes from "./cardRestrictionCodes";
import accountTypeCodes from "./accountTypeCodes";
import invoicingModeCodes from "./invoicingModeCodes";
import userStatuses from "./userStatuses";

const references = {
    currencyCodes,
    statusCodes,
    mccCodes,
    homeCountryCodes,
    paymentMeanCodes,
    businessUnitCodes,
    settlementDelayCodes,
    settlementFrequencyCodes,
    enableStatus,
    suspensionReasonCodes,
    settlementDays,
    cardRestrictionCodes,
    accountTypeCodes,
    invoicingModeCodes,
    userStatuses,
};

export default references;
