import React from "react";
import PropTypes from "prop-types";
import { withStylesAndTranslate } from "../../../../../hoc";
import Grid from "@mui/material/Grid";
import HiButton from "@hipay/hipay-material-ui/HiButton";
import HistoryIcon from "mdi-material-ui/History";
import Table from "./Table";
import { isMobile } from "react-device-detect";
import classNames from "classnames";
import { withInnerPage } from "../../../../../services";

export const stylesSearchHistory = (theme) => ({
    body: {
        width: 800,
        margin: "auto",
        boxShadow: "none",
    },
    paddingTop: { paddingTop: 112 },
    list: {
        overflow: "auto",
        height: 600,
    },
    textInfo: {
        ...theme.typography.body3,
        color: theme.palette.text.secondary,
    },
    noRecord: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        textAlign: "center",
    },
    noRecordTextInfo: {
        ...theme.typography.body3,
        color: theme.palette.neutral.dark,
        lineHeight: "24px",
    },
    noRecordIcon: {
        width: 180,
        height: 180,
        color: "lightGrey",
    },
    noResultInfo: {
        ...theme.typography.body2,
        textAlign: "center",
    },
    deleteAllButton: {
        float: "right",
        marginRight: 5,
    },
    rightItem: {
        position: "absolute",
        right: 16,
        top: 16,
        textAlign: "right",
        textTransform: "uppercase",
        ...theme.typography.body3,
        color: theme.palette.common.lightBlack,
    },
    listItemCollapse: {
        marginLeft: 48,
        height: 100,
    },
    listItemHeader: {
        ...theme.typography.body2,
        padding: "3px 0",
    },
    listItemNotHover: { padding: "0" },
    listItemHover: {
        padding: 0,
        fontWeight: theme.typography.fontWeightBold,
    },
    listItemRightIcon: { paddingLeft: 16 },
    enlargeItemLeftIcon: { marginTop: -20 },
    topContainer: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        position: "fixed",
        width: "100%",
        left: 0,
        padding: "112px 25% 20px 25%",
        backgroundColor: theme.palette.background3,
        zIndex: 1,
        minHeight: 48,
        marginTop: -112,
    },
    topContainerMobile: {
        padding: "20px 5% 20px 5%",
        position: "relative",
        marginBottom: -50,
    },
    tableMobile: { marginTop: 36 },
    table: { marginTop: 48 },
});

class SearchHistoryClass extends React.Component {
    static propTypes = {
        classes: PropTypes.object.isRequired,
        searchHistory: PropTypes.array,
        selectedAccountIdList: PropTypes.array,
        deleteSearchHistory: PropTypes.func.isRequired,
        deleteAllSearchHistory: PropTypes.func.isRequired,
        loading: PropTypes.bool,
        error: PropTypes.object,
        phrases: PropTypes.object,
    };

    render() {
        const {
            classes,
            p,
            phrases,
            history,
            searchHistory,
            authentication,
            addSearchHistory,
            selectedAccountIdList,
            accountEntities,
            businessEntities,
            deleteSearchHistory,
            deleteAllSearchHistory,
        } = this.props;

        return (
            <Grid container spacing={0} justifyContent="center" className={classes.paddingTop}>
                <Grid item xs={12} sm={10} md={8} lg={6}>
                    {searchHistory && searchHistory.length ? (
                        <div>
                            <div>
                                <Grid container justifyContent="center">
                                    <Grid item xs={12} lg={12}>
                                        <div
                                            className={classNames(classes.topContainer, {
                                                [classes.topContainerMobile]: isMobile,
                                            })}
                                        >
                                            <span className={classes.textInfo}>
                                                {p.t(
                                                    "search_activity.the_system_records_your_last_100_searches"
                                                )}
                                            </span>
                                            <HiButton
                                                className={classes.deleteAllButton}
                                                onClick={deleteAllSearchHistory}
                                            >
                                                {p.t("button.delete_all")}
                                            </HiButton>
                                        </div>
                                    </Grid>
                                </Grid>
                            </div>
                            <Grid container justifyContent="center">
                                <Grid item xs={12} lg={12}>
                                    <div
                                        className={classNames({
                                            [classes.table]: !isMobile,
                                            [classes.tableMobile]: isMobile,
                                        })}
                                    >
                                        <Table
                                            searchHistory={searchHistory}
                                            deleteSearchHistory={deleteSearchHistory}
                                            authentication={authentication}
                                            selectedAccountIdList={selectedAccountIdList}
                                            accountEntities={accountEntities}
                                            businessEntities={businessEntities}
                                            onClose={this.props.closeInnerPage}
                                            addSearchHistory={addSearchHistory}
                                            history={history}
                                            phrases={phrases}
                                        />
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                    ) : (
                        <Grid container justifyContent="center">
                            <Grid item xs={12} lg={12} className={classes.noRecord}>
                                <HistoryIcon className={classes.noRecordIcon} />
                                <div className={classes.noRecordTextInfo}>
                                    {p.t("search_activity.your_browsing_history_is_displayed_here")}
                                    <br />
                                    {p.t(
                                        "search_activity.the_system_records_your_last_100_searches"
                                    )}
                                </div>
                            </Grid>
                        </Grid>
                    )}
                </Grid>
            </Grid>
        );
    }
}

export const SearchHistory = withStylesAndTranslate(stylesSearchHistory)(
    withInnerPage(SearchHistoryClass)
);
