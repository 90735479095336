const privilege_resource = {
    id: "privilege_resource",
    data: [
        {
            id: "AccessControl",
            label: "ref.privilege.privilege_resource.AccessControl",
        },
        {
            id: "AccountDetails",
            label: "ref.privilege.privilege_resource.AccountDetails",
        },
        {
            id: "Business",
            label: "ref.privilege.privilege_resource.Business",
        },
        {
            id: "Catalog",
            label: "ref.privilege.privilege_resource.Catalog",
        },
        {
            id: "Challenge",
            label: "ref.privilege.privilege_resource.Challenge",
        },
        {
            id: "Communications",
            label: "ref.privilege.privilege_resource.Communications",
        },
        {
            id: "Connections",
            label: "ref.privilege.privilege_resource.Connections",
        },
        {
            id: "ConsoleCommunications",
            label: "ref.privilege.privilege_resource.ConsoleCommunications",
        },
        {
            id: "Disputes",
            label: "ref.privilege.privilege_resource.Disputes",
        },
        {
            id: "DisputesInternal",
            label: "ref.privilege.privilege_resource.DisputesInternal",
        },
        {
            id: "Eterminal",
            label: "ref.privilege.privilege_resource.Eterminal",
        },
        {
            id: "FinancialAdministration",
            label: "ref.privilege.privilege_resource.FinancialAdministration",
        },
        {
            id: "FinancialDetails",
            label: "ref.privilege.privilege_resource.FinancialDetails",
        },
        {
            id: "Fraud",
            label: "ref.privilege.privilege_resource.Fraud",
        },
        {
            id: "GatewayCost",
            label: "ref.privilege.privilege_resource.GatewayCost",
        },
        {
            id: "HostedPage",
            label: "ref.privilege.privilege_resource.HostedPage",
        },
        {
            id: "Impersonation",
            label: "ref.privilege.privilege_resource.Impersonation",
        },
        {
            id: "InternalUsers",
            label: "ref.privilege.privilege_resource.InternalUsers",
        },
        {
            id: "Kyc",
            label: "ref.privilege.privilege_resource.Kyc",
        },
        {
            id: "Legacy",
            label: "ref.privilege.privilege_resource.Legacy",
        },
        {
            id: "Login",
            label: "ref.privilege.privilege_resource.Login",
        },
        {
            id: "Notifications",
            label: "ref.privilege.privilege_resource.Notifications",
        },
        {
            id: "PaymentMeans",
            label: "ref.privilege.privilege_resource.PaymentMeans",
        },
        {
            id: "Pricing",
            label: "ref.privilege.privilege_resource.Pricing",
        },
        {
            id: "Procedure",
            label: "ref.privilege.privilege_resource.Procedure",
        },
        {
            id: "RouteSwitch",
            label: "ref.privilege.privilege_resource.RouteSwitch",
        },
        {
            id: "Security",
            label: "ref.privilege.privilege_resource.Security",
        },
        {
            id: "Sentinel",
            label: "ref.privilege.privilege_resource.Sentinel",
        },
        {
            id: "Settlement",
            label: "ref.privilege.privilege_resource.Settlement",
        },
        {
            id: "TransactionalDetails",
            label: "ref.privilege.privilege_resource.TransactionalDetails",
        },
        {
            id: "Users",
            label: "ref.privilege.privilege_resource.Users",
        },
    ],
    translate: true,
};

export default privilege_resource;
