import PropTypes from "prop-types";
import { SortableContainer } from "react-sortable-hoc";
import SortableItem from "./Item/SortableItem";
import SortableItemColumn from "./Item/SortableItemColumn";

const SortableList = (props) => {
    const {
        columns,
        id,
        items,
        itemsLabels,
        itemsType,
        dragDisabled,
        onChangeProperty,
        mandatoryItemId,
    } = props;

    return (
        <div>
            {items.map((item, index) => {
                let itemDragDisabled = dragDisabled;
                if (item && (!item.displayed || item.draggable === false)) {
                    itemDragDisabled = true;
                }
                switch (itemsType) {
                    case "column":
                        return (
                            <SortableItemColumn
                                key={`sortable-item-${item.id}`}
                                id={`${id}-item-${item.id}`}
                                label={itemsLabels[item.id]}
                                item={item}
                                index={index}
                                dragDisabled={itemDragDisabled}
                                columns={columns}
                                onChangeProperty={onChangeProperty}
                                mandatoryItemId={mandatoryItemId}
                            />
                        );
                    default:
                        return (
                            <SortableItem
                                key={`sortable-item-${item.id}`}
                                id={`${id}-item-${item.id}`}
                                label={item.label ? item.label : itemsLabels[item.id]}
                                item={item}
                                index={index}
                                dragDisabled={itemDragDisabled}
                                onChangeProperty={onChangeProperty}
                            />
                        );
                }
            })}
        </div>
    );
};

SortableList.propTypes = {
    /** Objet contenant les colonnes avec leurs propriétés */
    columns: PropTypes.object,
    /** True si l'item ne peut pas être déplacé */
    dragDisabled: PropTypes.bool.isRequired,
    /** Identifiant de la liste (servira de préfixe à l'id des items) */
    id: PropTypes.string.isRequired,
    /** Liste des items à afficher */
    items: PropTypes.array.isRequired,
    /** Objet contenant les labels des items à affciher avec les ids en clef */
    itemsLabels: PropTypes.object.isRequired,
    /** Type des items (columns ou autre) afin de conditionner l'affichage */
    itemsType: PropTypes.string,
    /** Identifiant de la colonne qu'on ne peut pas supprimer pour forcer l'utilisateur à n'en avoir qu'une seule au minimum */
    mandatoryItemId: PropTypes.string,
    /** Fonction de callback appelée lors qu'on modifie un item (pas l'ordre) */
    onChangeProperty: PropTypes.func.isRequired,
};

SortableList.defaultProps = {
    columns: {},
    items: [],
    itemsLabels: {},
};

export default SortableContainer(SortableList);
