import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles({
    root: {
        height: "100%",
        width: "100%",
        position: "fixed",
    },
    container: {
        height: "100%",
        width: "100%",
        display: "flex",
        WebkitBackgroundSize: "cover",
        backgroundSize: "cover",
        backgroundPosition: "center left",
        backgroundRepeat: "no-repeat",
        overflowY: "auto",
        "@media (max-width: 1024px)": {
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "center",
        },
        "@media (min-width: 1024px)": {
            alignItems: "center",
            backgroundColor: "white",
            backgroundImage: "unset !important",
        },
    },
    content: {
        width: 360,
        marginLeft: 12,
        marginRight: 10,
        display: "flex",
        flexDirection: "column",
    },
    market: {
        height: "100%",
        width: "100%",
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        paddingLeft: 72,
        WebkitBackgroundSize: "cover",
        backgroundSize: "cover",
        backgroundPosition: "left bottom",
        backgroundRepeat: "no-repeat",
        "@media (max-width: 1024px)": {
            justifyContent: "center",
            alignItems: "flex-start",
            paddingLeft: 0,
            backgroundImage: "unset !important",
        },
    },
});
