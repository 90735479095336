import React from "react";
import Lock from "mdi-material-ui/Lock";
import LockOutline from "mdi-material-ui/LockOutline";
import {
    BlockOutlined,
    HelpOutlined,
    NoEncryption,
    ReportProblemOutlined,
} from "@mui/icons-material";
import SubstractIcon from "../../components/Icon/SubstractIcon";

const authStatus = {
    id: "auth_status",
    data: [
        // id gateway => "No authentication"
        {
            id: "no_authentication",
            label: "ref.transaction.auth_status.no_authentication",
            icon: <BlockOutlined color="error" />,
        },
        // id gateway =>  "Abandoned strong authentication"
        {
            id: "authentication_requested",
            label: "ref.transaction.auth_status.authentication_requested",
            icon: <NoEncryption color="warning" />,
        },
        // id gateway =>  "Unsuccessful authentication attempted"
        {
            id: "authentication_attempted",
            label: "ref.transaction.auth_status.authentication_attempted",
            icon: <ReportProblemOutlined color="warning" />,
        },
        {
            id: "Challenge authentication",
            label: "ref.transaction.auth_status.challenge_authentication",
            icon: <Lock color="success" />,
        },
        {
            id: "Frictionless authentication",
            label: "ref.transaction.auth_status.frictionless_authentication",
            icon: <Lock color="success" />,
        },
        // id gateway =>  "Successful authentication",
        {
            id: "successful_authentication",
            label: "ref.transaction.auth_status.successful_authentication",
            icon: <Lock color="success" />,
        },
        // id gateway =>  "Failed authentication",
        {
            id: "authentication_failed",
            label: "ref.transaction.auth_status.authentication_failed",
            icon: <SubstractIcon color="error" />,
        },
        {
            id: "Unknown authentication",
            label: "ref.transaction.auth_status.unknown_authentication",
            icon: <HelpOutlined color="warning" />,
        },
        {
            id: "authenticable",
            label: "ref.transaction.auth_status.authenticable",
            icon: <LockOutline color="neutral" />,
        },
        {
            id: "not_authenticable",
            label: "ref.transaction.auth_status.not_authenticable",
            icon: <Lock color="neutral" />,
        },
        {
            id: "authentication_impossible",
            label: "ref.transaction.auth_status.authentication_impossible",
            icon: <Lock color="error" />,
        },
        {
            id: "enrollment_unknown",
            label: "ref.transaction.auth_status.enrollment_unknown",
            icon: <Lock color="error" />,
        },
    ],
    translate: true,
};

export default authStatus;
