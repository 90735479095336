import { lazy } from "react";
import { AppCommon } from "@console/core";

// https://reactjs.org/docs/code-splitting.html#route-based-code-splitting
const Root = lazy(() => import("./Root"));

const AppConfig = (props) => {
    return <AppCommon ModuleRoot={Root} module="mkp_config" {...props} />;
};

const AppVendors = (props) => {
    return <AppCommon ModuleRoot={Root} module="mkp_vendors" {...props} />;
};

const AppPaymentOrders = (props) => {
    return <AppCommon ModuleRoot={Root} module="mkp_payment_orders" {...props} />;
};

const AppBatches = (props) => {
    return <AppCommon ModuleRoot={Root} module="mkp_batches" {...props} />;
};

export { AppConfig, AppVendors, AppPaymentOrders, AppBatches };
