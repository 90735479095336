import { useEffect } from "react";
import { usePhrases } from "../services";
import { PageLoading } from "../app/components";

import { AuthRouter } from "./index";

export default function Root() {
    const [phrases, injectPhrases] = usePhrases();

    useEffect(() => {
        // inject module phrases
        injectPhrases("authentication");
    }, [phrases, injectPhrases]);

    // Loading during translations fetch
    if (!phrases.modules || !phrases.modules.authentication) {
        return <PageLoading />;
    }

    return <AuthRouter />;
}
