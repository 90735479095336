import { lazy, Suspense } from "react";

import { PageLoading } from "../app/components";

// https://reactjs.org/docs/code-splitting.html#route-based-code-splitting
const Root = lazy(() => import("./Root"));

export const AppAuthentication = (props) => {
    return (
        <div id="app-unlogged">
            <Suspense fallback={<PageLoading />}>
                <Root {...props} />
            </Suspense>
        </div>
    );
};

export * from "./components";
export * from "./constants";
export * from "./Root";
export * from "./AuthRouter";
