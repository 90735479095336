import { connect } from "react-redux";
import { default as QuickSearchBackdrop } from "../../../components/AppLogged/InnerPage/QuickSearchBackdrop";

const mapStateToProps = (state, ownProps) => {
    return {
        isActive: state.app.topbar.isActive,
        query: state.app.topbar.query,
        errors: state.app.topbar.errors,
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(QuickSearchBackdrop);
