import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles({
    root: {
        position: "fixed",
        width: 60,
        heigh: 60,
        "@media (max-width: 1023px)": {
            display: "flex",
            justifyContent: "flex-end",
            width: "100%",
            padding: "12px 14px",
        },
        "@media (min-width: 1024px)": {
            display: "flex",
            justifyContent: "flex-end",
            width: "100%",
            padding: "12px 14px",
        },
        "&>img": { marginLeft: 16 },
    },
});
