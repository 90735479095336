const user_roles = {
    id: "user_roles",
    endpoint: `${process.env.NX_USER_MANAGEMENT_API_URL}/v1/roles?order_by=type&direction=desc`,
    withSelectedAccounts: false,
    translate: true,
    organizeFunc: (apiResult) => {
        // Parse and format API List
        let rolesList = apiResult.map((row) => ({
            uuid: row.uuid,
            type: row.type,
            id: row.code,
            label: `ref.role.role_name.${row.code}`,
        }));

        // Add Custom to roles
        rolesList.push({
            type: "EXTERNAL",
            id: "Custom",
            label: `ref.role.role_name.Custom`,
            uuid: "Custom",
        });

        // Return list
        return rolesList;
    },
};

export default user_roles;
