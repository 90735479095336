const settlementDays = {
    id: "settlementDays",
    data: [
        {
            id: "1",
            label: "ref.account.settlement_days.monday",
        },
        {
            id: "2",
            label: "ref.account.settlement_days.tuesday",
        },
        {
            id: "3",
            label: "ref.account.settlement_days.wednesday",
        },
        {
            id: "4",
            label: "ref.account.settlement_days.thursday",
        },
        {
            id: "5",
            label: "ref.account.settlement_days.friday",
        },
    ],
    translate: true,
};

export default settlementDays;
