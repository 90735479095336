import React from "react";
import Icon from "@mui/material/Icon";

const SubstractIcon = (props) => {
    return (
        <Icon
            style={{
                ...props.style,
                fontSize: props.size,
                fill: props.htmlColor,
            }}
            className={props.className}
        >
            <svg
                width="16"
                height="21"
                viewBox="0 0 16 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M13.4705 7H13V5C13 2.24 10.76 0 8 0C5.24 0 3 2.24 3 5V7H2.57408L7.6121 12.038L8.02227 12.4482L13.4705 7ZM15.3484 7.52618L9.22435 13.6503L9.96198 14.3879L15.6705 20.0964C15.8786 19.781 16 19.404 16 19V9C16 8.41829 15.7483 7.89251 15.3484 7.52618ZM6.82019 13.6503L6.23401 13.0641L0.674981 7.50506C0.261548 7.87214 0 8.40701 0 9V19C0 19.4154 0.128376 19.8024 0.347418 20.123L6.82019 13.6503ZM1.87831 20.9963L8.02227 14.8524L8.93591 15.766L14.1633 20.9933C14.1094 20.9978 14.055 21 14 21H2C1.95915 21 1.91857 20.9988 1.87831 20.9963ZM5 5C5 3.34 6.34 2 8 2C9.66 2 11 3.34 11 5V7H5V5Z"
                    fill="#CB2B0B"
                />
            </svg>
        </Icon>
    );
};

export default SubstractIcon;
