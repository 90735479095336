import Lock from "mdi-material-ui/Lock";
import LockOpenOutline from "mdi-material-ui/LockOpenOutline";
import BlockIcon from "@mui/icons-material/Block";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import NewReleasesIcon from "@mui/icons-material/NewReleases";

const userStatuses = {
    id: "userStatuses",
    data: [
        {
            id: "CREATED",
            label: "ref.account.user_status.CREATED",
            icon: <NewReleasesIcon htmlColor="#737373" />,
        },
        {
            id: "DELETED",
            label: "ref.account.user_status.DELETED",
            icon: <DeleteForeverIcon htmlColor="#737373" />,
        },
        {
            id: "LOCKED",
            label: "ref.account.user_status.LOCKED",
            icon: <Lock htmlColor="#CB2B0B" />,
        },
        {
            id: "ACTIVATED",
            label: "ref.account.user_status.ACTIVATED",
            icon: <LockOpenOutline htmlColor="#02A17B" />,
        },
        {
            id: "DEACTIVATED",
            label: "ref.account.user_status.DEACTIVATED",
            icon: <BlockIcon htmlColor="#737373" />,
        },
    ],
    translate: true,
};

export default userStatuses;
