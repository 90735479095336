import { useFullscreen } from "../../../hooks";
import { useToggle } from "react-use";
import { TopBarContainer } from "../../containers";
import AppMenu from "../AppLogged/AppMenu";
import { setEventTracker } from "../../../services";

export function LayoutContainer() {
    const { isFullscreen } = useFullscreen();
    // menu state as toggle open/close
    const [menuOpen, toggleMenu] = useToggle(false);

    const onClickMenu = () => {
        setEventTracker("click", {
            event_category: "menu",
            event_action: "menu_click_menu",
        });
        toggleMenu();
    };

    return (
        <>
            {!isFullscreen && <TopBarContainer onClickMenu={onClickMenu} />}
            <AppMenu open={menuOpen} onClose={toggleMenu} onToggleMenu={toggleMenu} />
        </>
    );
}
