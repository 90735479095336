import React from "react";
import PropTypes from "prop-types";
import { withRouterTranslateAndStore } from "../../../../hoc";
import { Error, PageLoading, SearchHistory } from "../../../components";
import * as appActions from "../../../actions/appActions";

class SearchHistoryContainer extends React.Component {
    static propTypes = {
        searchHistory: PropTypes.array,
        selectedAccountIdList: PropTypes.array,
        deleteSearchHistory: PropTypes.func.isRequired,
        deleteAllSearchHistory: PropTypes.func.isRequired,
        loading: PropTypes.bool,
        error: PropTypes.object,
    };

    render() {
        const {
            searchHistory,
            deleteSearchHistory,
            deleteAllSearchHistory,
            loading,
            error,
            authentication,
            addSearchHistory,
            selectedAccountIdList,
            accountEntities,
            businessEntities,
            history,
            phrases,
        } = this.props;

        if (loading) {
            return <PageLoading innerPage />;
        } else if (error) {
            return <Error error={error} />;
        }

        return (
            <SearchHistory
                searchHistory={searchHistory}
                deleteSearchHistory={deleteSearchHistory}
                deleteAllSearchHistory={deleteAllSearchHistory}
                authentication={authentication}
                selectedAccountIdList={selectedAccountIdList}
                accountEntities={accountEntities}
                businessEntities={businessEntities}
                addSearchHistory={addSearchHistory}
                history={history}
                phrases={phrases}
            />
        );
    }
}

const mapStateToProps = (state) => {
    // Get current module from local storage
    const module = window.localStorage.getItem(process.env.NX_SELECTED_MODULE);
    const searchHistory = state.app.global.searchHistory[module];
    return {
        searchHistory: searchHistory && searchHistory.data,
        loading: searchHistory && searchHistory.loading,
        error: searchHistory && searchHistory.error,
        selectedAccountIdList: state.app.global.selectedAccountIdList,
        accountEntities: state.app.global.entities.account,
        businessEntities: state.app.global.entities.business,
    };
};

const mapDispatchToProps = (dispatch) => {
    // Get current module from local storage
    const module = window.localStorage.getItem(process.env.NX_SELECTED_MODULE);
    return {
        addSearchHistory: (history) => dispatch(appActions.addSearchHistory(module, history)),
        deleteSearchHistory: (history) => dispatch(appActions.deleteSearchHistory(module, history)),
        deleteAllSearchHistory: () => dispatch(appActions.deleteAllSearchHistory(module)),
    };
};

export default withRouterTranslateAndStore(
    mapStateToProps,
    mapDispatchToProps
)(SearchHistoryContainer);
