import { Suspense } from "react";
import { createRoot } from "react-dom/client";
import { HiLogo } from "@hipay/design-system/components";

import { WidgetConfig } from "@console/widget";
import { DashboardConfig } from "@console/dashboard";
import { FinancialReportConfig } from "@console/financial_report";
import { IntelligenceConfig } from "@console/intelligence";
import { TransactionConfig } from "@console/transaction";
import { IntegrationConfig } from "@console/integration";
import { AccountConfig } from "@console/account";
import { InvoiceConfig } from "@console/invoice";
import { MarketplaceConfig } from "@console/marketplace";
import { UserConfig } from "@console/user";
import { VerificationConfig } from "@console/verification";
import { MasterRouter } from "@console/core";
import { PaymentMethodsSettings } from "@console/payment-methods-settings";

const container = document.getElementById("root");
const root = createRoot(container!); // createRoot(container!) if you use TypeScript

root.render(
    <Suspense fallback={<HiLogo id="loading_logo" />}>
        <MasterRouter
            modules={{
                dashboard: DashboardConfig.moduleConfig,
                transaction: TransactionConfig.moduleConfig,
                widget: WidgetConfig.moduleConfig,
                invoice: InvoiceConfig.moduleConfig,
                financial_report: FinancialReportConfig.moduleConfig,
                account: AccountConfig.moduleConfig,
                integration: IntegrationConfig.moduleConfigIntegration,
                eterminal: IntegrationConfig.moduleConfigEterminal,
                onboarding: VerificationConfig.moduleConfig,
                intelligence: IntelligenceConfig.moduleConfig,
                user: UserConfig.moduleConfigUser,
                role: UserConfig.moduleConfigRole,
                privilege: UserConfig.moduleConfigPrivilege,
                mkp_config: MarketplaceConfig.moduleConfig,
                mkp_vendors: MarketplaceConfig.moduleConfigVendors,
                mkp_payment_orders: MarketplaceConfig.moduleConfigPaymentOrders,
                mkp_batches: MarketplaceConfig.moduleConfigBatches,
                payment_methods_settings: PaymentMethodsSettings.moduleConfig,
            }}
            routing={[
                ...DashboardConfig.moduleRouting,
                ...TransactionConfig.moduleRouting,
                ...WidgetConfig.moduleRouting,
                ...InvoiceConfig.moduleRouting,
                ...FinancialReportConfig.moduleRouting,
                ...AccountConfig.moduleRouting,
                ...IntegrationConfig.moduleRouting,
                ...VerificationConfig.moduleRouting,
                ...IntelligenceConfig.moduleRouting,
                ...UserConfig.moduleRouting,
                ...MarketplaceConfig.moduleRouting,
                ...PaymentMethodsSettings.moduleRouting,
            ]}
        />
    </Suspense>
);
