const user_gdpr = {
    id: "user_gdpr",
    data: [
        {
            id: "true",
            label: "ref.user.boolean.yes",
        },
        {
            id: "false",
            label: "ref.user.boolean.no",
        },
    ],
    translate: true,
};

export default user_gdpr;
