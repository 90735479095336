import React, { useCallback, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link as BaseLink } from "react-router-dom";
import { getOldInterfaceUrl, getUrlWithAccounts } from "../../../utils/urls";

import { useOktaAuth } from "@okta/okta-react";
import { useInnerPage } from "../../../services/innerPage";
import { useUser } from "../../../services/user";

import { LOGOUT } from "../../actions/actionTypes";
import { hide403 } from "../../actions/appActions";

export default React.forwardRef(function Link(props, ref) {
    const {
        className,
        style,
        to,
        children,
        innerLink,
        target,
        search,
        hash,
        oldInterface,
        disabled,
        withAccounts,
        ...other
    } = props;

    const { oktaAuth } = useOktaAuth();

    const selectedAccountIdList = useSelector((state) => state.app.global.selectedAccountIdList);
    const accountEntities = useSelector((state) => state.app.global.entities.account);
    const dispatch = useDispatch();
    const innerPage = useInnerPage();
    const linkRef = useRef(null);
    const user = useUser();

    useEffect(() => {
        if (linkRef.current) {
            linkRef.current.addEventListener("keydown", (event) => {
                // Check if user pressed enter or space key on module item
                if (event.keyCode === 13 || event.keyCode === 32) {
                    linkRef.current.click();
                }
            });
        }
    }, [to]);

    const handleClick = useCallback(
        (e) => {
            if (typeof innerPage !== "undefined") {
                innerPage.closeInnerPage();
            }
            dispatch(hide403());
            e.stopPropagation();
        },
        [dispatch]
    );

    const logout = () => {
        // Logout user from okta
        oktaAuth.signOut().then((response) => {
            // LOGOUT is called here, to reset all store data.
            dispatch({ type: LOGOUT });
            // remove tokens from local storage
            window.localStorage.removeItem(process.env.NX_JWT_TOKEN_KEY);
        });
    };

    let link = to;

    if (disabled === true) {
        return children;
    }

    // exception for logout
    if (link === "/logout") {
        return (
            <div className={className} style={style} onClick={logout} ref={linkRef} {...other}>
                {children}
            </div>
        );
    } else if (innerLink) {
        // innerLink use BaseLink from react-router
        if (to.match(/\/?\[prefix]\/?/)) {
            link = getUrlWithAccounts(selectedAccountIdList, to);
        }
        if (search || hash) {
            link = {
                pathname: link,
                search: search,
                hash: hash,
            };
        }

        return (
            <BaseLink
                style={style}
                to={link}
                className={className}
                ref={linkRef}
                {...other}
                onClick={handleClick}
            >
                {children}
            </BaseLink>
        );
    }

    // build old interface link
    if (oldInterface) {
        // if user have access on only one account, it should be on the redirect url (PSYCHE-4322)
        const accountIdList =
            Object.keys(accountEntities).length === 1 && !user.allAccountsAccess
                ? [Object.keys(accountEntities)[0]]
                : selectedAccountIdList;
        link = getOldInterfaceUrl(to, accountIdList, withAccounts);
    }

    return (
        <a style={style} href={link} className={className} target={target} ref={linkRef} {...other}>
            {children}
        </a>
    );
});
