import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles({
    root: {
        width: 40,
        height: 40,
        color: "#20272B",
        backgroundColor: "white",
        marginBottom: 12,
        padding: "unset",
        boxShadow: "0pt 2pt 3px #24242429",
        "&:hover": { backgroundColor: "white" },
    },
});
