import { AppPaymentMethods } from "../App";

/**
 * Config
 *
 * On retrouve ici la configuration du module.
 *  - Ses attributes
 *  - La config du module selector de la quicksearch
 *  - Les default fields presents dans le quickForm & par défaut sur le formulaire de recherche (s'il est activé)
 *    ainsi que leurs valeurs par défaut (ou null)
 *  - La liste des features qui sont activées pour ce module
 *
 */
export const moduleConfig = {
    id: "payment-methods-settings", // module key
    icon: "hi_hosted_payment_page_menu", // module icon on module selector
    label: "payment-methods-settings", // translation path
    path: "payment-methods-settings", // module uri from search
    route: "[prefix]/payment-methods-settings", // module uri from quicksearch
    features: [], // module features enabled
    acl: {
        module: [
            {
                module: "settings",
                controller: "console",
                action: "hosted-page",
            },
        ],
    },
};

export const moduleRouting = [
    {
        key: "payment-methods-settings", // module key
        path: ["/payment-methods-settings", "/accounts-:ids/payment-methods-settings"], // module matching uri
        component: AppPaymentMethods, // module parent component
    },
];
