import user_type from "./references/user_type";
import user_status from "./references/user_status";
import user_roles from "./references/user_roles";
import user_departments from "./references/user_departments";
import user_partnerships from "./references/user_partnerships";
import user_gdpr from "./references/user_gdpr";
import user_privileges from "./references/user_privileges";
import timezones_names from "./references/timezones_names";

const references = {
    user_type,
    user_status,
    user_roles,
    user_departments,
    user_partnerships,
    user_gdpr,
    user_privileges,
    timezones_names,
};

export default references;
