import { useMemo } from "react";
import styled from "styled-components/macro";
import { useLocation } from "react-router-dom";

import { useLocalStorage } from "react-use";
import { useUserInfo, useUser } from "../../../../services/user";
import { useSelector } from "react-redux";
import { useP } from "../../../../services/i18n";

import { getOldInterfaceUrl } from "../../../../utils/urls";

import {
    MenuItemType,
    MenuSubItemType,
    menuItems,
    menuUserItems,
    MenuFavoriteType,
} from "./menu-items";
import { transformMenuItemsListToObject } from "./menu-utils";

import {
    MenuBlockLogo,
    MenuBlockUser,
    MenuBlockFavorites,
    MenuBlockTitle,
    MenuBlockItem,
} from "./components";
import { Drawer, DrawerProps, List, Theme } from "@mui/material";
import { setEventTracker } from "../../../../services/tracker";
import { useCheckModuleAccess } from "../../../../services/module/useCheckModuleAccess";

export interface AppMenuProps {
    /* If tru, menu drawer is open */
    open: boolean;
    /* Callback when the menu is closing */
    onClose: DrawerProps["onClose"];
    /* Manual action to open/close the menu */
    onToggleMenu: () => void;
}

const MAX_FAVORITES = 3;

const AppMenu = ({ open, onClose, onToggleMenu, ...other }: AppMenuProps) => {
    const user = useUser();
    const userInfo = useUserInfo();
    const location = useLocation();
    const p = useP();
    const { checkModuleAccess } = useCheckModuleAccess();

    const accountEntities = useSelector((state: any) => state.app.global.entities.account);
    const accountsFetched = useSelector((state: any) => state.app.global.entities.accountFetched);
    const selectedAccountIdList = useSelector(
        (state: any) => state.app.global.selectedAccountIdList
    );

    const menuItemsAsObject = useMemo(() => {
        return transformMenuItemsListToObject(menuItems);
    }, []);
    const menuUserItemsAsObject = useMemo(() => {
        return transformMenuItemsListToObject(menuUserItems);
    }, []);

    // Get TPP old interface URL
    const oldInterfaceUrl = useMemo(() => {
        // If user has access to only 1 account, it should be redirected with this account in url (PSYCHE-4322)
        const accountIdList =
            Object.keys(accountEntities).length === 1 && !user.allAccountsAccess
                ? [Object.keys(accountEntities)[0]]
                : selectedAccountIdList;
        return getOldInterfaceUrl("/user/dashboard", accountIdList, true);
    }, [accountEntities, selectedAccountIdList]);

    // Build url prefix from selected account id list
    const consoleUrlPrefix = useMemo(() => {
        return selectedAccountIdList.length === 0
            ? ""
            : `/accounts-${selectedAccountIdList.join("-")}`;
    }, [selectedAccountIdList]);

    const activeItem = useMemo(() => {
        // Remove accounts prefix from current locations
        // Remove parameters in route if exist (/users?fp_status=ACTIVATED => /users)
        return location.pathname.replace(consoleUrlPrefix, "").split("?")[0];
    }, [location, consoleUrlPrefix]);

    // Get favorites in local storage
    const [favList, setFavorites] = useLocalStorage<string[]>(
        `hipay-menu-favorites-${userInfo.uuid}`,
        []
    );

    // Check if item can be displayed in menu
    const checkItemAccess = (item: MenuItemType | MenuSubItemType) => {
        // If user has 0 account linked, display only onboarding module
        if (
            !userInfo.all_account_access &&
            accountsFetched &&
            Object.keys(accountEntities).length === 0 &&
            item.module !== "onboarding"
        ) {
            return false;
        }

        if (item.module) {
            return checkModuleAccess(item.module);
        }
        return true;
    };

    // Filter menu items to keep only those accessible by the connected user
    const filteredMenuItems = useMemo(() => {
        // Filter sub-items
        const filteredSubItems = menuItems.map((item) => {
            if (!item.subItems) {
                return { ...item };
            }
            return {
                ...item,
                // Remove sub items not accessible
                subItems: item.subItems.filter((subItem) => checkItemAccess(subItem)),
            };
        });

        // Filter main items
        return filteredSubItems.filter((item: MenuItemType) => {
            if (item.subItems) {
                // If no sub items after filtering, remove item from menu
                return item.subItems.length !== 0;
            }

            // Remove items not accessible
            return checkItemAccess(item);
        });
    }, [user, accountsFetched]);

    // Build items list from favorites keys
    const builtFavList = useMemo(() => {
        return favList?.map((fav) => {
            const [itemKey, itemSubKey] = fav.split("/");

            return {
                key: fav,
                type: "console",
                icon: menuItemsAsObject[itemKey].icon,
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore (OK because only sub items are in favorites)
                target: menuItemsAsObject[itemKey]?.subItems[itemSubKey].target,
            } as MenuFavoriteType;
        });
    }, [favList]);

    const handleItemClick = (itemClicked: string) => {
        // Get items from menu config
        let itemObject;
        const [itemKey, subItemKey] = itemClicked.split("/");
        if (subItemKey) {
            const subItems = menuItemsAsObject[itemKey].subItems;
            if (subItems) {
                itemObject = subItems[subItemKey];
            } else {
                return;
            }
        } else {
            itemObject = menuItemsAsObject[itemKey];
        }

        goToItem(itemObject);
    };

    const handleItemUserClick = (itemClicked: string) => {
        const itemObject = menuUserItemsAsObject[itemClicked] as MenuItemType;
        goToItem(itemObject);
    };

    const goToItem = (item: MenuItemType | MenuSubItemType) => {
        // Send analytics
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore (OK because only sub items are in favorites)
        setEventTracker("menu_click", {
            event_category: "menu",
            event_action: "menu_click_" + (item.key ? item.key : "menu"),
        });

        // If we are already on the module page, close menu to avoid params reset
        if (item.type === "console" && item.target === activeItem) {
            onToggleMenu();
            return;
        }

        // Close menu
        onToggleMenu();
    };

    // When item star is clicked, add or remove from local storage.
    const handleFavoriteClick = (itemKey: string) => {
        if (favList?.includes(itemKey)) {
            // Remove from favorites
            setFavorites(favList?.filter((i) => i !== itemKey));
        } else {
            // If max fav reached, do not add it
            if (favList && favList?.length >= MAX_FAVORITES) {
                return;
            }
            // Add to favorites
            setFavorites(favList ? [...favList, itemKey] : [itemKey]);
        }
    };

    return (
        <Drawer {...other} open={open} onClose={onClose} classes={{ paper: "AppMenu-paper" }}>
            <MenuBlockLogo onClick={() => handleItemClick("dashboard")} />
            <MenuBlockUser
                onItemClick={handleItemUserClick}
                activeItemPath={activeItem}
                consoleUrlPrefix={consoleUrlPrefix}
            />
            <MenuBlockFavorites
                favorites={builtFavList}
                maxFav={MAX_FAVORITES}
                onItemClick={handleItemClick}
                onRemoveFavorite={handleFavoriteClick}
                activeItemPath={activeItem}
                consoleUrlPrefix={consoleUrlPrefix}
            />
            <div className="AppMenu-items">
                <MenuBlockTitle className="AppMenu-items-title" label={p.t("app.menu.pages")} />
                <List className="AppMenu-items-list">
                    {filteredMenuItems.map((item: MenuItemType) => (
                        <MenuBlockItem
                            key={item.key}
                            item={item}
                            activeItemPath={activeItem}
                            consoleUrlPrefix={consoleUrlPrefix}
                            oldInterfaceUrl={oldInterfaceUrl}
                            favorites={builtFavList}
                            maxFavoritesReached={
                                !!builtFavList && builtFavList.length >= MAX_FAVORITES
                            }
                            onItemClick={handleItemClick}
                            onFavoriteClick={handleFavoriteClick}
                        />
                    ))}
                </List>
            </div>
        </Drawer>
    );
};

const StyledAppMenu = styled(AppMenu)`
    .AppMenu-paper {
        width: 270px;

        color: white;
        background-color: ${({ theme }: { theme: Theme }) => theme.palette.info.main};

        /* Hide scrollbar */
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
        &::-webkit-scrollbar {
            /* Chrome, Safari and Opera */
            display: none;
        }

        .AppMenu-items {
            &-title {
                padding: 16px;
                padding-bottom: 0;
            }

            &-list {
                padding-top: 0;
            }
        }
    }
`;

export default StyledAppMenu;
