const user_departments = {
    id: "user_departments",
    data: [
        {
            id: "ACCOUNT_MANAGER",
            label: "ref.user.user_departments.ACCOUNT_MANAGER",
            type: "INTERNAL",
        },
        {
            id: "ACCOUNTING",
            label: "ref.user.user_departments.ACCOUNTING",
            type: "INTERNAL",
        },
        {
            id: "CUSTOMER_SERVICE",
            label: "ref.user.user_departments.CUSTOMER_SERVICE",
            type: "EXTERNAL",
        },
        {
            id: "EXTERNAL_INTEGRATOR",
            label: "ref.user.user_departments.EXTERNAL_INTEGRATOR",
            type: "EXTERNAL",
        },
        {
            id: "FINANCE",
            label: "ref.user.user_departments.FINANCE",
        },
        {
            id: "HUMAN_RESOURCES",
            label: "ref.user.user_departments.HUMAN_RESOURCES",
            type: "INTERNAL",
        },
        {
            id: "LEGAL",
            label: "ref.user.user_departments.LEGAL",
        },
        {
            id: "MARKETING",
            label: "ref.user.user_departments.MARKETING",
        },
        {
            id: "OPERATIONS",
            label: "ref.user.user_departments.OPERATIONS",
            type: "EXTERNAL",
        },
        {
            id: "OTHER",
            label: "ref.user.user_departments.OTHER",
        },
        {
            id: "PARTNERSHIP",
            label: "ref.user.user_departments.PARTNERSHIP",
            type: "INTERNAL",
        },
        {
            id: "PAYOPS",
            label: "ref.user.user_departments.PAYOPS",
            type: "INTERNAL",
        },
        {
            id: "PRODUCT_MANAGEMENT",
            label: "ref.user.user_departments.PRODUCT_MANAGEMENT",
        },
        {
            id: "RISK",
            label: "ref.user.user_departments.RISK",
            type: "INTERNAL",
        },
        {
            id: "SALES",
            label: "ref.user.user_departments.SALES",
            type: "INTERNAL",
        },
        {
            id: "SALESOPS",
            label: "ref.user.user_departments.SALESOPS",
            type: "INTERNAL",
        },
        {
            id: "TECHNICAL",
            label: "ref.user.user_departments.TECHNICAL",
            type: "INTERNAL",
        },
        {
            id: "TECHNICAL_SERVICE",
            label: "ref.user.user_departments.TECHNICAL_SERVICE",
            type: "EXTERNAL",
        },
        {
            id: "TECHNICAL_SUPPORT",
            label: "ref.user.user_departments.TECHNICAL_SUPPORT",
            type: "INTERNAL",
        },
        {
            id: "TOP_MANAGEMENT",
            label: "ref.user.user_departments.TOP_MANAGEMENT",
        },
    ],
    translate: true,
};

export default user_departments;
