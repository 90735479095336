import { lazy } from "react";
import { AppCommon } from "@console/core";

const Root = lazy(() => import("./ObApp"));

function AppVerification() {
    return <AppCommon ModuleRoot={Root} module={"onboarding"} />;
}

export default AppVerification;
