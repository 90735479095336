import { getNoticeSteps } from "../services/guidetour/noticeSteps";
import { getSearchSteps } from "../services/guidetour/searchSteps";

export function getGuideTourSteps(moduleState, tourOptions, p, setInitCallback) {
    let notice = document.getElementById("notice-content");
    let path = "/" + window.location.pathname.split("/")[1];
    let accountId;

    if (path === "/accounts" && window.location.pathname.split("/").length === 3) {
        accountId = window.location.pathname.split("/")[2];
        return notice ? getNoticeSteps(tourOptions, p, accountId, setInitCallback) : [];
    }

    return getSearchSteps(tourOptions, p, setInitCallback);
}
