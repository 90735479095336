import useToggle from "react-use/lib/useToggle";
import { useTheme } from "@mui/material/styles";
import { useP } from "../../../../../services/i18n";
import { RowLayout } from "./RowLayout";
import CellBuilder from "../../../Table/CellBuilder";

export function RowExpandable(props) {
    const { actions = [], attribute, data, hideLabel } = props;

    const p = useP();
    const [open, toggleOpen] = useToggle(false);
    const theme = useTheme();

    return (
        <RowLayout
            attribute={attribute}
            actions={[
                {
                    type: "text",
                    name: "expand",
                    value: open
                        ? p.t("common.notice.content.see_less")
                        : p.t("common.notice.content.see_more"),
                    onClick: toggleOpen,
                    style: {
                        color: open ? theme.palette.neutral.main : theme.palette.primary.main,
                    },
                },
                ...actions,
            ]}
            expanded={open}
            hideLabel={hideLabel}
        >
            <CellBuilder attribute={attribute} {...attribute.displayOptions} data={data} />
        </RowLayout>
    );
}
