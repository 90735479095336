import { Collapse, List } from "@mui/material";
import { useState } from "react";
import { useP } from "../../../../../services/i18n";
import { MenuFavoriteType, MenuItemType } from "../menu-items";
import { MenuItem } from ".";

export interface MenuBlockItemProps {
    /* CSS Classname */
    className?: string;
    /* menu item */
    item: MenuItemType;
    /* Block main background color */
    color?: string;
    /* Path of selected item in menu */
    activeItemPath?: string;
    /* Url prefix to add before target url */
    consoleUrlPrefix?: string;
    /* TPP url to redirect */
    oldInterfaceUrl?: string;
    /* Favorites list */
    favorites?: MenuFavoriteType[];
    /* If true, max number of favorites is reached */
    maxFavoritesReached?: boolean;
    /* Callback when an item is clicked */
    onItemClick: (item: string) => void;
    /* Callback when the favorite star is clicked */
    onFavoriteClick?: (item: string) => void;
}

/**
 * Item that handle a primary item and sub items.
 * The primary item can expand if there are sub items.
 */
const MenuBlockItem = ({
    className,
    item,
    color,
    activeItemPath,
    consoleUrlPrefix,
    oldInterfaceUrl,
    favorites,
    maxFavoritesReached = false,
    onItemClick,
    onFavoriteClick,
}: MenuBlockItemProps) => {
    const p = useP();
    const keyItems =
        process.env["NX_IS_INTERNAL"] === "true"
            ? "app.menu.items-internal."
            : "app.menu.items-external.";
    const expandable = !!item.subItems;
    const [expanded, setExpanded] = useState(false);

    const handleParentItemClick = () => {
        if (expandable) {
            setExpanded(!expanded);
        } else {
            onItemClick(item.key);
        }
    };

    const handleChildItemClick = (subItemKey: string) => {
        onItemClick(`${item.key}/${subItemKey}`);
    };

    const handleFavoriteClick = (subItemKey: string) => {
        onFavoriteClick && onFavoriteClick(`${item.key}/${subItemKey}`);
    };

    return (
        <div className={className}>
            <MenuItem
                id={"menuItem-" + item.key}
                item={item}
                type="main"
                label={p.t(keyItems + item.key)}
                color={color}
                leftIcon={item.icon}
                onItemClick={handleParentItemClick}
                expandable={expandable}
                expanded={expanded}
                active={activeItemPath === item.target}
                consoleUrlPrefix={consoleUrlPrefix}
                oldInterfaceUrl={oldInterfaceUrl}
            />
            {item.subItems && (
                <Collapse in={expanded}>
                    <List sx={{ pt: 0 }}>
                        {Object.values(item.subItems).map((subItem) => (
                            <MenuItem
                                id={"menuItem-" + subItem.key}
                                key={subItem.key}
                                item={subItem}
                                type="secondary"
                                color={color}
                                dense
                                label={p.t(keyItems + subItem.key)}
                                onItemClick={() => handleChildItemClick(subItem.key)}
                                withFavorites
                                maxFavoritesReached={maxFavoritesReached}
                                favoriteChecked={favorites?.some(
                                    (i) => i.key === item.key + "/" + subItem.key
                                )}
                                onFavoriteClick={() => handleFavoriteClick(subItem.key)}
                                active={activeItemPath === subItem.target}
                                consoleUrlPrefix={consoleUrlPrefix}
                            />
                        ))}
                    </List>
                </Collapse>
            )}
        </div>
    );
};

export default MenuBlockItem;
