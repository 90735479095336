import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { isMobile } from "react-device-detect";
import HistorySuggestionItem from "../../../../../containers/AppLogged/TopBar/QuickSearch/Suggestions/HistorySuggestionItemContainer";
import NoticeSuggestionItem from "./NoticeSuggestionItem";
import TargetFieldSuggestionItem from "./TargetFieldSuggestionItem";
import OperatorSuggestionItem from "./OperatorSuggestionItem";
import KeywordSuggestionItem from "./KeywordSuggestionItem";
import ClauseSuggestionItem from "./ClauseSuggestionItem";
import DefaultSuggestionItem from "./DefaultSuggestionItem";
import {
    SUGGESTION_FIELD,
    SUGGESTION_HISTORY,
    SUGGESTION_KEYWORD,
    SUGGESTION_OPERATOR,
    SUGGESTION_CLAUSE,
    SUGGESTION_VALUE,
    SUGGESTION_NOTICE,
} from "../../../../../../services/qsearch/SuggestionManager";
import styled from "styled-components/macro";
import Typography from "@mui/material/Typography";

class SuggestionItemBuilder extends React.Component {
    static propTypes = {
        hasDivider: PropTypes.bool.isRequired,
        isFocus: PropTypes.bool,
        onClick: PropTypes.func,
        suggestion: PropTypes.object.isRequired,
        updateFocusIndex: PropTypes.func,
    };

    static defaultProps = { hasDivider: false };

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return (
            JSON.stringify(nextProps.suggestion) !== JSON.stringify(this.props.suggestion) ||
            nextProps.isFocus !== this.props.isFocus
        );
    }

    handleMouseEnter = () => {
        this.props.updateFocusIndex(this.props.index + 1);
    };

    render() {
        const { suggestion, hasDivider, onClick, isFocus, module } = this.props;

        let content;
        switch (suggestion.type) {
            case SUGGESTION_OPERATOR:
                content = <OperatorSuggestionItem suggestion={suggestion} />;
                break;
            case SUGGESTION_KEYWORD:
                content = <KeywordSuggestionItem suggestion={suggestion} />;
                break;
            case SUGGESTION_VALUE:
            case SUGGESTION_CLAUSE:
                content = <ClauseSuggestionItem suggestion={suggestion} module={module} />;
                break;
            case "history":
            case SUGGESTION_HISTORY:
                content = <HistorySuggestionItem suggestion={suggestion} />;
                break;
            case "notice":
            case SUGGESTION_NOTICE:
                content = <NoticeSuggestionItem suggestion={suggestion} module={module} />;
                break;
            case "text":
            case SUGGESTION_FIELD:
                content = <TargetFieldSuggestionItem suggestion={suggestion} module={module} />;
                break;
            default:
                content = <DefaultSuggestionItem suggestion={suggestion} module={module} />;
                break;
        }

        let divider = hasDivider ? <div className="divider" /> : null;

        return (
            <div className={this.props.className}>
                <div
                    ref={(ref) => (this.itemRef = ref)}
                    tabIndex={0}
                    className={classNames("suggestion", {
                        "suggestion-focus": isFocus,
                        "suggestion-mobile": isMobile,
                    })}
                    onClick={onClick(suggestion)}
                    onMouseEnter={this.handleMouseEnter}
                >
                    {divider}
                    <Typography variant="body2" className="typography-wrapper" component="div">
                        {content}
                    </Typography>
                </div>
            </div>
        );
    }
}

export const StyledSuggestionItemBuilder = styled(SuggestionItemBuilder)`
    .suggestion {
        cursor: pointer;
        display: flex;
        align-items: center;
        width: 100%;
        height: 48px;
        padding: 7px 13px;
        position: relative;
        &:hover {
            background-color: #fafafa;
        }
    }

    .suggestion-focus {
        background-color: ${({ theme }) => theme.palette.action.hover};
    }

    .suggestion-mobile {
        height: 32px;
    }

    .mobile-item {
        width: 100%;
    }

    .item {
        display: flex;
        align-items: center;
        width: 100%;
    }

    .history-item {
        justify-content: space-between;
    }

    .text-div-mobile {
        display: inline;
        padding-left: 0px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }

    .text-div {
        display: inline;
        padding-left: 12px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }

    .divider {
        height: 1;
        background-color: ${({ theme }) => theme.palette.divider};
        padding: 0px 13px;
        position: absolute;
        top: 0px;
        width: calc(100% - 26px);
    }

    .suggestion-item-right-icon {
        padding-left: 16px;
    }

    .right-item {
        display: flex;
        flex-wrap: nowrap;
        justify-content: flex-end;
        align-items: center;
        min-width: 160px;
        text-transform: uppercase;
        color: ${({ theme }) => theme.palette.neutral.light};
        margin-top: 5px;
    }

    .left-item {
        display: flex;
        align-items: center;
        overflow: hidden;
    }

    .typography-wrapper {
        color: ${({ theme }) => theme.palette.neutral.light};
        width: inherit;
    }
`;

export default StyledSuggestionItemBuilder;
