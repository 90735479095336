import FullscreenExit from "mdi-material-ui/FullscreenExit";
import HiIconButton from "@hipay/hipay-material-ui/HiIconButton";
import { useFullscreen } from "../../../hooks";
import { useP } from "../../../services";

/**
 * @param props
 * @returns {*}
 * @constructor
 */
export function ExitFullscreen(props) {
    const { className } = props;
    const p = useP();
    const { isFullscreen, toggle } = useFullscreen();

    if (!isFullscreen) {
        return null;
    }

    const handleClick = (event) => {
        event.stopPropagation();
        toggle();
    };

    return (
        <HiIconButton
            className={className}
            data-name="fullscreen-exit-button"
            onClick={handleClick}
            title={p.t("app.topbar.actions.fullscreen_exit")}
            color="info"
        >
            <FullscreenExit />
        </HiIconButton>
    );
}
