import React from "react";
import PropTypes from "prop-types";
import HiLoader from "@hipay/hipay-material-ui/HiLoader";
import styled from "styled-components/macro";

class Loader extends React.Component {
    static propTypes = { loading: PropTypes.bool };

    render() {
        const { loading } = this.props;

        return (
            <div className={this.props.className}>{loading && <HiLoader className="loader" />}</div>
        );
    }
}

const StyledLoader = styled(Loader)`
    display: flex;
    width: 100%;
    height: 24px;

    .loader {
        margin: auto;
    }
`;

export default StyledLoader;
