import { combineReducers } from "redux";

import { alert } from "./alert";
import { authentication } from "./authentication";
import { getFeatures as features } from "./features";
import { globalReducer as global } from "./global";
import { guidetour } from "./guidetour";
import { settings } from "./settings";
import { snackbar } from "./snackbar";
import { topbar } from "./topbar";
import { user } from "./user";

export const rootReducer = combineReducers({
    alert,
    authentication,
    features,
    global,
    guidetour,
    settings,
    snackbar,
    topbar,
    user,
});
