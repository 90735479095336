import { lazy } from "react";
import { AppCommon } from "@console/core";

// https://reactjs.org/docs/code-splitting.html#route-based-code-splitting
const Root = lazy(() => import("./Root"));

function AppIntelligence(props: any) {
    return <AppCommon ModuleRoot={Root} module={"intelligence"} {...props} />;
}

export default AppIntelligence;
