import { connect } from "react-redux";
import pure from "recompose/pure";
import { default as HistorySuggestionItem } from "../../../../../components/AppLogged/TopBar/QuickSearch/SuggestionList/HistorySuggestionItem";

const mapStateToProps = (state, ownProps) => {
    return {
        accountEntities: state.app.global.entities.account,
        businessEntities: state.app.global.entities.business,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {};
};

const pureHistorySuggestionItem = pure(HistorySuggestionItem);

export default connect(mapStateToProps, mapDispatchToProps)(pureHistorySuggestionItem);
