const settlementDelayCodes = {
    id: "settlementDelayCodes",
    endpoint: () => {
        return `${process.env.NX_ACCOUNT_API_URL}/enum/settlement-delay-codes?_locale=${window.USER_LANGUAGE}`;
    },
    withSelectedAccounts: false,
    translate: true,
    organizeFunc: (data) => {
        if (Array.isArray(data["hydra:member"])) {
            return data["hydra:member"].map((item) => ({
                id: item.id,
                label: () => item.label,
            }));
        }
        return data;
    },
};

export default settlementDelayCodes;
