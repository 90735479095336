import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { DialogTitleContent } from "./DialogTitleContent";

interface DialogTemplateProps {
    className?: string;
    open: boolean;
    handleClose: () => void;
    title: string;
    content: any;
    actions?: any[];
}

export const DialogTemplate = (props: DialogTemplateProps) => {
    return (
        <Dialog open={props.open} maxWidth={"md"} fullWidth={true}>
            <DialogTitle>
                <DialogTitleContent title={props.title} handleClose={props.handleClose} />
            </DialogTitle>
            <DialogContent>{props.content}</DialogContent>
            <DialogActions>{props.actions}</DialogActions>
        </Dialog>
    );
};
