/**
 * Available locales
 *
 * @type {string[]}
 */
export const availableLocales = {
    fr_FR: {
        id: "fr_FR",
        label: "FR",
    },
    en_US: {
        id: "en_US",
        label: "EN",
    },
    it_IT: {
        id: "it_IT",
        label: "IT",
    },
};
