import { RSAA } from "redux-api-middleware";
import * as actionTypes from "./actionTypes";
import { hasOneValue } from "../../utils/arrays";

export const setSelectedAccountIdList = (selectedAccountIdList) => {
    return {
        type: actionTypes.SET_SELECTED_ACCOUNT_ID_LIST,
        selectedAccountIdList,
    };
};

export const setSelectedBusinessIdList = (selectedBusinessIdList) => {
    return {
        type: actionTypes.SET_SELECTED_BUSINESS_ID_LIST,
        selectedBusinessIdList,
    };
};

/**
 * (MERCHANT USER)
 * Return all available accounts for user
 * Don't use this action for internal user because they potentially have access to every accounts...
 * -> prefer use fetchSelectedAccountList
 *
 * GET /accounts
 * @returns {{}}
 */
export const fetchSelectableAccountList = () => {
    return {
        [RSAA]: {
            endpoint: "/accounts",
            method: "GET",
            types: [
                {
                    type: actionTypes.FETCH_ACCOUNT_LIST_REQUEST,
                    meta: { setLoadingAccounts: true },
                },
                {
                    type: actionTypes.FETCH_ACCOUNT_LIST_SUCCESS,
                    meta: { setLoadingAccounts: true },
                },
                {
                    type: actionTypes.FETCH_ACCOUNT_LIST_FAILURE,
                    meta: { setLoadingAccounts: true },
                },
            ],
        },
    };
};

/**
 * (INTERNAL USER)
 * GET /accounts?accountId=[SELECTED_ACCOUNT_ID_LIST]
 *
 * Return available accounts by specifying account ids
 *
 * @param accountIdList
 * @returns {*}
 */
export const fetchSelectedAccountList = (accountIdList = []) => {
    if (accountIdList.length && !hasOneValue(accountIdList, "_every")) {
        return {
            [RSAA]: {
                endpoint: "/accounts?accountId=" + accountIdList.join(","),
                method: "GET",
                types: [
                    {
                        type: actionTypes.FETCH_ACCOUNT_LIST_REQUEST,
                        meta: { setLoadingAccounts: false },
                    },
                    {
                        type: actionTypes.FETCH_ACCOUNT_LIST_SUCCESS,
                        meta: { setLoadingAccounts: false },
                    },
                    {
                        type: actionTypes.FETCH_ACCOUNT_LIST_FAILURE,
                        meta: { setLoadingAccounts: false },
                    },
                ],
            },
        };
    }
    return {
        type: actionTypes.PERSIST_SEARCH_ACCOUNT_AND_BUSINESS_ENTITIES,
        data: {
            accountEntities: {},
            businessEntities: {},
        },
    };
};

/**
 * Sauvegarde dans le store (app.entities.*) les entitées sélectionnées account & business de l'accountSelector
 *
 * @param accountEntities
 * @param businessEntities
 * @returns {{type: string, data: {accountEntities, businessEntities}}}
 */
export const persistSearchAccountAndBusinessEntities = (
    accountEntities = {},
    businessEntities = {}
) => {
    return {
        type: actionTypes.PERSIST_SEARCH_ACCOUNT_AND_BUSINESS_ENTITIES,
        data: {
            accountEntities,
            businessEntities,
        },
    };
};

export const activeTopBar = (query = null, errors = null) => {
    return {
        type: actionTypes.ACTIVE_TOP_BAR,
        meta: {
            query,
            errors,
        },
    };
};

export const inactiveTopBar = () => {
    return { type: actionTypes.INACTIVE_TOP_BAR };
};

export const addSnackBar = (snackbar) => {
    return {
        type: actionTypes.SET_SNACKBAR,
        meta: { snackbar },
    };
};

export const closeSnackBar = () => {
    return { type: actionTypes.CLOSE_SNACKBAR };
};

export const addAlert = (alert) => {
    return {
        type: actionTypes.SET_ALERT,
        alert,
    };
};

export const closeAlert = () => {
    return { type: actionTypes.CLOSE_ALERT };
};

export const setLoadingMessage = (message) => {
    return {
        type: actionTypes.SET_LOADING_MESSAGE,
        loadingMessage: message,
    };
};

/**
 * GET /histories
 * @returns {function(*=)}
 */
export const fetchSearchHistory = (module) => {
    return {
        [RSAA]: {
            endpoint: `/histories?module=${module}`,
            method: "GET",
            types: [
                {
                    type: actionTypes.FETCH_SEARCH_HISTORY_REQUEST,
                    meta: { module },
                },
                {
                    type: actionTypes.FETCH_SEARCH_HISTORY_SUCCESS,
                    meta: { module },
                },
                {
                    type: actionTypes.FETCH_SEARCH_HISTORY_FAILURE,
                    meta: { module },
                },
            ],
        },
    };
};

/**
 * POST /histories
 * @returns {function(*=)}
 */
export const addSearchHistory = (module, history) => {
    return {
        [RSAA]: {
            endpoint: "/histories",
            method: "POST",
            body: JSON.stringify(history),
            types: [
                actionTypes.ADD_SEARCH_HISTORY_REQUEST,
                {
                    type: actionTypes.ADD_SEARCH_HISTORY_SUCCESS,
                    meta: { module },
                },
                actionTypes.ADD_SEARCH_HISTORY_FAILURE,
            ],
        },
    };
};

/**
 * DELETE /histories/{id}
 * @returns {function(*=)}
 */
export const deleteSearchHistory = (module, history) => {
    return {
        [RSAA]: {
            endpoint: "/histories/" + history["historyId"],
            method: "DELETE",
            types: [
                actionTypes.DELETE_SEARCH_HISTORY_REQUEST,
                {
                    type: actionTypes.DELETE_SEARCH_HISTORY_SUCCESS,
                    meta: {
                        history,
                        module,
                    },
                },
                actionTypes.DELETE_SEARCH_HISTORY_FAILURE,
            ],
        },
    };
};

/**
 * DELETE /histories
 * @returns {function(*=)}
 */
export const deleteAllSearchHistory = (module) => {
    return {
        [RSAA]: {
            endpoint: `/histories?module=${module}`,
            method: "DELETE",
            types: [
                actionTypes.DELETE_SEARCH_HISTORY_REQUEST,
                {
                    type: actionTypes.DELETE_SEARCH_HISTORY_SUCCESS,
                    meta: { module },
                },
                actionTypes.DELETE_SEARCH_HISTORY_FAILURE,
            ],
        },
    };
};

export const dispatch403 = () => {
    return { type: actionTypes.DISPATCH_403 };
};

export const hide403 = () => {
    return { type: actionTypes.HIDE_403 };
};

export const addExport = (data) => {
    return {
        type: actionTypes.ADD_EXPORT_SUCCESS,
        data,
    };
};

export const fetchRecurringExportList = (module) => {
    return {
        [RSAA]: {
            endpoint: `/exports?module=${module}&withExportFiles&recurrence=NOT%20once`,
            method: "GET",
            types: [
                actionTypes.FETCH_RECURRING_EXPORT_LIST_REQUEST,
                actionTypes.FETCH_RECURRING_EXPORT_LIST_SUCCESS,
                actionTypes.FETCH_RECURRING_EXPORT_LIST_FAILURE,
            ],
        },
    };
};

export const removeExportIntent = (exportItem, p) => {
    return {
        type: actionTypes.REMOVE_RECURRING_EXPORT_INTENT,
        meta: {
            snackbar: {
                message: "common.search.export.list.delete_intent",
                messageParams: { filename: exportItem.filePrefix },
                undo: actionTypes.SNACKBAR_UNDO_ACTION_REFUSE,
                confirm: () => removeExport(exportItem),
                refuse: () => ({
                    type: actionTypes.REMOVE_RECURRING_EXPORT_CANCELED,
                    meta: { exportItem },
                }),
            },
            exportItem,
        },
    };
};

export const removeExport = (exportItem) => {
    return {
        [RSAA]: {
            endpoint: `/exports/${exportItem.exportId}`,
            method: "DELETE",
            types: [
                {
                    type: actionTypes.REMOVE_RECURRING_EXPORT_REQUEST,
                    meta: { exportItem },
                },
                {
                    type: actionTypes.REMOVE_RECURRING_EXPORT_SUCCESS,
                    meta: { exportItem },
                },
                {
                    type: actionTypes.REMOVE_RECURRING_EXPORT_FAILURE,
                    meta: { exportItem },
                },
            ],
        },
    };
};

export const updateExportFile = (exportId, exportFile) => {
    return {
        type: actionTypes.ADD_OR_REPLACE_EXPORT_FILE,
        meta: {
            exportId,
            exportFile,
        },
    };
};
