import styled from "styled-components/macro";

import { lighten, List, Typography } from "@mui/material";
import StarOutline from "@mui/icons-material/StarOutline";
import { MenuBlockTitle, MenuItem } from ".";
import { MenuFavoriteType } from "../menu-items";
import { useP } from "../../../../../services/i18n";

export interface MenuFavoritesProps {
    /* CSS Classname */
    className?: string;
    /* Favorites list */
    favorites: MenuFavoriteType[] | undefined;
    /* Max number of favorites */
    maxFav: number;
    /* Callback when favorite item is clicked */
    onItemClick: (item: string) => void;
    /* Callback when star to remove favorite is clicked */
    onRemoveFavorite: (item: string) => void;
    /* Path of selected item in menu */
    activeItemPath?: string;
    /* Url prefix to add before target url */
    consoleUrlPrefix?: string;
}

/**
 * Block that display the favorites list.
 * Favorites items are clickable like the menu items.
 * A star icon is available to remove the favorite.
 */
const MenuBlockFavorites = ({
    className,
    favorites,
    maxFav,
    onItemClick,
    onRemoveFavorite,
    activeItemPath,
    consoleUrlPrefix,
}: MenuFavoritesProps) => {
    const p = useP();
    const keyItems =
        process.env["NX_IS_INTERNAL"] === "true"
            ? "app.menu.items-internal."
            : "app.menu.items-external.";

    const handleItemClick = (itemKey: string) => {
        onItemClick(itemKey);
    };

    const handleRemoveFavoriteClick = (itemKey: string) => {
        onRemoveFavorite(itemKey);
    };

    return (
        <div className={className}>
            <MenuBlockTitle
                className="MenuBlockFavorites-title"
                label={`${p.t("app.menu.favorites.title")} (${favorites?.length}/${maxFav})`}
            />

            {!favorites?.length ? (
                <div className="MenuBlockFavorites-noFav">
                    <div className="MenuBlockFavorites-noFav-title">
                        <StarOutline className="MenuBlockFavorites-noFav-icon" />
                        <Typography variant="subtitle2" color="white">
                            {p.t("app.menu.favorites.noFav")}
                        </Typography>
                    </div>
                    <Typography variant="caption" color="white">
                        {p.t("app.menu.favorites.noFavText")}
                    </Typography>
                </div>
            ) : (
                <List className="MenuBlockFavorites-list">
                    {Object.values(favorites).map((fav) => {
                        const subItemKey = fav.key.split("/")[1];

                        return (
                            <MenuItem
                                id={"fav-" + fav.key}
                                item={fav}
                                key={fav.key}
                                type="main"
                                label={p.t(keyItems + subItemKey)}
                                leftIcon={fav.icon}
                                withFavorites
                                favoriteChecked
                                onItemClick={() => handleItemClick(fav.key)}
                                onFavoriteClick={() => handleRemoveFavoriteClick(fav.key)}
                                active={activeItemPath === fav.target}
                                consoleUrlPrefix={consoleUrlPrefix}
                            />
                        );
                    })}
                </List>
            )}
        </div>
    );
};

const StyledMenuBlockFavorites = styled(MenuBlockFavorites)`
    margin-bottom: ${({ theme }) => theme.spacing(4)};

    .MenuBlockFavorites-title {
        padding: ${({ theme }) => theme.spacing(4)};
        padding-bottom: 0;
    }

    .MenuBlockFavorites-noFav {
        padding: 12px;
        margin: 0 16px;

        background-color: ${({ theme }) => lighten(theme.palette.info.main, 0.3)};
        border-radius: 3px;

        text-align: center;

        &-title {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 4px;
        }

        &-icon {
            margin-right: 4px;
        }
    }

    .MenuBlockFavorites-list {
        padding: 0;

        .MenuBlockFavorites-icon {
            min-width: 28px;
            color: white;
        }
    }
`;

export default StyledMenuBlockFavorites;
