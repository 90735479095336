import { Flag } from "../Flag/Flag";
import { useStyles } from "./stylesheet";

export const ExpandedFlags = (props) => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            {Object.values(props.locales).map((key) => {
                return (
                    <Flag
                        key={"flag-" + key}
                        path={`${props.flagsPath}${key.substr(-2).toLowerCase()}.svg`}
                        locale={key}
                        handleOnClick={props.handleOnClick}
                    />
                );
            })}
        </div>
    );
};
