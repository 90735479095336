import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import { useOktaAuth } from "@okta/okta-react";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import classNames from "classnames";
import makeStyles from "@mui/styles/makeStyles";
import HiIcon from "@hipay/hipay-material-ui/HiIcon";
import HiIconButton from "@hipay/hipay-material-ui/HiIconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import InboxOutlinedIcon from "@mui/icons-material/InboxOutlined";
import FullscreenIcon from "@mui/icons-material/Fullscreen";

import AppBar from "@mui/material/AppBar";
import { fade } from "@hipay/hipay-material-ui/styles/colorManipulator";
import { QuickSearch } from "../../../containers";
import { isMobile } from "react-device-detect";
import { HiLogoSmall, HiButton, HiSwitch } from "@hipay/design-system/components";

import { useFullscreen } from "../../../../hooks/useFullscreen";
import { GuideTour } from "../../../../common/components/GuideTour/GuideTour";
import { useP, useUser, useInnerPage, setEventTracker } from "../../../../services";
import { MessageBar } from "./MessageBar/MessageBar";
import { AccountSelector } from "../index";

export const useStylesTopBar = makeStyles((theme) => ({
    root: {
        width: "100%",
        boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.16)",
    },
    colorPrimary: {
        backgroundColor: theme.palette.info.main,
        color: theme.palette.white,
    },
    colorPrimaryFocus: {
        backgroundColor: theme.palette.background2,
        color: theme.palette.text.secondary,
    },
    toolbar: { minHeight: theme.spacing(16) },
    leftButtonMobile: {
        marginLeft: -theme.spacing(1),
        marginRight: theme.spacing(2),
    },
    leftButton: {
        paddingLeft: theme.spacing(1),
        marginRight: theme.spacing(3),
        minWidth: 100,
    },
    leftButtonLabel: {
        marginLeft: theme.spacing(2),
        textTransform: "none",
    },
    flex: {
        flex: 1,
        minWidth: "50%",
    },
    rightComponentMobile: { marginRight: -theme.spacing(3) },
    accountSelectorButton: {
        paddingLeft: 25,
        minWidth: 144,
        maxWidth: 288,
    },
    accountSelectorButtonLabel: {
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap",
    },
    iconButton: { marginRight: -theme.spacing(4) },
    showTopBarButton: {
        position: "absolute",
        right: 33,
        marginTop: 5,
    },
    endAdornmentButton: {
        height: 32,
        lineHeight: 32,
        padding: 0,
        margin: 0,
        display: "inline-block",
        color: fade("#000", 0.38),
        textTransform: "capitalize",
        fontWeight: theme.typography.fontWeightRegular,
        minWidth: 50,
    },
    appBarElement: { marginRight: theme.spacing(6) },
    collapseButton: {},
    mobileButtonAccountRoot: { marginLeft: theme.spacing(2) },
    internalColoredLabel: {
        borderRadius: 3,
        color: theme.palette.neutral.contrastText,
        padding: "3px 4px",
    },
    internalColorPrivate: { backgroundColor: theme.palette.primary.main },
    internalColorPublic: { backgroundColor: theme.palette.neutral.main },
    switchLabelInternal: {
        display: "inline-block",
        verticalAlign: "super",
    },
    switchDiv: {
        display: "flex",
        alignItems: "center",
    },
    switchLabel: {
        display: "inline-block",
        verticalAlign: "super",
    },
    internalLabel: { marginLeft: 4 },
}));

export function TopBar(props) {
    const {
        hideable,
        hasSettings,
        hasAccountSelector,
        onClickMenu,
        onClickSettings,
        onClickReturn,
        accountSelectorContent,
        refButtons,
        isActive,
        inactiveTopBar,
    } = props;

    const classes = useStylesTopBar(props);
    const p = useP();
    const { isEnabled, toggle } = useFullscreen();
    const user = useUser();
    const { authState } = useOktaAuth();
    const token = authState?.accessToken?.accessToken;
    const refreshToken = authState?.refreshToken?.refreshToken;

    const [isInternal, setIsInternal] = useState(process.env.NX_IS_INTERNAL === "true");
    const { openInnerPage, closeInnerPage, getInnerComponent } = useInnerPage();

    const handleCollapse = () => {
        setEventTracker("click_full_screen", { event_category: "core" });
        inactiveTopBar();
        toggle();
    };

    const handleClickReturn = () => {
        onClickReturn();
        closeInnerPage();
    };

    const handleFocusCollapse = (event) => {
        if (event.relatedTarget !== null && !hasAccountSelector) {
            handleClickReturn();
        }
    };

    const handleOpenAccountSelector = () => {
        setEventTracker("click_account_selection", {
            event_category: "core",
            event_action: "click_account_selection",
        });
        openInnerPage(
            <AccountSelector />,
            {
                fromTopBar: true,
                titleKey: "account_selector.title",
            },
            "AccountSelector"
        );
    };

    const handleSwitchInternal = useCallback(() => {
        setEventTracker("switch_public_private", { event_category: "core" });

        setIsInternal(!isInternal);

        let _origin = window.location.origin;
        if (_origin.includes("localhost")) {
            if (_origin.includes("4200")) {
                _origin = _origin.replace("4200", "4201");
            } else if (_origin.includes("4201")) {
                _origin = _origin.replace("4201", "4200");
            }
        } else if (_origin.includes("console-internal")) {
            _origin = _origin.replace("console-internal", "console");
        } else if (_origin.includes("console")) {
            _origin = _origin.replace("console", "console-internal");
        }

        window.location.href = _origin + "/login/sso?redirectTo=" + window.location.pathname;
    }, [isInternal, token, refreshToken, setIsInternal]);

    return (
        <AppBar className={classNames(classes.root, classes.colorPrimary)} id="top-bar">
            <MessageBar />
            <Toolbar className={classes.toolbar}>
                {isActive ? (
                    <div ref={refButtons} id="menu-button-container">
                        {isMobile ? (
                            <IconButton
                                color="darkBackground"
                                classes={{ root: classes.leftButtonMobile }}
                                onClick={handleClickReturn}
                                size="large"
                            >
                                <ArrowBackIcon />
                            </IconButton>
                        ) : (
                            <HiButton
                                id="back-btn"
                                color="darkBackground"
                                variant="text"
                                size="large"
                                classes={{ root: classes.leftButton }}
                                onClick={handleClickReturn}
                            >
                                <ArrowBackIcon />
                                <span className={classes.leftButtonLabel}>
                                    {p.t("top_bar.back")}
                                </span>
                            </HiButton>
                        )}
                    </div>
                ) : (
                    <div ref={refButtons} id="menu-button-container">
                        {isMobile ? (
                            <IconButton
                                classes={{ root: classes.leftButtonMobile }}
                                onClick={onClickMenu}
                                size="large"
                                color="darkBackground"
                            >
                                <MenuIcon />
                            </IconButton>
                        ) : (
                            <HiButton
                                classes={{ root: classes.leftButton }}
                                onClick={onClickMenu}
                                variant="text"
                                color="darkBackground"
                                size="large"
                            >
                                <HiLogoSmall width={34} color="white" />
                                <span className={classes.leftButtonLabel}>
                                    {p.t("top_bar.menu")}
                                </span>
                            </HiButton>
                        )}
                    </div>
                )}
                <div
                    id="quick-search-input"
                    className={classNames(classes.flex, { [classes.appBarElement]: !isMobile })}
                >
                    <QuickSearch handleClickReturn={handleClickReturn} />
                </div>
                {isMobile && hasAccountSelector && (
                    <IconButton
                        color="darkBackground"
                        size="large"
                        onClick={handleOpenAccountSelector}
                        aria-owns={"top-bar-mobile-menu"}
                        aria-haspopup="true"
                        classes={{ root: classes.mobileButtonAccountRoot }}
                    >
                        <InboxOutlinedIcon />
                    </IconButton>
                )}
                {!isMobile && user.internalstaff && (
                    <div
                        title={
                            isInternal
                                ? p.t("app.topbar.actions.switch_public")
                                : p.t("app.topbar.actions.switch_private")
                        }
                        className={classes.switchDiv}
                    >
                        <HiSwitch
                            checked={isInternal}
                            color="darkBackground"
                            onChange={handleSwitchInternal}
                            className={classes.switch}
                            label={
                                isInternal
                                    ? p.t("app.topbar.label.private")
                                    : p.t("app.topbar.label.public")
                            }
                        />
                    </div>
                )}
                {!isMobile && <GuideTour />}
                {!isMobile && hasAccountSelector && (
                    <HiButton
                        color="darkBackground"
                        variant="text"
                        className={classNames(
                            classes.accountSelectorButton,
                            classes.accountSelectorButtonLabel
                        )}
                        onClick={handleOpenAccountSelector}
                        onFocus={handleClickReturn}
                        id="account-selector-button"
                        disabled={getInnerComponent() === "AccountSelector"}
                        startIcon={<InboxOutlinedIcon />}
                    >
                        {accountSelectorContent}
                    </HiButton>
                )}
                {!isMobile && isEnabled && hideable && (
                    <IconButton
                        color="darkBackground"
                        title={p.t("app.topbar.actions.fullscreen")}
                        id="dash-hide-app-bar"
                        onFocus={handleFocusCollapse}
                        onClick={handleCollapse}
                        className={classes.collapseButton}
                    >
                        <FullscreenIcon />
                    </IconButton>
                )}
                {!isMobile && hasSettings && (
                    <HiIconButton onClick={onClickSettings}>
                        <HiIcon onClick={onClickSettings} icon="fa-gear" size={24} />
                    </HiIconButton>
                )}
            </Toolbar>
        </AppBar>
    );
}

TopBar.propTypes = {
    /**
     * Contenu du bouton à droite de l'input de recherche (accountSelector)
     */
    accountSelectorContent: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    /**
     * Permet des surcharges de style
     */
    classes: PropTypes.object,
    /**
     * Définit si l'on doit afficher le bouton "Account selector" ou non
     */
    hasAccountSelector: PropTypes.bool,
    /**
     * Définit si l'on doit afficher le bouton "Settings" ou non
     */
    hasSettings: PropTypes.bool,
    /**
     * La TopBar peut être cachée vie le bouton chevron
     */
    hideable: PropTypes.bool,
    /**
     * Inactive la TopBar
     */
    inactiveTopBar: PropTypes.func,
    /**
     * Top bar is active
     */
    isActive: PropTypes.bool.isRequired,
    /**
     * Fonction de callback au click sur le menu
     */
    onClickMenu: PropTypes.func.isRequired,
    /**
     * Fonction de callback au click sur le bouton "Retour" du menu
     */
    onClickReturn: PropTypes.func.isRequired,
    /**
     * Fonction de callback au click sur le bouton "Settings" du menu
     */
    onClickSettings: PropTypes.func,
    /**
     * Positionement de la topBar. The behavior of the different options is described
     * [here](https://developer.mozilla.org/en-US/docs/Learn/CSS/CSS_layout/Positioning).
     */
    position: PropTypes.oneOf(["fixed", "absolute", "sticky", "static"]),
    /**
     *  Passe une ref callback à la div contenant les boutons "menu" et "back"
     */
    refButtons: PropTypes.func,
    /**
     * @ignore
     */
    theme: PropTypes.object,
    /**
     * Traductions
     */
    translations: PropTypes.object,
};
TopBar.defaultProps = {
    hideable: false,
    hasSettings: false,
    hasAccountSelector: false,
};
