const privilege_type = {
    id: "privilege_type",
    data: [
        {
            id: "INTERNAL",
            label: "ref.privilege.privilege_type.INTERNAL",
        },
        {
            id: "EXTERNAL",
            label: "ref.privilege.privilege_type.EXTERNAL",
        },
    ],
    translate: true,
};

export default privilege_type;
