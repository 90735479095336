import * as actionTypes from "../actions/actionTypes";

// initial state of your module store partition
export const initialStateTopbar = {
    isActive: false, // change top bar color, back button, ...
    query: null,
    errors: null,
};

export const topbar = (state = initialStateTopbar, action) => {
    switch (action.type) {
        case actionTypes.ACTIVE_TOP_BAR:
            return {
                ...state,
                isActive: true,
                query: action.meta.query,
                errors: action.meta.errors,
            };

        case actionTypes.INACTIVE_TOP_BAR:
            return {
                ...state,
                isActive: false,
                query: null,
                errors: null,
            };

        case actionTypes.LOGOUT:
            return initialStateTopbar;

        default:
            return state;
    }
};
