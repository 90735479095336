import { useHistory } from "react-router-dom";
import classNames from "classnames";

import { Content } from "../Content";
import { LanguageSwitcher } from "../LanguageSwitcher";
import { Header } from "../Header";
import { MarketContainer } from "../MarketContainer";
import { useStyles } from "./stylesheet";

export const LoginPage = (props) => {
    const classes = useStyles();
    const history = useHistory();
    const {
        titleKey,
        marketMessageKey,
        backgroundPath = "url(/assets/authentication/img/login-background.jpg)",
        content,
        showHeader = true,
    } = props;

    const redirectToLogin = () => {
        history.push("/login");
    };

    const title = showHeader ? titleKey : undefined;

    return (
        <div className={classes.root}>
            <LanguageSwitcher />
            <div className={classes.container} style={{ backgroundImage: backgroundPath }}>
                <div className={classes.content}>
                    <Header handleClickOnLogo={redirectToLogin} />
                    <Content titleKey={title} children={content} />
                </div>
                <div
                    className={classNames(classes.market)}
                    style={{ backgroundImage: backgroundPath }}
                >
                    <MarketContainer messageKey={marketMessageKey} />
                </div>
            </div>
        </div>
    );
};
