import PropTypes from "prop-types";
import makeStyles from "@mui/styles/makeStyles";
import HiBadge from "@hipay/hipay-material-ui/HiBadge";
import HiIcon from "@hipay/hipay-material-ui/HiIcon";
import HiIconButton from "@hipay/hipay-material-ui/HiIconButton";

export const useStylesNotification = makeStyles((theme) => ({
    root: {
        color: theme.palette.text.secondary,
        marginRight: 12,
    },
}));

/**
 * Notification button - meant to be used in TopBar
 * @param props
 * @returns {*}
 * @constructor
 */
export function Notification(props) {
    const { onClick, value } = props;

    const classes = useStylesNotification(props);

    if (value === 0) {
        return null;
    }

    return (
        <HiIconButton className={classes.root} onClick={onClick}>
            <HiBadge value={value}>
                <HiIcon icon="fa-bell-o" size={25} />
            </HiBadge>
        </HiIconButton>
    );
}

Notification.propTypes = {
    onClick: PropTypes.func.isRequired,
    value: PropTypes.number,
};

Notification.defaultProps = { value: 0 };
