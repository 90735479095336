import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles((theme) => ({
    root: {
        borderRadius: 3,
        position: "relative",
    },
    fieldContainer: {
        position: "relative",
        height: "100%",
    },
    link: {
        textDecoration: "none",
        fontSize: 14,
        cursor: "pointer",
        "&:hover": { color: theme.palette.primary.main },
    },
    notYetLink: { color: theme.palette.primary.main },
    button: {
        width: "100%",
        lineHeight: "20px",
        padding: "10px 0",
        marginTop: 35,
        marginBottom: 50,
    },
    notYet: {
        marginTop: 20,
        color: "#737373",
        textAlign: "center",
    },
    loader: {
        margin: "36px auto",
        width: 1,
        display: "block",
    },
}));
