import { useEffect } from "react";
import { __RouterContext as RouterContext, useLocation } from "react-router-dom";
import { RouterToUrlQuery, multiReplaceInUrlQuery } from "react-url-query";
import { init } from "@sentry/react";
import {
    ModuleProvider,
    ContextForwarder,
    InnerPageProvider,
    MessageBarProvider,
} from "../services";

import { useSelector, useDispatch } from "react-redux";
import { useOktaAuth } from "@okta/okta-react";
import { parseJwt } from "../utils/jwt";

import { ErrorBoundary, AppUnlogged, AppLogged } from "./components";

import "typeface-roboto";
import "../index.css";
import locizeEditor from "locize-editor";

import { setAuthentication } from "./actions";
import { SET_AUTHENTICATION_NEW } from "./actions/actionTypes";

if (process.env.NX_LOCIZE === "true") {
    locizeEditor.init({
        //lng: "en-US",
        defaultNS: "console",
        projectId: "72ab23bb-84d4-4a9a-bee9-ff4bfe3dfdbf",
        referenceLng: "fr-FR",
    });
}
//sentry
if (process.env.NX_SENTRY_DSN) {
    init({
        dsn: process.env.NX_SENTRY_DSN,
        environment: process.env.NX_ENV,
        release: `${process.env.NX_VERSION}`,
    });
}

export function App({ module, ModuleRoot }) {
    const location = useLocation();

    const { authState, oktaAuth } = useOktaAuth();
    const token = authState?.accessToken?.accessToken;

    const dispatch = useDispatch();

    const storeAuthData = useSelector((state) => state.app && state.app.authentication.data);
    const userData = useSelector((state) => state.app.user.data);

    useEffect(() => {
        const jwtStorage = localStorage.getItem(process.env.NX_JWT_TOKEN_KEY);

        let _token, refresh_token;
        // eslint-disable-next-line no-extra-boolean-cast
        if (!!jwtStorage) {
            _token = jwtStorage?.token;
            refresh_token = jwtStorage?.refresh_token;
        }

        // eslint-disable-next-line no-extra-boolean-cast
        if (!!_token) {
            multiReplaceInUrlQuery({
                token: undefined,
                refresh_token: undefined,
            });

            dispatch({
                type: SET_AUTHENTICATION_NEW,
                payload: {
                    data: {
                        ...storeAuthData,
                        token: _token,
                        refresh_token,
                    },
                    loading: false,
                    error: null,
                },
            });

            const tokenData = parseJwt(_token);

            oktaAuth.tokenManager.add("accessToken", {
                scopes: tokenData.scp,
                expiresAt: tokenData.exp,
                accessToken: _token,
                claims: tokenData,
                tokenType: "Baerer",
                authorizeUrl: process.env.NX_OKTA_ISSUER + "/v1/authorize",
                userinfoUrl: process.env.NX_OKTA_ISSUER + "/v1/userinfo",
            });

            oktaAuth.tokenManager.add("idToken", {
                authorizeUrl: process.env.NX_OKTA_ISSUER + "/v1/authorize",
                claims: tokenData,
                expiresAt: tokenData.exp,
                clientId: process.env.NX_OKTA_WIDGET_CLIENT_ID,
                idToken: _token,
                issuer: process.env.NX_OKTA_ISSUER,
                scopes: tokenData.scp,
            });
        }
    }, []);

    useEffect(() => {
        if (!!token && !storeAuthData.token) {
            dispatch(setAuthentication({ token }));
        }
    });

    if (location.pathname === "/logout") {
        // Handle redirection from TPP to console /logout
        oktaAuth.signOut();
    }

    if (!token || !userData) {
        return <AppUnlogged redirectTo={location.pathname + location.search + location.hash} />;
    }

    return (
        <RouterToUrlQuery routerContext={RouterContext}>
            <ModuleProvider module={module}>
                <MessageBarProvider>
                    <InnerPageProvider>
                        <ErrorBoundary>
                            <AppLogged ModuleRoot={ModuleRoot} />
                        </ErrorBoundary>
                    </InnerPageProvider>
                </MessageBarProvider>
            </ModuleProvider>
        </RouterToUrlQuery>
    );
}

/**
 * AppCommon
 * use context forwarder to get inherit contexts
 * @param contexts
 * @param module
 * @param ModuleRoot
 * @returns {*}
 */
export const AppCommon = ({ contexts, module, ModuleRoot }) => {
    return (
        <ContextForwarder contexts={contexts}>
            <App module={module} ModuleRoot={ModuleRoot} />
        </ContextForwarder>
    );
};
