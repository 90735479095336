import * as actionTypes from "../actions/actionTypes";

// initial state of your module store partition
export const initialStateAlert = { isOpen: false };

export const alert = (state = initialStateAlert, action) => {
    switch (action.type) {
        case actionTypes.SET_ALERT:
            return {
                isOpen: true,
                ...action.alert,
            };

        case actionTypes.CLOSE_ALERT:
            return { isOpen: false };

        case actionTypes.LOGOUT:
            return initialStateAlert;

        default:
            return state;
    }
};
