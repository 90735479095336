import attributes from "./attributes";
import references from "../references";
import AppWidget from "../AppWidget";
import { FEATURE_SEARCH_SELECTION } from "@console/core";
import { ModuleConfig, ModuleRouting } from "@console/types";
import WidgetsOutlined from "@mui/icons-material/WidgetsOutlined";

/**
 * Config
 *
 * On retrouve ici la configuration du module.
 *  - Ses attributes
 *  - La config du module selector de la quicksearch
 *  - Les default fields presents dans le quickForm & par défaut sur le formulaire de recherche (s'il est activé)
 *    ainsi que leurs valeurs par défaut (ou null)
 *  - La liste des features avec un booléen pour indiquer si elles sont activées ou non
 *
 */
export const moduleConfig: ModuleConfig = {
    id: "widget",
    attributes: attributes,
    references: references,
    defaultFormFields: {},
    icon: WidgetsOutlined,
    label: "master.widget.title",
    path: "widgets",
    route: "[prefix]/widgets",
    features: [FEATURE_SEARCH_SELECTION],
};

export const moduleRouting: ModuleRouting[] = [
    {
        key: "widgets",
        path: ["/widgets", "/accounts-:ids/widgets"],
        component: AppWidget,
    },
];
