const privilege_right = {
    id: "privilege_right",
    data: [
        {
            id: "Viewer",
            label: "ref.privilege.privilege_description.Viewer",
        },
        {
            id: "Editor",
            label: "ref.privilege.privilege_description.Editor",
        },
    ],
    translate: true,
};

export default privilege_right;
