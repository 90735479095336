import * as actionTypes from "../actions/actionTypes";

// initial state of your module store partition
export const initialState = {
    data: {
        authBearer: "Bearer",
        authHeader: "X-Authorization",
        token: null,
        refresh_token: null,
        lastRefreshToken: null,
        lastRefreshTokenLoading: false,
    },
    loading: false,
    error: null,
};

export const authentication = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.REFRESH_TOKEN_REQUEST:
            return {
                ...state,
                data: {
                    ...state.data,
                    lastRefreshTokenLoading: true,
                },
            };

        case actionTypes.REFRESH_TOKEN_SUCCESS:
        case actionTypes.SET_AUTHENTICATION:
            const authData = {
                ...state.data,
                ...action.payload,
                lastRefreshToken: Date.now(),
                lastRefreshTokenLoading: false,
            };
            // Put authentication in localstorage too.
            window.localStorage.setItem(process.env.NX_JWT_TOKEN_KEY, JSON.stringify(authData));
            return {
                data: authData,
                loading: false,
                error: null,
            };

        case actionTypes.LOGOUT:
        case actionTypes.ERROR_401:
            if (action.error) {
                return {
                    ...initialState,
                    error: action.error,
                };
            }
            return initialState;

        case actionTypes.REFRESH_TOKEN_FAILURE:
            window.localStorage.setItem(process.env.NX_JWT_TOKEN_KEY, "{}");
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case actionTypes.SET_AUTHENTICATION_NEW:
            return action.payload;

        default:
            return state;
    }
};
