import React from "react";
import { withRouterStylesAndTranslate } from "../../../../../hoc";
import HiIconButton from "@hipay/hipay-material-ui/HiIconButton";
import HiIcon from "@hipay/hipay-material-ui/HiIcon";
import HiInput from "@hipay/hipay-material-ui/HiForm/HiInput";
import HiButton from "@hipay/hipay-material-ui/HiButton/HiButton";
// import { HiLogo } from "@hipay/design-system/components";
import classNames from "classnames";
import CheckCircle from "mdi-material-ui/CheckCircle";
import CloseCircle from "mdi-material-ui/CloseCircle";
import SearchIcon from "@mui/icons-material/Search";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";

import {
    QueryBuilder,
    QueryParser,
    setEventTracker,
    SuggestionManager,
    SUGGESTION_NOTICE,
    TERM_KEYWORD,
    TERM_OPERATOR,
    TERM_FREETEXT,
    OPERATOR_IN,
    OPERATOR_NOT_IN,
    withCan,
    withConfig,
    withApi,
    withInnerPage,
} from "../../../../../services/index";

import { ModuleSelector, SuggestionList, QuickForm } from "./index";
import SearchHistory from "../../../../containers/AppLogged/InnerPage/SearchHistoryContainer";
import { QuickSearchBackdrop } from "../../../../components";
import { FETCH_SEARCH_HISTORY_SUCCESS } from "../../../../actions/actionTypes";
import { isMobile } from "react-device-detect";

import { trimSuggestInput, redirectToNotice, getUrlWithAccounts } from "../../../../../utils";

const stylesQuickSearch = (theme) => ({
    root: {
        backgroundColor: "rgba(255, 255, 255, 0.3)",
        height: 48,
        display: "flex",
        alignItems: "center",
    },
    rootFocused: { backgroundColor: "white" },
    moduleSelector: { float: "left" },
    divider: {
        backgroundColor: theme.palette.divider,
        width: 1,
        height: "calc(100% - 12px)",
    },
    inputRoot: {
        backgroundColor: "transparent !important",
        borderBottom: "none !important",
        overflow: "hidden",
    },
    inputRootInactive: {
        "& input::placeholder": {
            color: "rgba(255,255,255,.7)",
            opacity: 1,
        },
    },
    inkbar: {
        "&:after": {
            width: "calc(100% + 48px)",
            left: "-48px !important",
            bottom: "-3px !important",
        },
    },
    filterButton: { marginRight: 8 },
    iconSearchInactive: { color: "rgba(255,255,255,.5)" },
    iconValidQuery: { color: theme.palette.success.main },
    iconInvalidQuery: { color: theme.palette.error.main },
    noQuickSearch: {
        display: "flex",
        justifyContent: "center",
    },
});

// Availables quicksearch dropdown container
const MODULE_SELECTOR = "MODULE_SELECTOR";
const QUICK_FORM = "QUICK_FORM";
const SUGGESTION_LIST = "SUGGESTION_LIST";

const maxLength = 1000;
class QuickSearch extends React.Component {
    initialDataState = {
        termAnalysis: undefined,
        suggestionList: [],
        suggestionFocusIndex: 0,
        query: "",
        caretPosition: 0,
        isValidQuery: null,
        errors: null,
        lastClickTs: 0,
    };

    constructor(props) {
        super(props);

        // Init module selector active module from localStorage.
        let module = window.localStorage.getItem(process.env.NX_SELECTED_MODULE);

        const moduleEnabled = props
            .getModuleListByFeature("qsearch")
            .filter((_module) => props.can("manage-module", _module));

        if (module && !moduleEnabled.includes(module)) {
            module = null;
            window.localStorage.removeItem(process.env.NX_SELECTED_MODULE);
        }

        // if localStorage is empty set it to first module found by default.
        if ((!module || module === "null") && moduleEnabled.length) {
            const defaultModule = moduleEnabled[0];
            module = defaultModule;
            window.localStorage.setItem(process.env.NX_SELECTED_MODULE, defaultModule);
        }

        if (module) {
            let moduleConfig = props.getConfigByModule(module);
            if (!moduleConfig || !moduleConfig.has("qsearch")) {
                // null if module has not qsearch (transaction is absent, ...)
                module = null;
            } else {
                this.suggestionManager = new SuggestionManager(
                    moduleConfig,
                    props.phrases,
                    props.api
                );
            }
        }

        // Load SearchHistory && accounts for each module
        this.moduleList = moduleEnabled;
        setTimeout(() => {
            // Wait store properly initialized
            moduleEnabled.forEach((m) => this.loadHistoryAndAccounts(m));
        }, 1);

        this.state = {
            /**
             * Module
             */
            module,
            /**
             * Which dropdown is shown
             * null, MODULE_SELECTOR, QUICK_FORM, SUGGESTION_LIST
             */
            openedContainer: null,
            /**
             * Container width
             */
            containerWidth: 0,
            /**
             * Query, caretPosition, termAnalysis, suggestionList, suggestionFocusIndex
             */
            ...this.initialDataState,
        };
    }

    // SuggestionManager
    suggestionManager = null;

    // Refs
    searchInputElement = null;
    viewMoreElement = null;

    /**
     * Boolean
     * Should set caret position in input element if state change
     * used when suggestions are submit
     */
    updateCaret = false;

    /**
     * Timeout
     * Delay the update of suggestionList by 100ms to improve user experience of rapid typing/navigating
     */
    updateSuggestionTimeout = null;

    disabled = false;

    moduleList = [];

    /**
     * Search input element ref
     * @param el
     */
    searchInputRef = (el) => {
        this.searchInputElement = el;
    };

    /**
     * View More button element ref
     * @param el
     */
    viewMoreButtonRef = (el) => {
        this.viewMoreElement = el;
    };

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return (
            nextProps.isActive !== this.props.isActive ||
            nextProps.isFocused !== this.props.isFocused ||
            nextState.openedContainer !== this.state.openedContainer ||
            nextState.suggestionList !== this.state.suggestionList ||
            nextState.suggestionLoading !== this.state.suggestionLoading ||
            nextState.suggestionError !== this.state.suggestionError ||
            nextState.suggestionFocusIndex !== this.state.suggestionFocusIndex ||
            nextState.query !== this.state.query ||
            nextState.caretPosition !== this.state.caretPosition ||
            nextProps.searchHistory[nextState.module] !==
                this.props.searchHistory[this.state.module] ||
            nextProps.p !== this.props.p ||
            nextState.module !== this.state.module ||
            JSON.stringify(nextProps.accountEntities) !== JSON.stringify(this.props.accountEntities)
        );
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.module === null) {
            return;
        }

        if (this.props.activeGuideTour) {
            if (this.props.isFocused) {
                this.handleFocusInput();
            } else {
                this.handleCloseModuleSelector();
            }
        }

        if (prevProps.isActive === false && this.props.isActive === true) {
            this.onResize();
        }

        if (prevProps.isActive && !this.props.isActive) {
            this.setState({ query: "" });
        }

        // Query has changed
        // - check isValid
        if (prevState.query !== this.state.query) {
            const termAnalysis = QueryParser.analyzeCurrentTermQuery(
                this.state.query,
                this.state.caretPosition,
                this.state.module,
                this.getModuleAttributes(),
                this.props.phrases
            );
            const operatorList = this.suggestionManager.buildOperatorSuggestions(
                termAnalysis,
                this.state.module,
                this.props.phrases
            );
            const analyzedQuery = QueryParser.analyzeQuery(
                this.state.query,
                this.state.module,
                this.getModuleAttributes(),
                this.props.phrases,
                operatorList
            );

            this.setState({
                isValidQuery: analyzedQuery.errors.length === 0,
                errors: analyzedQuery.errors,
                termAnalysis,
            });

            // Open/Update backdrop if focus and query or isValidQuery change
            if (document.activeElement === this.searchInputElement) {
                this.props.activeTopBar(this.state.query, analyzedQuery.errors);
                // Open inner page backdrop
                this.props.openInnerPage(
                    <QuickSearchBackdrop />,
                    { withLayout: false },
                    "QuickSearchBackdrop"
                );
            }
        }

        // Caret position has changed
        // - set caret position in input element (updateCaret is true)
        if (prevState.caretPosition !== this.state.caretPosition) {
            // Fix to update input caretPosition on suggestion submit
            if (this.updateCaret && this.searchInputElement) {
                this.updateCaret = false;
                this.searchInputElement.setSelectionRange(
                    this.state.caretPosition,
                    this.state.caretPosition
                );
            }
        }

        // Update suggestion list
        // + Input is focused AND
        // + Module has changed (for history suggestions)
        // + Query has changed
        // + Caret has changed
        // + Query is empty AND termAnalysis is undefined (on first focus)
        // + Search history has changed
        // + polyglot has changed
        if (
            (document.activeElement === this.searchInputElement &&
                (prevState.module !== this.state.module ||
                    prevState.query !== this.state.query ||
                    prevState.caretPosition !== this.state.caretPosition ||
                    (this.state.query === "" && prevState.termAnalysis !== null))) ||
            prevProps.searchHistory[prevState.module] !==
                this.props.searchHistory[this.state.module]
        ) {
            this.updateSuggestionList();
        }

        if (prevState.module !== this.state.module) {
            let moduleConfig = this.props.getConfigByModule(this.state.module);
            if (moduleConfig && moduleConfig.has("qsearch")) {
                this.suggestionManager = new SuggestionManager(
                    moduleConfig,
                    this.props.phrases,
                    this.props.api
                );
            }
        }

        let nbAccounts = this.props.selectedAccountIdList.length;
        if (
            nbAccounts === 0 &&
            !this.props.userData.insternalstaff &&
            !this.props.userData.allAccountsAccess
        ) {
            nbAccounts = Object.values(this.props.accountEntities).length;
        }

        if (!this.state.suggestionError && nbAccounts > process.env.NX_QSEARCH_MAX_ACCOUNT) {
            this.setState({
                suggestionError: {
                    displayMsg: true,
                    type: "too_any_accounts",
                    message: this.props.p.t("app.qsearch.errors.error_too_many_accounts", {
                        smart_count: process.env.NX_QSEARCH_MAX_ACCOUNT,
                    }),
                },
                suggestionList: [],
            });
        } else if (
            !!this.state.suggestionError &&
            this.state.suggestionError.type === "too_any_accounts" &&
            nbAccounts <= process.env.NX_QSEARCH_MAX_ACCOUNT
        ) {
            this.setState({ suggestionError: null });
        }
    }

    componentDidMount() {
        this.onResize();
        window.addEventListener("resize", this.onResize);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.onResize);
        clearTimeout(this.updateSuggestionTimeout);
    }

    onResize = () => {
        this.setState({
            containerWidth: document.getElementById("quick-search-container").clientWidth,
        });
    };

    /**
     * Build SuggestionList from query & caretPosition and update state
     * - termAnalysis
     * - suggestionList
     * - suggestionLoading
     * - suggestionError
     * - suggestionFocusIndex
     *
     * handle promise from axios call to re-update state.suggestionList on success.
     */
    updateSuggestionList = () => {
        if (!this.state.suggestionError) {
            clearTimeout(this.updateSuggestionTimeout);
            this.updateSuggestionTimeout = setTimeout(() => {
                const ts = Date.now();

                const hasMultipleAccount =
                    this.props.userData.allAccountsAccess ||
                    Object.values(this.props.accountEntities).length > 1;
                const { termAnalysis, suggestionList, promise } =
                    this.suggestionManager.getSuggestionList(
                        this.state.query,
                        this.state.caretPosition,
                        this.props.searchHistory,
                        this.state.module,
                        this.props.phrases,
                        this.props.userData.rgpdCompliance,
                        { account: this.props.selectedAccountIdList },
                        hasMultipleAccount
                    );
                if (promise !== null) {
                    promise
                        .then((response) => {
                            setEventTracker("suggest", {
                                event_category: "qsearch",
                                event_label: termAnalysis.previousTerm.field,
                                value: Date.now() - ts,
                            });

                            this.setState((prevState) => {
                                // Don't update suggestion if query has change
                                if (
                                    prevState.termAnalysis &&
                                    termAnalysis &&
                                    prevState.termAnalysis.term !== termAnalysis.term
                                ) {
                                    return null;
                                }

                                return {
                                    suggestionList: [
                                        ...prevState.suggestionList,
                                        ...this.suggestionManager.buildResponseSuggestions(
                                            response,
                                            termAnalysis,
                                            prevState.module
                                        ),
                                    ],
                                    suggestionLoading: false,
                                    suggestionError: null,
                                };
                            });
                        })
                        .catch((error) => {
                            // if user aborted the request it means another is still going on
                            if (error && error.message !== "The user aborted a request.") {
                                console.warn(error.message);
                                this.setState({
                                    suggestionLoading: false,
                                    suggestionError: error,
                                });
                            }
                        });
                    if (!this.state.suggestionLoading) {
                        this.setState({
                            suggestionList,
                            suggestionLoading: true,
                            suggestionError: null,
                        });
                    }
                    this.setState({ termAnalysis });
                } else {
                    this.setState({
                        termAnalysis,
                        suggestionList,
                        suggestionLoading: false,
                        suggestionFocusIndex: 0, // A commenter si on veut laisser le focus sur la souris
                    });
                }
            }, 750);
        }
    };

    loadHistoryAndAccounts = (moduleId) => {
        this.props.fetchSearchHistory(moduleId).then((response) => {
            if (
                response.type === FETCH_SEARCH_HISTORY_SUCCESS &&
                this.props.userData.allAccountsAccess
            ) {
                let accountIdList = [];
                response.payload.forEach((historyItem) => {
                    accountIdList = accountIdList.concat(historyItem.selectedAccountIdList);
                });
                // remove duplicates
                accountIdList = accountIdList.filter(
                    (value, index, array) => array.indexOf(value) === index
                );
                this.props.fetchSelectedAccountList(accountIdList);
            }
        });
    };

    /**
     * Close ModuleSelector
     */
    handleCloseModuleSelector = () => {
        this.setState({ openedContainer: null });
    };

    /**
     * Toggle ModuleSelector open/close
     * force quickForm close
     */
    toggleModuleSelector = () => {
        this.setState((prevState) => ({
            openedContainer: prevState.openedContainer === MODULE_SELECTOR ? null : MODULE_SELECTOR,
        }));
        this.props.activeTopBar();
        // Open inner page backdrop
        this.props.openInnerPage(
            <QuickSearchBackdrop />,
            { withLayout: false },
            "QuickSearchBackdrop"
        );
    };

    /**
     * Toggle QuickForm open/close
     * force moduleSelector close
     */
    toggleQuickForm = () => {
        setEventTracker("filter", { event_category: "qsearch", event_action: "qsearch_filter" });
        this.setState((prevState) => ({
            openedContainer: prevState.openedContainer === QUICK_FORM ? null : QUICK_FORM,
        }));
        this.props.activeTopBar();
        // Open inner page backdrop
        this.props.openInnerPage(
            <QuickSearchBackdrop />,
            { withLayout: false },
            "QuickSearchBackdrop"
        );
    };

    handleKeyDownFilter = (event) => {
        if (event.code === "Enter" || event.code === "Space") {
            this.toggleQuickForm();
        }
    };

    /**
     * Handle Change Module (ModuleSelector)
     * - Set to state new module
     * - Close ModuleSelector
     * - Focus on input
     * @param module
     */
    handleChangeModule = (module) => {
        this.setState((prevState) => {
            if (prevState.module !== module) {
                return {
                    ...this.initialDataState,
                    module,
                    openedContainer: null,
                };
            }

            return null;
        });

        // update LocalStorage
        window.localStorage.setItem(process.env.NX_SELECTED_MODULE, module);

        // focus on input
        this.searchInputElement.focus();
    };

    handleKeyDownButton = (event) => {
        if (this.state.openedContainer === MODULE_SELECTOR && event.key === "ArrowDown") {
            document.querySelector("#modules-list > div").focus();
        }
    };

    /**
     * Handle change query
     * @param event
     */
    handleChangeQuery = (event) => {
        const termAnalysis = QueryParser.analyzeCurrentTermQuery(
            event.target.value,
            event.target.selectionStart,
            this.state.module,
            this.getModuleAttributes(),
            this.props.phrases
        );

        let newQuery = event.target.value;
        const selectionStart = event.target.selectionStart;

        let caretPosition = selectionStart;

        // New term was typed
        if (
            event.target.value.length > this.state.query.length &&
            termAnalysis &&
            termAnalysis.previousTerm &&
            termAnalysis.term === null &&
            termAnalysis.completeTerm === null
        ) {
            // previous term is keyword, format it
            if (termAnalysis.previousTerm.type === TERM_KEYWORD) {
                let replaceValue =
                    this.props.phrases.app.qsearch.keywords[termAnalysis.previousTerm.keyword];

                // replace keyword from termAnalysis.previousTerm.start to length-1
                newQuery =
                    event.target.value.slice(0, termAnalysis.previousTerm.start) +
                    replaceValue +
                    " " +
                    event.target.value.slice(selectionStart).trimLeft();

                caretPosition = termAnalysis.previousTerm.start + replaceValue.length + 1;
            } else if (termAnalysis.previousTerm.type === TERM_OPERATOR) {
                // previous term is operator, format it
                let replaceValue =
                    this.props.phrases.app.qsearch.operators[termAnalysis.previousTerm.operator];

                // insert parenthesis for in & not_in operators
                const insertParenthesis =
                    [OPERATOR_IN, OPERATOR_NOT_IN].includes(termAnalysis.previousTerm.operator) &&
                    event.target.value.slice(selectionStart).trimLeft().charAt(0) !== "(";

                // replace operator from termAnalysis.previousTerm.start to length-1
                newQuery =
                    event.target.value.slice(0, termAnalysis.previousTerm.start) +
                    replaceValue +
                    (insertParenthesis ? " (  ) " : " ") +
                    event.target.value.slice(selectionStart).trimLeft();

                caretPosition = termAnalysis.previousTerm.start + replaceValue.length + 1;
                if (insertParenthesis) {
                    caretPosition += 2;
                }
            } else if (
                termAnalysis.previousTerm.type === TERM_FREETEXT &&
                termAnalysis.previousTerm.misformatClause
            ) {
                // previous term is freetext relative to misformatted clause, format it
                let replaceValue = QueryParser.decodeQuery(
                    termAnalysis.previousTerm.misformatClause,
                    this.state.module,
                    this.getModuleAttributes(),
                    this.props.phrases
                );

                // replace decoded clause from termAnalysis.previousTerm.start to length-1
                newQuery =
                    event.target.value.slice(0, termAnalysis.previousTerm.start) +
                    replaceValue +
                    " " +
                    event.target.value.slice(selectionStart).trimLeft();

                caretPosition = termAnalysis.previousTerm.start + replaceValue.length + 1;
            }
        }

        this.setState({
            query: newQuery,
            caretPosition: caretPosition,
        });

        // Set caret position properly
        setTimeout(() => {
            if (this.searchInputElement) {
                this.searchInputElement.setSelectionRange(caretPosition, caretPosition);
            }
        }, 1);
    };

    /**
     * Handle paste data into query
     * remove spaces
     * could be used to transfer data from excel/table,
     * replace \n by ,
     * @param event
     */
    handlePasteQuery = (event) => {
        event.preventDefault();
        let value = event.target.value;
        let pasteData = trimSuggestInput(event.clipboardData.getData("text/plain"));
        if (pasteData) {
            // from excel to string
            const matches = pasteData.match(/[^\n]+/g);
            let formatData = `"${matches.join('", "')}"`;
            if (value.charAt(event.target.selectionStart - 1) === "(") {
                formatData = ` ${formatData}`;
            }
            const query =
                value.slice(0, event.target.selectionStart) +
                formatData +
                value.slice(event.target.selectionEnd);
            this.setState({
                query: query.length <= maxLength ? query : `${query.substring(0, maxLength)}`,
                caretPosition: event.target.selectionStart + formatData.length,
                suggestionFocusIndex: 0,
            });

            // update caret position onComponentUpdate
            this.updateCaret = true;
        }
    };

    /**
     * Reset query by settings initialDataState
     */
    handleResetQuery = () => {
        this.setState(this.initialDataState);
    };

    /**
     * Focus input
     * @param event
     */
    handleFocusInput = (event) => {
        this.setState({ openedContainer: SUGGESTION_LIST });
        this.props.activeTopBar(this.state.query, this.state.errors);
        // Open inner page backdrop
        this.props.openInnerPage(
            <QuickSearchBackdrop />,
            { withLayout: false },
            "QuickSearchBackdrop"
        );
    };

    /**
     * Click input
     * set caretPosition (potentially trigger getSuggestionList by update)
     * @param event
     */
    handleInputClick = (event) => {
        this.setState({ caretPosition: event.target.selectionStart });
    };

    handleBlurInput = (event) => {
        // Event target is not in suggestionList
        if (
            !event ||
            !event.relatedTarget ||
            !document.getElementById("suggestion-list") ||
            !document.getElementById("suggestion-list").contains(event.relatedTarget)
        ) {
            this.setState({ openedContainer: null });
        }
    };

    handleSuggestionClick = (suggestion) => (event) => {
        event.preventDefault();
        if (Date.now() - this.state.lastClickTs > 500) {
            // Prevent double click
            this.submitSuggestion(suggestion);
        }
    };

    submitSuggestion = (suggestion) => {
        const { history, phrases, selectedAccountIdList } = this.props;

        if (suggestion.type === SUGGESTION_NOTICE) {
            // save in history
            this.props.addSearchHistory(this.state.module, {
                query: `${suggestion.targetField}¤equal¤${suggestion.value}`,
                selectedAccountIdList: selectedAccountIdList,
                nbResults: 1,
                module: this.state.module,
                redirectId: suggestion.redirectId,
            });

            let moduleConfig = this.props.getConfigByModule(this.state.module);

            // redirect to notice by identifier
            redirectToNotice(
                history,
                suggestion.redirectId,
                moduleConfig.path,
                selectedAccountIdList
            );
            this.clearOnSubmit();
        } else {
            this.searchInputElement.focus();

            this.setState((prevState) => {
                const { termAnalysis, query, module } = prevState;

                // update caret position onComponentUpdate
                this.updateCaret = true;

                const { newQuery, newCaretPosition } = QueryBuilder.hydrateQueryWithSuggestion(
                    query,
                    suggestion,
                    termAnalysis,
                    module,
                    phrases
                );

                return {
                    query: newQuery,
                    caretPosition: newCaretPosition,
                    suggestionList: [],
                    lastClickTs: Date.now(),
                };
            });
        }
    };

    getModuleAttributes = () => {
        let moduleConfig = this.props.getConfigByModule(this.state.module);
        return moduleConfig ? moduleConfig.attributes : [];
    };

    /**
     * Input Submit
     * - only submit if query is valid !
     * - clear backdrop timeout
     */
    submitInput = () => {
        const encodedQuery = QueryParser.encodeQuery(
            this.state.query,
            this.state.module,
            this.getModuleAttributes(),
            this.props.phrases
        );

        const moduleConfig = this.props.getConfigByModule(this.state.module);

        if (this.state.query.length > 0) {
            // update url
            this.props.history.push(
                `${getUrlWithAccounts(
                    this.props.selectedAccountIdList,
                    moduleConfig.route
                )}?qsearch=${encodeURIComponent(encodedQuery)}`
            );

            // save in history
            this.props.addSearchHistory(this.state.module, {
                query: encodedQuery,
                selectedAccountIdList: this.props.selectedAccountIdList,
                nbResults: 0,
                module: this.state.module,
            });

            this.clearOnSubmit();
        }
    };

    clearOnSubmit = () => {
        this.setState({ openedContainer: null });

        this.props.inactiveTopBar();

        // close inner page (backdrop)
        this.props.closeInnerPage();

        this.handleResetQuery();

        // blur input
        this.searchInputElement.blur();
    };

    /**
     * Set focused suggestion index
     *
     * focusIndex === 0 means the input is focused
     * focusIndex === suggestionList + 2 means the [VIEW MORE] button is focused
     *
     * @param event
     */
    inputVerticalNavigationEvent = (event) => {
        // Prevent default input behavior of setting cursor on input begin|end.
        event.preventDefault();

        let direction = event.key === "ArrowDown" ? 1 : -1; // +1 with "down", -1 with "up"
        this.setState((prevState) => {
            const listLength = prevState.suggestionList.length + 2; // +2 to handle input & history item button
            if (listLength > 1) {
                // set index from direction and modulo list length to loop over suggestions
                const nextIndex =
                    (prevState.suggestionFocusIndex + direction + listLength) % listLength;

                // focus VIEW MORE button
                if (this.viewMoreElement && nextIndex === listLength - 1) {
                    this.viewMoreElement.focus();
                }

                return {
                    ...prevState,
                    suggestionFocusIndex: nextIndex,
                };
            }
            return {
                ...prevState,
                // set to 0 if suggestionList is empty
                suggestionFocusIndex: 0,
            };
        });
    };

    /**
     * Set input caret position (after event)
     * @param event
     */
    inputHorizontalNavigationEvent = (event) => {
        let caretPosition = event.target.selectionStart;
        if (event.key === "ArrowLeft" && caretPosition > 0) {
            caretPosition -= 1;
        }
        if (event.key === "ArrowRight" && caretPosition < event.target.value.length) {
            caretPosition += 1;
        }
        this.setState({ caretPosition });
    };

    /**
     * Input Key Down
     * @param event
     */
    handleInputKeyDown = (event) => {
        const { suggestionList, suggestionFocusIndex, isValidQuery } = this.state;

        switch (event.key) {
            case "Enter":
                if (suggestionFocusIndex !== 0 && suggestionList.length >= suggestionFocusIndex) {
                    // suggestion is focus -> submit suggestion
                    this.submitSuggestion(suggestionList[suggestionFocusIndex - 1]);
                } else if (isValidQuery) {
                    // submit input
                    this.submitInput();
                } else {
                    this.props.handleClickReturn(); // if nothing, act like a back click
                }
                break;
            case "Tab":
                if (suggestionFocusIndex !== 0 && suggestionList.length >= suggestionFocusIndex) {
                    // suggestion is focus -> submit suggestion
                    event.preventDefault(); // prevent focus on next element
                    this.submitSuggestion(suggestionList[suggestionFocusIndex - 1]);
                }
                break;
            case "ArrowDown":
            case "ArrowUp":
                this.inputVerticalNavigationEvent(event);
                break;
            case "ArrowLeft":
            case "ArrowRight":
                this.inputHorizontalNavigationEvent(event);
                break;
            default:
                break;
        }
    };

    /**
     * View More button Key Down
     * @param event
     */
    handleViewMoreKeyDown = (event) => {
        switch (event.key) {
            case "ArrowDown":
            case "ArrowUp":
                this.inputVerticalNavigationEvent(event);
                // focus on input
                this.searchInputElement.focus();
                break;
            default:
                break;
        }
    };

    handleBlurList = () => {
        this.setState({ openedContainer: null });
    };

    handleOpenSearchHistory = () => {
        this.setState({ query: "" });
        this.handleBlurInput();
        this.props.inactiveTopBar();

        this.props.openInnerPage(
            <SearchHistory />,
            {
                fromTopBar: true,
                titleKey: "search_activity.title",
            },
            "SearchHistory"
        );
    };

    handleSubmitQuickForm = () => {
        this.props.inactiveTopBar();
        // close inner page (backdrop)
        this.props.closeInnerPage();
        this.setState({ openedContainer: null });
    };

    handleBlurQuickForm = () => {
        this.setState({ openedContainer: null });
    };

    updateSuggestionFocusIndex = (index) => {
        this.setState({ suggestionFocusIndex: index });
    };

    handleClick = () => {
        window.gtag("event", "qsearch", {
            event_category: "qsearch",
            event_action: "qsearch_suggest",
        });
    };

    render() {
        const {
            classes,
            p,
            isActive,
            userData,
            selectedAccountIdList,
            getConfigByModule,
            phrases,
            accountEntities,
        } = this.props;

        if (
            this.moduleList.length === 0 ||
            (!userData.allAccountsAccess && Object.keys(accountEntities).length === 0)
        ) {
            return (
                <div
                    id="quick-search-container"
                    ref={(container) => {
                        this.container = container;
                    }}
                    className={classes.noQuickSearch}
                    onClick="handleClick"
                >
                    {/* <HiLogo width={80} /> */}
                </div>
            );
        }

        const {
            openedContainer,
            containerWidth,
            module,
            suggestionList,
            suggestionLoading,
            suggestionError,
            suggestionFocusIndex,
            query,
            isValidQuery,
            termAnalysis,
        } = this.state;

        const FilterButton = (
            <HiButton
                id="quick-form-button"
                color={isActive ? "info" : "darkBackground"}
                onClick={this.toggleQuickForm}
                onKeyDown={this.handleKeyDownFilter}
                className={classes.filterButton}
                disabled={this.disabled}
            >
                {openedContainer === QUICK_FORM ? (
                    <ArrowDropUpIcon icon="arrow_drop_up" color="info" />
                ) : (
                    <React.Fragment>
                        {p.t("top_bar.quick_search.all_types")}
                        <HiIcon icon="arrow_drop_down" />
                    </React.Fragment>
                )}
            </HiButton>
        );

        let startAdornment = (
            <SearchIcon className={classNames({ [classes.iconSearchInactive]: !isActive })} />
        );
        if (query.length >= 3) {
            startAdornment = isValidQuery ? (
                <CheckCircle className={classes.iconValidQuery} />
            ) : (
                <CloseCircle className={classes.iconInvalidQuery} />
            );
        }

        const ModuleIconComponent = getConfigByModule(module).icon;

        return (
            <div
                id="quick-search-container"
                ref={(container) => {
                    this.container = container;
                }}
                className={classNames(classes.root, { [classes.rootFocused]: isActive })}
                onFocus={this.props.onFocus}
            >
                <HiIconButton
                    id="module-selector"
                    title={p.t("top_bar.quick_search.select_module")}
                    className={classes.moduleSelector}
                    disabled={this.moduleList.length < 2}
                    onClick={this.toggleModuleSelector}
                    onKeyDown={this.handleKeyDownButton}
                >
                    <ModuleIconComponent color={isActive ? "info" : "darkBackground"} />
                </HiIconButton>
                <div className={classes.divider} />
                <HiInput
                    inputId="quick-search-input"
                    tabindex="0"
                    classes={{
                        root: classNames(classes.inputRoot, {
                            [classes.inputRootFocused]: isActive,
                            [classes.inputRootInactive]: !isActive,
                        }),
                        inkbar: classes.inkbar,
                    }}
                    startAdornment={startAdornment}
                    endAdornment={!isMobile && FilterButton}
                    onFocus={this.handleFocusInput}
                    onBlur={this.handleBlurInput}
                    inputRef={this.searchInputRef}
                    onChange={this.handleChangeQuery}
                    onKeyDown={this.handleInputKeyDown}
                    onClick={this.handleInputClick}
                    onReset={this.handleResetQuery}
                    value={query}
                    placeholder={
                        isMobile
                            ? p.t("top_bar.quick_search.placeholder_mobile")
                            : p.t(`quick_search.${module}.placeholder`)
                    }
                    autoComplete={"off"} // disable default browser autoComplete
                    spellcheck={false} // disable default browser spellcheck
                    inputProps={{
                        onPaste: this.handlePasteQuery,
                        maxLength: maxLength,
                        disabled:
                            !(
                                module !== null &&
                                phrases &&
                                phrases.attributes &&
                                phrases.attributes[module]
                            ) || this.disabled,
                    }}
                />
                {openedContainer === SUGGESTION_LIST && (
                    <SuggestionList
                        onBlur={this.handleBlurList}
                        onClickSuggestion={this.handleSuggestionClick}
                        onClickSeeMore={this.handleOpenSearchHistory}
                        containerWidth={containerWidth}
                        suggestionList={suggestionList}
                        emptySuggestionListMessage={this.suggestionManager.buildEmptySuggestionListMessage(
                            termAnalysis,
                            p
                        )}
                        loading={suggestionLoading}
                        error={suggestionError}
                        focusIndex={suggestionFocusIndex - 1}
                        hasViewMoreButton={true}
                        viewMoreButtonRef={this.viewMoreButtonRef}
                        handleViewMoreKeyDown={this.handleViewMoreKeyDown}
                        module={module}
                        updateFocusIndex={this.updateSuggestionFocusIndex}
                    />
                )}
                {openedContainer === MODULE_SELECTOR && (
                    <ModuleSelector
                        containerWidth={containerWidth}
                        value={module}
                        onChange={this.handleChangeModule}
                        onBlur={this.handleCloseModuleSelector}
                    />
                )}
                {openedContainer === QUICK_FORM && (
                    <QuickForm
                        handleCloseInnerPage={this.props.closeInnerPage}
                        containerWidth={containerWidth}
                        selectedModule={module}
                        connectedUser={userData}
                        onSubmit={this.handleSubmitQuickForm}
                        onBlur={this.handleBlurQuickForm}
                        selectedAccountIdList={selectedAccountIdList}
                        accountEntities={accountEntities}
                    />
                )}
            </div>
        );
    }
}

export default withRouterStylesAndTranslate(stylesQuickSearch)(
    withConfig(withApi(withCan(withInnerPage(QuickSearch))))
);
