import { useP } from "../../../../services/i18n";
import makeStyles from "@mui/styles/makeStyles";
import { ExitFullscreen } from "../../common/ExitFullscreen";
import { ReactComponent as ReactLogo } from "../../../../../../../assets/src/lib/core/img/Erreur_404_Oops.svg";
import Link from "@mui/material/Link";

const useStylesNotAuthorized = makeStyles((theme) => ({
    root: {
        textAlign: "center",
        margin: 0,
        // height: "100%",
        height: "calc(100vh - 64px)",
        background: "url(/assets/core/img/404-background-new.png) no-repeat center fixed",
        webkitBackgroundSize: "cover" /* pour anciens Chrome et Safari */,
        backgroundSize: "cover" /* version standardisée */,
    },
    errorcode: {
        ...theme.typography.h3,
        color: theme.palette.neutral.contrastText,
        fontSize: "15px",
        fontWeight: "400 !important",
    },
    sidebarLeft: {
        height: "100%",
        opacity: 0.64,
        position: "fixed",
        bottom: "20px",
        width: "100vw",
    },
    messagediv: {
        width: "32%",
        position: "fixed",
        bottom: "43%",
        paddingRight: "24px",
        paddingLeft: "24px",
        top: "40%",
        left: "50%",
        transform: "translate(-50%, -90%)",
        opacity: 1,
    },
    message: {
        ...theme.typography.h3,
        color: theme.palette.neutral.contrastText,
        fontSize: "25px",
        marginBottom: "-10px !important",
    },
    links: {
        display: "block",
        color: theme.palette.neutral.contrast,
    },
    oops_img_new: {
        paintOrder: "stroke",
        stroke: "rgba(255, 140, 80, 1)",
        strokeWidth: "3px",
        strokeLinecap: "round",
        filter: "drop-shadow(0px 0px 5px rgb(255 140 80)) drop-shadow(0px 0px 15px rgb(255 140 80)) drop-shadow(0px 0px 30px rgb(255 140 80))",
        marginBottom: "36px",
        animation: "2s linear 0s infinite alternate glowSvg",
    },

    iconArrowDown: {
        backgroundColor: theme.palette.neutral.contrastText,
        borderRadius: "50%",
    },
    corner: {
        position: "absolute",
        top: 8,
        right: 8,
        color: "#fff",
    },

    button: {
        ...theme.typography.h3,
        color: "#ffffff",
        border: "1px solid #ffffff",
        padding: "6px",
        fontSize: "15px",
        boxShadow:
            "0 0px 20px 0 #a50979 inset, 0 0px 25px 0 #a50979, 0 0px 50px 0 #a50979, 0 0px 27px 0 #a50979",
        marginLeft: "25%",
        marginRight: "25%",
        fontWeight: "400",
        marginTop: "8%",
        textShadow: "0 0 0.8em #a50979",
        backgroundColor: "#A50979",
    },
}));

export function NotAuthorized() {
    const p = useP();
    const classes = useStylesNotAuthorized();

    return (
        <div className={classes.root}>
            <ExitFullscreen className={classes.corner} />
            <div className={classes.sidebarLeft} />
            <div className={classes.messagediv}>
                <ReactLogo className={classes.oops_img_new} />
                <div className={classes.message}>
                    <p>{p.t("error403.message1")}</p>
                </div>
                <div className={classes.errorcode}>
                    <p>{p.t("error403.error_code")}</p>
                </div>
                <Link href={`/dashboard`}>
                    <div className={classes.button}>{p.t("error403.return_button")}</div>
                </Link>
            </div>
        </div>
    );
}
