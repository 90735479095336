import PeopleAltOutlined from "@mui/icons-material/PeopleAltOutlined";
import SendOutlined from "@mui/icons-material/SendOutlined";
import DashboardOutlined from "@mui/icons-material/DashboardOutlined";
import UndoOutlined from "@mui/icons-material/UndoOutlined";
import AttributionOutlined from "@mui/icons-material/AttributionOutlined";
import SettingsOutlined from "@mui/icons-material/SettingsOutlined";
import LogoutOutlined from "@mui/icons-material/LogoutOutlined";
import LeaderboardOutlined from "@mui/icons-material/LeaderboardOutlined";
import AirplayOutlined from "@mui/icons-material/AirplayOutlined";
import AssignmentOutlined from "@mui/icons-material/AssignmentOutlined";
import SellOutlined from "@mui/icons-material/SellOutlined";
import ConstructionOutlined from "@mui/icons-material/ConstructionOutlined";
import DataUsageOutlined from "@mui/icons-material/DataUsageOutlined";
import StoreOutlined from "@mui/icons-material/StoreOutlined";

import { SvgIconProps } from "@mui/material";

export type MenuItemType = {
    /* Item key, used for favorites, translations & analytics */
    key: string;
    /* Module id in module config */
    module?: string;
    /**
     *  Item type
     *  console: internal redirection to console
     *  tpp: redirect to old interface
     *  logout: internal action that logout user
     */
    type: "group" | "console" | "tpp" | "logout";
    /* URL to redirect */
    target: string;
    /* URL to redirect GET params */
    targetParams?: string;
    /* Item icon */
    icon: React.ComponentType<SvgIconProps>;
    /* Sub items list */
    subItems?: MenuSubItemType[];
};
export type MenuSubItemType = Omit<MenuItemType, "icon" | "subItems">;
export type MenuFavoriteType = Pick<MenuItemType, "key" | "type" | "target" | "icon">;

export const menuItems: MenuItemType[] = [
    {
        key: "dashboard",
        module: "dashboard",
        type: "console",
        target: "/dashboard",
        icon: DashboardOutlined,
    },
    {
        key: "transactions_group",
        type: "group",
        target: "",
        icon: SendOutlined,
        subItems: [
            {
                key: "transactions",
                module: "transaction",
                type: "console",
                target: "/transactions",
            },
            {
                key: "eterminal",
                module: "eterminal",
                type: "console",
                target: "/eterminal",
            },
        ],
    },
    {
        key: "finance_group",
        type: "group",
        target: "",
        icon: LeaderboardOutlined,
        subItems: [
            {
                key: "financial_reports",
                module: "financial_report",
                type: "console",
                target: "/financial_reports",
            },
            {
                key: "invoices",
                module: "invoice",
                type: "console",
                target: "/invoices",
            },
        ],
    },
    {
        key: "users_group",
        type: "group",
        target: "",
        icon: PeopleAltOutlined,
        subItems: [
            {
                key: "users",
                module: "user",
                type: "console",
                target: "/users",
            },
            {
                key: "roles",
                module: "role",
                type: "console",
                target: "/roles",
            },
            {
                key: "privileges",
                module: "privilege",
                type: "console",
                target: "/privileges",
            },
        ],
    },
    {
        key: "integration_group",
        type: "group",
        target: "",
        icon: AirplayOutlined,
        subItems: [
            {
                key: "payment_pages",
                module: "integration",
                type: "console",
                target: "/integration/themes",
            },
            /*{
                key: "payment_methods_settings",
                module: "payment_methods_settings",
                type: "console",
                target: "/payment-methods-settings",
            },*/
        ],
    },
    {
        key: "merchant_group",
        type: "group",
        target: "",
        icon: AssignmentOutlined,
        subItems: [
            {
                key: "businesses",
                module: "business",
                type: "console",
                target: "/businesses",
            },
            {
                key: process.env["NX_IS_INTERNAL"] === "true" ? "kyc" : "onboarding",
                module: "onboarding",
                type: "console",
                target: "/verifications",
            },
        ],
    },
    {
        key: "accounts_group",
        type: "group",
        target: "",
        icon: SellOutlined,
        subItems: [
            {
                key: "accounts",
                module: "account",
                type: "console",
                target: "/accounts",
            },
            {
                key: "finance_admin",
                module: "finance_admin",
                type: "console",
                target: "/finance_admin",
            },
        ],
    },
    {
        key: "connexions_group",
        type: "group",
        target: "",
        icon: ConstructionOutlined,
        subItems: [
            {
                key: "route_switch",
                module: "route_switch",
                type: "console",
                target: "/route_switches",
            },
        ],
    },
    {
        key: "intelligence",
        module: "intelligence",
        type: "console",
        target: "/intelligence",
        icon: DataUsageOutlined,
    },
    {
        key: "marketplace",
        module: "mkp_config",
        type: "console",
        target: "/marketplaces",
        icon: StoreOutlined,
    },
    {
        key: "old_interface",
        type: "tpp",
        target: "/user/dashboard",
        icon: UndoOutlined,
    },
];

export const menuUserItems: MenuItemType[] = [
    {
        key: "profile",
        type: "console",
        target: "/profile",
        icon: AttributionOutlined,
    },
    {
        key: "preference",
        type: "console",
        target: "/preference",
        icon: SettingsOutlined,
    },
    {
        key: "sign_out",
        type: "logout",
        target: "/logout",
        icon: LogoutOutlined,
    },
];
