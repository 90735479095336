import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import makeStyles from "@mui/styles/makeStyles";
import Grid from "@mui/material/Grid";
import { ExitFullscreen } from "../../../../app/components/common/ExitFullscreen";

import { useP, useModule, useModuleConfig, setEventTracker } from "../../../../services";

import { GoBackBtn } from "./GoBackBtn";

const useStylesHeader = makeStyles((theme) => ({
    root: {
        position: "fixed",
        zIndex: 100,
        width: "100%",
        color: theme.palette.text.primary,
        backgroundColor: (props) => {
            if (theme.palette[props.color]) {
                return theme.palette[props.color].main;
            }
            return props.color;
        },
        backgroundImage: (props) => {
            if (!props.noGradient) {
                return `linear-gradient(${theme.palette.background3} 0%, rgba(255,255,255,0.80) 0%)`;
            }
            return "inherit";
        },
        boxShadow: (props) => (props.compact ? "0px 1px 3px rgba(0, 0, 0, 0.16)" : "inherit"),
    },
    rootCollapse: { top: 0 },
    titleRow: {
        padding: (props) => (props.compact ? "0 12px" : "8px 12px"),
        height: (props) => (props.compact ? 48 : 64),
        // under large device, title row is display on two rows
        [theme.breakpoints.down("sm")]: { height: (props) => (props.compact ? 48 : 128) },
        transition: ".2s",
    },
    hideable: {
        display: "contents",
        // under large device, if compact, hideable items disappear
        [theme.breakpoints.down("sm")]: {
            "&>*": {
                opacity: (props) => (props.compact ? 0 : 1),
                transition: ".2s",
            },
        },
    },
    titleDiv: {
        textAlign: "left",
        display: "flex",
        alignItems: "center",
    },
    statusDiv: { margin: "auto" },
    actionsDiv: {
        display: "flex",
        justifyContent: "flex-end",
        alignSelf: "center",
        textAlign: "end",
        "&>*": { marginLeft: theme.spacing(2) },
    },
}));

/**
 * Notice/Header component
 *
 * build header component with
 * - left   : back button + title   : <- title
 * - center : status chip           : (status)
 * - right  : actions buttons       : (a)(b)(c)
 *
 * props :
 * - compact (boolean) : add/remove padding (64px/48px), compact is set to true when notice is scrolled.
 * - actions ([Component]) : customs actions
 *
 * Browser view (>= md) :
 *  ______________________________________________________________________________________________
 * |                                                                                              |
 * | <- title                                 (status)                                 (a)(b)(c)  |
 * |______________________________________________________________________________________________|
 *
 * Browser view compact (>= md) :
 *  ______________________________________________________________________________________________
 * | <- title                                 (status)                                 (a)(b)(c)  |
 *  ----------------------------------------------------------------------------------------------
 *
 * Mobile view (two rows) (< md) :
 *  __________________________________
 * |                                  |
 * | <- title                         |
 * |            (status)   (a)(b)(c)  |
 * |__________________________________|
 *
 * Mobile view compact (< md) :
 *  __________________________________
 * | <- title                         |
 *  ----------------------------------
 */
export function HeaderContainer(props) {
    const {
        actionComponents,
        noGradient,
        color,
        children,
        titleComponent,
        statusComponent,
        referer,
        hideArrow,
    } = props;

    const classes = useStylesHeader(props);
    const p = useP();
    const history = useHistory();
    const module = useModule();
    const moduleConfig = useModuleConfig(module.id);

    const handleBackButtonClick = () => {
        setEventTracker("back", {
            event_category: "transaction_notice",
            event_action: "transaction_notice_" + module.id,
            //TODO get back/chargeback
        });
        referer ? history.push(referer) : history.push(`/${moduleConfig.path}`);
    };

    /**********************************************************************************************
     * RENDERS
     *********************************************************************************************/
    const titleDiv = (
        <div className={classes.titleDiv}>
            {hideArrow === false ? (
                <GoBackBtn
                    color={noGradient ? "inherit" : color}
                    onClick={handleBackButtonClick}
                    title={p.t("common.notice.header.go_back")}
                />
            ) : (
                ""
            )}
            {titleComponent}
        </div>
    );

    const statusDiv = <div className={classes.statusDiv}>{statusComponent}</div>;

    const actionsDiv = (
        <div className={classes.actionsDiv}>
            {actionComponents}
            <ExitFullscreen color="info" />
        </div>
    );

    return (
        <div id="notice-header" className={classes.root}>
            <Grid
                className={classes.titleRow}
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
            >
                <Grid item xs={12} md={4}>
                    {titleDiv}
                </Grid>
                <div className={classes.hideable}>
                    <Grid item xs={12} sm={6} md={4}>
                        {statusDiv}
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        {actionsDiv}
                    </Grid>
                </div>
            </Grid>
            {children}
        </div>
    );
}

HeaderContainer.propTypes = {
    /**
     * Components displayed inline on top right
     */
    actionComponents: PropTypes.arrayOf(PropTypes.any),
    /**
     * Component displayed inside header (as KPIs for transaction)
     */
    children: PropTypes.node,
    /**
     * Boolean triggering collapse animation
     * Used in styles
     */
    compact: PropTypes.bool,
    /**
     * Referer location for back button (<-)
     * Don't display back button if null
     * Used with history.push()
     * ie: /sample?grid=...
     */
    referer: PropTypes.string,
    /**
     * Component display on top center (as status chip for transaction)
     */
    statusComponent: PropTypes.node,
    /**
     * Component display on top left (next to back button)
     */
    titleComponent: PropTypes.node,
    /**
     * Theme color OR HEX color
     * Color background and back button
     */
    color: PropTypes.string,
    /**
     * Do not display arrow to go back to list view
     */
    hideArrow: PropTypes.bool,
};

HeaderContainer.defaultProps = {
    actionComponents: [],
    compact: false,
    referer: null,
    statusComponent: null,
    titleComponent: null,
    color: "#ffffff",
    hideArrow: false,
};
