import React from "react";
import PropTypes from "prop-types";
import HiIcon from "@hipay/hipay-material-ui/HiIcon";
import { translate } from "../../../../../../services/i18n/translate";
import { isMobile } from "react-device-detect";
import classNames from "classnames";

class ClauseSuggestionItem extends React.Component {
    static propTypes = {
        suggestion: PropTypes.object.isRequired,
        module: PropTypes.string,
    };

    render() {
        const { module, suggestion, p } = this.props;

        return (
            <div className="search item">
                {!isMobile && <HiIcon icon="search" size={24} />}
                <div
                    className={classNames({
                        "text-div": !isMobile,
                        "text-div-mobile": isMobile,
                    })}
                >
                    {p.t(`attributes.${module}.${suggestion.targetField}.suggestLabel`)}
                    &nbsp;
                    {p.t(`app.qsearch.operators.${suggestion.operator}`)}
                    &nbsp;
                    {suggestion.reference ? (
                        <span className="dark">
                            {p.t(`ref.${module}.${suggestion.reference}.${suggestion.value}`, {
                                smart_count: 1,
                            })}
                        </span>
                    ) : (
                        <span
                            className="dark"
                            dangerouslySetInnerHTML={{
                                __html: suggestion.highlight || suggestion.value,
                            }}
                        />
                    )}
                </div>
            </div>
        );
    }
}

export default translate(ClauseSuggestionItem);
