import makeStyles from "@mui/styles/makeStyles";
import { useP } from "../../../../../services/i18n";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { ListItem } from "@mui/material";

import { useModuleList } from "../../../../../services/config";
import { useCan } from "../../../../../services/ability";

const useStylesModuleSelector = makeStyles((theme) => ({
    root: {
        position: "absolute",
        backgroundColor: theme.palette.background.paper,
        top: 64,
        boxShadow: "inset 0px 8px 5px -7px rgba(0, 0, 0, 0.16), 0px 3px 5px rgba(0,0,0,0.24)",
    },
    moduleItem: {
        cursor: "pointer",
        width: "100%",
        height: 48,
        display: "flex",
        alignItems: "center",
        padding: "7px 13px",
        "&:hover": { backgroundColor: theme.palette.action.hover },
        "&:focus": { backgroundColor: theme.palette.action.hover },
    },
    label: {
        ...theme.typography.body2,
        color: theme.palette.neutral.dark,
        paddingLeft: 12,
    },
}));

export function ModuleSelector(props) {
    const { containerWidth, onBlur, onChange, value } = props;

    const classes = useStylesModuleSelector();
    const p = useP();
    const { getModuleConfigListByFeature } = useModuleList();
    const can = useCan();

    let handleClickModule = (module) => () => {
        onChange(module);
    };

    let handleKeyDownList = (event) => {
        if (event.key === "Tab") {
            onBlur();
            // Two separates timeouts otherwise not working properly
            if (!event.shiftKey) {
                setTimeout(() => {
                    document.querySelector("#quick-search-container input").focus();
                }, 1);
            } else {
                setTimeout(() => {
                    document.querySelector("#module-selector").focus();
                }, 1);
            }
        } else if (event.key === "Enter") {
            onChange(document.activeElement.id);
        } else if (event.key === "ArrowDown" && document.activeElement.nextSibling) {
            document.activeElement.nextSibling.focus();
        } else if (event.key === "ArrowUp" && document.activeElement.previousSibling) {
            document.activeElement.previousSibling.focus();
        }
    };

    let handleClickAway = (event) => {
        if (
            document.getElementById("module-selector") &&
            !document.getElementById("module-selector").contains(event.srcElement)
        ) {
            onBlur();
        }
    };

    return (
        <ClickAwayListener onClickAway={handleClickAway}>
            <div
                id="modules-list"
                className={classes.root}
                style={{ width: containerWidth }}
                onKeyDown={handleKeyDownList}
            >
                {getModuleConfigListByFeature("qsearch")
                    .filter((module) => can("manage-module", module.id))
                    .map((module) => {
                        const IconComponent = module.icon;
                        return (
                            <ListItem
                                button
                                onClick={handleClickModule(module.id)}
                                className={classes.moduleItem}
                                id={module.id}
                                key={module.id}
                            >
                                <IconComponent
                                    color={value === module.id ? "primary" : "neutral"}
                                />
                                <span className={classes.label}>{p.t(module.label)}</span>
                            </ListItem>
                        );
                    })}
            </div>
        </ClickAwayListener>
    );
}
