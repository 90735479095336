import { stopPropagationEvent } from "../../utils";
import { setEventTracker } from "@console/core";

export function getSearchSteps(tourOptions, p, setInitCallback) {
    const firstRow = document.querySelector("#search-results div.lazy-row");
    if (!firstRow) {
        return [];
    }

    const horizontalScrollbar = document.querySelector("#scroll-track-horizontal div:first-child");
    let horizontalScrollBarIsVisible = true;
    if (
        horizontalScrollbar &&
        horizontalScrollbar.style["width"] &&
        horizontalScrollbar.style["width"] === "0px"
    ) {
        horizontalScrollBarIsVisible = false;
    }

    const firstRowChildrenCount = firstRow.childNodes.length;
    let cellSelector = "div:nth-child(3)";
    if (firstRowChildrenCount <= 2) {
        cellSelector = `div:nth-child(${firstRowChildrenCount})`;
    }

    setInitCallback({
        callback: (tour) => {
            ["close", "cancel", "complete"].forEach((event) =>
                tour.on(event, () => {
                    const settingsMenu = document.getElementById("settings-menu");
                    if (settingsMenu) {
                        settingsMenu.removeEventListener("click", stopPropagationEvent);
                    }

                    const favoriteMenu = document.getElementById("favorite-menu");
                    if (favoriteMenu) {
                        favoriteMenu.removeEventListener("click", stopPropagationEvent);
                    }

                    const columnFilterMenu = document.getElementById("column-filter-menu");
                    if (columnFilterMenu) {
                        columnFilterMenu.removeEventListener("click", stopPropagationEvent);
                    }
                })
            );
        },
    });

    let steps = [];

    if (document.getElementById("new-menu-button") !== null) {
        steps.push({
            id: "new-button",
            arrow: false,
            attachTo: {
                element: "#new-menu-button",
                on: "auto-start",
            },
            title: p.t("modules.account.guidetour.search.new.title"),
            text: p.t("modules.account.guidetour.search.new.text"),
            buttons: [
                {
                    ...tourOptions.defaultStepOptions.buttons[0],
                    text: "",
                },
                tourOptions.defaultStepOptions.buttons[1],
            ],
            beforeShowPromise: () => {
                if (document.getElementById("new-menu-button") !== null) {
                    // GA v4
                    setEventTracker("account_guide_tour_search", {
                        event_category: "account_guide_tour_search",
                        event_action: "account_guide_tour_search",
                    });
                }
            },
        });
    }

    steps.push({
        id: "parameter-button",
        attachTo: {
            element: "#settings-menu div:nth-child(3n)",
            on: "auto",
        },
        title: p.t("modules.account.guidetour.search.parameter-button.title"),
        text: p.t("modules.account.guidetour.search.parameter-button.text"),
        ...(steps.length === 0
            ? {
                  buttons: [
                      {
                          ...tourOptions.defaultStepOptions.buttons[0],
                          text: "",
                      },
                      tourOptions.defaultStepOptions.buttons[1],
                  ],
              }
            : {}),
        beforeShowPromise: () => {
            return new Promise((resolve) => {
                if (document.getElementById("new-menu-button") === null) {
                    // GA v4
                    setEventTracker("account_guide_tour_search", {
                        event_category: "account_guide_tour_search",
                        event_action: "account_guide_tour_search",
                    });
                }
                const settingsButton = document.getElementById("settings-menu-button");
                if (settingsButton) {
                    settingsButton.click();
                }

                if (document.getElementById("search-form-submit")) {
                    const searchTitle = document.getElementById("search-title");
                    if (searchTitle) {
                        searchTitle.click();
                    }
                }

                // Fix canClickTarget: false not working for this step
                const settingsMenu = document.getElementById("settings-menu");
                settingsMenu.addEventListener("click", stopPropagationEvent);

                setTimeout(() => resolve(), 200);
            });
        },
        when: {
            // Needed because when property override default show from step options
            show: tourOptions.defaultStepOptions.when.show,
            hide: function () {
                const settingsMenu = document.getElementById("settings-menu");

                // Fix canClickTarget: false not working for this step
                settingsMenu.removeEventListener("click", stopPropagationEvent);

                if (window.getComputedStyle(settingsMenu).visibility === "visible") {
                    // If setting menu is visible, close it
                    if (settingsMenu.children[0]) {
                        settingsMenu.children[0].click();
                    }
                }
            },
        },
    });

    if (horizontalScrollBarIsVisible) {
        steps.push({
            id: "scrollbar",
            canClickTarget: true,
            scrollTo: false,
            attachTo: {
                element: "#scroll-track-horizontal",
                on: "auto",
            },
            title: p.t("modules.account.guidetour.search.scrollbar.title"),
            text: p.t("modules.account.guidetour.search.scrollbar.text"),
        });
    }

    steps.push.apply(steps, [
        {
            id: "favorite-button",
            attachTo: {
                element: "#favorite-menu div:nth-child(3n)",
                on: "auto",
            },
            title: p.t("modules.account.guidetour.search.favorite-button.title"),
            text: p.t("modules.account.guidetour.search.favorite-button.text"),
            beforeShowPromise: () => {
                return new Promise((resolve) => {
                    const favoriteButton = document.getElementById("favorite-menu-button");
                    if (favoriteButton) {
                        favoriteButton.click();
                    }

                    // Fix canClickTarget: false not working for this step
                    const favoriteMenu = document.getElementById("favorite-menu");
                    favoriteMenu.addEventListener("click", stopPropagationEvent);

                    setTimeout(() => resolve(), 200);
                });
            },
            when: {
                // Needed because when property override default show from step options
                show: tourOptions.defaultStepOptions.when.show,
                hide: function () {
                    const favoriteMenu = document.getElementById("favorite-menu");

                    // Fix canClickTarget: false not working for this step
                    favoriteMenu.removeEventListener("click", stopPropagationEvent);

                    if (window.getComputedStyle(favoriteMenu).visibility === "visible") {
                        // If favorite menu is visible, close it
                        if (favoriteMenu.children[0]) {
                            favoriteMenu.children[0].click();
                        }
                    }
                },
            },
        },
        {
            id: "titles-line",
            arrow: false,
            attachTo: {
                element: "#search-results-header",
                on: "auto-start",
            },
            title: p.t("modules.account.guidetour.search.titles-line.title"),
            text: `${p.t("modules.account.guidetour.search.titles-line.text")}`,
            when: {
                // Needed because when property override default show from step options
                show: tourOptions.defaultStepOptions.when.show,
                // Preparation for next step
                hide: function () {
                    ["close", "cancel", "complete"].forEach((event) =>
                        this.tour.on(event, () => {
                            if (firstRow) {
                                firstRow.style["pointer-events"] = "auto";
                            }
                        })
                    );
                },
            },
        },
        {
            id: "cell",
            attachTo: {
                element: `#${firstRow.id} ${cellSelector}`,
                on: "auto",
            },
            title: p.t("modules.account.guidetour.search.cell.title"),
            text: p.t("modules.account.guidetour.search.cell.text"),
            beforeShowPromise: () => {
                return new Promise((resolve) => {
                    if (firstRow) {
                        firstRow.style["pointer-events"] = "none";
                    }

                    resolve();
                });
            },
        },
        {
            id: "search",
            attachTo: {
                element: "#search-title",
                on: "auto",
            },
            title: p.t("modules.account.guidetour.search.search.title"),
            text: p.t("modules.account.guidetour.search.search.text"),
        },
        {
            id: "line",
            arrow: false,
            attachTo: {
                element: firstRow,
                on: "auto-start",
            },
            title: p.t("modules.account.guidetour.search.line.title"),
            text: p.t("modules.account.guidetour.search.line.text"),
        },
        {
            id: "help-button",
            attachTo: {
                element: "#launcher",
                on: "auto",
            },
            title: p.t("modules.account.guidetour.search.help-button.title"),
            text: p.t("modules.account.guidetour.search.help-button.text"),
            buttons: [
                tourOptions.defaultStepOptions.buttons[0],
                {
                    ...tourOptions.defaultStepOptions.buttons[1],
                    text: p.t("guidetour.buttons.done"),
                },
            ],
        },
    ]);

    return steps;
}
