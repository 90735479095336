import { formFieldTypes, displayTypes } from "@console/core";

interface IAttribute {
    id: string;
    apiFieldPath: string;
    displayType: string;
    formFieldType: string;
    formFieldOptions: object;
    displayOptions: object;
    reference?: string;
    internal?: boolean;
}

const attributes: Record<string, IAttribute> = {
    kycId: {
        id: "kycId",
        apiFieldPath: "id",
        displayType: displayTypes.DISPLAY_TEXT,
        formFieldType: formFieldTypes.FORM_FIELD_TEXT,
        formFieldOptions: {},
        displayOptions: {},
    },
    businessName: {
        id: "businessName",
        apiFieldPath: "business.businessName",
        displayType: displayTypes.DISPLAY_TEXT,
        formFieldType: formFieldTypes.FORM_FIELD_TEXT,
        formFieldOptions: {},
        displayOptions: {},
    },
    legalName: {
        id: "legalName",
        apiFieldPath: "business.legalName",
        displayType: displayTypes.DISPLAY_TEXT,
        formFieldType: formFieldTypes.FORM_FIELD_TEXT,
        formFieldOptions: {},
        displayOptions: {
            sortable: true,
        },
        internal: true,
    },
    kycType: {
        id: "kycType",
        apiFieldPath: "onboardingType.translationCode",
        displayType: displayTypes.DISPLAY_TEXT,
        formFieldType: formFieldTypes.FORM_FIELD_SELECT,
        formFieldOptions: {},
        displayOptions: {},
        reference: "onboardingTypes",
    },
    kycStatus: {
        id: "kycStatus",
        apiFieldPath: "verificationStatus.translationCode",
        displayType: displayTypes.DISPLAY_TEXT,
        formFieldType: formFieldTypes.FORM_FIELD_SELECT,
        formFieldOptions: {},
        displayOptions: {},
        reference: "onboardingStatuses",
    },
    kycManager: {
        id: "kycManager",
        apiFieldPath: "kycManager.fullname",
        displayType: displayTypes.DISPLAY_AVATAR,
        formFieldType: formFieldTypes.FORM_FIELD_TEXT,
        formFieldOptions: {},
        displayOptions: {
            sortable: false,
        },
        internal: true,
    },
    goLiveDate: {
        id: "goLiveDate",
        apiFieldPath: "goLiveExpectedDate",
        displayType: displayTypes.DISPLAY_DATE,
        formFieldType: formFieldTypes.FORM_FIELD_DATE,
        formFieldOptions: {
            disableFutureDays: false,
        },
        displayOptions: {
            displayTime: false,
        },
    },
    updatedAt: {
        id: "updatedAt",
        apiFieldPath: "updatedAt",
        displayType: displayTypes.DISPLAY_DATE,
        formFieldType: formFieldTypes.FORM_FIELD_DATE,
        formFieldOptions: {},
        displayOptions: {
            displayTime: true,
        },
    },
    createdAt: {
        id: "createdAt",
        apiFieldPath: "createdAt",
        displayType: displayTypes.DISPLAY_DATE,
        formFieldType: formFieldTypes.FORM_FIELD_DATE,
        formFieldOptions: {},
        displayOptions: {
            displayTime: true,
        },
    },
    kycDecision: {
        id: "kycDecision",
        apiFieldPath: "riskReview.riskDecision.riskDecisionStatus.translationCode",
        displayType: displayTypes.DISPLAY_TEXT,
        formFieldType: formFieldTypes.FORM_FIELD_SELECT,
        formFieldOptions: {},
        displayOptions: {
            sortable: false,
        },
        reference: "kycDecision",
        internal: true,
    },
    riskDecisionLevel: {
        id: "riskDecisionLevel",
        apiFieldPath: "riskReview.riskDecision.riskDecisionLevel.translationCode",
        displayType: displayTypes.DISPLAY_TEXT,
        formFieldType: formFieldTypes.FORM_FIELD_SELECT,
        formFieldOptions: {},
        displayOptions: {
            sortable: false,
        },
        reference: "riskDecisionLevels",
        internal: true,
    },
    nextReviewDate: {
        id: "nextReviewDate",
        apiFieldPath: "riskReview.riskDecision.nextReviewDate",
        displayType: displayTypes.DISPLAY_DATE,
        formFieldType: formFieldTypes.FORM_FIELD_DATE,
        formFieldOptions: {
            disableFutureDays: false,
        },
        displayOptions: {
            displayTime: false,
        },
    },
    businessAgreementProdId: {
        id: "businessAgreementProdId",
        apiFieldPath: "business.businessAgreementProdId",
        displayType: displayTypes.DISPLAY_TEXT,
        formFieldType: formFieldTypes.FORM_FIELD_TEXT,
        formFieldOptions: {},
        displayOptions: {
            sortable: false,
        },
        internal: true,
    },
};

export default attributes;
