const enableStatus = {
    id: "enableStatus",
    data: [
        {
            id: "true",
            label: "ref.account.misc.enabled",
            color: "#02A17B",
        },
        {
            id: "false",
            label: "ref.account.misc.disabled",
            color: "#CB2B0B",
        },
    ],
    translate: true,
};

export default enableStatus;
