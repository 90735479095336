const privilege_description = {
    id: "privilege_description",
    endpoint: `${process.env.NX_USER_MANAGEMENT_API_URL}/v1/privileges`,
    withSelectedAccounts: false,
    translate: true,
    organizeFunc: (apiResult) => {
        return apiResult.map((row) => {
            const splittedScope = row.scope.split(":");

            return {
                id: row.scope,
                label: `ref.privilege.privilege_description.${splittedScope[1]}:${splittedScope[2]}`,
            };
        });
    },
};

export default privilege_description;
