const role_description = {
    id: "role_description",
    endpoint: `${process.env.NX_USER_MANAGEMENT_API_URL}/v1/roles`,
    withSelectedAccounts: false,
    translate: true,
    organizeFunc: (apiResult) => {
        return apiResult.map((row) => ({
            id: row.code,
            label: `ref.role.role_description.${row.code}`,
        }));
    },
};

export default role_description;
