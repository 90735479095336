import { useCallback, useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import HiExpansionPanel from "@hipay/hipay-material-ui/HiExpansionPanel";
import { useScrollbars } from "../../../../services/scrollbars";

export const useStylesExpPannel = makeStyles((theme, props) => ({
    panel: { "&:before": { display: "none" } },
    panelDetails: {
        width: "100%",
        display: "block",
        maxWidth: (props) => (props.fullWidth ? "inherit" : 688),
        margin: "auto",
    },
    expanded: { margin: "initial" },
    summaryRoot: {
        maxWidth: 688,
        margin: "auto",
    },
    expandIcon: { marginRight: 0 },
}));

export function ExpansionPanel({ children, initOpen = true, ...props }) {
    // Controlled or uncontrolled open state
    const [_open, setOpen] = useState(initOpen);
    const _handleChange = useCallback(() => {
        setOpen(!_open);
    }, [_open, setOpen]);

    const {
        collapseDisable,
        handleChange = _handleChange,
        heading,
        open = _open,
        secondaryHeading,
        secondaryHeadingIcon,
    } = props;

    let classes = useStylesExpPannel(props);
    const { scrollTo } = useScrollbars();

    const handleExpansionPanelEntered = useCallback(
        (node) => {
            if (scrollTo) {
                // scroll to display all panel
                const nodePosition = node.getBoundingClientRect();
                if (nodePosition.bottom >= window.innerHeight) {
                    scrollTo(nodePosition.bottom - window.innerHeight, true);
                }
            }
        },
        [scrollTo]
    );

    return (
        <HiExpansionPanel
            classes={classes}
            heading={heading}
            secondaryHeading={secondaryHeading}
            secondaryHeadingIcon={secondaryHeadingIcon}
            collapseDisable={collapseDisable}
            expanded={open}
            onChange={handleChange}
            TransitionProps={{ onEntered: handleExpansionPanelEntered }}
        >
            {children}
        </HiExpansionPanel>
    );
}
