import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        "@media (max-width: 1024px)": {
            maxWidth: 350,
            margin: 0,
        },
    },
    header: { maxHeight: 211 },
    logo: {
        margin: "unset",
        "@media (max-width: 1024px)": { display: "none" },
    },
    body: {
        marginTop: 40,
        marginRight: 20,
        maxWidth: 450,
        "@media (max-width: 1024px)": { marginRight: 0 },
    },
    bodyText: {
        wordBreak: "break-word",
        color: "#FFFFFF",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        "&>h1": {
            ...theme.typography.h1,
            color: "#FFFFFF",
        },
        "&>p": {
            ...theme.typography.body1,
            color: "#FFFFFF",
            margin: 0,
        },
        "&>a": { color: "white" },
    },
    connectionDetails: {
        ...theme.typography.subtitle2,
        fontWeight: theme.typography.fontWeightRegular,
        color: "#FBB90F",
        display: "inline-flex",
        alignItems: "center",
        marginTop: 40,
        "&>div>a": { color: "#FBB90F" },
    },
}));
