const role_type = {
    id: "role_type",
    data: [
        {
            id: "INTERNAL",
            label: "ref.role.role_type.INTERNAL",
        },
        {
            id: "EXTERNAL",
            label: "ref.role.role_type.EXTERNAL",
        },
    ],
    translate: true,
};

export default role_type;
