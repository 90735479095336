import React from "react";
import PropTypes from "prop-types";
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { translate, withUser } from "../../../../services";

import { TopBar } from "../../../components";
import * as appActions from "../../../actions/appActions";
import { buildAccountDisplayPropsFromEntities } from "../../../../utils";
import withStyles from "@mui/styles/withStyles";

const styles = {
    accountSelectorButton: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        paddingRight: 4,
    },
    accountIcon: { marginRight: 8 },
};

/**
 * Container managing data in topbar.
 */
class TopBarContainerClass extends React.Component {
    static propTypes = {
        /**
         * Fonction de callback au click sur le menu
         */
        onClickMenu: PropTypes.func.isRequired,
    };

    shouldComponentUpdate(nextProps, nextState) {
        if (
            JSON.stringify(nextProps) === JSON.stringify(this.props) &&
            JSON.stringify(nextState) === JSON.stringify(this.state)
        ) {
            return false;
        }
        return true;
    }

    /**
     * Back button
     * - inactive top bar
     * - close inner page
     */
    handleClickReturn = () => {
        this.props.inactiveTopBar();
    };

    render() {
        const {
            classes,
            onClickMenu,
            selectedAccountIdList,
            accountEntities,
            businessEntities,
            isAccountSelectorOpen,
            isActive,
            inactiveTopBar,
            user,
            p,
        } = this.props;

        const accountDisplay = buildAccountDisplayPropsFromEntities(
            p,
            selectedAccountIdList,
            accountEntities,
            businessEntities
        );

        const accountSelectorContent = (
            <span className={classes.accountSelectorButton} title={accountDisplay.title}>
                {accountDisplay.label}
            </span>
        );

        const hasMultipleAccounts =
            user.store.user.allAccountsAccess || Object.keys(accountEntities).length > 1;

        return (
            <TopBar
                onClickMenu={onClickMenu}
                onClickReturn={this.handleClickReturn}
                hideable={true}
                hasAccountSelector={hasMultipleAccounts}
                accountSelectorContent={accountSelectorContent}
                isAccountSelectorOpen={isAccountSelectorOpen}
                refButtons={(el) => (this.overlay = el)}
                isActive={isActive}
                inactiveTopBar={inactiveTopBar}
            />
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        selectedAccountIdList: state.app.global.selectedAccountIdList,
        accountEntities: state.app.global.entities.account,
        businessEntities: state.app.global.entities.business,
        isActive: state.app.topbar.isActive,
    };
};

const mapDispatchToProps = (dispatch) => {
    return { inactiveTopBar: () => dispatch(appActions.inactiveTopBar()) };
};

export const TopBarContainer = compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps),
    translate,
    withStyles(styles),
    withUser
)(TopBarContainerClass);
