import "@okta/okta-signin-widget/css/okta-sign-in.min.css";
import { useStyles } from "./stylesheet";

export const ExternalSignInButton = (props) => {
    const classes = useStyles();
    const { label } = props;

    const showExternalSignInView = () => {
        const signInForm = document.getElementsByClassName("primary-auth-form")[0];
        const externalButton = document.getElementById("external-signin-button");

        if (!!signInForm && !!externalButton) {
            signInForm.setAttribute("style", "display: block;");
            externalButton.style.display = "none";
        }
    };

    return (
        <button
            id="external-signin-button"
            className={classes.root}
            onClick={showExternalSignInView}
        >
            {label}
        </button>
    );
};
