import React from "react";
import PropTypes from "prop-types";
import HiIcon from "@hipay/hipay-material-ui/HiIcon";
import { translate } from "../../../../../../services/i18n/translate";
import { isMobile } from "react-device-detect";
import classNames from "classnames";

class DefaultSuggestionItem extends React.Component {
    static propTypes = { suggestion: PropTypes.object.isRequired };

    render() {
        const { module, suggestion, p } = this.props;

        let contentValue = ` ${suggestion.operator} ${suggestion.valueHighlight}`;

        return (
            <div className="search item">
                {!isMobile && <HiIcon icon="search" size={24} />}
                <div
                    className={classNames({
                        "text-div": !isMobile,
                        "text-div-mobile": isMobile,
                    })}
                >
                    {p.t(`attributes.${module}.${suggestion.targetField}.suggestLabel`)}
                    {suggestion.operator && (
                        <span className="dark" dangerouslySetInnerHTML={{ __html: contentValue }} />
                    )}
                </div>
            </div>
        );
    }
}

export default translate(DefaultSuggestionItem);
