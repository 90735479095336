import React from "react";
import PropTypes from "prop-types";
import { translate } from "../../../../../services/i18n/translate";
import classNames from "classnames";
import keycode from "keycode";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import withStyles from "@mui/styles/withStyles";
import HiColoredLabel from "@hipay/hipay-material-ui/HiColoredLabel";
import HiCheckbox from "@hipay/hipay-material-ui/HiCheckbox";
import { escapeHTML } from "@hipay/hipay-material-ui/utils/helpers";
import { getAccountStatusLabel } from "../../../../../utils/entities";
import { highlightString } from "../../../../../utils/strings";
import { isMobile } from "react-device-detect";

const styleSheet = (theme) => ({
    checkbox: {
        width: 24,
        height: 24,
    },
    listItem: {
        paddingTop: 8,
        paddingBottom: 8,
        paddingLeft: 50,
        cursor: "default",
        height: 35,
    },
    selected: { backgroundColor: theme.palette.background2 },
    notSelected: {
        padding: "2px 8px",
        lineHeight: 1,
    },
    iconChecked: { color: theme.palette.primary.main },
    secondaryLabel: {
        ...theme.typography.body3,
        color: theme.palette.neutral.main,
        marginLeft: 6,
        textAlign: "left",
        paddingTop: 3,
    },
    deactivated: { color: theme.palette.error.main },
    pending: { color: theme.palette.warning.main },
    listItemText: {
        ...theme.typography.body2,
        color: theme.palette.neutral.dark,
        textAlign: "left",
        marginLeft: 4,
        padding: 0,
        height: 21,
        "& strong": { fontWeight: theme.typography.fontWeightMedium },
    },
    listItemTextContent: {
        display: "inline-flex",
        width: "100%",
    },
    label: {
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    rightLabel: {
        ...theme.typography.body3,
        color: theme.palette.neutral.main,
        textAlign: "right",
        marginLeft: 4,
    },
    hovered: {
        backgroundColor: theme.palette.action.hover,
        cursor: "pointer",
    },
});

class ListAccountItem extends React.PureComponent {
    static propTypes = {
        classes: PropTypes.object,
        selected: PropTypes.bool.isRequired,
        account: PropTypes.object.isRequired,
        onItemClick: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = { hover: false };

        this.handleMouseEnter = this.handleMouseEnter.bind(this);
        this.handleMouseLeave = this.handleMouseLeave.bind(this);
        this.handleItemClick = this.handleItemClick.bind(this);
    }

    handleMouseEnter(event) {
        this.setState({ hover: true });
    }

    handleMouseLeave(event) {
        this.setState({ hover: false });
    }

    handleItemClick() {
        this.props.onItemClick(this.props.account);
    }

    render() {
        const { classes, p, selected, account, highlight, onSubmit, theme } = this.props;

        let label = account.name;
        if (highlight) {
            label = highlightString(label, highlight);
        }

        return (
            <ListItem
                key={`a-${account.accountId}`}
                onMouseEnter={this.handleMouseEnter}
                onMouseLeave={this.handleMouseLeave}
                onClick={this.handleItemClick}
                className={classNames(
                    classes.listItem,
                    { [classes.selected]: selected },
                    this.state.hover && classes.hovered
                )}
                onKeyDown={(event) => {
                    if (keycode(event) === "enter") {
                        event.preventDefault();
                        onSubmit();
                    } else if (keycode(event) === "space") {
                        event.preventDefault();
                        this.handleItemClick();
                    }
                }}
                tabIndex={0}
            >
                <HiCheckbox
                    checked={selected}
                    checkedIcon="check_box"
                    className={classes.checkbox}
                    classes={{ checked: classes.iconChecked }}
                    icon={this.state.hover ? "check_box_outline_blank" : "fa-tag"}
                    value={`a-${account.accountId}`}
                    key={`a-${account.accountId}`}
                    color="primary"
                    tabIndex={-1}
                />
                <ListItemText
                    disableTypography
                    primary={
                        <div className={classes.listItemTextContent}>
                            {selected ? (
                                <HiColoredLabel
                                    className={classes.label}
                                    // eslint-disable-next-line react/no-danger
                                    label={
                                        <span
                                            dangerouslySetInnerHTML={{ __html: escapeHTML(label) }}
                                        />
                                    }
                                    color={
                                        account.color ? account.color : theme.palette.neutral.main
                                    }
                                />
                            ) : (
                                <div
                                    className={classNames(classes.label, classes.notSelected)}
                                    // eslint-disable-next-line react/no-danger
                                    dangerouslySetInnerHTML={{ __html: escapeHTML(label) }}
                                />
                            )}
                            <span className={classes.secondaryLabel}>{account.publicref}</span>
                            {!isMobile &&
                                ["DELETED", "SUSPENDED", "PENDING", "AWAITING_VALIDATION"].indexOf(
                                    account.status.code
                                ) >= 0 && (
                                    <span
                                        className={classNames(classes.secondaryLabel, {
                                            [classes.deactivated]:
                                                account.status.code === "SUSPENDED" ||
                                                account.status.code === "DELETED",
                                            [classes.pending]:
                                                account.status.code === "PENDING" ||
                                                account.status.code === "AWAITING_VALIDATION",
                                        })}
                                    >
                                        {p.t(getAccountStatusLabel(account.status.code))}
                                    </span>
                                )}
                        </div>
                    }
                    className={classes.listItemText}
                />

                {!isMobile && (
                    <div>
                        <span className={classes.rightLabel}>
                            {account.invoicing.invoicingCurrency}
                        </span>
                    </div>
                )}
            </ListItem>
        );
    }
}
export default withStyles(styleSheet, { withTheme: true })(translate(ListAccountItem));
