import { useP } from "../../../services/i18n";
import { useStyles } from "./stylesheet";
import { HiLogo } from "@hipay/design-system/components";

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const MarketContainer = (props) => {
    const classes = useStyles();
    const p = useP();

    const { header, messageKey } = props;

    return (
        <div className={classes.root}>
            {header && <div className={classes.header}> {header} </div>}
            <HiLogo className={classes.logo} color="white" height={44} />
            <div className={classes.body}>
                <div
                    className={classes.bodyText}
                    dangerouslySetInnerHTML={{ __html: p.t(messageKey) }}
                />
            </div>
        </div>
    );
};

MarketContainer.defaultProps = { messageKey: "modules.authentication.app.default_message" };
