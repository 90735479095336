import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Switch, Route, useHistory, useLocation } from "react-router-dom";

import { useUser } from "../services";
import { Page404, ErrorBoundary, AppLoading } from "../app/components";

import { Login } from "./components";
import TppRedirect from "./components/Login/TppRedirect";
import { useOktaAuth } from "@okta/okta-react";
import { getRedirectToUrl, getRedirectToIntelligence, isRedirectToIntelligence } from "../utils";

export const AuthRouter = () => {
    const history = useHistory();
    const location = useLocation();
    const user = useUser();
    const { authState, oktaAuth } = useOktaAuth();
    const token = authState?.accessToken?.accessToken;
    const refreshToken = authState?.refreshToken?.refreshToken;

    const query = new URLSearchParams(location.search);
    const url = location.pathname !== "/forgotten-password" ? query.get("url") : null;

    // eslint-disable-next-line
    // @ts-ignore: store de console-core
    const redirectLocation = useSelector((state) => state.app.global.redirectLocation);

    // eslint-disable-next-line
    // @ts-ignore: store de console-core
    const selectedAccountIdList = useSelector((state) => state.app.global.selectedAccountIdList);
    const redirectDashboard = selectedAccountIdList.length
        ? "/accounts-" + selectedAccountIdList.join("-") + "/dashboard"
        : "/dashboard";

    useEffect(() => {
        if (location.pathname === "/logout") {
            if (user) {
                history.replace("/login");
            }
        } else if (location.pathname !== "/tpp/redirect" && user) {
            const redirectTo = redirectLocation || getRedirectToUrl(location);

            if (
                // Login for old_interface
                url &&
                process?.env?.NX_OLD_INTERFACE_BASE_URL &&
                url.indexOf(process.env.NX_OLD_INTERFACE_BASE_URL) >= 0
            ) {
                // eslint-disable-next-line
                // @ts-ignore
                window.location =
                    process.env.NX_OLD_INTERFACE_BASE_URL + "/default/auth/login?sso=1";
            } else if (token && redirectTo) {
                const oldInterfaceUrl = new URL(String(process.env.NX_OLD_INTERFACE_BASE_URL));
                if (
                    refreshToken &&
                    typeof redirectTo === "string" &&
                    process.env.NX_OLD_INTERFACE_BASE_URL &&
                    redirectTo.includes(oldInterfaceUrl.host)
                ) {
                    const tppUrl = new URL(redirectTo);
                    const pathname = tppUrl.pathname;
                    const host = tppUrl.host;

                    // eslint-disable-next-line
                    // @ts-ignore
                    window.location = `http://${host}/default/auth/login/?sso=1&redirect=${pathname}`;
                } else if (typeof redirectTo === "string" && isRedirectToIntelligence(redirectTo)) {
                    window.location.href = getRedirectToIntelligence(redirectTo, token);
                } else {
                    history.replace(redirectTo);
                }
            } else if (token) {
                history.replace(redirectDashboard);
            }
        }
    }, [user, location, history, oktaAuth, redirectDashboard, redirectLocation]);

    return (
        <ErrorBoundary>
            <Switch>
                <Route path={"/logout"} render={() => <AppLoading message={"Logout user..."} />} />
                {url && token && user && (
                    <Route render={() => <AppLoading message={"Redirect to " + url} />} />
                )}
                <Route exact path={"/login"} component={Login} />
                <Route
                    exact
                    path={"/login/sso"}
                    render={() => <AppLoading message={"Login..."} />}
                />
                <Route exact path={"/tpp/redirect"} component={TppRedirect} />
                <Route render={() => Page404} />
            </Switch>
        </ErrorBoundary>
    );
};
