const user_type = {
    id: "user_type",
    data: [
        {
            id: "INTERNAL",
            label: "ref.user.user_type.INTERNAL",
        },
        {
            id: "EXTERNAL",
            label: "ref.user.user_type.EXTERNAL",
        },
    ],
    translate: true,
};

export default user_type;
