import attributes from "../../constants/attributes";
import { getApiField, getApiSubField, getApiSubSubField, removeWrappingQuotes } from "../../utils";
import { formFieldTypes } from "@console/core";
import moment from "moment-timezone";

/**
 * Format response from account api
 * @param apiResponse
 * @returns {*}
 */
export const formatSearchResults = (apiResponse) => {
    if (!Array.isArray(apiResponse)) {
        return [];
    }
    return apiResponse.map((item) => {
        const row = { ...item };

        const resultItem = {
            rowId: row.uuid,
            datas: {},
            details: {},
        };

        // Filter to display only active payment means
        if (row.paymentMeans) {
            row.paymentMeans = row.paymentMeans.filter(
                (paymentMean) => paymentMean.statusCode === "ACTIVATED"
            );
        }

        Object.keys(attributes).forEach((attrKey) => {
            const attrConfig = attributes[attrKey];
            const apiFieldParts = attrConfig.apiField.split(".");
            const apiField = getApiField(attrConfig.apiField);
            const apiSubField = getApiSubField(attrConfig.apiField);
            const apiSubSubField = getApiSubSubField(attrConfig.apiField);

            // Relation data
            if (typeof attrConfig.displayOptions.dataType !== "undefined") {
                // Handle apiField[].apiSubfield
                if (Array.isArray(row[apiField]) && row[apiField].length > 0) {
                    resultItem.details[attrConfig.displayOptions.dataType] = {
                        rows: row[apiField].map((subItem, key) => ({
                            rowId: key,
                            datas: {
                                [attrConfig.id]: {
                                    value: apiSubSubField
                                        ? subItem[apiSubField][apiSubSubField]
                                        : subItem[apiSubField],
                                    label: apiSubSubField
                                        ? subItem[apiSubField][apiSubSubField]
                                        : subItem[apiSubField],
                                },
                            },
                        })),
                    };
                }
                // Handle apiField.apiSubfield[value]
                else if (
                    typeof row[apiField] === "object" &&
                    row[apiField] !== null &&
                    apiSubField &&
                    Array.isArray(row[apiField][apiSubField]) &&
                    row[apiField][apiSubField].length > 0
                ) {
                    resultItem.details[attrConfig.displayOptions.dataType] = {
                        rows: row[apiField][apiSubField].map((subItem, key) => ({
                            rowId: key,
                            datas: { [attrConfig.id]: { value: subItem, label: subItem } },
                        })),
                    };
                }
            }
            // Normal field
            else {
                resultItem.datas[attrKey] = formatResultItemValue(row, apiFieldParts);
            }
        });

        return resultItem;
    });
};

/**
 * Format search params to account api query params
 * @param searchParams
 */
export const formatSearchParams = (searchParams) => {
    const formattedSearchParams = {};
    // Handle searchable form fields
    Object.keys(searchParams)
        .filter((name) => Object.keys(attributes).includes(name))
        .forEach((name) => {
            switch (true) {
                case attributes[name]["formFieldType"] === formFieldTypes.FORM_FIELD_DATE:
                    formattedSearchParams[`${attributes[name].apiField}[after]`] = moment(
                        searchParams[name]["from"]
                    ).toISOString();
                    formattedSearchParams[`${attributes[name].apiField}[before]`] = moment(
                        searchParams[name]["to"]
                    )
                        .endOf("day")
                        .toISOString();
                    break;
                case attributes[name]["formFieldType"] === formFieldTypes.FORM_FIELD_NUMERIC:
                    formattedSearchParams[`op_${attributes[name].apiField}`] = searchParams[name];
                    break;
                case attributes[name]["formFieldType"] === formFieldTypes.FORM_FIELD_SELECT:
                case attributes[name]["formFieldType"] === formFieldTypes.FORM_FIELD_ACCOUNT:
                case attributes[name]["formFieldType"] === formFieldTypes.FORM_FIELD_CHECKBOX:
                    if (searchParams[name].length > 0) {
                        formattedSearchParams[`in_${attributes[name].apiField}`] =
                            searchParams[name].join("--");
                    }
                    break;
                case attributes[name]["formFieldType"] === formFieldTypes.FORM_FIELD_SUGGEST:
                default:
                    formattedSearchParams[attributes[name].apiField] = encodeURIComponent(
                        removeWrappingQuotes(searchParams[name].trim())
                    );
            }
        });
    // Handle order
    if (searchParams["order_by"] && searchParams["direction"]) {
        const orderFieldName = attributes[searchParams["order_by"]].apiField;
        formattedSearchParams[`order[${orderFieldName}]`] = searchParams["direction"];
    }
    return formattedSearchParams;
};

/**
 * Format result item value and wrap additional data
 * @param row
 * @param apiFieldParts
 * @returns {{value: null, extra: {color: *}}}
 */
const formatResultItemValue = (row, apiFieldParts) => {
    let value = row;
    for (let i = 0; i < apiFieldParts.length; i++) {
        value = Object.prototype.hasOwnProperty.call(value, apiFieldParts[i])
            ? value[apiFieldParts[i]]
            : null;
        if (!value) {
            break;
        }
    }
    if (value === true) value = "true";
    if (value === false) value = "false";

    return value || value === 0
        ? {
              value: value,
              label: value,
              currency: row.currencyCode,
              extra: {
                  colorCode: row.colorCode,
              },
          }
        : null;
};

/**
 * Format export columns configuration
 * @param columns
 * @param p
 */
export const formatExportColumns = (columns, p) => {
    let formattedColumns = {};
    columns.forEach((column) => {
        if (column.displayed === true) {
            formattedColumns[column.colId] = {
                apiField: attributes[column.colId]["apiField"],
                label: p.t(`attributes.account.${column.colId}.tableLabel`),
            };
            if (Object.prototype.hasOwnProperty.call(column, "displayCurrency")) {
                formattedColumns[column.colId] = column.displayCurrency;
            }
            if (Object.prototype.hasOwnProperty.call(column, "displayTime")) {
                formattedColumns[column.colId].displayTime = column.displayTime;
            }
        }
    });
    return formattedColumns;
};
