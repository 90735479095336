import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import styled from "styled-components/macro";
import HiLoader from "@hipay/hipay-material-ui/HiLoader";
import CustomCollapseRow from "./CustomCollapseRow";

/**
 * This component is a custom table to show any type of data, not only attributes.
 * Collapse content can be specified for each rows.
 * To enable collapse feature, a config must be set with an opened and a closed text.
 */
const CustomTable = (props) => {
    const { columns, rows, collapseConfig, loading } = props;

    if (loading) {
        return (
            <div className={props.className}>
                <HiLoader className="loader" />
            </div>
        );
    }

    return (
        <div className={props.className}>
            <Table>
                <TableHead>
                    <TableRow>
                        {columns.map((column, index) => (
                            <TableCell
                                key={`notification-header-cell-${index}`}
                                className="header-cell"
                            >
                                <Typography className="header-cell-text" variant="body3">
                                    {column}
                                </Typography>
                            </TableCell>
                        ))}

                        {collapseConfig && collapseConfig.showToggle && (
                            <TableCell className="collapse-header-cell" />
                        )}
                    </TableRow>
                </TableHead>

                <TableBody>
                    {rows.map((row, index) => {
                        return (
                            <CustomCollapseRow
                                key={`notification-${index}-row`}
                                cells={row.cells}
                                error={row.error}
                                collapseConfig={collapseConfig}
                                collapseContent={row.collapseContent}
                            />
                        );
                    })}
                </TableBody>
            </Table>
        </div>
    );
};

const StyledCustomTable = styled(CustomTable)`
    .loader {
        margin: auto;
        margin-top: ${({ theme }) => theme.spacing(6)};
    }

    table {
        table-layout: fixed;
        background-color: #f8f9fb;
        box-shadow: inset 0px 4px 3px -2px rgba(0, 0, 0, 0.16),
            inset 0px 0px 2px 0px rgba(0, 0, 0, 0.16);
    }

    .header-cell {
        border-bottom: 1px solid ${({ theme }) => theme.palette.divider};

        &-text {
            font-weight: ${({ theme }) => theme.typography.fontWeightLight} !important;
        }
    }

    .collapse-header-cell {
        width: 200px;
        border-bottom: 1px solid ${({ theme }) => theme.palette.divider};
        font-weight: normal;
    }
`;

export default StyledCustomTable;
