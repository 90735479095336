import PropTypes from "prop-types";
import ContentLoader from "react-content-loader";

import { displayTypes } from "../../../../../common/constants/displayTypes";
import { useCan } from "../../../../../services/ability";

import { RowLayout } from "./RowLayout";
import { RowExpandable } from "./RowExpandable";
import { RowCustomData } from "./RowCustomData";
import CellBuilder from "../../../Table/CellBuilder";

const AttributeRowLoader = () => (
    <ContentLoader speed={2} primaryColor="#f3f3f3" secondaryColor="#ecebeb">
        <rect x={8} y={8} rx="3" ry="3" width={220} height={16} />
    </ContentLoader>
);

export function AttributeRowBuilder(props) {
    const { hideLabel, attribute, data } = props;

    const can = useCan();
    const disableContextMenu = attribute.rgpdCompliance && !can("read", "gdpr");

    if (!data.value && data.isPending === true) {
        return <AttributeRowLoader />;
    }

    const type = attribute.displayOptions.noticeType || attribute.displayType;

    switch (type) {
        case displayTypes.DISPLAY_EXPANDABLE:
            return (
                <RowExpandable
                    attribute={attribute}
                    data={data}
                    {...attribute.displayOptions}
                    hideLabel={hideLabel}
                />
            );

        case displayTypes.DISPLAY_CUSTOM_DATA:
            return (
                <RowCustomData
                    attribute={attribute}
                    data={data}
                    disableContextMenu={disableContextMenu}
                    hideLabel={hideLabel}
                    {...attribute.displayOptions}
                />
            );

        default:
            return (
                <RowLayout
                    attribute={attribute}
                    actions={attribute.displayOptions.actions}
                    hideLabel={hideLabel}
                >
                    <CellBuilder
                        attribute={attribute}
                        data={data}
                        displayType={type}
                        dataEntity={attribute.dataList}
                        {...attribute.displayOptions}
                        size={24}
                        view={type === displayTypes.DISPLAY_DATE ? "m" : "l"}
                    />
                </RowLayout>
            );
    }
}

AttributeRowBuilder.propTypes = {
    /**
     * Useful to extend the style applied to components.
     */
    classes: PropTypes.object,
    /**
     * Attribut de la ligne
     */
    attribute: PropTypes.object.isRequired,
    /**
     * Données du paragraphe
     */
    data: PropTypes.object.isRequired,
};
