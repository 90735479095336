// DB table : risk.risk_decision_levels
export default {
    id: "riskDecisionLevels",
    translate: true,
    data: [
        {
            id: "low",
            label: "ref.onboarding.risk_decision_levels.low",
            color: "success",
        },
        {
            id: "medium",
            label: "ref.onboarding.risk_decision_levels.medium",
            color: "warning",
        },
        {
            id: "high",
            label: "ref.onboarding.risk_decision_levels.high",
            color: "error",
        },
    ],
};
