import React from "react";
import { connect } from "react-redux";
import * as appActions from "../../actions/appActions";
import HiAlertModal from "@hipay/hipay-material-ui/HiAlertModal";

/**
 * AlertContainer
 *
 * Le composant/service AlertContainer gère les HiAlertModal à afficher.
 *
 * Une seule HiAlertModal peut être ouverte à la fois.
 * On enregistre l'état de ce composant dans le store
 * app : {
 *      alert: {
 *          isOpen: true,
 *          title: "Êtes-vous sûr de bloquer cet UBO ?"
 *          content: "En bloquant cet UBO vous bloquez instantanément l’opérateur de marketplace LDLC6."
 *          labelSubmitButton: "Ok"
 *          submitColor: "primary"
 *          onSubmitClick: this.handleClickSubmit
 *          onCancelClick: this.handleClickCancel
 *          onClose: this.handleClose
 *          labelCancelButton: 'CANCEL'
 *          cancelColor: 'neutral'
 *          backgroundIcon: 'mdi_bulletin_board'
 *          iconSize: 123
 *      }
 * }
 *
 * Avec
 * - isOpen : true pour ouvrir l'alert, false pour la fermer
 * ...others: voir l'API de HiAlertModal
 * http://materialui.hipay.dev.local/hi-demos/alert-modals/
 *
 * Utilisation;
 * - Inclure la fonction addAlert dans le composant nécessitant l'affichage d'une alert
 * - Ouvrir l'alert:
 *      this.props.addAlert({
 *           isOpen: true,
 *           ...
 *       });
 * - Fermer l'alert:
 *      handleClose: () => dispatch(appActions.closeAlert())
 */
class AlertContainer extends React.Component {
    handleSubmitClick = (e) => {
        this.props.handleClose(e);
        if (this.onSubmitClick) {
            this.onSubmitClick(e);
        }
    };

    render() {
        const { isOpen, handleClose, ...alertProps } = this.props;

        return (
            <HiAlertModal
                id="alert-modal"
                open={isOpen}
                onClose={handleClose}
                onSubmitClick={this.handleSubmitClick}
                {...alertProps}
            />
        );
    }
}

const mapStateToProps = (state) => {
    return { ...state.app.alert };
};

const mapDispatchToProps = (dispatch) => {
    return { handleClose: () => dispatch(appActions.closeAlert()) };
};

export default connect(mapStateToProps, mapDispatchToProps)(AlertContainer);
