import { dispatch403 } from "../app/actions/appActions";

export const e403Middleware = (store) => (next) => (action) => {
    if (action.error && action.payload && action.payload.status === 403) {
        if (!action.meta || action.meta.dispatch403 !== false) {
            store.dispatch(dispatch403());
        }
    }
    return next(action);
};
