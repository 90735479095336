export function getGuideTourSteps(moduleState, tourOptions, p) {
    let firstWidgetId;
    let thirdWidgetId;
    let fifthWidgetId;

    let firstWidgetHeader = "widget-header_0";
    let fifthWidgetHeader = "widget-header_4";

    let fifthHasInformation = false;
    let fifthHasNoData = false;

    if (moduleState.dashboard) {
        if (moduleState.dashboard.dashboard.loading === true) {
            return [];
        }

        let widgets = moduleState.dashboard.entities.widget;

        let firstWidget;
        let firstWidgetNumId;
        let thirdWidget;
        let fifthWidget;
        let fifthWidgetNumId;

        if (widgets.data.allIds.length !== 0 && widgets.loading === false) {
            widgets.data.allIds.forEach((id) => {
                let widget = widgets.data.byId[id];

                if (widget.config.gridPos.order === 1) {
                    firstWidget = widget;
                    firstWidgetNumId = id;
                } else if (widget.config.gridPos.order === 3) {
                    thirdWidget = widget;
                } else if (widget.config.gridPos.order === 5) {
                    fifthWidget = widget;
                    fifthWidgetNumId = id;
                }
            });

            if (firstWidget) {
                firstWidgetId = `${firstWidget.typeId}-${firstWidget.widgetId.toString()}`;
            } else {
                firstWidget = widgets.data.byId[widgets.data.allIds[0]];
                firstWidgetId = `${firstWidget.typeId}-${firstWidget.widgetId.toString()}`;
                firstWidgetNumId = widgets.data.allIds[0];
            }

            if (thirdWidget) {
                thirdWidgetId = `${thirdWidget.typeId}-${thirdWidget.widgetId.toString()}`;
            } else if (firstWidgetId) {
                thirdWidgetId = firstWidgetId;
            }

            if (fifthWidget && fifthWidgetNumId) {
                fifthWidgetId = `${fifthWidget.typeId}-${fifthWidget.widgetId.toString()}`;

                let widgetData = moduleState.dashboard.entities.widgetType[fifthWidget.typeId];
                if (widgetData) {
                    if (
                        widgetData.data.benefits_header ||
                        widgetData.data.benefits_body ||
                        widgetData.data.benefits_footer
                    ) {
                        fifthHasInformation = true;
                    }
                }
            } else if (firstWidget && firstWidgetNumId && firstWidgetId) {
                fifthWidgetId = firstWidgetId;
                fifthWidgetNumId = firstWidgetNumId;
                fifthWidgetHeader = firstWidgetHeader;

                let widgetData = moduleState.dashboard.entities.widgetType[firstWidget.typeId];
                if (widgetData) {
                    if (
                        widgetData.data.benefits_header ||
                        widgetData.data.benefits_body ||
                        widgetData.data.benefits_footer
                    ) {
                        fifthHasInformation = true;
                    }
                }
            }
        }
    }

    if (firstWidgetId === undefined || thirdWidgetId === undefined || fifthWidgetId === undefined) {
        return [];
    }

    let steps = [
        {
            id: "global-window",
            title: p.t("modules.dashboard.guidetour.global-window.title"),
            text: p.t("modules.dashboard.guidetour.global-window.text"),
            buttons: [
                {
                    ...tourOptions.defaultStepOptions.buttons[0],
                    text: "",
                },
                tourOptions.defaultStepOptions.buttons[1],
            ],
        },
        {
            id: "button-widget-store",
            attachTo: {
                element: "#dash-store-button",
                on: "auto",
            },
            title: p.t("modules.dashboard.guidetour.button-widget-store.title"),
            text: p.t("modules.dashboard.guidetour.button-widget-store.text"),
        },
        {
            id: "header-widget-location-1",
            attachTo: {
                element: `#${firstWidgetHeader}`,
                on: "auto",
            },
            title: p.t("modules.dashboard.guidetour.header-widget-location-1.title"),
            text: p.t("modules.dashboard.guidetour.header-widget-location-1.text"),
        },
        {
            id: "widget-3",
            attachTo: {
                element: `#${thirdWidgetId}`,
                on: "auto",
            },
            title: p.t("modules.dashboard.guidetour.widget-3.title"),
            text: p.t("modules.dashboard.guidetour.widget-3.text"),
            beforeShowPromise: () => {
                return new Promise((resolve) => {
                    let popoverCloseButton = document.getElementById(
                        "settings-popover-close-button"
                    );
                    if (popoverCloseButton) {
                        popoverCloseButton.click();
                    }
                    resolve();
                });
            },
        },
        {
            id: "settings-button",
            attachTo: {
                element: "#dash-settings-button",
                on: "auto",
            },
            title: p.t("modules.dashboard.guidetour.settings-button.title"),
            text: p.t("modules.dashboard.guidetour.settings-button.text"),
            beforeShowPromise: () => {
                return new Promise((resolve) => {
                    let settingsButton = document.getElementById("dash-settings-button-container");
                    if (settingsButton) {
                        settingsButton.click();
                    }

                    // Must wait for the setting animation to finish
                    setTimeout(() => {
                        resolve();
                    }, 200);
                });
            },
        },
        {
            id: "header-widget-location-5",
            attachTo: {
                element: `#${fifthWidgetHeader}`,
                on: "auto",
            },
            title: p.t("modules.dashboard.guidetour.header-widget-location-5.title"),
            text: p.t("modules.dashboard.guidetour.header-widget-location-5.text"),
            beforeShowPromise: () => {
                return new Promise((resolve) => {
                    let reduceButton = document.getElementById("modal-widget-close-button");
                    if (reduceButton) {
                        reduceButton.click();
                    }

                    let popoverCloseButton = document.getElementById(
                        "settings-popover-close-button"
                    );
                    if (popoverCloseButton) {
                        popoverCloseButton.click();
                    }

                    resolve();
                });
            },
        },
    ];

    if (!fifthHasNoData) {
        steps.push({
            id: "big-vue-widget-5-parameter",
            attachTo: {
                element: "#settings-widget-button",
                on: "auto",
            },
            title: p.t("modules.dashboard.guidetour.big-vue-widget-5-parameter.title"),
            text: p.t("modules.dashboard.guidetour.big-vue-widget-5-parameter.text"),
            beforeShowPromise: () => {
                return new Promise((resolve) => {
                    let widgetHeader = document.getElementById(fifthWidgetHeader);
                    if (widgetHeader) {
                        widgetHeader.click();
                    }

                    let settingsButton = document.getElementById("settings-widget-button");
                    if (settingsButton) {
                        settingsButton.click();
                    }
                    resolve();
                });
            },
        });

        if (fifthHasInformation) {
            steps.push({
                id: "big-vue-widget-5-information",
                attachTo: {
                    element: "#info-widget-button",
                    on: "auto",
                },
                title: p.t("modules.dashboard.guidetour.big-vue-widget-5-information.title"),
                text: p.t("modules.dashboard.guidetour.big-vue-widget-5-information.text"),
                beforeShowPromise: () => {
                    return new Promise((resolve) => {
                        let infoWidgetButton = document.getElementById("info-widget-button");
                        if (infoWidgetButton) {
                            infoWidgetButton.click();
                        }
                        resolve();
                    });
                },
            });
        }

        steps.push.apply(steps, [
            {
                id: "big-vue-widget-5-trash",
                attachTo: {
                    element: "#modal-delete-action",
                    on: "auto",
                },
                title: p.t("modules.dashboard.guidetour.big-vue-widget-5-trash.title"),
                text: p.t("modules.dashboard.guidetour.big-vue-widget-5-trash.text"),
            },
            {
                id: "big-vue-widget-5-reduce",
                attachTo: {
                    element: "#modal-widget-close-button",
                    on: "auto",
                },
                title: p.t("modules.dashboard.guidetour.big-vue-widget-5-reduce.title"),
                text: p.t("modules.dashboard.guidetour.big-vue-widget-5-reduce.text"),
                beforeShowPromise: () => {
                    return new Promise((resolve) => {
                        if (!document.getElementById("modal-widget-close-button")) {
                            let widgetHeader = document.getElementById(fifthWidgetHeader);
                            if (widgetHeader) {
                                widgetHeader.click();
                            }

                            let settingsButton = document.getElementById("settings-widget-button");
                            if (settingsButton) {
                                settingsButton.click();
                            }
                        }
                        resolve();
                    });
                },
            },
        ]);
    }

    steps.push({
        id: "help-button",
        attachTo: {
            element: "#launcher",
            on: "auto",
        },
        title: p.t("modules.dashboard.guidetour.help-button.title"),
        text: p.t("modules.dashboard.guidetour.help-button.text"),
        buttons: [
            tourOptions.defaultStepOptions.buttons[0],
            {
                ...tourOptions.defaultStepOptions.buttons[1],
                text: p.t("guidetour.buttons.done"),
            },
        ],
        beforeShowPromise: () => {
            return new Promise((resolve) => {
                let modalCloseButton = document.getElementById("modal-widget-close-button");
                if (modalCloseButton) {
                    modalCloseButton.click();
                }
                resolve();
            });
        },
    });

    return steps;
}
