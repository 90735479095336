import classNames from "classnames";
import makeStyles from "@mui/styles/makeStyles";
import HiIcon from "@hipay/hipay-material-ui/HiIcon";
import { useNoticeContextMenu } from "../../../../services/ContextMenu";

export const useStylesStatus = makeStyles((theme) => ({
    root: {
        display: "inline-flex",
        ...theme.typography.body3,
        fontWeight: theme.typography.fontWeightMedium,
    },
    pin: {
        display: "flex",
        height: "fit-content",
        lineHeight: "24px",
        padding: "0px 10px",
        margin: "auto",
        backgroundColor: theme.palette.background3,
        borderRadius: 12,
    },
    success: { color: theme.palette.success.main },
    neutral: { color: theme.palette.neutral.main },
    error: { color: theme.palette.error.main },
    primary: { color: theme.palette.primary.main },
    secondary: { color: theme.palette.secondary.main },
    warning: { color: theme.palette.warning.main },
    info: { color: theme.palette.info.main },
    icon: {
        height: "100%",
        lineHeight: "24px",
        marginRight: 4,
    },
    label: {},
}));

export const Status = (props) => {
    const {
        className,
        attributeKey,
        value,
        color = null,
        label = null,
        copyLabel = null,
        icon = null,
    } = props;

    const classes = useStylesStatus();

    const handleContextMenu = useNoticeContextMenu({
        [attributeKey]: {
            value: value,
            label: label || copyLabel,
        },
    });

    return (
        <div
            id="notice-item-status"
            className={classNames(classes.root, className, classes[color])}
            onContextMenu={handleContextMenu}
        >
            <div className={classes.pin}>
                {icon && (
                    <HiIcon className={classes.icon} size={14}>
                        {icon}
                    </HiIcon>
                )}
                <div className={classes.label}>{label}</div>
            </div>
        </div>
    );
};
