import styled from "styled-components/macro";
import CloseIcon from "@mui/icons-material/Close";
import HiIconButton from "@hipay/hipay-material-ui/HiIconButton";

interface DialogTitleContentProps {
    className?: string;
    handleClose: () => void;
    title: string;
    subtitle?: string;
}

const _DialogTitleContent = (props: DialogTitleContentProps) => {
    return (
        <div className={props.className}>
            <p>{props.title}</p>
            <HiIconButton onClick={props.handleClose}>
                <CloseIcon />
            </HiIconButton>
        </div>
    );
};

export const DialogTitleContent = styled(_DialogTitleContent)`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;
