const user_privileges = {
    id: "user_privileges",
    endpoint: `${process.env.NX_USER_MANAGEMENT_API_URL}/v1/privileges?order_by=scope`,
    withSelectedAccounts: false,
    translate: false,
    organizeFunc: (apiResult, p) => {
        return apiResult.map((row) => {
            const splittedScope = row.scope.split(":");

            return {
                uuid: row.uuid,
                id: row.scope,
                scope: row.scope,
                type: row.type,
                // Display: Resource - Right
                label:
                    p.t(`ref.privilege.privilege_resource.${splittedScope[1]}`) +
                    " - " +
                    p.t(`ref.privilege.privilege_description.${splittedScope[2]}`),
            };
        });
    },
};

export default user_privileges;
