import { compose } from "recompose";
import { connect } from "react-redux";
import { default as QuickSearch } from "../../../../components/AppLogged/TopBar/QuickSearch/QuickSearch";
import * as appActions from "../../../../actions/appActions";

const mapStateToProps = (state, ownProps) => {
    return {
        authentication: state.app.authentication.data,
        isActive: state.app.topbar.isActive,
        activeGuideTour: state.app.guidetour.active,
        isFocused: state.app.guidetour.focusedInput,
        userData: state.app.user.data,
        searchHistory: state.app.global.searchHistory,
        selectedAccountIdList: state.app.global.selectedAccountIdList,
        accountEntities: state.app.global.entities.account,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchSearchHistory: (module) => dispatch(appActions.fetchSearchHistory(module)),
        fetchSelectedAccountList: (accountIdList) =>
            dispatch(appActions.fetchSelectedAccountList(accountIdList)),
        addSearchHistory: (module, history) =>
            dispatch(appActions.addSearchHistory(module, history)),
        activeTopBar: (query, isValid) => dispatch(appActions.activeTopBar(query, isValid)),
        inactiveTopBar: () => dispatch(appActions.inactiveTopBar()),
    };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(QuickSearch);
