import { useStyles } from "./stylesheet";
import { HiLogo } from "@hipay/design-system/components";

export const Header = () => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <HiLogo color="white" height={44} />
        </div>
    );
};
