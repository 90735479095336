import { useP } from "../../../services/i18n";
import { useStyles } from "./stylesheet";

export const TitleContent = (props) => {
    const classes = useStyles();
    const p = useP();

    return (
        <div className={classes.root}>
            <div
                className={classes.header}
                dangerouslySetInnerHTML={{ __html: p.t(props.titleKey) }}
            />
            <hr className={classes.hr} />
        </div>
    );
};
