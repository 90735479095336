import { useEffect } from "react";
import PropTypes, { any } from "prop-types";
import { useLocation } from "react-router-dom";
import makeStyles from "@mui/styles/makeStyles";
import { useScrollbars } from "../../../services";
import { HeaderContainer } from "./Header";
import { ErrorBoundary } from "../../../app/components/common/ErrorBoundary";

const useStyles = makeStyles((theme) => ({
    root: { height: "100%" },
    content: {
        width: "100%",
        height: "100%",
        zIndex: 2,
        padding: 20,
        paddingTop: (props) => {
            if (props.contentPaddingTop) {
                return props.contentPaddingTop;
            }
            return 184;
        },
        [theme.breakpoints.down("sm")]: {
            paddingLeft: 0,
            paddingRight: 0,
        },
        backgroundColor: (props) => {
            if (theme.palette[props.color]) {
                return theme.palette[props.color].main;
            }
            return props.color;
        },
        backgroundImage: (props) => {
            if (!props.noGradient) {
                return (
                    "linear-gradient(rgba(255,255,255,0.80) 20%, " +
                    theme.palette.background3 +
                    " 50%)"
                );
            }
            return "inherit";
        },
        transition: "background-color 1.5s linear",
    },
}));

const _renderLoader = () => {
    return "LOADING ...";
};

const _renderError = (error) => {
    console.warn("[Notice] Error : ", error);
    return "An error occured : " + JSON.stringify(error);
};

export function Notice(props) {
    const {
        // datas
        loading,
        error,

        // config
        actionComponents,
        referer,

        // renders
        color,
        noGradient,
        children,
        headerComponent,
        statusComponent,
        titleComponent,

        renderLoader = _renderLoader,
        renderError = _renderError,

        hideArrow,
    } = props;

    const classes = useStyles(props);
    const location = useLocation();
    const { scrolled, scrollTo } = useScrollbars();

    /**********************************************************************************************
     * MANAGE AUTO SCROLL FROM HASH
     *********************************************************************************************/

    useEffect(() => {
        let element;
        if (location.hash !== "") {
            const id = location.hash.replace("#", "");
            element = document.getElementById(id);
        }
        if (element) {
            const topBarEl = document.getElementById("top-bar").getBoundingClientRect();
            const headerEl = document.getElementById("notice-header").getBoundingClientRect();
            scrollTo(element.getBoundingClientRect().top - headerEl.height - topBarEl.height, true);
        }
    }, [location, scrollTo]);

    /**********************************************************************************************
     * RENDERS
     *********************************************************************************************/
    if (loading) {
        return renderLoader();
    } else if (error) {
        return renderError(error);
    }

    return (
        <div className={classes.root}>
            <HeaderContainer
                color={color}
                noGradient={noGradient}
                compact={scrolled}
                referer={referer}
                actionComponents={actionComponents}
                titleComponent={titleComponent}
                statusComponent={statusComponent}
                hideArrow={hideArrow}
            >
                <ErrorBoundary>{headerComponent}</ErrorBoundary>
            </HeaderContainer>
            <div id="notice-content" className={classes.content}>
                {children}
            </div>
        </div>
    );
}

Notice.propTypes = {
    classes: PropTypes.object,
    module: PropTypes.string,
    referer: PropTypes.string,
    actionComponents: PropTypes.arrayOf(PropTypes.node),
    color: PropTypes.string,
    headerComponent: PropTypes.node,
    statusComponent: PropTypes.node,
    titleComponent: PropTypes.node,
    hideArrow: PropTypes.bool,
    noGradient: PropTypes.bool,
    contentPaddingTop: PropTypes.number,
    children: any,
};

Notice.defaultProps = {
    actionComponents: [],
    color: "#ffffff",
    headerComponent: null,
    statusComponent: null,
    titleComponent: null,
    hideArrow: false,
    children: null,
};
