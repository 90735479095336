import Typography from "@mui/material/Typography";
import styled from "styled-components/macro";

const ErrorMessage = (props) => {
    const { message } = props;

    return (
        <Typography className={props.className} variant="body2">
            {message}
        </Typography>
    );
};

const StyledErrorMessage = styled(ErrorMessage)`
    background-color: ${({ theme }) => theme.palette.error.dark};
    color: ${({ theme }) => theme.palette.primary.contrastText};
    padding: 12px;
    border-radius: 4px;
`;

export default StyledErrorMessage;
