import { compose } from "recompose";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import { virtualize } from "react-swipeable-views-utils";

import { NavBtnPrev } from "./NavBtnPrev";
import { NavBtnNext } from "./NavBtnNext";

/* HOCs: virtualize
 * (i) bindKeyboard is not used because it's already handle by NavButtons.
 */
const EnhancedSwipeableViews = compose(virtualize)(SwipeableViews);

const containerStyle = { height: "100%" };

/**
 * Navigation component.
 *
 * It use react-swipeable-views to manage swipeable views. (https://react-swipeable-views.com/)
 *
 * It use the virtualize HOC to provide infinite feature. (https://react-swipeable-views.com/getting-started/usage/#example-with-virtualize)
 * This will pre-mount the 2 previous and 2 next View components by index. (this could be set with overscanSlide prop).
 * e.g index=4 >> [2][3 ][ 4 ][ 5][6]
 *
 * It add previous/next buttons.
 *
 * Usage:
 * <Navigation ... >
 *     {({ key, index }) => (
 *          <View
 *              key={key}
 *              index={index}
 *          />
 *     )}
 * </Navigation>
 *
 * /!\ View component should use key props /!\
 * /!\ View component should be relative to an index, which is not the noticeId /!\
 *
 * @param props
 * @returns {*}
 * @constructor
 */
export function Navigation(props) {
    const {
        index,
        children,
        onNext,
        onPrevious,
        overscanSlide,
        nextColor,
        nextContent,
        previousColor,
        previousContent,
        slideCount,
    } = props;

    const handleChangeIndex = (_index, indexLatest) => {
        if (onNext && _index > indexLatest) {
            onNext();
        }
        if (onPrevious && _index < indexLatest) {
            onPrevious();
        }
    };

    return (
        <>
            <EnhancedSwipeableViews
                containerStyle={containerStyle}
                index={index}
                onChangeIndex={handleChangeIndex}
                overscanSlideAfter={overscanSlide}
                overscanSlideBefore={overscanSlide}
                slideCount={slideCount}
                slideRenderer={children}
                style={containerStyle}
            />
            {index > 0 && (
                <NavBtnPrev color={previousColor} onClick={onPrevious}>
                    {previousContent}
                </NavBtnPrev>
            )}
            {index < slideCount - 1 && (
                <NavBtnNext color={nextColor} onClick={onNext}>
                    {nextContent}
                </NavBtnNext>
            )}
        </>
    );
}

Navigation.propTypes = {
    children: PropTypes.func.isRequired,
    index: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    onClickNext: PropTypes.func,
    onClickPrevious: PropTypes.func,
    overscanSlide: PropTypes.oneOf([1, 2, 3]),
    nextColor: PropTypes.string,
    nextContent: PropTypes.node,
    previousColor: PropTypes.string,
    previousContent: PropTypes.node,
    slideCount: PropTypes.number.isRequired,
};

Navigation.defaultTypes = {
    onClickNext: null,
    onClickPrevious: null,
    overscanSlide: 2,
    nextColor: "neutral",
    nextContent: null,
    previousColor: "neutral",
    previousContent: null,
};
