import AppIntelligence from "../AppIntelligence";
import references from "./references";
import DataUsageOutlined from "@mui/icons-material/DataUsageOutlined";

/**
 * Config
 *
 * On retrouve ici la configuration du module.
 *  - Ses attributes
 *  - La config du module selector de la quicksearch
 *  - Les default fields presents dans le quickForm & par défaut sur le formulaire de recherche (s'il est activé)
 *    ainsi que leurs valeurs par défaut (ou null)
 *  - La liste des features qui sont activées pour ce module
 *
 */
export const moduleConfig = {
    id: "intelligence", // module key
    icon: DataUsageOutlined, // module icon on module selector
    label: "master.intelligence.title", // translation path
    path: "intelligence", // module uri from search
    route: "[prefix]/intelligence", // module uri from quicksearch
    features: [], // module features enabled
    references: references, // module references
};

export const moduleRouting = [
    {
        key: "intelligence", // module key
        path: ["/intelligence", "/accounts-:ids/intelligence"], // module matching uri
        component: AppIntelligence, // module parent component
    },
];
