export default {
    id: "exchange_currencies",
    data: [
        {
            id: "EUR",
            label: "ref.invoice.currencies.EUR",
        },
    ],
    translate: true,
};
