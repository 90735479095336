import { lazy } from "react";
import { AppCommon } from "@console/core";

const Root = lazy(() => import("./Root"));

const AppInvoice = (props: any) => {
    return <AppCommon ModuleRoot={Root} module={"invoice"} {...props} />;
};

export default AppInvoice;
