import { SET_USER } from "../app/actions/actionTypes";
import { setEventTracker } from "../services/tracker";

/**
 * Google Analytics Middleware
 *
 * Track REDUX actions
 *
 * @param store
 * @returns {function(*): function(*=): *}
 */
export const gaMiddleware = (store) => (next) => (action) => {
    switch (action.type) {
        // init user_id and user-level parameters
        case SET_USER:
            const isInternal = action.payload.internalstaff;

            setEventTracker("connection", {
                // user-level scope
                user_id: action.payload.id,
                language: action.payload.language,
                user_category: isInternal ? "internal" : "external",
                user_rgpd: action.payload.rgpdCompliance ? "RGPD" : "",
                // hit-level scope
                event_category: "user",
                event_label: "SET_USER",
            });
            break;

        default:
            break;
    }

    return next(action);
};
