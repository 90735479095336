import * as actionTypes from "../actions/actionTypes";

// initial state of your module store partition
export const initialStateSnackbar = {
    isOpen: false,
    message: null,
    undo: null,
    undoMessage: null,
    redirectLink: null,
    persist: false,
    children: undefined,
    onCloseAction: null,
    error: false,
};

export const snackbar = (state = initialStateSnackbar, action) => {
    switch (action.type) {
        case actionTypes.CLOSE_SNACKBAR:
            return {
                ...state,
                isOpen: false,
            };
        case actionTypes.LOGOUT:
            return initialStateSnackbar;

        default:
            /**
             * GENERIC ACTIONS SNACKBAR
             * Prop 'snackbar' in any actions meta infos will trigger snackbar
             * see app/actions/settings for simple example
             */
            if (action.meta && action.meta.snackbar) {
                return {
                    ...state,
                    isOpen: true,
                    id: Date.now(),
                    message: action.meta.snackbar.message ? action.meta.snackbar.message : null,
                    messageParams: action.meta.snackbar.messageParams
                        ? action.meta.snackbar.messageParams
                        : {},
                    undo: action.meta.snackbar.undo ? action.meta.snackbar.undo : null,
                    confirm: action.meta.snackbar.confirm ? action.meta.snackbar.confirm : null,
                    refuse: action.meta.snackbar.refuse ? action.meta.snackbar.refuse : null,
                    duration: action.meta.snackbar.duration ? action.meta.snackbar.duration : null,
                    onCloseAction: action.meta.snackbar.onCloseAction
                        ? action.meta.snackbar.onCloseAction
                        : null,
                    error: action.meta.snackbar.error ? action.meta.snackbar.error : null,
                };
            }
            return state;
    }
};
