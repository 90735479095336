import { useEffect } from "react";
import { useOktaAuth } from "@okta/okta-react";
import { AppLoading } from "../../../app/components/AppLoading";
import { getOldInterfaceUrl } from "../../../utils/urls";
import { isRedirectToOldInterface } from "../../../utils/redirectTo";

const TppRedirect = () => {
    const { authState } = useOktaAuth();
    const token = authState?.accessToken?.accessToken;

    useEffect(() => {
        // eslint-disable-next-line no-extra-boolean-cast
        if (!!token) {
            const params = new URLSearchParams(window.location.search);
            if (params.get("redirectTo") && isRedirectToOldInterface(params.get("redirectTo"))) {
                const urlObj = new URL(params.get("redirectTo"));
                window.location.href = getOldInterfaceUrl(urlObj.pathname, []);
            }
        }
    }, [token]);

    return <AppLoading message={"Redirecting..."} />;
};

export default TppRedirect;
