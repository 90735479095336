const threeDsAuthenticationMethod = {
    id: "three_ds_authentication_method",
    data: [
        {
            id: "frictionless",
            label: "ref.transaction.three_ds_authentication_method.frictionless",
        },
        {
            id: "challenge",
            label: "ref.transaction.three_ds_authentication_method.challenge",
        },
    ],
    translate: true,
};

export default threeDsAuthenticationMethod;
