export default {
    id: "verification_locales",
    data: [
        {
            id: "fr_FR",
            img: "/assets/core/img/references/countries/fr.svg",
        },
        {
            id: "en_US",
            img: "/assets/core/img/references/countries/gb.svg",
        },
    ],
};
