import { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { useApi } from "../../../services/api";
import { useUser } from "../../../services/user";
import { useAsync } from "react-async";
import Script from "react-load-script";

export const Help = (props) => {
    const { accounts, lang } = props;
    const truncatedLang = useMemo(() => lang.slice(0, 2), [lang]);

    const [scriptLoaded, setScriptLoaded] = useState(false);
    const [tokenLoaded, setTokenLoaded] = useState(false);

    const user = useUser();
    const { get } = useApi();
    const { data: zendeskToken, run: fetchToken } = useAsync({
        deferFn: get,
        url: "/zendesk-token",
    });

    useEffect(() => {
        fetchToken();
        document.addEventListener("keydown", handleKeyboardShortcut, false);
    }, []);

    useEffect(() => {
        if (zendeskToken) {
            window.zESettings = { authenticate: { jwt: zendeskToken } };
            setTokenLoaded(true);
        }
    }, [zendeskToken]);

    useEffect(() => {
        /**
         * Change the widget's language based on the new language displayed in Console
         */
        if (scriptLoaded && window.zE) {
            if (window.zE.setLocale) {
                window.zE.setLocale(truncatedLang);
            }
            if (window.zE("webWidget:get", "display") !== "launcher") {
                window.zE("webWidget", "close");
                window.zE("webWidget", "reset");
            }
        }
    }, [truncatedLang]);

    useEffect(() => {
        /**
         *Change the suggested articles in the widget based on the url's pathname
         */
        if (scriptLoaded && window.zE) {
            const pathname = window.location.pathname;
            switch (pathname) {
                case "/preference":
                    window.zE("webWidget", "reset");
                    window.zE(function () {
                        window.zE.setHelpCenterSuggestions({ labels: ["Preference"] });
                    });
                    break;
                case "/dashboard":
                    window.zE("webWidget", "reset");
                    window.zE(function () {
                        window.zE.setHelpCenterSuggestions({ labels: ["Dashboard"] });
                    });
                    break;
                case "/transactions":
                    window.zE("webWidget", "reset");
                    window.zE(function () {
                        window.zE.setHelpCenterSuggestions({ labels: ["Transaction"] });
                    });
                    break;
                default:
                    return null;
            }
        }
    }, []);

    const widgetSettings = useMemo(() => {
        return {
            webWidget: {
                color: { theme: "#A50979" },
                contactForm: {
                    ticketForms: [{ id: 194509 }],
                    fields: [
                        {
                            // URL of the page
                            id: 360008519299,
                            prefill: { "*": window.location.href },
                        },
                        {
                            // User agent
                            id: 360008496100,
                            prefill: { "*": navigator.userAgent },
                        },
                        {
                            // Merchant Account Number
                            id: 42558229,
                            prefill: {
                                "*": accounts?.map((account) => account["publicref"]).join(","),
                            },
                        },
                        // Account name
                        {
                            id: 44619265,
                            prefill: { "*": accounts?.map((account) => account["name"]).join(",") },
                        },
                    ],
                },
                helpCenter: { filter: { category: "360003033180,115000205989,115000205969" } },
                chat: {
                    suppress:
                        process.env.NX_IS_INTERNAL === "true"
                            ? true
                            : !window.location.pathname.includes("/verification"),
                },
            },
        };
    }, [accounts, window.location.href]);

    useEffect(() => {
        if (window.zE) {
            window.zE("webWidget", "updateSettings", widgetSettings);
        }
    }, [widgetSettings]);

    const handleScriptLoad = () => {
        setScriptLoaded(true);
        // Add user informations
        if (window.zE && user) {
            window.zE(function () {
                if (window.zE.identify) {
                    window.zE.identify({
                        name: `${user.firstname} ${user.surname}`,
                        email: user.email,
                    });
                }
            });
        }

        // Add settings (current URL, userAgent)
        window.zE("webWidget", "updateSettings", widgetSettings);

        if (window.zE) {
            window.zE("webWidget", "show");
            window.zE(function () {
                window.zE.show();
            });
            window.zE(function () {
                if (window.zE.setLocale) {
                    window.zE.setLocale(truncatedLang);
                }
            });
        }
    };

    const handleKeyboardShortcut = (event) => {
        // If the alt key and letter h are pressed then we open/closed the ZenDesk help dialog
        if (event.altKey && event.keyCode === 72 && window.zE) {
            if (window.zE("webWidget:get", "display") !== "launcher") {
                window.zE("webWidget", "close");
            } else {
                window.zE.activate();
            }
        }
    };

    if (!tokenLoaded) {
        return null;
    }

    return (
        <Script
            url="https://static.zdassets.com/ekr/snippet.js?key=3ae478e4-f330-4ed5-b6ca-5efed3fcc7f7"
            // shared secret =>  a62c8b5c664cb057774472bb2a5a950d
            onLoad={handleScriptLoad}
            attributes={{ id: "ze-snippet" }}
        />
    );
};

Help.propTypes = { lang: PropTypes.string };
