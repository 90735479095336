import { lazy } from "react";
import { AppCommon } from "@console/core";

// https://reactjs.org/docs/code-splitting.html#route-based-code-splitting
const Root = lazy(() => import("./Root"));

// AppUser
const AppUser = (props) => {
    return <AppCommon ModuleRoot={Root} module="user" {...props} />;
};

const AppRole = (props) => {
    return <AppCommon ModuleRoot={Root} module="role" {...props} />;
};

const AppPrivilege = (props) => {
    return <AppCommon ModuleRoot={Root} module="privilege" {...props} />;
};

export { AppUser, AppRole, AppPrivilege };
