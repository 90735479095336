import styled from "styled-components/macro";
import Typography from "@mui/material/Typography";
import HiChip from "@hipay/hipay-material-ui/HiChip";

const ExpansionPanelHeading = (props) => {
    const { title, chipLabel } = props;

    return (
        <div className={props.className}>
            <Typography variant="h3" className="title">
                {title}
            </Typography>
            {chipLabel && <HiChip className="chip" label={chipLabel} />}
        </div>
    );
};

const StyledExpansionPanelHeading = styled(ExpansionPanelHeading)`
    display: flex;
    align-items: center;

    .title {
        margin-right: ${({ theme }) => theme.spacing(4)};
        font-weight: ${({ theme }) => theme.typography.fontWeightMedium};
    }

    .chip {
        ${(props) =>
            props.chipError &&
            `
        background-color: #F9D9D9;
    `}
    }
`;

export default StyledExpansionPanelHeading;
