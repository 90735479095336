const user_partnerships = {
    id: "user_partnerships",
    data: [
        {
            id: "ONEY",
            label: "Oney",
        },
        {
            id: "BNP_PARIBAS",
            label: "BNP Paribas",
        },
        {
            id: "CREDIT_DU_NORD",
            label: "Crédit du Nord",
        },
        {
            id: "SOCIETE_GENERALE",
            label: "Société Générale",
        },
        {
            id: "CREDIT_AGRICOLE",
            label: "Crédit Agricole",
        },
    ],
};

export default user_partnerships;
