import { useState, useCallback, useEffect } from "react";
import { useLocale } from "../../../services/i18n";
import { ExpandedFlags } from "./ExpandedFlags/ExpandedFlags";
import { Flag } from "./Flag/Flag";
import { availableLocales } from "./AvailableLocales";
import { useStyles } from "./stylesheet";

const FLAGS_PATH = "/assets/core/img/references/countries/";

export const LanguageSwitcher = () => {
    const classes = useStyles();

    const [isExpanded, setIsExpanded] = useState(false);
    const [locale, setLocale] = useLocale();

    const handleChange = useCallback(
        (e) => {
            setLocale(e.target.alt);
            setIsExpanded(false);
        },
        [setLocale]
    );

    const handleOnExpand = () => {
        setIsExpanded((previousState) => !previousState);
    };

    let flags = Object.keys(availableLocales);
    flags.splice(flags.indexOf(locale), 1);

    useEffect(() => {
        function handleKeyDown(e) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            if (e.key === "Enter" && e.target?.alt === locale) {
                handleOnExpand();
            } else if (e.key === "Enter" && new RegExp(/flag-/g).test(e.target?.id)) {
                handleChange(e);
            }
        }

        window.addEventListener("keydown", handleKeyDown);

        return () => window.removeEventListener("keydown", handleKeyDown);
    }, [handleChange, locale]);

    return (
        <div className={classes.root}>
            {isExpanded && (
                <ExpandedFlags
                    flagsPath={FLAGS_PATH}
                    locales={flags}
                    handleOnClick={handleChange}
                />
            )}
            <Flag
                path={FLAGS_PATH + locale.substr(-2).toLowerCase() + ".svg"}
                locale={locale}
                handleOnClick={handleOnExpand}
            />
        </div>
    );
};
