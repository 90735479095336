import WidgetTypes from "../../types/Widget/WidgetTypes";

const widgetTypes: WidgetTypes = {
    id: "widget_types",
    translate: true,
    data: [
        {
            id: "TOPFLOP",
            label: "ref.widget.widget_types.TOPFLOP",
        },
        {
            id: "KPI",
            label: "ref.widget.widget_types.KPI",
        },
        {
            id: "SHORTCUT",
            label: "ref.widget.widget_types.SHORTCUT",
        },
        {
            id: "ACTIVITY",
            label: "ref.widget.widget_types.ACTIVITY",
        },
        {
            id: "TREND",
            label: "ref.widget.widget_types.TREND",
        },
    ],
};

export default widgetTypes;
