// DB table : risk.risk_decision_statuses
export default {
    id: "kycDecision",
    translate: true,
    data: [
        {
            id: "approved",
            label: "ref.onboarding.kyc_decision.approved",
            color: "success",
        },
        {
            id: "approved_with_conditions",
            label: "ref.onboarding.kyc_decision.approved_with_conditions",
            color: "warning",
        },
        {
            id: "awaiting_information",
            label: "ref.onboarding.kyc_decision.awaiting_information",
            color: "secondary",
        },
        {
            id: "reject",
            label: "ref.onboarding.kyc_decision.reject",
            color: "error",
        },
    ],
};
