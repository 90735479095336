const privilege_category = {
    id: "privilege_category",
    data: [
        {
            id: "Account",
            label: "ref.privilege.privilege_category.Account",
        },
        {
            id: "Finance",
            label: "ref.privilege.privilege_category.Finance",
        },
        {
            id: "InternalConfiguration",
            label: "ref.privilege.privilege_category.InternalConfiguration",
        },
        {
            id: "PaymentExperience",
            label: "ref.privilege.privilege_category.PaymentExperience",
        },
        {
            id: "Risk",
            label: "ref.privilege.privilege_category.Risk",
        },
        {
            id: "TechnicalConfiguration",
            label: "ref.privilege.privilege_category.TechnicalConfiguration",
        },
        {
            id: "Transactions",
            label: "ref.privilege.privilege_category.Transactions",
        },
        {
            id: "UserManagement",
            label: "ref.privilege.privilege_category.UserManagement",
        },
    ],
    translate: true,
};

export default privilege_category;
