import { useCallback, useContext, useState, createContext } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useModule } from "../../../../services/module/index";
import { updateSettings } from "../../../../app/actions/userActions";
import { useP } from "../../../../services/i18n";

/**
 * Context ParagraphsManager
 *
 * This context create a state to manage paragraphs order in Notices such as transaction, financial-report, ...
 * It initiate the state from user settings and default paragraphs order.
 *
 * This is meant to be used as state provider for OrderParagraphs component.
 */
const ParagraphsManagerContext = createContext([[]]);

/**
 * Context Provider
 */
export const ParagraphsManager = (props) => {
    const { defaultParagraphsOrder, children } = props;

    const p = useP();
    const dispatch = useDispatch();
    const { id: module } = useModule();
    const userSettings = useSelector((state) => state.app.settings.data);

    const [paragraphsOrder, set] = useState(() => {
        // build initial paragraph order from user settings and default order
        let initialParagraphsOrder;
        if (
            !userSettings ||
            !userSettings.noticeConfig ||
            !userSettings.noticeConfig[module] ||
            !userSettings.noticeConfig[module].paragraphs
        ) {
            initialParagraphsOrder = defaultParagraphsOrder;
        } else {
            // clone settings + remove paragraph from settings that is not present in default
            initialParagraphsOrder = [...userSettings.noticeConfig[module].paragraphs]
                // remove paragraph from settings that is not present in default
                .filter(
                    (initialParagraph) =>
                        defaultParagraphsOrder.findIndex(
                            (paragraph) => paragraph.id === initialParagraph.id
                        ) !== -1
                );

            // add paragraph from default that is not present in settings
            defaultParagraphsOrder.forEach((defaultParagraph) => {
                if (
                    initialParagraphsOrder.findIndex(
                        (paragraph) => paragraph.id === defaultParagraph.id
                    ) === -1
                ) {
                    initialParagraphsOrder.push(defaultParagraph);
                }
            });
        }
        return (
            initialParagraphsOrder
                // filter unavailable paragraphs
                .filter((paragraph) => !paragraph.internal || process.env.NX_IS_INTERNAL === "true")
                .map((paragraph) => ({
                    ...paragraph,
                    label: p.t(`modules.${module}.notice.paragraph.${paragraph.id}.title`),
                }))
        );
    });

    /* update state and persist user settings for module
     */
    const setParagraphsOrder = useCallback(
        (_paragraphsOrder) => {
            // update state
            set(_paragraphsOrder);

            // persist user settings for module
            dispatch(
                updateSettings({
                    noticeConfig: {
                        ...userSettings.noticeConfig,
                        [module]: { paragraphs: _paragraphsOrder },
                    },
                })
            );
        },
        [dispatch, module, userSettings]
    );

    return (
        <ParagraphsManagerContext.Provider value={[paragraphsOrder, setParagraphsOrder]}>
            {children}
        </ParagraphsManagerContext.Provider>
    );
};

ParagraphsManager.propTypes = {
    defaultParagraphsOrder: PropTypes.array.isRequired,
    children: PropTypes.node.isRequired,
};

export const useParagraphsOrder = () => useContext(ParagraphsManagerContext);
