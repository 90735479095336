import { useCallback } from "react";
import PropTypes from "prop-types";
import Drag from "mdi-material-ui/Drag";
import classNames from "classnames";
import makeStyles from "@mui/styles/makeStyles";
import { SortableElement, SortableHandle } from "react-sortable-hoc";
import FormControlLabel from "@mui/material/FormControlLabel";
import HiCheckbox from "@hipay/hipay-material-ui/HiCheckbox";

const useStyles = makeStyles((theme) => ({
    itemFilter: {
        "&:hover": {
            backgroundColor: theme.palette.action.hover,
            fontWeight: theme.typography.fontWeightMedium,
        },
        height: 40,
        width: "100%",
        paddingLeft: 3,
        fontFamily: "Roboto, Helvetica, Arial, sans-serif",
        display: "flex",
        alignItems: "center",
    },
    checkbox: {
        position: "relative",
        top: 2,
        "&$checkboxNotDraggable": { top: 1 },
    },
    checkboxNotDraggable: {},
    controlLabelRoot: {
        marginBottom: 2,
        fontSize: 14,
        width: 210,
        "&$controlLabelSmall": {
            overflow: "hidden",
            marginRight: 6,
        },
        "&$controlLabelNotDraggable": {
            marginLeft: 0,
            position: "relative",
            width: 242,
        },
    },
    controlLabelSmall: {},
    controlLabelNotDraggable: {},
    itemLabel: {
        fontSize: 14,
        position: "relative",
        top: 2,
        left: 5,
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        "&$itemLabelNotDraggable": { top: 2 },
        "&$itemLabelNotDraggableNotChecked": { top: 3 },
    },
    itemLabelNotDraggable: {},
    itemLabelNotDraggableNotChecked: {},
    label: {
        fontWeight: "inherit",
        fontSize: "inherit",
    },
    itemControls: {
        display: "flex",
        width: "calc(100% - 84px)",
        justifyContent: "space-between",
        alignItems: "center",
        "&$itemControlsNotDraggableChecked": {
            width: "calc(100% - 50px)",
            marginTop: -2,
        },
        "&$itemControlsNotDraggableNotChecked": {
            width: "calc(100% - 48px)",
            marginTop: -2,
        },
        "&$itemControlsSelectedNotDraggable": { marginTop: -1 },
        "&$itemControlsNotDraggableCellFixed": {
            width: "calc(100% - 18px)",
            marginTop: -2,
        },
    },
    widthCellFixed: { width: "82%" },
    itemControlsNotDraggableChecked: {},
    itemControlsNotDraggableNotChecked: {},
    itemControlsSelectedNotDraggable: {},
    itemControlsNotDraggableCellFixed: {},
    dragIcon: {
        cursor: "move",
        position: "relative",
        marginRight: 12,
    },
}));

const DragHandle = SortableHandle(() => {
    const classes = useStyles();
    return <Drag tabIndex={0} classes={{ root: classes.dragIcon }} />;
});

const Item = (props) => {
    const { id, item, label, dragDisabled, onChangeProperty } = props;

    const classes = useStyles();

    let viewIcon;

    const handleChangeDisplay = useCallback(() => {
        onChangeProperty(item.id, "displayed", !item.displayed);
    }, [item, onChangeProperty]);

    return (
        <div id={id} className={classes.itemFilter}>
            {dragDisabled || <DragHandle />}
            <div
                className={classNames(classes.itemControls, {
                    [classes.itemControlsNotDraggableNotChecked]: dragDisabled && !item.displayed,
                    [classes.itemControlsNotDraggableChecked]: dragDisabled && item.displayed,
                    [classes.itemControlsNotDraggableCellFixed]: dragDisabled && !viewIcon,
                    [classes.itemControlsSelectedNotDraggable]: dragDisabled && item.displayed,
                    [classes.widthCellFixed]: !viewIcon,
                })}
            >
                <FormControlLabel
                    classes={{
                        root: classNames(classes.controlLabelRoot, {
                            [classes.controlLabelNotDraggable]: dragDisabled,
                        }),
                        label: classNames(classes.itemLabel, classes.label, {
                            [classes.itemLabelNotDraggable]: dragDisabled,
                            [classes.itemLabelNotDraggableNotChecked]: !item.displayed,
                        }),
                    }}
                    control={
                        <HiCheckbox
                            classes={{
                                root: classNames(classes.checkbox, {
                                    [classes.checkboxNotDraggable]: dragDisabled,
                                }),
                            }}
                            checked={item.displayed}
                            onChange={item.onToggleDisplay || handleChangeDisplay}
                        />
                    }
                    label={label}
                    disabled={!!item.disabled}
                />
            </div>
        </div>
    );
};

Item.propTypes = {
    /** True si l'item ne peut pas être déplacé */
    dragDisabled: PropTypes.bool.isRequired,
    /** Identifiant de la div de l'item */
    id: PropTypes.string.isRequired,
    /** Objet contenant les informations de l'item courant */
    item: PropTypes.object.isRequired,
    /** Label à afficher */
    label: PropTypes.string.isRequired,
    /** Fonction de callback appelée lors qu'on modifie un item (pas l'ordre) */
    onChangeProperty: PropTypes.func.isRequired,
};

Item.defaultProps = { dragDisabled: false };

export default SortableElement(Item);
