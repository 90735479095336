import * as actionTypes from "../actions/actionTypes";

// initial state of your module store partition
export const initialStateGuideTour = {
    active: false, //guide tour is playing
    focusedInput: false, // change the QuickSearch focus
    loading: false,
};

export const guidetour = (state = initialStateGuideTour, action) => {
    switch (action.type) {
        case actionTypes.START_TOUR:
            return {
                ...state,
                active: true,
            };

        case actionTypes.FINISH_TOUR:
            return {
                ...state,
                active: false,
            };

        case actionTypes.FOCUSED_INPUT:
            return {
                ...state,
                focusedInput: true,
            };

        case actionTypes.UNFOCUSED_INPUT:
            return {
                ...state,
                focusedInput: false,
            };

        case actionTypes.SET_TOUR_LOADING:
            return {
                ...state,
                loading: true,
            };

        case actionTypes.SET_TOUR_READY:
            return {
                ...state,
                loading: false,
            };

        default:
            return state;
    }
};
