import { useEffect } from "react";
import { useLocation, useParams, Switch, Route, Redirect } from "react-router-dom";
import { useApi } from "../../../services";
import { useAsync } from "react-async";
import { getAccountIdListFromUrl } from "../../../utils";

import { Page404, Preference } from "../../components";

export function Router() {
    let location = useLocation();
    let { return_to } = useParams();

    const selectedAccountIdList = getAccountIdListFromUrl(window.location.pathname);

    const redirectDashboard = selectedAccountIdList.length
        ? "/accounts-" + selectedAccountIdList.join("-") + "/dashboard"
        : "/dashboard";

    const { get } = useApi();
    const { data: zendeskToken, run: fetchToken } = useAsync({
        deferFn: get,
        url: "/zendesk-access-token",
    });

    useEffect(() => {
        if (return_to && location.pathname === "/login") {
            fetchToken();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (zendeskToken) {
            window.location.href = `${process.env.NX_ZENDESK_JWT_ACCESS_URL}?jwt=${zendeskToken}&return_to=${return_to}`;
        }
    }, [zendeskToken]);

    return (
        <Switch>
            <Route
                exact
                path={["/preference", "/accounts-:ids/preference"]}
                component={Preference}
            />
            <Redirect exact strict from="/" to={redirectDashboard} />
            <Redirect exact strict from="/accounts-:ids" to={redirectDashboard} />
            <Redirect replace from="/login" to={redirectDashboard} />
            <Redirect replace from="/accounts-:ids/login" to={redirectDashboard} />
            <Route component={Page404} />
        </Switch>
    );
}
