import * as actionTypes from "../actions/actionTypes";

// initial state of your module store partition
export const initialStateUser = {
    data: null,
    loading: false, // set as loading because fetch user is mandatory for every pages
    error: null,
};

export const user = (state = initialStateUser, action) => {
    switch (action.type) {
        case actionTypes.LOGOUT:
            return initialStateUser;

        case actionTypes.SET_USER:
            const { settings, ...userInfos } = action.payload;
            return {
                data: userInfos,
                loading: false,
                error: null,
            };

        default:
            return state;
    }
};
