import { useCallback, useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import makeStyles from "@mui/styles/makeStyles";
import { useP } from "../../../services/i18n";
import { Scrollbars } from "react-custom-scrollbars";
import Magnify from "mdi-material-ui/Magnify";
import HiButton from "@hipay/hipay-material-ui/HiButton";
import HiInput from "@hipay/hipay-material-ui/HiForm/HiInput";
import SortableList from "./SortableList";
import arrayMove from "array-move";
import _orderBy from "lodash/orderBy";

const useStylesChooseAndOrg = makeStyles(() => ({
    root: {
        width: 300,
        paddingTop: 12,
    },
    menuTitle: {
        fontSize: 20,
        fontWeight: 300,
        paddingLeft: 12,
        fontFamily: "Roboto, Helvetica, Arial, sans-serif",
        marginBottom: 16,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
    closeMenu: {
        width: 24,
        height: 24,
        float: "right",
        marginRight: 10,
        padding: "unset",
        marginTop: "-5px",
    },
    helperClass: { zIndex: 1550 },
    scrollbars: { maxHeight: 440 },
    buttonGroup: {
        display: "flex",
        padding: "12px 0px",
        justifyContent: "space-around",
    },
    button: {
        display: "block",
        margin: "7px auto",
    },
}));

const styleScrollbars = { maxHeight: 440 };

export const ChooseAndOrganize = (props) => {
    const {
        columns,
        defaultItems,
        displaySearchInput,
        filterSuggestionsFn,
        hasUpdates,
        itemsLabels,
        itemsType,
        mandatoryItemId,
        onClose,
        persistOrderedItems,
        pinnedItems,
        saveButtonTitle,
        sortItemsFn,
        updateOnChange, // rename to "persistOnChange"
        updateOrderedItems,
    } = props;

    const classes = useStylesChooseAndOrg();
    const p = useP();

    const [inputValue, setInputValue] = useState("");

    // Semi-controlled state (local state overriden by props change)
    const [listUpdated, setListUpdated] = useState(Boolean(hasUpdates));

    /**
     * "Clean" item list
     *  merge with default list (remove extra items and add missing ones)
     *  add item properties
     *      - displayed
     *      - invDisp
     *      - order
     * sort with sortFn
     */
    const buildColumns = (itemList) => {
        let newOrderedItems = itemList.reduce((memo, item) => {
            let displayed = typeof item.displayed === "undefined" ? true : item.displayed;
            let entry = {
                ...item,
                displayed: displayed,
                invDisp: !displayed,
                order: memo.length,
            };
            memo.push(entry);
            return memo;
        }, []);

        let index;
        newOrderedItems = defaultItems.reduce((memo, item) => {
            index = itemList.findIndex((propItem) => propItem.id === item.id);
            if (index === -1) {
                let entry = {
                    ...item,
                    displayed: false,
                    invDisp: true,
                    order: memo.length,
                };
                memo.push(entry);
            }
            return memo;
        }, newOrderedItems);

        if (sortItemsFn) {
            return sortItemsFn(_orderBy(newOrderedItems, ["invDisp", "order", "id"]));
        }
        return newOrderedItems;
    };

    const [orderedItems, setOrderedItems] = useState(buildColumns(props.orderedItems));

    useEffect(() => {
        if (typeof hasUpdates !== "undefined") {
            setListUpdated(hasUpdates);
        }
    }, [hasUpdates]);

    useEffect(() => {
        if (!updateOnChange) {
            updateOrderedItems(orderedItems);
        }
    }, [orderedItems, updateOnChange, updateOrderedItems]);

    useEffect(() => {
        window.addEventListener("keydown", handleMoveItem);

        return function cleanup() {
            window.removeEventListener("keydown", handleMoveItem);
        };
    }, []);

    const handleSearch = (event) => {
        setInputValue(event.target.value);
    };

    const handleReset = () => {
        setInputValue("");
    };

    const handleMoveItem = (event) => {
        if (document.activeElement.tagName === "BODY") {
            let liSelected = document.querySelector("body > li.order-columns-list-fixed");
            if (liSelected) {
                let container = document.getElementById("order-columns-container");
                if (event.key === "ArrowDown") {
                    container.firstChild.scrollBy(0, 40);
                } else if (event.key === "ArrowUp") {
                    container.firstChild.scrollBy(0, -40);
                }
            }
        }
    };

    /* build suggestions
     * filtered by search input
     */
    const suggestions = useMemo(() => {
        let suggs = [...pinnedItems, ...orderedItems];
        if (filterSuggestionsFn && !!inputValue) {
            return filterSuggestionsFn(suggs, inputValue);
        }
        return suggs;
    }, [inputValue, orderedItems, filterSuggestionsFn, pinnedItems]);

    /**
     * Restore default order
     */
    const handleRestoreDefaults = () => {
        if (props.onRestoreDefaults) {
            props.onRestoreDefaults();
        }

        let newOrderedItems = defaultItems.reduce((memo, item) => {
            let entry = {
                ...item,
                order: memo.length,
                invDisp: !item.displayed,
            };
            memo.push(entry);
            return memo;
        }, []);

        setInputValue("");
        setOrderedItems(_orderBy(newOrderedItems, ["invDisp", "order", "id"]));
    };

    const handleSaveOrder = useCallback(() => {
        setListUpdated(false);
        if (persistOrderedItems) {
            persistOrderedItems(orderedItems);
        }
        if (onClose) {
            onClose();
        }
    }, [orderedItems, persistOrderedItems, setListUpdated, onClose]);

    const handleSortEnd = useCallback(
        ({ oldIndex, newIndex }) => {
            setOrderedItems((_orderedItems) => {
                const reorderedItems = arrayMove(
                    _orderedItems,
                    oldIndex - pinnedItems.length,
                    newIndex - pinnedItems.length
                );
                if (updateOnChange) {
                    persistOrderedItems(reorderedItems);
                }
                return reorderedItems;
            });
        },
        [setOrderedItems, pinnedItems, updateOnChange, persistOrderedItems]
    );

    const handleSortStart = ({ node, index, collection, isKeySorting }, event) => {
        if (event.keyCode === 32) {
            let liSelected = document.querySelector("body > li.order-columns-list-fixed");
            if (liSelected) {
                liSelected.style.backgroundColor = "rgba(0, 0, 0, 0.08)";
                liSelected.style.fontWeight = 500;
            }
        }
    };

    /**
     * Mets à jour la propriété d'un item spécifique
     *
     * @param itemId
     * @param propertyName
     * @param propertyValue
     */
    const handleChangeItemProperty = useCallback(
        (itemId, propertyName, propertyValue) => {
            setOrderedItems((_orderedItems) => {
                const index = _orderedItems.findIndex((item) => item.id === itemId);

                let value = propertyValue;
                if (typeof propertyValue === "undefined") {
                    // In this case the property is a boolean value (true/false)
                    value = false;
                    if (_orderedItems[index].hasOwnProperty(propertyName)) {
                        value = !_orderedItems[index][propertyName];
                    }
                }

                // use Immutability to update array as new array
                const newOrderedItems = [..._orderedItems];
                newOrderedItems[index] = {
                    ...newOrderedItems[index],
                    [propertyName]: value,
                };

                if (updateOnChange) {
                    persistOrderedItems(newOrderedItems);
                }

                return newOrderedItems;
            });
        },
        [persistOrderedItems, setOrderedItems, updateOnChange]
    );

    const handleKeyDown = useCallback((event) => {
        if (event.key === "Tab") {
            event.stopPropagation();
        }
    }, []);

    return (
        <div className={classes.root} onKeyDown={handleKeyDown}>
            {displaySearchInput && (
                <HiInput
                    autoFocus
                    value={inputValue}
                    onChange={handleSearch}
                    onReset={handleReset}
                    placeholder={p.t("common.search.table.search")}
                    startAdornment={<Magnify />}
                />
            )}
            {!updateOnChange && (
                <div className={classes.buttonGroup}>
                    <HiButton
                        onClick={handleRestoreDefaults}
                        className={classes.restore}
                        // color="default"
                        variant="text"
                        size="small"
                        title={p.t("common.search.order_columns.restore_defaults")}
                    >
                        {p.t("common.search.order_columns.restore_defaults")}
                    </HiButton>
                    <HiButton
                        onClick={handleSaveOrder}
                        className={classes.restore}
                        // color="default"
                        variant="text"
                        size="small"
                        disabled={!listUpdated}
                        title={saveButtonTitle || p.t("common.search.order_columns.save")}
                    >
                        {p.t("common.search.order_columns.save")}
                    </HiButton>
                </div>
            )}
            <Scrollbars
                id="order-columns-container"
                className={classes.scrollbars}
                style={styleScrollbars}
                autoHeightMax={440}
                autoHeight
                hideTracksWhenNotNeeded
                autoHide
                renderView={(_props) => <div {..._props} style={{ ..._props.style }} />}
                renderTrackHorizontal={(_props) => (
                    <div {..._props} style={{ display: "none" }} className="track-horizontal" />
                )}
            >
                <SortableList
                    id={"order-columns-list"}
                    items={suggestions}
                    onSortEnd={handleSortEnd}
                    onSortStart={handleSortStart}
                    lockToContainerEdges
                    useDragHandle
                    helperClass={classes.helperClass}
                    lockAxis="y"
                    dragDisabled={inputValue.length > 0}
                    axis="y"
                    itemsLabels={itemsLabels}
                    onChangeProperty={handleChangeItemProperty}
                    itemsType={itemsType}
                    columns={columns}
                    mandatoryItemId={mandatoryItemId}
                />
            </Scrollbars>
        </div>
    );
};

ChooseAndOrganize.propTypes = {
    /** Objet regroupant les colonnes du module (seulement s'il s'agit d'organiser les colonnes). */
    columns: PropTypes.object,
    /** Liste par défaut des items */
    defaultItems: PropTypes.array.isRequired,
    /** Booleen pour afficher/masque l'input de recherche */
    displaySearchInput: PropTypes.bool,
    /** Fonction appelée au change de l'input de recherche pour filtrer les suggestions */
    filterSuggestionsFn: PropTypes.func,
    /**
     *  Booléen pour surcharger la variable qui indique si la liste a été modifiée.
     *  Par défaut, on compare simplement l'ordre de la liste avec la liste par défaut.
     */
    hasUpdates: PropTypes.bool,
    /** Objet contenant le label des items */
    itemsLabels: PropTypes.objectOf(PropTypes.string).isRequired,
    /** Indique le type des items de la liste (conditionne l'affichage) */
    itemsType: PropTypes.oneOf(["column", "default"]).isRequired,
    /** Identifiant d'un item qui ne peut pas être supprimé (optionnel) */
    mandatoryItemId: PropTypes.string,
    /** Fonction de callback appelée au clic sur le bouton save */
    onClose: PropTypes.func,
    /** Fonction de callback appelée lors du clic sur le bouton pour restaurer l'ordre par défaut    */
    onRestoreDefaults: PropTypes.func,
    /** Liste des items triée selon les choix de l'utilisateur */
    orderedItems: PropTypes.array,
    /** Save item list into DB */
    persistOrderedItems: PropTypes.func.isRequired,
    /** Items affichés en haut de liste et non déplaçables */
    pinnedItems: PropTypes.array,
    /** Titre du bouton save */
    saveButtonTitle: PropTypes.string,
    /**
     * Fonction de callback appelée pour le tri des éléments
     * @param array list(Liste des éléments triables)
     */
    sortItemsFn: PropTypes.func,
    /** Booléen pour sauvegarder à chaque changement (ou au clic sur le bouton de sauvegarde) */
    updateOnChange: PropTypes.bool,
    /** Callback appelé lors d'une modification pour màj le template */
    updateOrderedItems: PropTypes.func,
};

ChooseAndOrganize.defaultProps = {
    defaultItems: [],
    pinnedItems: [],
    itemsLabels: {},
    itemsType: "default",
};
