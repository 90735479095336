import { RSAA } from "redux-api-middleware";

export const authMiddleware = (store) => (next) => (action) => {
    const callApi = action[RSAA];

    // Check if this action is a redux-api-middleware action.
    if (callApi) {
        // Prepend API base URL to endpoint if it does not already contain a valid base URL.
        if (!/^((http|https|ftp):\/\/)/i.test(callApi.endpoint)) {
            callApi.endpoint = `${process.env.NX_CONSOLE_API_URL}${callApi.endpoint}`;
        }

        // Set headers to empty object if undefined.
        if (!callApi.headers) {
            callApi.headers = {};
        }

        callApi.headers["Accept"] = "application/json";
        callApi.headers["Content-Type"] = "application/json";
        // Inject authentication header
        let state = store.getState();
        if (state.app.authentication.data) {
            const authentication = state.app.authentication.data;
            if (authentication.authHeader) {
                callApi.headers[authentication.authHeader] =
                    authentication.authBearer + " " + authentication.token;
            }
        } else if (callApi.endpoint.indexOf("/login") === -1) {
            console.error("No authentication found.");
        }
    }

    return next(action);
};
