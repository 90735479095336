import PropTypes from "prop-types";
import makeStyles from "@mui/styles/makeStyles";
import { HiKPI } from "./HiKPI";

const useStylesHiKPINotice = makeStyles(() => ({
    container: {
        width: "100%",
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
    },
}));

/**
 * TODO - useless component ?
 * move it inside modules
 */
export function HiKPINotice(props) {
    const { kpis, activeKpi, minify, onClickOnKPI } = props;

    const classes = useStylesHiKPINotice();

    return (
        <div className={classes.container}>
            {Object.keys(kpis).map((key) => {
                const kpi = kpis[key];
                return (
                    <HiKPI
                        key={key}
                        id={key}
                        type={kpi.type}
                        color={kpi.color}
                        title={kpi.title}
                        body={kpi.body}
                        bodyMinify={kpi.bodyMinify}
                        subtitle={kpi.subtitle}
                        tooltip={kpi.tooltip}
                        onClick={() => onClickOnKPI && onClickOnKPI(key)}
                        active={activeKpi === key}
                        minify={minify}
                        disable={kpi.disable}
                        buttonId={kpi.buttonId}
                    />
                );
            })}
        </div>
    );
}

HiKPINotice.propTypes = {
    /**
     * Liste des KPIS actifs
     */
    activeKpi: PropTypes.string,
    /**
     * Liste des KPIs
     */
    kpis: PropTypes.object.isRequired,
    /**
     * Minifie les KPIs si vrai
     */
    minify: PropTypes.bool,
    /**
     * Fonction appelée au clic sur un KPI
     */
    onClickOnKPI: PropTypes.func,
};

HiKPINotice.defaultProps = {
    activeKpi: null,
    minify: false,
};
