import { useState } from "react";
import PropTypes from "prop-types";
import Popover from "@mui/material/Popover";
import makeStyles from "@mui/styles/makeStyles";
import HiIconButton from "@hipay/hipay-material-ui/HiIconButton";
import HiIcon from "@hipay/hipay-material-ui/HiIcon";

// popover config
const anchorOrigin = {
    vertical: "center",
    horizontal: "center",
};
const transformOrigin = {
    vertical: "top",
    horizontal: "right",
};

const useStylesMenuButton = makeStyles((theme) => ({
    menu: { width: 300 },
    header: {
        height: 56,
        whiteSpace: "nowrap",
    },
    headerLabel: {
        ...theme.typography.h3,
        lineHeight: "56px",
        width: 244,
        paddingLeft: 12,
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    headerCloseBtn: {
        float: "right",
        margin: 6,
    },
}));

/**
 * Component MenuSetting
 *
 * Display an action button that open an menu.
 */
export const MenuButton = ({ children, header, iconComponent, id, title }) => {
    const classes = useStylesMenuButton();

    /**********************************************************************************************
     * MANAGE OPEN STATE
     *********************************************************************************************/
    const [anchorEl, setAnchorEl] = useState(null);
    const handleOpen = (e) => setAnchorEl(e.target);
    const handleClose = () => setAnchorEl(null);

    /**********************************************************************************************
     * RENDERS
     *********************************************************************************************/

    const menuHeader = (
        <div className={classes.header} id={`${id}-menu-header`}>
            <HiIconButton className={classes.headerCloseBtn} onClick={handleClose} color="neutral">
                <HiIcon icon="fa-close" />
            </HiIconButton>
            <div className={classes.headerLabel}>{header}</div>
        </div>
    );

    return (
        <>
            <HiIconButton id={`${id}-button`} onClick={handleOpen} title={title}>
                {iconComponent}
            </HiIconButton>
            <Popover
                id={`${id}-menu`}
                anchorEl={anchorEl}
                anchorReference="anchorEl"
                anchorOrigin={anchorOrigin}
                transformOrigin={transformOrigin}
                open={Boolean(anchorEl)}
                classes={{ paper: classes.menu }}
                onClose={handleClose}
            >
                {menuHeader}
                {children}
            </Popover>
        </>
    );
};

MenuButton.propTypes = {
    header: PropTypes.node,
    iconComponent: PropTypes.node.isRequired,
    id: PropTypes.string.isRequired,
    title: PropTypes.string,
};

MenuButton.defaultProps = {
    header: null,
    title: null,
};
