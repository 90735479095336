import { useInterval, useToggle } from "react-use";
import makeStyles from "@mui/styles/makeStyles";
import classnames from "classnames";
import { HiLogo } from "@hipay/design-system/components";

const useStylesPageLoading = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background2,
        width: "100vw",
        height: "100%",
        "&>div": {
            opacity: "0.16",
            transition: "opacity 1400ms ease-in-out",
            margin: "auto",
            width: 250,
            maxWidth: "80%",
        },
        "&>div.fade": {
            opacity: "1",
            transition: "opacity 1400ms ease-in-out",
        },
    },
    rootInnerPage: { backgroundColor: theme.palette.background1 },
    logo: {
        width: "100%",
        margin: "25vh auto 0 auto",
    },
}));

/**
 * PageLoading with breathing HiPay logo
 */
export function PageLoading({ innerPage = false }) {
    const classes = useStylesPageLoading();
    const [on, toggle] = useToggle(false);

    // toggle each 1s
    useInterval(toggle, 1000);

    return (
        <div className={classnames(classes.root, { [classes.rootInnerPage]: innerPage })}>
            <div className={on ? "fade" : ""}>
                <HiLogo className={classes.logo} />
            </div>
        </div>
    );
}
