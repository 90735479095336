import { useMemo } from "react";
import CellBuilder from "../../../Table/CellBuilder";
import { useNoticeContextMenu } from "../../../../../services/ContextMenu";
import { RowLayout } from "./RowLayout";
import useToggle from "react-use/lib/useToggle";
import { useP } from "../../../../../services/i18n";
import { useTheme } from "@mui/material/styles";

export const RowCustomData = (props) => {
    const { attribute, data, disableContextMenu, hideLabel, actions = [] } = props;

    const p = useP();
    const theme = useTheme();

    const [open, toggleOpen] = useToggle(false);
    const [ckey, cvalue] = data.value.split(" : ");

    const keyData = useMemo(
        () => ({
            value: ckey,
            label: ckey,
            title: ckey,
            column: attribute.id,
        }),
        [attribute, ckey]
    );

    const valueData = useMemo(
        () => ({
            value: cvalue,
            label: cvalue,
            title: cvalue,
            column: attribute.id,
        }),
        [attribute, cvalue]
    );

    const contextLabel = useMemo(() => {
        return `${keyData.label} : ${valueData.label}`;
    }, [keyData, valueData]);

    const handleContextMenu = useNoticeContextMenu({
        [attribute.id]: {
            label: contextLabel,
            value: contextLabel,
        },
    });

    let _actions = [];
    // TODO => smthg dynamic ?
    if (valueData?.value?.length > 70) {
        _actions = [
            {
                type: "text",
                name: "expand",
                value: open
                    ? p.t("common.notice.content.see_less")
                    : p.t("common.notice.content.see_more"),
                onClick: toggleOpen,
                style: {
                    color: open ? theme.palette.neutral.main : theme.palette.primary.main,
                },
            },
            ...actions,
        ];
    }

    if (ckey && cvalue) {
        return (
            <RowLayout
                attribute={attribute}
                actions={_actions}
                expanded={open}
                hideLabel={hideLabel}
                customLabel={ckey}
            >
                <CellBuilder
                    attribute={attribute}
                    {...attribute.displayOptions}
                    data={valueData}
                    handleContextMenuFn={handleContextMenu}
                    disableContextMenu={disableContextMenu}
                />
            </RowLayout>
        );
    }

    return null;
};
