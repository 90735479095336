import { useState, useCallback, useRef, useMemo, useEffect } from "react";
import PropTypes from "prop-types";
import { useLocation, useHistory } from "react-router-dom";
import keycode from "keycode";
import _debounce from "lodash/debounce";
import qs from "query-string";
import { useDispatch, useSelector } from "react-redux";
import Filter from "mdi-material-ui/Filter";
import HiInput from "@hipay/hipay-material-ui/HiForm/HiInput";
import HiButton from "@hipay/hipay-material-ui/HiButton";
import HiLoader from "@hipay/hipay-material-ui/HiLoader";
import HiChip from "@hipay/hipay-material-ui/HiChip";
import HiPin from "@hipay/hipay-material-ui/HiPin";
import makeStyles from "@mui/styles/makeStyles";

import { useModule } from "../../../../../services/module";
import { useP } from "../../../../../services/i18n";
import { useUser } from "../../../../../services/user";
import * as actionTypes from "../../../../actions/actionTypes";

import { arraysAreEqualByValue, removeDuplicatedArrayElement } from "../../../../../utils/arrays";
import {
    filterEntitiesByIdList,
    filterBusinessEntitiesByAccountIdList,
    getBusinessAccountList,
    countSelectedAccountFromBusiness,
    buildAccountAndBusinessMapFromAccountDatas,
    getFiltersWithRestrictedByAccountParams,
} from "../../../../../utils/entities";
import { foldAccents } from "../../../../../utils/strings";
import { getUrlWithAccounts, parseUrlToParams, isOnNotice } from "../../../../../utils/urls";

import List from "@mui/material/List";
import ListAccountItem from "./ListAccountItem";
import ListBusinessItem from "./ListBusinessItem";
import HiSelectableListItem from "@hipay/hipay-material-ui/HiSelectableList/HiSelectableListItem";
import ReactDOM from "react-dom";
import HiAlertModal from "@hipay/hipay-material-ui/HiAlertModal";
import { isMobile } from "react-device-detect";
import classNames from "classnames";
import { useApi } from "../../../../../services/api";
import { useAsync } from "react-async";
import { useInnerPage } from "../../../../../services/innerPage";

import * as appActions from "../../../../actions/appActions";

const useStylesAccountSelector = makeStyles((theme) => ({
    root: {
        maxWidth: "800px",
        width: "100%",
        padding: "0 24px",
        margin: "0 auto",
    },
    rootMobile: { marginTop: 100 },
    stickyTop: {
        position: "sticky",
        zIndex: 1,
        top: 63,
        textAlign: "center",
        paddingTop: 24,
        paddingBottom: 24,
        backgroundColor: theme.palette.background3,
    },
    scrollable: { position: "relative" },
    stickyBottom: {
        position: "sticky",
        bottom: "0",
        textAlign: "center",
        paddingTop: 24,
        paddingBottom: 48,
        backgroundColor: theme.palette.background3,
    },
    stickyBottomMobile: { position: "relative" },
    loader: {
        width: 13,
        height: 10,
        margin: "auto",
        marginBottom: 20,
    },
    filterBlock: {
        textAlign: "center",
        width: "100%",
        margin: "20px 0",
    },
    filterSpan: {
        margin: "0 4px",
        fontSize: 16,
    },
    results_title: {
        height: 48,
        padding: "12px 8px",
        ...theme.typography.h3,
        color: theme.palette.text.primary,
        textAlign: "left",
    },
    results_chip: {
        marginLeft: 8,
        verticalAlign: "bottom",
    },
    list: {
        paddingTop: 8,
        "& > div > li": { paddingLeft: "4px" },
    },
    selected: {
        backgroundColor: "transparent !important",
        paddingRight: "12px !important",
    },
    selectAllResult: {
        position: "sticky",
        zIndex: 1,
        top: 180,
    },
    everyaccount: { paddingRight: "24px !important" },
    everyaccountInfo: {
        width: "100%",
        fontWeight: `${theme.typography.fontWeightRegular}`,
    },
    iconFilter: { fontSize: 16 },
}));

/**
 * Container AccountSelector
 *
 * 3 distincts behaviours relative to user role and account count
 * - internal users:
 * As internal users have access to many accounts, it request selected account and search from 2 characters
 * - merchant users with more than 10 accounts:
 * As merchant user have few accounts, it display all account list and search bar allow to filter on it
 * - merchant users with less (or equal) than 10 accounts:
 * As merchant user have limited number of account (most frequent case), it just display account list without search bar
 *
 * Business notion is display only if user have at least 2 businesses.
 *
 * http://localhost:8090/default/index/index/ajax/list-merchants/format/json
 */
// Nombre de pixel avant la fin du scroll où l'on relance la recherche de comptes
const infiniteScrollHeight = 10;
let container;
let inputToFocus;

export const AccountSelector = (props) => {
    // store props
    const defaultSelectedAccountIdList = useSelector(
        (state) => state.app.global.selectedAccountIdList
    );
    const defaultSelectedBusinessIdList = useSelector(
        (state) => state.app.global.selectedBusinessIdList
    );
    const accountEntities = useSelector(
        (state) => new Map(Object.entries(state.app.global.entities.account))
    );
    const businessEntities = useSelector(
        (state) => new Map(Object.entries(state.app.global.entities.business))
    );
    const showAllAccounts = useMemo(() => accountEntities.size <= 40, [accountEntities]);

    const dispatch = useDispatch();
    const user = useUser();

    const classes = useStylesAccountSelector();
    const p = useP();
    let history = useHistory();
    let location = useLocation();

    const { closeInnerPage } = useInnerPage();
    const { get } = useApi();
    const {
        data: accountData,
        isPending: accountPending,
        error: accountError,
        run: fetchAccounts,
        cancel: cancelFetchAccounts,
    } = useAsync({
        deferFn: get,
        url: (search) => `/accounts?${search}`,
    });

    /**
     * On initialization
     * - internal user should only display selected account list ( fetch from url, or precedent selection )
     * - merchant user should display all available accounts
     *
     * @returns {{accountList: *, businessList: *, onlySelected: boolean, inputValue: string, loading: boolean, error: null}}
     */
    const [selectedAccountIdList, setSelectedAccountIdList] = useState(
        defaultSelectedAccountIdList
    );
    const [selectedBusinessIdList, setSelectedBusinessIdList] = useState(
        defaultSelectedBusinessIdList
    );
    const [onlySelected, setOnlySelected] = useState(false);
    const [inputValue, setInputValue] = useState("");
    const [allSelectedWanted, setAllSelectedWanted] = useState(selectedAccountIdList.length === 0);
    const [allResultsSelection, setAllResultsSelection] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error] = useState(null);
    const [hasMore, setHasMore] = useState(true);
    const [page, setPage] = useState(1);
    const [pageBeforeSelected, setPageBeforeSelected] = useState(1);
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [alertOpen, setAlertOpen] = useState(false);
    const [businessesBeforeSelected, setBusinessesBeforeSelected] = useState(new Map());
    const [accountsBeforeSelected, setAccountsBeforeSelected] = useState(new Map());
    const [accountList, setAccountList] = useState(
        user.internalstaff || !showAllAccounts
            ? filterEntitiesByIdList(accountEntities, selectedAccountIdList)
            : accountEntities
    );
    const [businessList, setBusinessList] = useState(
        user.internalstaff || !showAllAccounts
            ? filterBusinessEntitiesByAccountIdList(businessEntities, selectedAccountIdList)
            : businessEntities
    );

    useEffect(() => {
        setTimeout(() => {
            if (inputToFocus && inputToFocus.name === "search") {
                inputToFocus.focus();
            } else if (inputToFocus) {
                ReactDOM.findDOMNode(inputToFocus).focus();
            }
        }, 100);

        // On ajoute un listener sur le scroll pour l'infinite scroll pour les internalStaff

        if (user.internalstaff) {
            container = document.getElementById("inner-page-container");
            if (container) {
                container.addEventListener("scroll", () => {
                    if (
                        container.scrollHeight <=
                            container.scrollTop + container.offsetHeight + infiniteScrollHeight &&
                        !loading &&
                        hasMore &&
                        inputValue.length >= 2
                    ) {
                        requestAccountsFromSearch(inputValue, page);
                    }
                });
            }
        }
        document.addEventListener("keydown", handleKeyDown);

        setAlertOpen(true);
        return () => {
            document.removeEventListener("keydown", handleKeyDown);
        };
    }, []);

    useEffect(() => {
        if (accountData) {
            let _accounts = [];
            let hasNew = false;
            if (!page || page === 1) {
                _accounts = accountData;
                hasNew = true;
            } else {
                // On vérifie que la recherche retourne de nouveaux résultats, pour éviter de recharger x pages pour rien si le business est selectionné
                for (let i = 0; i < accountData.length; i++) {
                    if (!accountList.has(accountData[i].accountId.toString())) {
                        hasNew = true;
                        break;
                    }
                }
                _accounts = Array.from(accountList.values()).concat(accountData);
            }
            let { account, business } = buildAccountAndBusinessMapFromAccountDatas(_accounts);
            setAccountList(account);
            setBusinessList(business);
            setLoading(false);
            setPage(accountData.length >= 10 && hasNew ? page + 1 : page);
            setHasMore(accountData.length >= 10 && hasNew);
        }
    }, [accountData, accountError]);

    // Displayed account size listener ==> listener on number of account to select asynchronous update of displayed account
    useEffect(() => {
        if (allResultsSelection && businessList.size === selectedBusinessIdList.length) {
            let accountListToSelect = [];

            setSelectedAccountIdList(() => {
                for (let account of accountListDisplayed) {
                    accountListToSelect.push(account[1].accountId.toString());
                }
                return accountListToSelect;
            });
        }

        if (selectedAccountIdList.length === accountListDisplayed.size) {
            setAllResultsSelection(true);
        } else {
            setAllResultsSelection(false);
        }
    }, [selectedAccountIdList.length, accountList.size, allResultsSelection]);

    const handleKeyDown = (event) => {
        if (event.key === "Escape") {
            closeInnerPage();
        }
    };

    /**
     * Track input to focus reference (search or everyaccount)
     * @param el
     */
    const inputRef = (el) => {
        inputToFocus = el;
    };

    /**
     * Internal User
     * Request accounts from search query
     * cancel previous request when a new one is thrown
     */
    const delayedRequestAccountsFromSearch = useCallback((query, _page) => {
        // Cancel previous pending request
        if (accountPending) {
            cancelFetchAccounts();
        }

        // build path with query and page
        let search = "";
        if (query && query !== "") {
            search += qs.stringify({ name: query });
        }
        if (_page && _page > 1) {
            search += `&p=${_page}`;
        }

        fetchAccounts(search);

        setLoading({ loading: true });
    }, []);

    const requestAccountsFromSearch = useRef(
        _debounce(delayedRequestAccountsFromSearch, 400)
    ).current;

    /**
     * Filter account list and business list on account name and business denomination
     * @param query
     */
    const filterAccountsFromSearch = (query) => {
        let businessEntityList = new Map();
        for (let [businessId, business] of businessEntities) {
            if (
                foldAccents(business["denomination"].toString().toLowerCase()).search(
                    foldAccents(query.toLowerCase())
                ) !== -1
            ) {
                businessEntityList.set(businessId, business);
            }
        }

        let accountEntityList = new Map();
        let businessEntityListForAccount = new Map();
        for (let [accountId, account] of accountEntities) {
            if (businessEntityList.has(account.businessId)) {
                accountEntityList.set(accountId, account);
            } else if (
                foldAccents(account.name.toString().toLowerCase()).search(
                    foldAccents(query.toLowerCase())
                ) !== -1
            ) {
                accountEntityList.set(accountId, account);
                businessEntityListForAccount.set(
                    account.businessId,
                    businessEntities.get(account.businessId.toString())
                );
            }
        }
        return {
            accountList: accountEntityList,
            businessList: new Map([...businessEntityList, ...businessEntityListForAccount]),
        };
    };

    const persistAccountAndBusinessEntities = useCallback(
        (_accountEntities, _businessEntities) => {
            dispatch(
                appActions.persistSearchAccountAndBusinessEntities(
                    _accountEntities,
                    _businessEntities
                )
            );
        },
        [dispatch]
    );

    const fetchSelectedAccountList = useCallback(
        (accountIdList) => dispatch(appActions.fetchSelectedAccountList(accountIdList)),
        [dispatch]
    );

    /**
     * Add or remove all relative accounts from selected list
     * -- if business was selected, remove all relative accounts from selected list
     * -- if business was unselected, add all relative accounts to selected list and deduplicate them
     *
     * @param business
     */
    const handleSelectBusiness = (business) => {
        if (selectedBusinessIdList.includes(business.businessId)) {
            // DESELECT BUSINESS AND RELATIVE ACCOUNT
            setSelectedAccountIdList(
                selectedAccountIdList.filter((aId) => !business.accountIdList.includes(aId))
            );
            setSelectedBusinessIdList(
                selectedBusinessIdList.filter((bId) => bId !== business.businessId)
            );
            setOnlySelected(
                selectedAccountIdList.filter((aId) => !business.accountIdList.includes(aId))
                    .length > 0
                    ? onlySelected
                    : false
            );
            if (
                onlySelected &&
                selectedAccountIdList.filter((aId) => !business.accountIdList.includes(aId))
                    .length === 0
            ) {
                setAccountList(accountsBeforeSelected);
                setBusinessList(businessesBeforeSelected);
            }
        } else if (user.internalstaff) {
            // SELECT BUSINESS AND RELATIVE ACCOUNT => fetch all accounts from api
            let accountIdsToFetch = [];
            for (let i = 0; i < business.accountIdList.length; i++) {
                if (!accountList.has(business.accountIdList[i].toString())) {
                    accountIdsToFetch.push(business.accountIdList[i]);
                }
            }

            if (accountIdsToFetch.length) {
                setLoading(true);
                fetchSelectedAccountList(business.accountIdList).then((response) => {
                    let newAccountList = accountList;
                    for (let i = 0; i < response.payload.length; i++) {
                        response.payload[i].businessId = business.businessId;
                        newAccountList.set(
                            response.payload[i].accountId.toString(),
                            response.payload[i]
                        );
                    }

                    setAccountList(newAccountList);
                    setLoading(false);
                });
            }
            setSelectedAccountIdList(
                removeDuplicatedArrayElement([...selectedAccountIdList, ...business.accountIdList])
            );
            setSelectedBusinessIdList([...selectedBusinessIdList, business.businessId]);
            persistAccountAndBusinessEntities(getBusinessAccountList(business, accountList), {
                [business.businessId]: business,
            });
        } else {
            // SELECT BUSINESS AND RELATIVE ACCOUNT => fetch all accounts from store
            let mAccountList = new Map(accountList);
            for (let i = 0; i < business.accountIdList.length; i++) {
                mAccountList.set(
                    business.accountIdList[i].toString(),
                    accountEntities.get(business.accountIdList[i].toString())
                );
            }
            setSelectedAccountIdList(
                removeDuplicatedArrayElement([...selectedAccountIdList, ...business.accountIdList])
            );
            setSelectedBusinessIdList([...selectedBusinessIdList, business.businessId]);
            setAccountList(mAccountList);
        }
        if (selectedAccountIdList.length === 0 && selectedBusinessIdList.length === 0) {
            setAllSelectedWanted(false);
        }
    };

    /**
     * Add or remove accounts from selected list
     * if businessIsFullySelected, add it to selectedBusinessIdList
     * @param account
     */
    const handleSelectAccount = (account) => {
        if (selectedAccountIdList.includes(account.accountId.toString())) {
            // DESELECT ACCOUNT
            setOnlySelected(
                selectedAccountIdList.filter((aId) => aId !== account.accountId.toString()).length >
                    0
                    ? onlySelected
                    : false
            );
            if (
                onlySelected &&
                selectedAccountIdList.filter((aId) => aId !== account.accountId.toString())
                    .length === 0
            ) {
                setAccountList(accountsBeforeSelected);
                setBusinessList(businessesBeforeSelected);
            }
            if (selectedBusinessIdList.includes(account.businessId)) {
                // DESELECT BUSINESS
                setSelectedBusinessIdList(
                    selectedBusinessIdList.filter((id) => id !== account.businessId)
                );
            }
            setSelectedAccountIdList(
                selectedAccountIdList.filter((id) => id !== account.accountId.toString())
            );
        } else {
            // SELECT ACCOUNT
            let business = { ...businessList.get(account.businessId.toString()) };
            persistAccountAndBusinessEntities(
                { [account.accountId.toString()]: account },
                { [account.businessId.toString()]: business }
            );

            setSelectedAccountIdList([...selectedAccountIdList, account.accountId.toString()]);
            // if every relative business account are selected, add it to selectedBusinessIdList
            if (
                business.accountIdList.every((accountId) =>
                    [...selectedAccountIdList, account.accountId.toString()].includes(
                        accountId.toString()
                    )
                )
            ) {
                setSelectedBusinessIdList([
                    ...selectedBusinessIdList,
                    account.businessId.toString(),
                ]);
            }
        }
        if (selectedAccountIdList.length === 0 && selectedBusinessIdList.length === 0) {
            setAllSelectedWanted(false);
        }
    };

    /**
     * Clic sur le bouton "Sélectionnés"
     *  - Si on active le bouton
     *      => on affiche seulement les comtpes sélectionnés et on disable le champs de recherche
     *      => on sauvegarde les comptes affichés avant le clic afin de les réafficher ensuite sans relancer de requête
     *  - Si on désactive le bouton et qu'une recherche est saisie (>2)
     *     => on affiche les comptes correspondants à la recherche sauvegardés dans le state
     *  - Sinon
     *     => on affiche que les comptes sélectionnés
     */
    const handleOnlySelectFilterClick = () => {
        setPage(!onlySelected || inputValue < 2 ? 1 : pageBeforeSelected);
        setPageBeforeSelected(page);
        if (!onlySelected || (inputValue < 2 && (user.internalstaff || !showAllAccounts))) {
            setAccountList(filterEntitiesByIdList(accountEntities, selectedAccountIdList));
            setBusinessList(
                filterBusinessEntitiesByAccountIdList(businessEntities, selectedAccountIdList)
            );
        } else {
            setAccountList(accountsBeforeSelected);
            setBusinessList(businessesBeforeSelected);
        }
        setBusinessesBeforeSelected(!onlySelected ? businessList : new Map());
        setAccountsBeforeSelected(!onlySelected ? accountList : new Map());
        setOnlySelected(!onlySelected);
        setHasMore(onlySelected);
    };

    /**
     * Deselect all account (equivalent to select "All accounts")
     */
    const deselectAllAccount = () => {
        setSelectedAccountIdList([]);
        setSelectedBusinessIdList([]);
        setAllSelectedWanted(!allSelectedWanted);
    };

    /**
     * Fetch all displayed business and select all account displayed
     */
    const selectAllAccountsResults = async () => {
        let accountListToSelect = [];
        let businessListToSelect = [];

        setSelectedAccountIdList(() => {
            for (let account of accountListDisplayed) {
                accountListToSelect.push(account[1].accountId.toString());
            }
            return accountListToSelect;
        });

        for (let business of businessListDisplayed) {
            let accountSelectedOfBusiness = [];
            for (let accountId of accountListToSelect) {
                for (let businessIdAccount of business[1].accountIdList)
                    if (accountId === businessIdAccount) {
                        accountSelectedOfBusiness.push(accountId);
                    }
            }
            if (accountSelectedOfBusiness.length === business[1].accountIdList.length) {
                businessListToSelect.push(business[0]);
            }
        }
        setSelectedBusinessIdList(businessListToSelect);

        setAllResultsSelection(true);
        setAllSelectedWanted(false);
    };

    /**
     * Deselect all account displayed
     */
    const deselectAllAccountsResults = () => {
        setSelectedAccountIdList([]);
        setSelectedBusinessIdList([]);
        setAllResultsSelection(false);
    };

    const onSubmit = useCallback(
        (_selectedAccountIdList, _selectedBusinessIdList) => {
            dispatch(appActions.setSelectedAccountIdList(_selectedAccountIdList));
            dispatch(appActions.setSelectedBusinessIdList(_selectedBusinessIdList));
            // PSYCHE-3043 - Redirect to dashboard when select an account from transaction notice
            let pathname = history.location.pathname;
            if (_selectedAccountIdList.length && isOnNotice(pathname)) {
                pathname = "dashboard";
            }
            history.push({
                pathname: getUrlWithAccounts(_selectedAccountIdList, pathname),
                search: history.location.search,
            });
        },
        [dispatch, getUrlWithAccounts]
    );

    const addSnackBar = useCallback(
        (snackBar) => dispatch(appActions.addSnackBar(snackBar)),
        [dispatch]
    );

    /**
     * Submit selection
     * Persist current account selection to global store selection
     *
     * Submit selected account + selected business
     * Launch snackbar with selected account counts
     */
    const handleSubmit = () => {
        setButtonDisabled(true);

        if (arraysAreEqualByValue(defaultSelectedAccountIdList, selectedAccountIdList)) {
            // Prevent send request if submit same account selection -> just close inner page
            closeInnerPage();
            return;
        }

        if (selectedAccountIdList.length) {
            fetchSelectedAccountList(selectedAccountIdList).then((response) => {
                let accountIdList = Array.isArray(response.payload)
                    ? response.payload.map((account) => account.accountId.toString())
                    : [];
                onSubmit(accountIdList, selectedBusinessIdList);

                if (typeof addSnackBar !== "undefined") {
                    addSnackBar({
                        isOpen: true,
                        message: accountIdList.length
                            ? "account_selector.snackbar_confirmation"
                            : "account_selector.snackbar_confirmation_all",
                        messageParams: accountIdList.length,
                        undo: actionTypes.SET_SELECTED_ACCOUNT_ID_LIST,
                    });
                }

                closeInnerPage();
            });
        } else {
            onSubmit([], []);
            closeInnerPage();
        }
    };

    /**
     * No query -> reset search
     * 3+ char query
     *    -> (internal user) request for accounts
     *    -> (merchant user) filter account list
     * @param e
     */
    const handleSearchChange = (e) => {
        let query = e.target.value;

        if (query.length < 2) {
            handleSearchReset();
        } else {
            setPage(1);
            if (user.internalstaff) {
                requestAccountsFromSearch(query, 1);
            } else {
                const lists = filterAccountsFromSearch(query);

                setAccountList(lists.accountList);
                setBusinessList(lists.businessList);
            }
        }

        setInputValue(query);
    };

    /**
     * Reset state but keep selected id list
     *
     * INTERNAL USER -> only get currently selected account and relative business from redux
     * MERCHANT USER -> get all account and business from redux
     */
    const handleSearchReset = () => {
        if (user.internalstaff || !showAllAccounts) {
            setAccountList(filterEntitiesByIdList(accountEntities, selectedAccountIdList));
            setBusinessList(
                filterBusinessEntitiesByAccountIdList(businessEntities, selectedAccountIdList)
            );
        } else {
            setAccountList(accountEntities);
            setBusinessList(businessEntities);
        }
        setInputValue("");
        setLoading(false);
        setHasMore(true);
        setPage(1);
    };

    const createBusinessesList = (_businessListDisplayed, _accountListDisplayed) => {
        let list = [];

        // Sort businesses by denomination
        const sortedBusinesses = new Map(
            [..._businessListDisplayed].sort((a, b) => {
                if (a[1]["denomination"].toLowerCase() === b[1]["denomination"].toLowerCase()) {
                    return 0;
                }
                return a[1]["denomination"].toLowerCase() > b[1]["denomination"].toLowerCase()
                    ? 1
                    : -1;
            })
        );

        for (const _business of sortedBusinesses.values()) {
            let bSelected = selectedBusinessIdList.includes(_business.businessId);
            let business = {
                ..._business,
                selectedAccountCount: countSelectedAccountFromBusiness(
                    _business,
                    selectedAccountIdList
                ),
            };

            list.push(
                <div key={`business-${_business.businessId}`}>
                    {(!onlySelected || bSelected || business.selectedAccountCount > 0) && (
                        <ListBusinessItem
                            business={business}
                            selected={bSelected}
                            onItemClick={handleSelectBusiness}
                            highlight={inputValue}
                            onSubmit={handleSubmit}
                        />
                    )}
                    <div>{createAccountsList(_business.businessId, _accountListDisplayed)}</div>
                </div>
            );
        }
        return list;
    };

    const createAccountsList = (businessId, _accountListDisplayed) => {
        let list = [];

        // Sort accounts by name
        const sortedAccounts = new Map(
            [..._accountListDisplayed].sort((a, b) => {
                if (a[1]["name"] === b[1]["name"]) {
                    return 0;
                }
                return a[1]["name"] > b[1]["name"] ? 1 : -1;
            })
        );

        for (const [accountId, account] of sortedAccounts) {
            let aSelected = selectedAccountIdList.includes(accountId);

            if (account.businessId === businessId && (!onlySelected || aSelected)) {
                list.push(
                    <ListAccountItem
                        key={`account-${accountId}`}
                        account={account}
                        selected={aSelected}
                        onItemClick={handleSelectAccount}
                        highlight={inputValue}
                        onSubmit={handleSubmit}
                    />
                );
            }
        }
        return list;
    };

    const handleSubmitAlert = () => {
        setAlertOpen(false);
    };

    let accountListDisplayed = accountList;
    let businessListDisplayed = businessList;

    if ((user.internalstaff || !showAllAccounts) && onlySelected) {
        accountListDisplayed = filterEntitiesByIdList(accountList, selectedAccountIdList);
        businessListDisplayed = filterBusinessEntitiesByAccountIdList(
            businessList,
            selectedAccountIdList
        );
    }

    // empty selectedAccountIdList mean "Every account"
    let allSelected = selectedAccountIdList.length === 0;
    let noneSelected = selectedAccountIdList.length === 0 && !allSelectedWanted;
    let noneSelectable = accountListDisplayed.length === 0;

    let hasSearch = user.internalstaff || accountEntities.size > 10 || !showAllAccounts;

    const { attributes, id: moduleId } = useModule();
    const activeSearchParams = parseUrlToParams(location.search);
    const restrictedFilters = attributes
        ? getFiltersWithRestrictedByAccountParams(moduleId, activeSearchParams, attributes, p)
        : [];

    return (
        <div className={classNames(classes.root, { [classes.rootMobile]: isMobile })}>
            {restrictedFilters.length > 0 && !activeSearchParams.fp_account && (
                <HiAlertModal
                    id="alert-restricted"
                    open={alertOpen}
                    title={p.t("alert.information")}
                    content={p.t("alert.restricted_filters.account_selector.body", {
                        filters_name: restrictedFilters.join(", "),
                        smart_count: restrictedFilters.length,
                    })}
                    onSubmitClick={handleSubmitAlert}
                    onCancelClick={closeInnerPage}
                    onClose={closeInnerPage}
                    labelSubmitButton={p.t("alert.action.ok")}
                    labelCancelButton={p.t("alert.action.cancel")}
                />
            )}
            <div className={classNames({ [classes.stickyTop]: !isMobile })}>
                {hasSearch && (
                    <div id="account-search-input-container">
                        <HiInput
                            onChange={handleSearchChange}
                            onReset={handleSearchReset}
                            value={inputValue}
                            startAdornment="search"
                            inputRef={inputRef}
                            placeholder={p.t("account_selector.search")}
                            disabled={onlySelected}
                            name={"search"}
                            autoComplete="off"
                        />
                    </div>
                )}

                <div className={classes.filterBlock}>
                    <HiButton
                        className={classes.filterButton}
                        color={onlySelected ? "primary" : "neutral"}
                        disabled={allSelected && !onlySelected}
                        onClick={handleOnlySelectFilterClick}
                    >
                        <Filter className={classes.iconFilter} />
                        <span className={classes.filterSpan}>
                            {p.t("account_selector.selected")}
                        </span>
                        {selectedAccountIdList.length > 0 && (
                            <HiPin color="primary">{selectedAccountIdList.length}</HiPin>
                        )}
                    </HiButton>
                </div>
            </div>

            <div className={classNames({ [classes.scrollable]: !isMobile })}>
                {!onlySelected && (
                    <HiSelectableListItem
                        ref={!hasSearch ? inputRef : null}
                        classes={{
                            selected: classes.selected,
                            root: classes.everyaccount,
                            info: classes.everyaccountInfo,
                        }}
                        disabled={noneSelectable}
                        label={p.t("account_selector.everywhere")}
                        icon="fa-archive"
                        id="all"
                        onSelect={deselectAllAccount}
                        selected={noneSelectable || allSelectedWanted}
                        type="icon"
                        info={
                            !user.internalstaff
                                ? p.t("account_selector.n_accounts", accountEntities.size)
                                : undefined
                        }
                        onKeyDown={(event) => {
                            if (keycode(event) === "enter") {
                                event.preventDefault();
                                handleSubmit();
                            } else if (keycode(event) === "space") {
                                event.preventDefault();
                                deselectAllAccount();
                            }
                        }}
                    />
                )}

                {hasSearch && accountListDisplayed.size > 0 && (
                    <>
                        <div className={classes.results_title}>
                            <span>{p.t("common.search.results")}</span>
                            {!loading && (
                                <HiChip
                                    classes={{ root: classes.results_chip }}
                                    label={accountListDisplayed.size}
                                />
                            )}
                        </div>
                        <HiSelectableListItem
                            classes={{
                                selected: classes.selected,
                                root: classes.selectAllResult,
                                info: classes.everyaccountInfo,
                            }}
                            disabled={noneSelectable}
                            label={p.t("account_selector.select_all_results")}
                            id="select_all-result"
                            onSelect={
                                accountList.size === selectedAccountIdList.length
                                    ? deselectAllAccountsResults
                                    : selectAllAccountsResults
                            }
                            selected={allResultsSelection}
                            type="icon"
                            info={
                                !user.internalstaff
                                    ? p.t("account_selector.select_all_results")
                                    : undefined
                            }
                        />
                    </>
                )}

                <div id="account-list" className={classes.list}>
                    <List className={classes.list}>
                        {createBusinessesList(businessListDisplayed, accountListDisplayed)}
                    </List>
                </div>
            </div>
            <div
                className={classNames(classes.stickyBottom, {
                    [classes.stickyBottomMobile]: isMobile,
                })}
            >
                {(loading || error) && (
                    <div className={classes.loader}>
                        {loading ? <HiLoader loading /> : <span>error</span>}
                    </div>
                )}
                {!error && (
                    <div>
                        <HiButton
                            id="account-selector-submit"
                            variant="contained"
                            color="primary"
                            disabled={noneSelected || buttonDisabled}
                            onClick={handleSubmit}
                        >
                            {p.t("button.select_account")}
                        </HiButton>
                    </div>
                )}
            </div>
        </div>
    );
};

AccountSelector.propTypes = { classes: PropTypes.object };
