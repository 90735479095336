import { rootReducer as app } from "./app/reducers/app";
import { getFeatures as features } from "./app/reducers";
import { reducerManager } from "./services/references/reducerManager";

export const staticReducersManager = ({ references }) => {
    const ref = reducerManager(references);

    return {
        app,
        ref,
        features,
    };
};
