import { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useOktaAuth } from "@okta/okta-react";
import { useDispatch } from "react-redux";
import qs from "query-string";
import { SET_REDIRECT_LOCATION } from "../actions/actionTypes";
import { AppLoading } from "./AppLoading";

/**
 * Container AppUnlogged
 */
export function AppUnlogged({ redirectTo = null }) {
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();

    const { oktaAuth } = useOktaAuth();

    useEffect(() => {
        if (process.env.NX_SCREEB === "true") {
            window.$screeb("close");
        }
    }, []);

    const urlParams = qs.parse(location.search);
    // Redirect to zendesk if logout from zendesk
    if (urlParams && urlParams.return_to && location.pathname === "/logout") {
        window.location.href = urlParams.return_to;
    }
    // if Login page is display from another location than /login
    // persist this location in store to redirect to it after login.
    else if (location.pathname !== "/login") {
        //Don't save insecure locations or logout.
        let matches = redirectTo.match(/\/{2,10}/);
        if (!matches?.length && redirectTo.indexOf("logout") === -1) {
            oktaAuth.setOriginalUri(redirectTo);
            dispatch({
                type: SET_REDIRECT_LOCATION,
                redirectLocation: redirectTo,
            });
        }
        oktaAuth.isAuthenticated().then((response) => {
            if (response === false) {
                history.replace("/login");
            }
        });
    }

    return <AppLoading />;
}
