import { AppUser, AppRole, AppPrivilege } from "../AppUser";

import attributesUsers from "./user/attributes";
import { defaultFields as defaultFieldsUsers } from "./user/form";
import referencesUsers from "./user/references";

import attributesRoles from "./role/attributes";
import { defaultFields as defaultFieldsRoles } from "./role/form";
import referencesRoles from "./role/references";

import attributesPrivileges from "./privilege/attributes";
import { defaultFields as defaultFieldsPrivileges } from "./privilege/form";
import referencesPrivileges from "./privilege/references";

import PeopleAltOutlined from "@mui/icons-material/PeopleAltOutlined";

/**
 * Config
 *
 * On retrouve ici la configuration du module.
 *  - Ses attributes
 *  - La config du module selector de la quicksearch
 *  - Les default fields presents dans le quickForm & par défaut sur le formulaire de recherche (s'il est activé)
 *    ainsi que leurs valeurs par défaut (ou null)
 *  - La liste des features qui sont activées pour ce module
 *
 */
export const moduleConfigUser = {
    id: "user", // module key
    attributes: attributesUsers, // module attributes for search
    defaultFormFields: defaultFieldsUsers, // default attributes on search
    icon: PeopleAltOutlined, // module icon on module selector
    label: "menu.user.title", // translation path
    path: "users", // module uri from search
    route: "[prefix]/users", // module uri from quicksearch
    features: ["form"], // module features enabled
    references: referencesUsers, // module references
    apiUrl: process.env.NX_USER_MANAGEMENT_API_URL,
    suggestRoute: "/v1/users:suggest?",
    acl: {
        module: [
            {
                module: "account",
                controller: "user",
                action: "index",
            },
        ],
    },
};

export const moduleConfigRole = {
    id: "role", // module key
    attributes: attributesRoles, // module attributes for search
    defaultFormFields: defaultFieldsRoles, // default attributes on search
    icon: PeopleAltOutlined, // module icon on module selector
    label: "menu.role.title", // translation path
    path: "roles", // module uri from search
    route: "[prefix]/roles", // module uri from quicksearch
    features: ["form"], // module features enabled
    references: referencesRoles, // module references
    internal: true,
    acl: {
        module: [
            {
                module: "admin",
                controller: "role",
            },
        ],
    },
};

export const moduleConfigPrivilege = {
    id: "privilege", // module key
    attributes: attributesPrivileges, // module attributes for search
    defaultFormFields: defaultFieldsPrivileges, // default attributes on search
    icon: PeopleAltOutlined, // module icon on module selector
    label: "menu.privilege.title", // translation path
    path: "privileges", // module uri from search
    route: "[prefix]/privileges", // module uri from quicksearch
    features: ["form"], // module features enabled
    references: referencesPrivileges, // module references
    internal: true,
    acl: {
        module: [
            {
                module: "admin",
                controller: "role",
            },
        ],
    },
};

export const moduleRouting = [
    {
        key: "profile", // module key
        path: ["/profile", "/accounts-:ids/profile"], // module matching uri
        component: AppUser, // module parent component
    },
    {
        key: "user", // module key
        path: ["/users", "/accounts-:ids/users", "/users/exports", "/accounts-:ids/users/exports"], // module matching uri
        component: AppUser, // module parent component
    },
    {
        key: "role", // module key
        path: ["/roles", "/accounts-:ids/roles"], // module matching uri
        component: AppRole, // module parent component
    },
    {
        key: "privilege", // module key
        path: ["/privileges", "/accounts-:ids/privileges"], // module matching uri
        component: AppPrivilege, // module parent component
    },
];
