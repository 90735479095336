import ContentLoader from "react-content-loader";
import withStyles from "@mui/styles/withStyles";

const styles = () => ({
    root: {
        width: 160,
        height: 120,
        color: "white",
        border: "#fff 1px solid",
        borderRadius: 3,
        textAlign: "center",
        margin: "auto",
    },
});

const KpiLoadING = (props) => {
    const { classes } = props;

    return (
        <ContentLoader
            className={classes.root}
            height={120}
            width={160}
            speed={2}
            secondaryColor="#4846a1"
            primaryColor="#6864E7"
        >
            <rect x="0" y="0" rx="0" ry="0" width="198" height="120" />
        </ContentLoader>
    );
};

export default withStyles(styles)(KpiLoadING);
