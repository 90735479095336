import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { isMobile } from "react-device-detect";
import HiButton from "@hipay/hipay-material-ui/HiButton";
import HiIcon from "@hipay/hipay-material-ui/HiIcon";
import { translate } from "../../../../../../services/i18n/translate";
import SuggestionItemBuilder from "./SuggestionItemBuilder";
import Loader from "./Loader";
import styled from "styled-components/macro";
import Typography from "@mui/material/Typography";

class SuggestionList extends React.PureComponent {
    static propTypes = {
        containerWidth: PropTypes.number.isRequired,
        hasViewMoreButton: PropTypes.bool,
        onBlur: PropTypes.func,
        onClickSeeMore: PropTypes.func,
        onClickSuggestion: PropTypes.func,
        suggestionList: PropTypes.array,
        loading: PropTypes.bool,
        error: PropTypes.object,
        focusIndex: PropTypes.number,
        updateFocusIndex: PropTypes.func,
    };

    handleMouseLeave = () => {
        this.props.updateFocusIndex(0);
    };

    render() {
        const {
            p,
            module,
            containerWidth,
            emptySuggestionListMessage,
            suggestionList,
            loading,
            onClickSuggestion,
            focusIndex,
            hasViewMoreButton,
            viewMoreButtonRef,
            handleViewMoreKeyDown,
            updateFocusIndex,
            error,
        } = this.props;

        let suggestionType;

        const suggestions = isMobile ? suggestionList.slice(0, 5) : suggestionList;

        return (
            <div
                id="suggestion-list"
                className={classNames(this.props.className, { "root-mobile": isMobile })}
                style={!isMobile ? { width: containerWidth - 48 } : null}
                onMouseLeave={this.handleMouseLeave}
            >
                {suggestions.map((suggestion, index) => {
                    let hasDivider = false;
                    if (index > 0 && suggestionType !== suggestion.type) {
                        hasDivider = true;
                    }
                    suggestionType = suggestion.type;
                    return (
                        <SuggestionItemBuilder
                            key={index}
                            index={index}
                            isFocus={index === focusIndex}
                            onClick={onClickSuggestion}
                            suggestion={suggestion}
                            hasDivider={hasDivider}
                            module={module}
                            updateFocusIndex={updateFocusIndex}
                        />
                    );
                })}
                {suggestionList.length === 0 && !loading && !error && (
                    <Typography variant="body2" component="div">
                        <div
                            className="message"
                            dangerouslySetInnerHTML={{ __html: emptySuggestionListMessage }}
                        />
                    </Typography>
                )}
                {!!error && (
                    <div className="error-message-container">
                        <HiIcon color="neutral" icon="hi_briefcase_rescue" />
                        <Typography variant="body2" component="div">
                            <div
                                className="error-message"
                                dangerouslySetInnerHTML={{
                                    __html: !error.displayMsg
                                        ? p.t("top_bar.quick_search.api_error")
                                        : error.message,
                                }}
                            />
                        </Typography>
                    </div>
                )}
                {loading && <Loader loading={loading} />}
                {hasViewMoreButton && (
                    <HiButton
                        ref={viewMoreButtonRef}
                        // className={classNames("see-more", { ["see-more-mobile"]: isMobile })}
                        className="see-more"
                        color="primary"
                        onClick={this.props.onClickSeeMore}
                        onKeyDown={handleViewMoreKeyDown}
                    >
                        {p.t("top_bar.quick_search.view_more")}
                    </HiButton>
                )}
            </div>
        );
    }
}

const StyledSuggestionList = styled(SuggestionList)`
    position: absolute;
    background-color: ${({ theme }) => theme.palette.background.paper};
    top: 64px;
    box-shadow: inset 0px 8px 5px -7px rgba(0, 0, 0, 0.16), 0px 3px 5px rgba(0, 0, 0, 0.24);
    margin-left: 48px;

    .root-mobile {
        width: 100%;
        left: 0px;
        margin-left: 0px;
    }

    .see-more {
        width: 100%;
        height: 48px;
        border-top: ${({ theme }) => "1px solid" + theme.palette.divider};
    }

    .see-more-mobile {
        height: 32px;
    }

    .message {
        color: ${({ theme }) => theme.palette.neutral.light};
        padding: ${({ theme }) => theme.spacing(2)};
    }

    .error-message-container {
        border-top: ${({ theme }) => "1px solid" + theme.palette.divider};
        width: calc(100% - 26px);
        margin: auto;
        padding: 12px;
        display: flex;
    }

    .error-message {
        color: ${({ theme }) => theme.palette.neutral.dark};
        margin-left: 12px;
        text-align: left;
    }
`;

export default translate(StyledSuggestionList);
