import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setTourLoading, unfocusedInput } from "../../../app/actions/guidetourActions";
import { useLocalStorage } from "react-use";
import { ShepherdTour } from "react-shepherd";
import "shepherd.js/dist/css/shepherd.css";
import { useP } from "../../../services/i18n";
import makeStyles from "@mui/styles/makeStyles";
import { ModuleContext } from "../../../services/module/module-context";
import { GuideTourButton } from "./GuideTourButton";

export const useStylesGuideTour = makeStyles((theme) => ({
    tour: {
        "& .shepherd-content": { padding: "18px 24px 24px 24px" },
        "& .shepherd-arrow:before": { backgroundColor: "white !important" },

        "& .shepherd-content header": {
            padding: "0 !important",
            marginBottom: "14px",
            backgroundColor: "white !important",
            "& .shepherd-title": {
                ...theme.typography.h3,
                whiteSpace: "pre-line",
            },
        },

        "& .shepherd-content .shepherd-text": {
            marginBottom: "21px",
            padding: 0,
            ...theme.typography.body2,
            whiteSpace: "pre-line",
        },

        "& .shepherd-content .shepherd-steps": {
            color: theme.palette.neutral.ultraLight,
            textAlign: "center",
            marginBottom: "21px",
            ...theme.typography.body2,
        },

        "& .shepherd-content footer": {
            justifyContent: "space-between",
            padding: 0,
        },
        "& .shepherd-button": {
            backgroundColor: theme.palette.primary.main,
            flex: 1,
            borderRadius: 0,
            ...theme.typography.button,
            color: "white",
            fontWeight: theme.typography.fontWeightMedium,
            "&:hover": { backgroundColor: theme.palette.primary.dark },
            "&:first-child": {
                color: theme.palette.primary.main,
                backgroundColor: "white",
                "&:hover": { backgroundColor: "rgba(165, 9, 121, 0.08)" },
            },
        },
    },
    guideTourButton: {
        pointerEvents: "auto !important", // Allow title on disabled
        marginLeft: 30,
    },
}));

export const GuideTour = (props) => {
    const classes = useStylesGuideTour();

    const dispatch = useDispatch();
    const p = useP();
    const moduleContext = useContext(ModuleContext);
    let moduleState = useSelector((state) => state);
    const loading = useSelector((state) => state.app.guidetour.loading);
    const [tourDone, setTourDone] = useLocalStorage(
        "guide_tour_done",
        true //Because the global guidetour is deactivated, the localstorage default value is true
    );
    const [steps, setSteps] = useState([]);
    // Use an object so it can be pass as reference for getGuideTourSteps
    const [initCallback, setInitCallback] = useState({});

    const popperOptions = {
        modifiers: [
            {
                name: "offset",
                options: { offset: [0, 24] },
            },
        ],
    };

    const tourButtons = [
        {
            text: p.t("guidetour.buttons.previous"),
            action() {
                this.back();
            },
        },
        {
            text: p.t("guidetour.buttons.next"),
            action() {
                this.next();
            },
        },
    ];

    const tourSteps = () => {
        return p.t("guidetour.steps");
    };

    const tourOptions = {
        defaultStepOptions: {
            cancelIcon: { enabled: false },
            canClickTarget: false,
            classes: classes.tour,
            popperOptions: popperOptions,
            buttons: tourButtons,
            modalOverlayOpeningRadius: 4,
            scrollTo: {
                behavior: "smooth",
                block: "center",
            },
            when: {
                show() {
                    // Create steps element
                    const currentStepElement = this.tour.currentStep.el;
                    const content = currentStepElement.querySelector(".shepherd-content");
                    const progress = document.createElement("div");
                    progress.classList.add("shepherd-steps");
                    progress.innerText = `${this.tour.steps.indexOf(this.tour.currentStep) + 1}/${
                        this.tour.steps.length
                    } ${tourSteps()}`;
                    content.insertBefore(
                        progress,
                        currentStepElement.querySelector(".shepherd-footer")
                    );
                },
            },
        },
        useModalOverlay: true,
    };

    const unfocusInput = () => {
        dispatch(unfocusedInput());
    };

    useEffect(() => {
        // The main guidetour loading is deactivated
        // The explanations are in the GuideTourButton component
        if (moduleContext.getGuideTourSteps) {
            let moduleSteps = moduleContext.getGuideTourSteps(
                moduleState,
                tourOptions,
                p,
                setInitCallback
            );
            setSteps(moduleSteps);
            if (moduleSteps.length === 0) {
                if (!loading) {
                    dispatch(setTourLoading());
                }
            }
        }
    }, [loading, p, moduleState]);

    let buttonProps = {
        unfocusInput,
        disabled: steps.length === 0,
        classes,
        p,
        tourDone,
        setTourDone,
        initCallback,
    };

    return (
        <ShepherdTour steps={steps} tourOptions={tourOptions}>
            <GuideTourButton {...buttonProps} />
        </ShepherdTour>
    );
};
