import { useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import Drag from "mdi-material-ui/Drag";
import classNames from "classnames";
import { useP } from "../../../../services/i18n";
import { useModule } from "../../../../services/module";
import makeStyles from "@mui/styles/makeStyles";
import { SortableElement, SortableHandle } from "react-sortable-hoc";
import FormControlLabel from "@mui/material/FormControlLabel";
import HiCheckbox from "@hipay/hipay-material-ui/HiCheckbox";
import HiIcon from "@hipay/hipay-material-ui/HiIcon";
import HiSizeMediumIcon from "@hipay/hipay-material-ui/hi-svg-icons/HiSizeMedium";
import HiSizeLargeIcon from "@hipay/hipay-material-ui/hi-svg-icons/HiSizeLarge";
import HiSizeSmallIcon from "@hipay/hipay-material-ui/hi-svg-icons/HiSizeSmall";
import HiSwitch from "@hipay/hipay-material-ui/HiSwitch";
import { VIEWS } from "../../../../constants";
import { DISPLAY_DATE } from "../../../constants/displayTypes";

const useStyles = makeStyles((theme) => ({
    itemFilter: {
        "&:hover": {
            backgroundColor: theme.palette.action.hover,
            fontWeight: theme.typography.fontWeightMedium,
        },
        height: 40,
        width: "100%",
        paddingLeft: 3,
        fontFamily: "Roboto, Helvetica, Arial, sans-serif",
        display: "flex",
        alignItems: "center",
    },
    checkbox: {
        position: "relative",
        top: 2,
        "&$checkboxNotDraggable": { top: 1 },
    },
    checkboxNotDraggable: {},
    controlLabelRoot: {
        marginBottom: 2,
        fontSize: 14,
        width: 210,
        "&$controlLabelSmall": {
            overflow: "hidden",
            marginRight: 6,
            width: 182,
        },
        "&$controlLabelNotDraggable": {
            marginLeft: 0,
            position: "relative",
            width: 242,
        },
    },
    controlLabelSmall: {},
    controlLabelNotDraggable: {},
    itemLabel: {
        position: "relative",
        top: 2,
        left: 5,
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        display: "flex",
        alignItems: "center",
        "&$itemLabelNotDraggable": { top: 2 },
        "&$itemLabelNotDraggableNotChecked": { top: 3 },
    },
    itemLabelNotDraggable: {},
    itemLabelNotDraggableNotChecked: {},
    label: {
        fontWeight: "inherit",
        fontSize: "inherit",
    },
    itemControls: {
        display: "flex",
        width: "calc(100% - 84px)",
        justifyContent: "space-between",
        alignItems: "center",
        "&$itemControlsNotDraggableChecked": {
            width: "calc(100% - 50px)",
            marginTop: -2,
        },
        "&$itemControlsNotDraggableNotChecked": {
            width: "calc(100% - 48px)",
            marginTop: -2,
        },
        "&$itemControlsSelectedNotDraggable": { marginTop: -1 },
        "&$itemControlsNotDraggableCellFixed": {
            width: "calc(100% - 18px)",
            marginTop: -2,
        },
    },
    textLabel: {
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
    },
    widthCellFixed: { width: "82%" },
    itemControlsNotDraggableChecked: {},
    itemControlsNotDraggableNotChecked: {},
    itemControlsSelectedNotDraggable: {},
    itemControlsNotDraggableCellFixed: {},
    dragIcon: {
        cursor: "move",
        position: "relative",
        marginRight: 12,
    },
    svgIcon: {
        position: "absolute",
        right: 15,
        float: "right",
        cursor: "pointer",
        fill: theme.palette.primary.main,
    },
    iconDisabled: { fill: theme.palette.action.disabled },
    reducedLabel: {
        position: "relative",
        textTransform: "uppercase",
        fontSize: 11,
        fontWeight: theme.typography.fontWeightLight,
        top: 2,
        color: theme.palette.neutral.main,
        "&$reducedLabelNotDraggable": { top: 3 },
    },
    reducedLabelNotDraggable: {},
    timeContainer: {
        position: "absolute",
        marginTop: 30,
        left: 30,
    },
    itemFilterDate: {
        height: 80,
        alignItems: "baseline",
        paddingTop: 8,
    },
    internalIcon: {
        marginRight: theme.spacing(),
        marginBottom: 2,
    },
}));

const DragHandle = SortableHandle(() => {
    const classes = useStyles();
    return <Drag tabIndex={0} classes={{ root: classes.dragIcon }} />;
});

const SortableItemColumn = (props) => {
    const { id, item, label, dragDisabled, onChangeProperty, columns, mandatoryItemId } = props;

    const p = useP();
    const classes = useStyles();
    const { id: moduleId } = useModule();

    const column = useMemo(() => {
        return columns[item.colId] || {};
    }, [item]);

    const handleChangeDisplay = useCallback(() => {
        onChangeProperty(item.id, "displayed", !item.displayed);
    }, [item, onChangeProperty]);

    const handleChangeDisplayTime = useCallback(() => {
        onChangeProperty(item.id, "displayTime", !item.displayTime);
    }, [item, onChangeProperty]);

    const handleChangeView = useCallback(() => {
        if (item.displayed === false) {
            return null;
        }
        let newView;
        switch (item.view) {
            case VIEWS.SMALL:
                newView = VIEWS.MEDIUM;
                break;
            case VIEWS.MEDIUM:
                newView = VIEWS.LARGE;
                break;
            case VIEWS.LARGE:
            default:
                newView = VIEWS.SMALL;
                break;
        }
        onChangeProperty(item.id, "view", newView);
    }, [item, onChangeProperty]);

    const handleKeyDownItem = useCallback(
        (event) => {
            if (event.keyCode === 32) {
                event.preventDefault();
                handleChangeView();
            }
        },
        [item, handleChangeView]
    );

    const itemIcon = useMemo(() => {
        const rootIconClass = classNames(classes.svgIcon, {
            [classes.iconDisabled]: item.displayed === false,
        });

        if (column.fixedCellWidth === true || !Object.keys(column).length) {
            return null;
        }

        let viewIcon;
        switch (item.view) {
            case VIEWS.SMALL:
                viewIcon = (
                    <HiSizeSmallIcon
                        tabIndex={0}
                        onKeyDown={handleKeyDownItem}
                        classes={{ root: rootIconClass }}
                        onClick={handleChangeView}
                        className="viewIconSmall"
                        color="inherit"
                    />
                );
                break;
            case VIEWS.MEDIUM:
                viewIcon = (
                    <HiSizeMediumIcon
                        tabIndex={0}
                        onKeyDown={handleKeyDownItem}
                        classes={{ root: rootIconClass }}
                        onClick={handleChangeView}
                        className="viewIconMedium"
                        color="inherit"
                    />
                );
                break;
            default:
                viewIcon = (
                    <HiSizeLargeIcon
                        tabIndex={0}
                        onKeyDown={handleKeyDownItem}
                        classes={{ root: rootIconClass }}
                        onClick={handleChangeView}
                        className="viewIconLarge"
                        color="inherit"
                    />
                );
                break;
        }
        return viewIcon;
    }, [item, columns]);

    return (
        <div
            id={id}
            className={classNames(classes.itemFilter, {
                [classes.itemFilterDate]:
                    column.type === "date" && !(item.view === VIEWS.SMALL && column.fixedCellWidth),
            })}
        >
            {dragDisabled || <DragHandle />}
            <div
                className={classNames(classes.itemControls, {
                    [classes.itemControlsNotDraggableNotChecked]: dragDisabled && !item.displayed,
                    [classes.itemControlsNotDraggableChecked]: dragDisabled && item.displayed,
                    [classes.itemControlsNotDraggableCellFixed]: dragDisabled && !itemIcon,
                    [classes.itemControlsSelectedNotDraggable]: dragDisabled && item.displayed,
                    [classes.widthCellFixed]: !itemIcon,
                })}
            >
                <FormControlLabel
                    classes={{
                        root: classNames(classes.controlLabelRoot, {
                            [classes.controlLabelNotDraggable]: dragDisabled,
                            [classes.controlLabelSmall]: item.view === VIEWS.SMALL,
                        }),
                        label: classNames(classes.itemLabel, classes.label, {
                            [classes.itemLabelNotDraggable]: dragDisabled,
                            [classes.itemLabelNotDraggableNotChecked]: !item.displayed,
                        }),
                    }}
                    control={
                        <HiCheckbox
                            classes={{
                                root: classNames(classes.checkbox, {
                                    [classes.checkboxNotDraggable]: dragDisabled,
                                }),
                            }}
                            checked={item.displayed}
                            onChange={item.onToggleDisplay || handleChangeDisplay}
                            disabled={mandatoryItemId && mandatoryItemId === item.colId}
                        />
                    }
                    label={
                        <>
                            {column.internal && (
                                <HiIcon
                                    className={classes.internalIcon}
                                    size={14}
                                    icon="lock"
                                    color="neutral"
                                />
                            )}
                            <span className={classes.textLabel}>{label || item.label}</span>
                        </>
                    }
                />
                {item.view === VIEWS.SMALL && typeof column.reducedLabel !== "undefined" && (
                    <span
                        className={classNames(classes.reducedLabel, {
                            [classes.reducedLabelNotDraggable]: dragDisabled,
                        })}
                    >
                        {p.t(`attributes.${moduleId}.${column.colId}.smallLabel`)}
                    </span>
                )}
                {itemIcon}
            </div>
            {column.type === DISPLAY_DATE &&
                !(item.view === VIEWS.SMALL && column.fixedCellWidth) && (
                    // TODO => improve in smthg generic ?
                    <div className={classes.timeContainer}>
                        <FormControlLabel
                            classes={{
                                root: classes.switchLabelRoot,
                                label: classes.label,
                            }}
                            control={
                                <HiSwitch
                                    checked={item.displayTime}
                                    color="primary"
                                    onChange={handleChangeDisplayTime}
                                />
                            }
                            disabled={item.displayed === false}
                            label={p.t("common.search.table.time")}
                        />
                    </div>
                )}
        </div>
    );
};

SortableItemColumn.propTypes = {
    /** Objet contenant les colonnes avec leurs propriétés */
    columns: PropTypes.object.isRequired,
    /** True si l'item ne peut pas être déplacé */
    dragDisabled: PropTypes.bool.isRequired,
    /** Identifiant de la div de l'item */
    id: PropTypes.string.isRequired,
    /** Objet contenant les informations de l'item courant */
    item: PropTypes.object.isRequired,
    /** Label à afficher, utilise item.label si non défini */
    label: PropTypes.string,
    /** Identifiant de la colonne qu'on ne peut pas supprimer pour forcer l'utilisateur à n'en avoir qu'une seule au minimum */
    mandatoryItemId: PropTypes.string,
    /** Fonction de callback appelée lors qu'on modifie un item (pas l'ordre) */
    onChangeProperty: PropTypes.func.isRequired,
};

SortableItemColumn.defaultProps = {
    columns: {},
    dragDisabled: false,
};

export default SortableElement(SortableItemColumn);
