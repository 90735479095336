import styled from "styled-components/macro";
import PropTypes from "prop-types";

import Link from "@mui/material/Link";
import OpenInNew from "@mui/icons-material/OpenInNew";
import Download from "@mui/icons-material/Download";

const CustomLink = (props) => {
    return (
        <Link
            className={props.className}
            href={props.url}
            rel="noreferrer"
            target="_blank"
            variant="body2"
            onClick={() => {
                props.onClick && props.onClick();
            }}
        >
            {props.label}
            {props.type === "download" ? (
                <Download className="icon" color="primary" />
            ) : (
                <OpenInNew className="icon" color="primary" />
            )}
        </Link>
    );
};

CustomLink.propTypes = {
    url: PropTypes.string,
    onClick: PropTypes.func,
    label: PropTypes.string.isRequired,
    type: PropTypes.oneOf(["link", "download"]),
};

CustomLink.defaultProps = {
    type: "link",
};

const StyledCustomLink = styled(CustomLink)`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
    padding-left: 4px;
    padding-right: 4px;

    .icon {
        height: 14px;
    }
`;

export default StyledCustomLink;
