import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles((theme) => ({
    root: { padding: "0px 24px" },
    header: {
        textAlign: "left",
        fontSize: 36,
        fontFamily: theme.typography.fontFamily,
        fontWeight: theme.typography.fontWeightLight,
        color: "#333333",
        "&>img": {
            height: 32,
            marginLeft: 6,
            marginRight: 4,
        },
    },
    hr: {
        width: "43px",
        border: `1px solid ${theme.palette.primary.main}`,
        margin: "2vh 0 2vh 0",
    },
}));
