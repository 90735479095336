const three_ds_liability = {
    id: "three_ds_liability",
    data: [
        {
            id: "merchant",
            label: "modules.transaction.attributes.boolean.values.yes",
        },
        {
            id: "issuer",
            label: "modules.transaction.attributes.boolean.values.no",
        },
    ],
    translate: true,
};

export default three_ds_liability;
