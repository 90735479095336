import React from "react";
import PropTypes from "prop-types";
import { translate } from "../../../../../../services/i18n/translate";
import { withConfig } from "../../../../../../services/config";
import classNames from "classnames";
import { isMobile } from "react-device-detect";

class NoticeSuggestionItem extends React.Component {
    static propTypes = {
        suggestion: PropTypes.object.isRequired,
        module: PropTypes.string,
    };

    render() {
        const { getConfigByModule, module, suggestion, p } = this.props;

        const moduleConfig = getConfigByModule(module);
        const ModuleIcon = moduleConfig.icon;

        return (
            <div className={classNames("notice", "item")}>
                {!isMobile && moduleConfig && <ModuleIcon fontSize="small" />}
                <div
                    className={classNames({
                        "text-div": !isMobile,
                        "text-div-mobile": isMobile,
                    })}
                >
                    {p.t(`attributes.${module}.${suggestion.targetField}.suggestLabel`)}
                    &nbsp;
                    {p.t(`app.qsearch.operators.${suggestion.operator}`)}
                    &nbsp;
                    <span
                        className="dark"
                        dangerouslySetInnerHTML={{ __html: suggestion.highlight }}
                    />
                </div>
            </div>
        );
    }
}

export default translate(withConfig(NoticeSuggestionItem));
