import PropTypes from "prop-types";
import classNames from "classnames";
import makeStyles from "@mui/styles/makeStyles";
import HiLoader from "@hipay/hipay-material-ui/HiLoader";
import HiIcon from "@hipay/hipay-material-ui/HiIcon";
import { ExitFullscreen } from "./ExitFullscreen";

const useStylesTitle = makeStyles((theme) => ({
    root: {
        position: "sticky",
        top: 0,
        height: 64,
        lineHeight: "64px",
        zIndex: 17,
        backgroundColor: theme.palette.background1,
        cursor: (props) => (props.onClick ? "pointer" : "inherit"),
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "flex-end",
    },
    fromTopbar: { backgroundColor: theme.palette.background3 },
    titleText: {
        display: "flex",
        alignItems: "center",
        marginBottom: 12,
    },
    hElem: {
        ...theme.typography.h1,
        marginTop: 0,
        marginBottom: 0,
    },
    hr: {
        width: 36,
        backgroundColor: theme.palette.input.bottomLine,
        height: 1,
    },
    icon: {
        marginRight: 8,
        verticalAlign: "text-bottom",
    },
    actions: {
        display: "inline-block",
        position: "absolute",
        right: 8,
    },
    loading: {
        ...theme.typography.h4,
        color: theme.palette.primary.main,
        textAlign: "center",
        paddingTop: "10px",
    },
    loader: {
        marginTop: "8px",
        marginLeft: "auto",
        marginRight: "auto",
        width: "1px",
    },
}));

export function Title(props) {
    const {
        id,
        value,
        withLine,
        endAdornment,
        level,
        className,
        icon,
        loadingMessage,
        onClick,
        actions,
        fromTopbar,
    } = props;

    const classes = useStylesTitle(props);
    const H = "h" + level;

    if (loadingMessage && loadingMessage !== "") {
        return (
            <div className={classes.loading}>
                <div className={classes.loadingMessage}>{loadingMessage}</div>
                <div className={classes.loader}>
                    <HiLoader loading />
                </div>
            </div>
        );
    }

    return (
        <div
            id={id}
            className={classNames(classes.root, { [classes.fromTopbar]: fromTopbar }, className)}
            onClick={onClick}
        >
            <div className={classes.titleText}>
                {icon && <HiIcon className={classes.icon} icon={icon} color="info" size={24} />}
                <H className={classes.hElem}>
                    {value}
                    {endAdornment}
                </H>
            </div>
            <div className={classes.actions} onClick={(e) => e.stopPropagation()}>
                {actions}
                <ExitFullscreen />
            </div>
            {withLine && <div className={classes.hr} />}
        </div>
    );
}

Title.propTypes = {
    /**
     * Array of elements to display on right side
     */
    actions: PropTypes.arrayOf(PropTypes.element),
    /**
     * Allow to override root div styles
     */
    className: PropTypes.string,
    /**
     * Icon slug (used by HiIcon)
     */
    icon: PropTypes.string,
    /**
     * Header level
     */
    level: PropTypes.oneOf([1, 2, 3]),
    /**
     * Callback on click on all title bar + set cursor as pointer if defined
     */
    onClick: PropTypes.func,
    /**
     * Title string
     */
    value: PropTypes.string.isRequired,
    /**
     * If true, display a separator line on bottom
     */
    withLine: PropTypes.bool,
};

Title.defaultProps = {
    actions: [],
    icon: null,
    level: 1,
    onClick: null,
    withLine: false,
};
