import moize from "moize";
import qs from "query-string";
import { Serialize } from "react-url-query";
import { hasOneValue } from "./arrays";
import { toISODateString } from "./dates";
import { isMultiValueFormFieldType } from "./forms";
import { displayTypes } from "../common/constants/displayTypes";
import { formFieldTypes } from "../common/constants/formFieldTypes";
import {
    URL_ENTRY_SEPARATOR,
    URL_PARAM_PREFIXES,
    URL_SELECTION_KEY,
} from "../common/constants/urls";
import { NoticeContext } from "../services/notice/NoticeContext";
import {
    getCustomDataAttributeId,
    mapCustomDataUrlToSearchParams,
    buildToDeleteCustomData,
} from "./custom-data";

export function getUrlParamsIsFilter(urlParamKey) {
    return (
        urlParamKey.startsWith(URL_PARAM_PREFIXES.form) ||
        urlParamKey.startsWith(URL_PARAM_PREFIXES.lookup) ||
        urlParamKey.startsWith(URL_PARAM_PREFIXES.exclude)
    );
}

/**
 * Build Url params to delete
 *
 * return object with undefined value to "remove" param and relative/multi params
 *
 * e.g.1
 * key    = fp_payment_means
 * type   = lookup
 * return => { lkp_fp_payment_means: undefined }
 *
 * e.g.2
 * key    = sentinel_profile
 * type   = form
 * return => { fp_sentinel_profile: undefined, fp_sentinel_rule: undefined }
 *
 * @param attributes
 * @param key
 * @param type
 * @param value
 */
export const buildToDeleteUrlParams = (attributes, key, type, value) => {
    const prefix = type in URL_PARAM_PREFIXES ? URL_PARAM_PREFIXES[type] : "";

    let toDeleteParams = {};

    const attributeId = key.replace(new RegExp("_[0-9]+$", "ig"), "");

    const attribute = attributes[attributeId];

    // CUSTOM DATA
    if (attribute && attribute.formFieldType === formFieldTypes.FORM_FIELD_CUSTOM_DATA) {
        toDeleteParams = buildToDeleteCustomData(prefix, attributeId, value);
    } else if (attribute && attribute.formFieldType === formFieldTypes.FORM_FIELD_DATE) {
        // DATE
        toDeleteParams[`${prefix}${key}`] = undefined;
        toDeleteParams[`${prefix}${key}_from`] = undefined;
        toDeleteParams[`${prefix}${key}_to`] = undefined;
        toDeleteParams[`${prefix}${key}_derivedFrom`] = undefined;
        toDeleteParams[`${prefix}${key}_derivedTo`] = undefined;
        toDeleteParams[`${prefix}${key}_interval`] = undefined;
    } else {
        toDeleteParams = { [`${prefix}${key}`]: undefined };
    }

    // MULTI FIELDS
    if (attribute && attribute.formFieldOptions && attribute.formFieldOptions.multiFields) {
        Object.values(attribute.formFieldOptions.multiFields).forEach((field) => {
            toDeleteParams[`${prefix}${field.id}`] = undefined;
        });
    }
    // RELATIVE FIELDS
    // Remove relativeFields (ex: sentinel_rule)
    if (attribute && attribute.formFieldOptions && attribute.formFieldOptions.relativeFormFields) {
        attribute.formFieldOptions.relativeFormFields.forEach((fId) => {
            toDeleteParams[`${prefix}${fId}`] = undefined;
        });
    }

    return toDeleteParams;
};

export const buildUrlGrid = (orderedColumns) => {
    const displayedColumns = orderedColumns.filter((column) => column.displayed);
    let gridParam = Object.values(displayedColumns).reduce((_gridParam, column) => {
        const view = column.view ? column.view.toUpperCase() : "L";
        if (column.hasOwnProperty("displayTime") && column.displayTime) {
            _gridParam.push(column.id + view + "H");
        } else if (column.displayCurrency) {
            _gridParam.push(column.id + view + "C");
        } else if (column.withFormating) {
            _gridParam.push(column.id + view + "F");
        } else {
            _gridParam.push(column.id + view);
        }
        return _gridParam;
    }, []);

    return gridParam.join("_");
};

/**
 * Build Url param
 * - ignore NULL values
 * - map Date fields with suffix (_from, _to, _derivedFrom, _derivedTo, _interval)
 * - map CustomData fields with key > value or only key
 * - encode array with urlEntrySeparator
 *
 * e.g.1
 * key    = payment_means
 * value  = ['visa', 'mastercard']
 * prefix = 'fp_'
 * return => { fp_payment_means: 'visa--mastercard' }
 *
 * e.g.2
 * key    = status_period
 * value  = 'current'
 * prefix = 'fp_'
 * return => false (because status_period isn't a valid attribute)
 *
 * @param attributes
 * @param key
 * @param value
 * @param prefix
 * @returns {boolean}
 */
export const buildUrlParam = (attributes, key, value, prefix = "") => {
    let urlParam = {};

    let dryKey = key.replace(new RegExp("_[0-9]+$", "ig"), "");

    const attribute = attributes[dryKey];

    if (value === null || !attribute) {
        return false;
    }

    // CUSTOM_DATA
    // FROM > state.formFields: { custom_data_1: { key: data1, value: toto }, custom_data_2: { key: data2 } }
    // TO   > { custom_data-data1: toto, custom_data: [ data2 ] }
    if (attribute.formFieldType === formFieldTypes.FORM_FIELD_CUSTOM_DATA) {
        // Format data for multi fields (custom data)
        if (prefix === "fp_") {
            // KEY > DATA
            if (typeof value === "object" && value.key && value.value) {
                urlParam[`${prefix}${dryKey}-${value.key}`] = value.value.trim();
            } else {
                // Only KEY
                urlParam[`${prefix}${dryKey}`] = value.key;
            }
        } else if (prefix === "lkp_" || prefix === "exc_") {
            // Lookup or exclude
            if (Array.isArray(value) && value.length > 0) {
                for (let i = 0; i < value.length; i++) {
                    let keyValue = value[i].split(" : ");
                    // if number between 1 and 10 => old custom data
                    dryKey =
                        keyValue[0].match(/^\d+$/) && keyValue[0] >= 1 && keyValue[0] <= 10
                            ? `old_${dryKey}`
                            : dryKey;
                    if (keyValue[0] === "EMPTY") {
                        urlParam[`${prefix}${key}`] = `${keyValue[0]}`;
                    } else {
                        urlParam[`${prefix}${dryKey}-${keyValue[0]}`] = /^"[^"]+"$/.test(
                            keyValue[1]
                        )
                            ? keyValue[1]
                            : `"${keyValue[1]}"`;
                    }
                }
            } else if (value.key === "EMPTY") {
                urlParam[`${prefix}${dryKey}`] = `${value.key}`;
            } else {
                urlParam[`${prefix}${dryKey}-${value.key}`] = /^"[^"]+"$/.test(value.value)
                    ? value.value
                    : `"${value.value}"`;
            }
        } else {
            return false;
        }
    } else if (attribute.formFieldType === formFieldTypes.FORM_FIELD_DATE) {
        // Format data as date range
        if (value && value.hasOwnProperty("to") && value.hasOwnProperty("from")) {
            if (value.from instanceof Date) {
                urlParam[`${prefix}${key}_from`] = toISODateString(value.from);
            }
            if (value.to instanceof Date) {
                urlParam[`${prefix}${key}_to`] = toISODateString(value.to);
            }
            if (value.interval) {
                urlParam[`${prefix}${key}_interval`] = value.interval;
            }

            // Handle derived dates from filters navigation
            if (value.hasOwnProperty("derivedFrom") && value.hasOwnProperty("derivedTo")) {
                if (value.derivedFrom instanceof Date) {
                    urlParam[`${prefix}${key}_derivedFrom`] = toISODateString(value.derivedFrom);
                } else if (value.derivedFrom === null) {
                    urlParam[`${prefix}${key}_derivedFrom`] = undefined;
                }
                if (value.derivedTo instanceof Date) {
                    urlParam[`${prefix}${key}_derivedTo`] = toISODateString(value.derivedTo);
                } else if (value.derivedTo === null) {
                    urlParam[`${prefix}${key}_derivedTo`] = undefined;
                }
            }
        } else if (typeof value === "string") {
            // used for lookup
            urlParam[`${prefix}${key}`] = value;
        } else {
            return false;
        }
    } else if (Array.isArray(value)) {
        // ARRAY (join by urlEntrySeparator)
        if (value.length > 0) {
            urlParam[`${prefix}${key}`] = Serialize.encodeArray(value, URL_ENTRY_SEPARATOR);
        } else {
            urlParam[`${prefix}${key}`] = undefined;
        }
    } else if (value !== undefined && value != null && value !== "") {
        // OTHERS (not empty)
        urlParam[`${prefix}${key}`] = typeof value === "string" ? value.trim() : value * 1;
    } else {
        return false;
    }

    // MULTI FIELDS
    if (attribute.formFieldOptions && attribute.formFieldOptions.multiFields) {
        Object.values(attribute.formFieldOptions.multiFields).forEach((field) => {
            // add associated field if defined or get default value from formFieldOptions
            urlParam[`${prefix}${field.id}`] = field.value;
        });
    }

    return urlParam;
};

export function buildSelectionParam(values) {
    if (!values) {
        return {};
    }

    return { [URL_SELECTION_KEY]: values.join(URL_ENTRY_SEPARATOR) };
}
/**
 * Build Url params
 *
 * for each param key => value in params object
 * - build url param relative to field type
 * - add multi fields if defined in attribute options
 *
 * e.g.1
 * params = { fp_payment_means: ['visa', 'mastercard'], status: ['created'] }
 * prefix = 'fp_'
 * return => { fp_fp_payment_means: 'visa--mastercard', fp_status: 'created', fp_status_period: 'past' }
 *
 * e.g.2
 * params = { status_period: 'current' }
 * prefix = 'fp_'
 * return => false (because status_period isn't a valid datafield)
 *
 * @param attributes
 * @param params
 * @param prefix
 */
export const buildUrlParams = (attributes, params = {}, prefix = "") => {
    let urlParams = {};
    Object.keys(params).forEach((key) => {
        let urlParam = buildUrlParam(attributes, key, params[key], prefix);
        const attributeId = key.replace(new RegExp("_[0-9]+$", "ig"), "");

        const attribute = attributes[attributeId];

        // If key already exists => concat params
        if (
            attribute &&
            attribute.formFieldType === formFieldTypes.FORM_FIELD_CUSTOM_DATA &&
            params[key] &&
            !params[key].value &&
            urlParams[`${prefix}${attributeId}`]
        ) {
            const valToAdd = urlParam[`${prefix}${attributeId}`];
            urlParams[`${prefix}${attributeId}`] += `--${valToAdd}`;
        } else if (urlParam) {
            urlParams = {
                ...urlParams,
                ...urlParam,
            };

            // MULTI FIELDS
            if (attribute.formFieldOptions && attribute.formFieldOptions.multiFields) {
                Object.values(attributes[key].formFieldOptions.multiFields).forEach((field) => {
                    // add associated field if defined or get default value from formFieldOptions
                    urlParams[`${prefix}${field.id}`] = params[field.id]
                        ? params[field.id]
                        : field.value;
                });
            }
        }
    });

    return urlParams;
};

export function getOldInterfaceUrl(url, selectedAccountIdList = [], withAccounts = true) {
    let link = getUrlSuffix(url);

    if (!process.env.NX_OLD_INTERFACE_BASE_URL) {
        throw Error("Environement variable NX_OLD_INTERFACE_BASE_URL does not exists");
    }
    let replacement = "";
    if (withAccounts) {
        if (selectedAccountIdList.length > 1) {
            replacement = "/maccounts/" + selectedAccountIdList.join("-");
        } else if (
            selectedAccountIdList.length === 1 &&
            !hasOneValue(selectedAccountIdList, "_every")
        ) {
            replacement = "/maccount/" + selectedAccountIdList[0];
        }
    }

    return (
        process.env.NX_OLD_INTERFACE_BASE_URL +
        "/default/auth/login" +
        "?sso=1&redirect=" +
        replacement +
        link
    );
}

/**
 * Return maccount prefix for Zend routes
 *
 * @param selectedAccountIdList
 */
export function getRoutePrefix(selectedAccountIdList) {
    let routePrefix = "";
    if (selectedAccountIdList.length) {
        routePrefix = selectedAccountIdList.length > 1 ? "/maccounts/" : "/maccount/";
        routePrefix += selectedAccountIdList.reduce(function (a, b) {
            return a === "" ? b : a + "-" + b;
        }, "");
    }
    return routePrefix;
}

export function getUrlSuffix(url) {
    //Match everything after /accounts-2144124-241414
    // eslint-disable-next-line no-useless-escape
    let matchSuffix = url.match(/((\/accounts(?!\/)[\-\d+]*)?)(\/[\w\W*]*)/);
    let urlSuffix = matchSuffix && matchSuffix[3] ? matchSuffix[3] : "";
    urlSuffix = urlSuffix.indexOf("/accounts-") === -1 ? urlSuffix : "";
    return urlSuffix;
}

/**
 * Retourne une URL relative
 * @param selectedAccountIdList - tableau des comptes sélectionnés
 * @param from - url de base
 * @param withAccounts - si true, prefixe l'url avec les comptes sélectionnés
 * @returns {string|*}
 */
export function getUrlWithAccounts(selectedAccountIdList = [], from = "", withAccounts = true) {
    let urlSuffix = getUrlSuffix(from);
    if (from.match(/\/?\/\[prefix]\/?/)) {
        urlSuffix = from.replace("/[prefix]", "");
    }
    let url = urlSuffix;
    if (withAccounts) {
        let accounts;
        if (selectedAccountIdList.length > 1) {
            accounts = selectedAccountIdList.join("-");
        } else if (
            selectedAccountIdList.length === 1 &&
            !hasOneValue(selectedAccountIdList, "_every")
        ) {
            accounts = selectedAccountIdList[0];
        }
        url = (accounts ? "/accounts-" + accounts : "") + url;
    }
    return url;
}

/**
 * Return true if we have a param beginning with 'fp_' and with restrictedBySelectedAccountList = true
 * @param searchParams
 * @param attributes
 */
export const isSearchWithRestrictedByAccountParams = (searchParams, attributes) => {
    let isRestricted = false;
    Object.keys(searchParams).forEach((param) => {
        if (param.startsWith("fp_") && attributes[param.substring(3, param.length)]) {
            if (
                attributes[param.substring(3, param.length)].formFieldOptions
                    .restrictedBySelectedAccountList === true
            ) {
                isRestricted = true;
            }
        }
    });
    return isRestricted;
};

/**
 * Map Url params to Search params
 * - match prefix to define param type ('fp_' to formParams)
 * - map Date field from suffix (_from, _to, _derivedFrom, _derivedTo, _interval)
 * - map CustomData fields with key > value or only key
 * - decode array with urlEntrySeparator
 *
 * e.g.1
 * urlParams = { fp_payment_means: 'visa--mastercard', qsearch: 'John Doe' }
 * return    => { formParams: { payment_means: ['visa', 'mastercard'] }, qsearch: 'John Doe' }
 *
 * e.g.2
 * urlParams = { fp_order_date_from: '2018-01-01', fp_order_date_to: '2018-01-31' }
 * return    => { formParams: { order_date: { from: new Date(2018-01-01), to: new Date(2018-01-31) } }
 *
 * @param attributes
 * @param urlParams
 * @param defaultGrid (set to false if grid param is not interesting)
 * @param defaultSortParams
 * @param rgpdCompliance
 * @returns {{order_by: (string), direction: string, columnParams: {}, excludeParams: {}, lookupParams: {}, formParams: {}}}
 */
export const mapUrlParamsToSearchParams = (
    attributes,
    urlParams,
    defaultGrid = false,
    defaultSortParams = {},
    rgpdCompliance = false,
    onCellsClick = {}
) => {
    let multiFieldsIndexes = {};

    let initParams = {
        order_by: defaultSortParams.order_by || "order_date",
        direction: defaultSortParams.direction || "desc",
        columnParams: {},
        excludeParams: {},
        lookupParams: {},
        formParams: {},
        orderedColumns: [],
        selectionParam: [],
        isDisplaySelector: false,
        isDisplayRank: false,
    };

    return Object.keys(urlParams).reduce((memo, key) => {
        switch (key) {
            case "qsearch":
            case "order_by":
            case "direction":
                memo[key] = urlParams[key];
                break;

            case "inSearch":
            case "noSubmit":
            case "fromRedirect":
                memo[key] = true;
                break;

            case URL_SELECTION_KEY:
                memo["selectionParam"] = urlParams[key].split(URL_ENTRY_SEPARATOR);
                break;

            case "selector":
                memo["isDisplaySelector"] = urlParams[key] === "true";
                break;

            case "rank":
                memo["isDisplayRank"] = urlParams[key] === "true";
                break;

            case "grid":
                if (defaultGrid === false) {
                    break;
                }
                let grid = [];
                const gridUrl = urlParams[key].split("_");
                Object.values(gridUrl).forEach((param, index) => {
                    const id = param.substring(0, 3);
                    const view = param.substring(3, 4).toLowerCase();
                    const column = defaultGrid.find((col) => col.id === id);
                    if (
                        column &&
                        (!attributes[column.colId]?.rgpdCompliance || rgpdCompliance) &&
                        (!attributes[column.colId]?.internal ||
                            process.env.NX_IS_INTERNAL === "true")
                    ) {
                        const displayTime = param.length > 4 && param.substring(4, 5) === "H";
                        const displayCurrency = param.length > 4 && param.substring(4, 5) === "C";
                        const withFormating = param.length > 4 && param.substring(4, 5) === "F";
                        grid.push({
                            ...column,
                            view: view,
                            order: index,
                            displayed: true,
                            ...(attributes[column.colId]?.displayType === displayTypes.DISPLAY_DATE
                                ? { displayTime: displayTime }
                                : {}),
                            ...(attributes[column.colId]?.displayType ===
                            displayTypes.DISPLAY_AMOUNT
                                ? { displayCurrency: displayCurrency }
                                : {}),
                            ...(attributes[column.colId]?.displayOptions.formatable
                                ? { withFormating: withFormating }
                                : {}),
                            ...(onCellsClick.hasOwnProperty(column.colId)
                                ? { onClick: onCellsClick[column.colId] }
                                : {}),
                        });
                    }
                });

                memo["orderedColumns"] = grid.sort(
                    (columnA, columnB) => columnA.order - columnB.order
                );

                break;

            default:
                Object.keys(URL_PARAM_PREFIXES).forEach((prefixKey) => {
                    let prefix = URL_PARAM_PREFIXES[prefixKey];

                    if (key.indexOf(prefix) === 0) {
                        // key without type prefix
                        let dryKey = key.replace(prefix, "");
                        let attributeId = dryKey.replace(new RegExp("_[0-9]+$", "ig"), "");
                        if (dryKey.includes("custom_data")) {
                            attributeId = getCustomDataAttributeId(dryKey);
                        }

                        // Filter RGPD params if user not compliant or internal
                        if (
                            (attributes[attributeId]?.rgpdCompliance && !rgpdCompliance) ||
                            (attributes[attributeId]?.internal &&
                                process.env.NX_IS_INTERNAL !== "true")
                        ) {
                            return memo;
                        }

                        // CUSTOM DATA
                        if (dryKey.includes("custom_data")) {
                            mapCustomDataUrlToSearchParams(
                                key,
                                prefixKey,
                                dryKey,
                                multiFieldsIndexes,
                                urlParams,
                                memo
                            );
                        } else if (
                            /(.*)(_from|_to|_derivedFrom|_derivedTo|_interval)$/.test(dryKey)
                        ) {
                            const matches = dryKey.match(
                                /(.*)(_from|_to|_derivedFrom|_derivedTo|_interval)$/
                            );
                            const dateKey = matches[1];
                            if (memo[`${prefixKey}Params`][dateKey] === undefined) {
                                memo[`${prefixKey}Params`][dateKey] = {};
                            }
                            if (matches[2] === "_from") {
                                memo[`${prefixKey}Params`][dateKey]["from"] = new Date(
                                    urlParams[key]
                                );
                            } else if (matches[2] === "_to") {
                                memo[`${prefixKey}Params`][dateKey]["to"] = new Date(
                                    urlParams[key]
                                );
                            } else if (matches[2] === "_derivedFrom") {
                                memo[`${prefixKey}Params`][dateKey]["derivedFrom"] = new Date(
                                    urlParams[key]
                                );
                            } else if (matches[2] === "_derivedTo") {
                                memo[`${prefixKey}Params`][dateKey]["derivedTo"] = new Date(
                                    urlParams[key]
                                );
                            } else if (matches[2] === "_interval") {
                                memo[`${prefixKey}Params`][dateKey]["interval"] = urlParams[key];
                            }
                        } else if (
                            attributes[attributeId] &&
                            (isMultiValueFormFieldType(attributes[attributeId].formFieldType) ||
                                prefixKey === "column")
                        ) {
                            memo[`${prefixKey}Params`][dryKey] = Serialize.decodeArray(
                                urlParams[key],
                                URL_ENTRY_SEPARATOR
                            );
                        } else {
                            memo[`${prefixKey}Params`][dryKey] = urlParams[key];
                        }
                    }
                });
                break;
        }
        return memo;
    }, initParams);
};

/**
 * Parse params from url
 * @param url
 * @returns {*}
 */
export function parseUrlToParams(url) {
    return qs.parse(url, { arrayFormat: "bracket" });
}

/**
 * Build path to notice
 * @param noticeId
 * @param modulePath
 * @param accountIdList
 * @returns {string}
 */
export const pathToNotice = (noticeId, modulePath, accountIdList = []) => {
    if (!noticeId) {
        console.warn("Cannot redirect to undefined noticeId.");
        return;
    }

    if (modulePath === undefined) {
        console.warn(`Cannot redirect to undefined module path ${modulePath}.`);
        return;
    }

    let path = "/";

    if (
        accountIdList.length > 0 &&
        !(accountIdList.length === 1 && accountIdList[0] === "_every")
    ) {
        path += "accounts-" + accountIdList.join("-") + "/";
    }

    return path + modulePath + "/" + noticeId;
};

/**
 * Build path to search
 * @param params
 * @param modulePath
 * @param accountIdList
 * @returns {string}
 */
export const pathToSearch = (params = {}, modulePath, accountIdList = []) => {
    if (modulePath === undefined) {
        console.warn(`Cannot redirect to undefined module path ${modulePath}.`);
        return;
    }

    let path = "/";

    if (accountIdList.length > 0) {
        path += "accounts-" + accountIdList.join("-") + "/";
    }

    return path + modulePath + "?" + stringifySearchParams(params);
};

/**
 * Stringify params to be used in url
 * @param params
 * @returns {*}
 */
export function stringifyParamsForUrl(params) {
    return params.filter((param) => param && param.length > 0).join("&");
}

/**
 * Stringify search params object to handle specific field behaviors
 * - split & suffix date params with _from|_to
 * - join multi values params with '--' as separator (to save url chars)
 *
 * @param searchParams
 * @param prefix
 * @param forUrl
 * @returns {*}
 */
export function stringifySearchParams(searchParams, prefix = "", forUrl = true) {
    const reducedSearchParams = Object.keys(searchParams).reduce((memo, key) => {
        let searchParam = searchParams[key];

        // exceptions for account, empty means _every and undefined means _current
        if (forUrl && key === "account" && Array.isArray(searchParam) && searchParam.length === 0) {
            memo[`${prefix}${key}`] = "_every";
            return memo;
        }

        // Remove empty params
        if (
            searchParam === undefined ||
            searchParam === null ||
            (Array.isArray(searchParam) && searchParam.length === 0) ||
            (typeof searchParam === "string" && searchParam === "")
        ) {
            return memo;
        }

        if (Array.isArray(searchParam)) {
            // For multi values params, send the list as joined string to reduce url length
            memo[`${prefix}${key}`] = searchParam.join("~");
        } else if (searchParam.hasOwnProperty("from") && searchParam.hasOwnProperty("to")) {
            if (
                searchParam.hasOwnProperty("derivedFrom") &&
                searchParam.hasOwnProperty("derivedTo")
            ) {
                if (searchParam.derivedFrom instanceof Date) {
                    memo[`${prefix}${key}_from`] = toISODateString(searchParam.derivedFrom);
                }
                if (searchParam.derivedTo instanceof Date) {
                    memo[`${prefix}${key}_to`] = toISODateString(searchParam.derivedTo);
                }
            } else {
                // For params as date range
                if (searchParam.from instanceof Date) {
                    memo[`${prefix}${key}_from`] = toISODateString(searchParam.from);
                }
                if (searchParam.to instanceof Date) {
                    memo[`${prefix}${key}_to`] = toISODateString(searchParam.to);
                }
            }
            if (searchParam.interval) {
                memo[`${prefix}${key}_interval`] = searchParam.interval;
            }
        } else if (key === "qsearch") {
            // never add prefix to qsearch
            memo["qsearch"] = searchParam;
        } else if (typeof searchParam === "boolean") {
            memo[`${prefix}${key}`] = searchParam === true ? 1 : undefined;
        } else {
            memo[`${prefix}${key}`] = searchParam;
        }

        return memo;
    }, {});

    // convert '~' > '--' after stringify() as stringify() encode '--'
    return qs
        .stringify(reducedSearchParams, { arrayFormat: "bracket" })
        .split("~")
        .join(URL_ENTRY_SEPARATOR);
}

/**
 * Redirect to notice
 * @param history
 * @param noticeId
 * @param modulePath
 * @param accountIdList
 * @param context - object { referer, query, total, entities, idList, outset }
 * @param _blank - open in new tab
 * @param anchor
 */
export const redirectToNotice = (
    history,
    noticeId,
    modulePath,
    accountIdList = [],
    context = null,
    _blank = false,
    anchor = null
) => {
    // persist context in localStorage to navigate
    if (context instanceof NoticeContext) {
        window.localStorage.setItem(process.env.NX_NOTICE_CONTEXT, context.toString());
    }

    // build path to notice
    let path = pathToNotice(noticeId, modulePath, accountIdList);

    // add anchor
    if (anchor) {
        path += `#${anchor}`;
    }

    // redirect or open new tab
    if (path) {
        if (_blank) {
            window.open(path, "_blank");
        } else {
            history.push(path);
        }
    }
};

/**
 * Redirect to quicksearch
 * Simulate onChange event on qsearch input and focus it
 * @param value
 */
export const redirectToQSearch = (value) => {
    let input = document.querySelector("input#quick-search-input");
    if (input) {
        let lastValue = input.value;
        input.value = value;
        let event = new Event("input", { bubbles: true });

        // hack React16
        let tracker = input._valueTracker;
        if (tracker) {
            tracker.setValue(lastValue);
        }

        input.dispatchEvent(event);
        input.focus();
    }
};

/**
 * /!\ Do not delete, this function is used in modules /!\
 * Redirect to search
 * @param history
 * @param params
 * @param modulePath
 * @param accountIdList
 */
export const redirectToSearch = (history, params = {}, modulePath, accountIdList = []) => {
    const path = pathToSearch(params, modulePath, accountIdList);
    if (path) {
        history.push(path);
    }
};

export const isOnNotice = moize((path) => {
    const regexTrx = RegExp(/\/transactions\/\d+$/);
    const regexAccount = RegExp(/\/accounts\/\d+$/);
    return regexTrx.test(path) || regexAccount.test(path);
});

/**
 * Remove contextual params from search url
 * - used by favorites
 * @param url
 * @returns {*}
 */
export const drySearchUrl = (url) =>
    url.replace(/&?(fromRedirect|inSearch)=(1|true)/g, "").replace(/\?&/, "?");

/**
 * Use https://images.weserv.nl/ service as proxy (for CSP concern) and resizing tool (don't forget to check the CORS in your env)
 * - filter not http(s) url
 * - encode url
 * @param imgUrl
 * @param width
 * @param height
 * @returns string || null
 */
export const buildProxyImgUrl = (imgUrl, width, height) => {
    if (imgUrl.indexOf("http") !== 0) {
        return null;
    }
    return `//images.weserv.nl/?url=${encodeURIComponent(
        imgUrl
    )}&w=${width}&h=${height}&fit=contain&cbg=black&encode=base64`;
};

/**
 * Update Grid params (grid, rank, selector) in search url
 * @param urlSearch
 * @param grid
 * @param rank
 * @param selector
 * @returns string
 */
export const updateGridParamsInSearchUrl = (urlSearch, grid, rank, selector) => {
    let newUrlSearch = urlSearch;
    newUrlSearch = /grid=[0-9A-Z_]*/.test(newUrlSearch)
        ? newUrlSearch.replace(/grid=[0-9A-Z_]*/, `grid=${grid}`)
        : newUrlSearch.concat(`&grid=${grid}`);
    newUrlSearch = /rank=(true|false)/.test(newUrlSearch)
        ? newUrlSearch.replace(/rank=(true|false)/, `rank=${rank}`)
        : newUrlSearch.concat(`&rank=${rank}`);
    newUrlSearch = /selector=(true|false)/.test(newUrlSearch)
        ? newUrlSearch.replace(/selector=(true|false)/, `selector=${selector}`)
        : newUrlSearch.concat(`&selector=${selector}`);
    return newUrlSearch;
};

export const hasInternalParams = (url, attributes, defaultGrid) => {
    let isInternal = false;

    const params = mapUrlParamsToSearchParams(attributes, parseUrlToParams(url), defaultGrid);

    if (params.orderedColumns.length) {
        params.orderedColumns.forEach((column) => {
            if (attributes[column.colId]?.internal === true) {
                isInternal = true;
            }
        });
    }
    const allParams = {
        ...params.formParams,
        ...params.columnParams,
        ...params.lookupParams,
        ...params.excludeParams,
    };
    if (isInternal === false && Object.keys(allParams).length > 0) {
        Object.keys(allParams).forEach((paramId) => {
            if (attributes[paramId]?.internal === true) {
                isInternal = true;
            }
        });
    }

    return isInternal;
};

export const buildParamObjectFromUrl = (query) => {
    const urlParams = new URLSearchParams(query);
    let paramsObject = {};
    [...urlParams.keys()].forEach((key) => {
        if (!paramsObject[key]) {
            const values = urlParams.getAll(key);
            paramsObject[key] = values.length === 1 ? values[0] : values;
        }
    });
    return paramsObject;
};

/**
 * Convert URLSearchParams to string.
 * Use this fonction to avoid problem with encoding.
 * @params URLSearchParams urlSearchParams
 * @returns string
 * */
export const urlSearchParamsToString = (urlSearchParams) => {
    let urlString = "";

    for (const [key, value] of urlSearchParams.entries()) {
        urlString += key + "=" + encodeURIComponent(value) + "&";
    }

    if (urlString.charAt(urlString.length - 1) === "&") {
        urlString = urlString.slice(0, -1);
    }

    return urlString;
};
