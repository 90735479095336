import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import classNames from "classnames";
import { useP } from "../../../../../services/i18n";

const useStylesMessage = makeStyles(() => ({
    root: {
        width: "calc(100% - 50px)",
        height: "inherit",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontSize: 15,
    },
    icon: {
        fontWeight: "bold",
        minWidth: 50,
    },
    content: {
        color: "inherit",
        marginLeft: 10,
        marginRight: 10,
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
    },
    link: {
        textDecoration: "underline",
        color: "inherit",
        minWidth: 78,
    },
}));

export const Message = ({ content, link, icon, customClass }) => {
    const classes = useStylesMessage();
    const p = useP();

    return (
        <div className={classNames(classes.root, customClass)}>
            <div className={classes.icon}>{icon}</div>
            <div className={classes.content} title={content}>
                {content}
            </div>
            {link && (
                <a className={classes.link} href={link} target="_blank" rel="noreferrer">
                    {p.t("app.message_bar.more_info")}
                </a>
            )}
        </div>
    );
};
