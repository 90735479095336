import PropTypes from "prop-types";
import makeStyles from "@mui/styles/makeStyles";

const useStylesTitle = makeStyles((theme) => ({
    root: {
        height: 48,
        lineHeight: "48px",
        display: "inline",
        flex: "none",
        flexWrap: "wrap",
        zIndex: 1,
        maxWidth: "100%",
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
    },
    mainTitle: {
        ...theme.typography.h4,
        color: (props) => {
            if (theme.palette[props.color]) {
                return theme.palette[props.color].main;
            }
            return props.color;
        },
    },
    subTitle: { ...theme.typography.caption },
}));

export const TitleHeader = (props) => {
    const { id, main, sub } = props;

    const classes = useStylesTitle(props);

    return (
        <div id={id} className={classes.root}>
            <span className={classes.mainTitle}>{main}</span>
            {sub && (
                <>
                    <span>&nbsp;•&nbsp;</span>
                    <span className={classes.subTitle}>{sub}</span>
                </>
            )}
        </div>
    );
};

TitleHeader.propTypes = {
    main: PropTypes.string.isRequired,
    sub: PropTypes.string,
    color: PropTypes.string,
};

TitleHeader.defaultProps = {
    sub: "",
    color: "inherit",
};
