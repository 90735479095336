// DB table : onboarding.onboarding_types
export default {
    id: "onboardingTypes",
    translate: true,
    data: [
        {
            id: "new_onboarding",
            label: "ref.onboarding.onboarding_types.new_onboarding",
        },
        {
            id: "activation_subscribed_feature",
            label: "ref.onboarding.onboarding_types.activation_subscribed_feature",
        },
        {
            id: "update",
            label: "ref.onboarding.onboarding_types.update",
        },
        {
            id: "upsell",
            label: "ref.onboarding.onboarding_types.upsell",
        },
        {
            id: "periodic_risk_review",
            label: "ref.onboarding.onboarding_types.periodic_risk_review",
        },
    ],
};
