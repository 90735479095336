import PropTypes from "prop-types";
import styled from "styled-components/macro";

import { HiButton } from "@hipay/design-system/components";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

const AlertModal = (props) => {
    return (
        <Dialog
            className={props.className}
            classes={{ paper: "dialog-paper" }}
            open={props.open}
            onClose={props.onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            {props.title && <DialogTitle className="alert-dialog-title">{props.title}</DialogTitle>}
            <DialogContent>
                <DialogContentText className="alert-dialog-description">
                    {props.content}
                </DialogContentText>
                {props.secondaryContent && (
                    <DialogContentText className="secondary-content">
                        {props.secondaryContent}
                    </DialogContentText>
                )}
            </DialogContent>
            <DialogActions className="dialog-actions">
                {props.cancelButtonLabel && (
                    <HiButton
                        onClick={props.onCancelClick}
                        className={"cancel-button"}
                        variant={props.cancelButtonVariant}
                        color={props.cancelButtonColor}
                    >
                        {props.cancelButtonLabel}
                    </HiButton>
                )}
                {props.submitButtonLabel && (
                    <HiButton
                        onClick={props.onSubmitClick}
                        className={"submit-button"}
                        color={props.submitButtonColor}
                        variant={props.submitButtonVariant}
                        autoFocus
                    >
                        {props.submitButtonLabel}
                    </HiButton>
                )}
            </DialogActions>
        </Dialog>
    );
};

const StyledAlertModal = styled(AlertModal)`
    .dialog-actions {
        padding: 16px 24px;
        justify-content: space-between;
    }

    .alert-dialog-title h2 {
        font-weight: 300;
    }

    .secondary-content {
        margin-top: 24px;
    }

    .submit-button {
        width: 250px;
    }

    ${({ theme }) => theme.breakpoints.up("sm")} {
        .dialog-paper {
            min-width: 600px;
        }
    }
`;

AlertModal.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func,
    title: PropTypes.string,
    content: PropTypes.string,
    onSubmitClick: PropTypes.func,
    secondaryContent: PropTypes.string,
    submitButtonLabel: PropTypes.string,
    submitButtonColor: PropTypes.oneOf([
        "default",
        "inherit",
        "primary",
        "secondary",
        "positive",
        "negative",
        "middle",
        "neutral",
    ]),
    submitButtonVariant: PropTypes.oneOf(["contained", "outlined", "text"]),
    onCancelClick: PropTypes.func,
    cancelButtonLabel: PropTypes.string,
    cancelButtonColor: PropTypes.oneOf([
        "default",
        "inherit",
        "primary",
        "secondary",
        "positive",
        "negative",
        "middle",
        "neutral",
    ]),
    cancelButtonVariant: PropTypes.oneOf(["contained", "outlined", "text"]),
};

AlertModal.defaultProps = {
    cancelButtonColor: "neutral",
    cancelButtonVariant: "text",
    submitButtonColor: "primary",
    submitButtonVariant: "text",
};

export default StyledAlertModal;
