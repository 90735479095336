import { lazy } from "react";
import { compose } from "recompose";
import { addUrlProps, replaceUrlQuery, multiReplaceInUrlQuery } from "react-url-query";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { translate } from "../../../services/i18n/translate";
import { withFavoritesProvider } from "../../services/FavoritesContext";

import * as userActions from "../../../app/actions/userActions";
import * as appActions from "../../../app/actions/appActions";
import * as referencesActions from "../../../services/references/actions";
import { URL_PARAM_PREFIXES } from "../../constants/urls";
import { withModule } from "../../../services/module";
import { withUser } from "../../../services/user";
import {
    buildUrlParam,
    buildUrlParams,
    buildSelectionParam,
    buildToDeleteUrlParams,
    mapUrlParamsToSearchParams,
    buildUrlGrid,
} from "../../../utils/urls";
import { LIMITED_SEARCH_STATUS } from "../../../constants";

const Search = lazy(() => import("../../components/Search/Search"));

/**
 * Build Url params from Search params
 * - add prefix if required ("fp_" for formParams...)
 * - ignore NULL values
 * - map Date fields with suffix (_from, _to, _derivedFrom, _derivedTo, _interval)
 * - map CustomData fields with key > value or only key
 * - encode array with urlEntrySeparator
 *
 * @param attributes
 * @param searchParams
 * @returns {{qsearch: *, order_by: *, direction: *}}
 */
const buildUrlParamsFromSearchParams = (attributes, searchParams) => {
    const {
        qsearch,
        order_by,
        direction,
        formParams,
        columnParams,
        excludeParams,
        lookupParams,
        selectionParam,
        orderedColumns,
        isDisplaySelector,
        isDisplayRank,
    } = searchParams;

    return {
        qsearch,
        order_by,
        direction,
        ...buildUrlParams(attributes, formParams, URL_PARAM_PREFIXES.form),
        ...buildUrlParams(attributes, columnParams, URL_PARAM_PREFIXES.column),
        ...buildUrlParams(attributes, excludeParams, URL_PARAM_PREFIXES.exclude),
        ...buildUrlParams(attributes, lookupParams, URL_PARAM_PREFIXES.lookup),
        grid: buildUrlGrid(orderedColumns),
        ...buildSelectionParam(selectionParam),
        selector: isDisplaySelector,
        rank: isDisplayRank,
    };
};

/**
 * Map from url query params to props. The values in `url` will still be encoded
 * as strings since we did not pass a `urlPropsQueryConfig` to addUrlProps.
 */
const mapUrlToProps = (url, props) => {
    return mapUrlParamsToSearchParams(
        props.attributes,
        url,
        props.defaultGrid,
        props.defaultSortParams,
        props.connectedUser.rgpdCompliance,
        props.onCellsClick
    );
};

/**
 * Replace in url query + add inSearch params
 */
const inSearchReplaceUrlQuery = (params) => {
    replaceUrlQuery({
        ...params,
        inSearch: true,
    });
};

/**
 * Multi replace in url query + add inSearch params + remove params redirect & no_submit
 */
const inSearchMultiReplaceInUrlQuery = (params) => {
    multiReplaceInUrlQuery({
        ...params,
        inSearch: true,
        fromRedirect: undefined,
        noSubmit: undefined,
    });
};

/**
 * Manually specify how to deal with changes to URL query param props.
 * We do this since we are not using a urlPropsQueryConfig.
 */
const mapUrlChangeHandlersToProps = (props) => {
    return {
        onSubmit: (formFields) => {
            const urlParams = buildUrlParamsFromSearchParams(props.attributes, {
                qsearch: props.qsearch,
                order_by: props.order_by,
                direction: props.direction,
                formParams: formFields,
                columnParams: props.columnParams,
                excludeParams: props.excludeParams,
                lookupParams: props.lookupParams,
                orderedColumns: props.orderedColumns,
                isDisplaySelector: props.isDisplaySelector,
                isDisplayRank: props.isDisplayRank,
                selectionParam: props.selectionParam,
            });
            inSearchReplaceUrlQuery(urlParams);
        },
        onDeleteParam: (type, id, value) => {
            const toDeleteParams = buildToDeleteUrlParams(props.attributes, id, type, value);
            inSearchMultiReplaceInUrlQuery(toDeleteParams);
        },
        onChangeParam: (type, id, value) => {
            const toChangeParam = buildUrlParam(
                props.attributes,
                id,
                value,
                URL_PARAM_PREFIXES[type]
            );
            inSearchMultiReplaceInUrlQuery(toChangeParam);
        },
        onSort: (order_by, direction) => {
            inSearchMultiReplaceInUrlQuery({
                order_by,
                direction,
            });
        },
        onChangeColumns: (orderedColumns, isDisplayRank, isDisplaySelector) => {
            const toChangeColumns = buildUrlGrid(orderedColumns);
            multiReplaceInUrlQuery({
                grid: toChangeColumns,
                selector: isDisplaySelector,
                rank: isDisplayRank,
            });
        },
        onChangeLimitedSearch: (searchState) => {
            if (searchState === LIMITED_SEARCH_STATUS.UNLIMITED) {
                multiReplaceInUrlQuery({ limitedSearch: false });
            } else {
                multiReplaceInUrlQuery({ limitedSearch: undefined });
            }
        },
    };
};

const mapStateToProps = (state, props) => {
    return {
        authentication: state.app.authentication.data,
        connectedUser: props.user.store.user,
        userSettings: state.app.settings.data,
        storeRef: state.ref,
        selectedAccountIdList: state.app.global.selectedAccountIdList,
        accountEntities: state.app.global.entities.account,
        businessEntities: state.app.global.entities.business,
        defaultSortParams: props.defaultSortParams,
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        // dispatch fetch only for dynamicDatas
        fetchReferences: (references, name, args, force) => {
            if (references[name] && !!references[name]["endpoint"]) {
                dispatch(referencesActions.fetchReferences(references, name, args, force));
            }
        },
        updateSettings: (settings) => dispatch(userActions.updateSettings(settings)),
        addSnackBar: (snackBar) => dispatch(appActions.addSnackBar(snackBar)),
        fetchRecurringExportList: () =>
            dispatch(appActions.fetchRecurringExportList(ownProps.module)),
        fetchSelectedAccountList: (accountIdList) =>
            dispatch(appActions.fetchSelectedAccountList(accountIdList)),
    };
};

export default compose(
    translate,
    withRouter,
    withModule,
    withUser,
    connect(mapStateToProps, mapDispatchToProps),
    addUrlProps({
        mapUrlToProps,
        mapUrlChangeHandlersToProps,
    }),
    withFavoritesProvider
)(Search);
