import qs from "query-string";
import { CALL_API } from "redux-api-middleware";

export function queryMiddleware() {
    return (next) => (action) => {
        if (action.hasOwnProperty(CALL_API) && action[CALL_API].hasOwnProperty("query")) {
            const request = action[CALL_API];
            request.endpoint = [
                request.endpoint.replace(/\?*/, ""),
                qs.stringify(request.query, { arrayFormat: "bracket" }),
            ].join("?");
            delete request.query;

            return next({ [CALL_API]: request });
        }

        return next(action);
    };
}
