import Lock from "mdi-material-ui/Lock";
import LockOpenOutline from "mdi-material-ui/LockOpenOutline";
import BlockIcon from "@mui/icons-material/Block";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import NewReleasesIcon from "@mui/icons-material/NewReleases";

const user_status = {
    id: "user_status",
    data: [
        {
            id: "CREATED",
            label: "ref.user.user_status.CREATED",
            icon: <NewReleasesIcon color="secondary" />,
        },
        {
            id: "DELETED",
            label: "ref.user.user_status.DELETED",
            icon: <DeleteForeverIcon color="neutral" />,
        },
        {
            id: "LOCKED",
            label: "ref.user.user_status.LOCKED",
            icon: <Lock color="error" />,
        },
        {
            id: "ACTIVATED",
            label: "ref.user.user_status.ACTIVATED",
            icon: <LockOpenOutline color="success" />,
        },
        {
            id: "DEACTIVATED",
            label: "ref.user.user_status.DEACTIVATED",
            icon: <BlockIcon color="neutral" />,
        },
        {
            id: "PENDING_USER_ACTION",
            label: "ref.user.user_status.PENDING_USER_ACTION",
            icon: <BlockIcon htmlColor="#F99408" />,
        },
    ],
    translate: true,
};

export default user_status;
