import * as actionTypes from "./actionTypes";

/**
 * @param jwtToken
 * @returns {{type: string, payload: *}}
 */
export const setAuthentication = (jwtToken) => {
    return {
        type: actionTypes.SET_AUTHENTICATION,
        payload: jwtToken,
    };
};

/**
 * @returns {{type: string, payload: *}}
 */
export const disconnect = (error = null) => {
    return {
        type: actionTypes.LOGOUT,
        error: error,
    };
};

/**
 * Update store as Error 401 happen
 * @returns {{type: string, payload: *}}
 */
export const e401 = () => {
    return { type: actionTypes.ERROR_401 };
};
