import { lazy } from "react";
import { AppCommon } from "@console/core";

// https://reactjs.org/docs/code-splitting.html#route-based-code-splitting
const Root = lazy(() => import("./Root"));

export { default as attributes } from "./constants/attributes";

const AppWidget = (props: any) => <AppCommon ModuleRoot={Root} module={"widget"} {...props} />;

export default AppWidget;
