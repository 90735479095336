import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles({
    root: {
        "@media (max-width: 1023px)": {
            margin: "0 auto 0 auto",
            boxShadow: "0pt 2pt 3px #24242429",
        },
        background: "white",
        padding: "15px 0px 40px 0px",
        borderRadius: 3,
        overflow: "auto",
        width: 360,
    },
    body: {
        margin: "0px 24px 0px 24px",
        color: "#737373",
    },
});
