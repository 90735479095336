import { useStyles } from "./stylesheet";

export const Flag = (props) => {
    const { locale, path, handleOnClick } = props;

    const classes = useStyles();

    return (
        <img
            tabIndex={0}
            key={locale}
            id={"flag-" + locale}
            src={path}
            className={classes.root}
            onClick={handleOnClick}
            alt={locale}
        />
    );
};
