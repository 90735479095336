import React from "react";
import PropTypes from "prop-types";
//import moment from 'moment-timezone';
import classNames from "classnames";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import History from "mdi-material-ui/History";
import CloseCircle from "mdi-material-ui/CloseCircle";
import HiColoredLabel from "@hipay/hipay-material-ui/HiColoredLabel";
//import Magnify from 'mdi-material-ui/Magnify';

import { withConfig } from "../../../../../services/config";
import { withStylesAndTranslate } from "../../../../../hoc/withStylesAndTranslate";
import { buildAccountDisplayPropsFromEntities } from "../../../../../utils/entities";
import { redirectToNotice, redirectToQSearch } from "../../../../../utils/urls";
import QueryParser from "../../../../../services/qsearch/QueryParser";
import { isMobile } from "react-device-detect";

export const stylesTableItem = (theme) => ({
    listItemRoot: {
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "space-between",
    },
    listItemRootMobile: { flexDirection: "column" },
    listItemQueryWrapper: {
        width: "100%",
        display: "flex",
    },
    listItemHover: {
        padding: 0,
        fontWeight: theme.typography.fontWeightBold,
    },
    listItemNotHover: { padding: "0" },
    listItemRightIcon: {
        paddingLeft: 16,
        lineHeight: "15px",
    },
    rightItem: {
        display: "flex",
        flexWrap: "nowrap",
        justifyContent: "flex-end",
        alignItems: "center",
        minWidth: 160,
        textTransform: "uppercase",
        ...theme.typography.body3,
        color: theme.palette.common.lightBlack,
        marginTop: 5,
    },
    rightItemMobile: { paddingLeft: 42 },
    accountIcon: {
        position: "relative",
        top: 2,
        marginRight: 6,
    },
    searchIcon: {
        width: 18,
        height: 18,
    },
    magnifyIcon: { verticalAlign: "-15%" },
});

class TableItem extends React.Component {
    static propTypes = {
        classes: PropTypes.object,
        selectedAccountIdList: PropTypes.array,
        searchRecord: PropTypes.object.isRequired,
        deleteItem: PropTypes.func.isRequired,
        phrases: PropTypes.object,
    };

    constructor(props) {
        super(props);

        this.state = { hover: false };

        this.handleHoverEnter = this.handleHoverEnter.bind(this);
        this.handleHoverLeave = this.handleHoverLeave.bind(this);
        this.handleClick = this.handleClick.bind(this);
    }

    handleHoverEnter = () => {
        this.setState({ hover: true });
    };

    handleHoverLeave = () => {
        this.setState({ hover: false });
    };

    // Redirect to search with query & selected account id list
    handleClick = () => {
        const {
            searchRecord,
            selectedAccountIdList,
            history,
            onClose,
            getConfigByModule,
            phrases,
        } = this.props;

        const moduleConfig = getConfigByModule(searchRecord.module);

        if (searchRecord.redirectId) {
            redirectToNotice(
                history,
                searchRecord.redirectId,
                moduleConfig.path,
                selectedAccountIdList
            );
            onClose();
        } else {
            // fill & focus qsearch
            const decodedQuery = QueryParser.decodeQuery(
                searchRecord.query,
                searchRecord.module,
                moduleConfig.attributes,
                phrases
            );
            redirectToQSearch(decodedQuery);
        }
    };

    render() {
        const {
            classes,
            p,
            key,
            searchRecord,
            deleteItem,
            accountEntities,
            businessEntities,
            phrases,
            getConfigByModule,
        } = this.props;
        const { hover } = this.state;

        const moduleConfig = getConfigByModule(searchRecord.module || "transaction");
        const decodedQuery = QueryParser.decodeQuery(
            searchRecord.query,
            searchRecord.module,
            moduleConfig.attributes,
            phrases
        );

        /*let subtitle = (
            <span>
                {searchRecord.redirectId ? (
                    <span className={classes.searchIcon + ' fa fa-lg fa-send'} />
                ) : (
                    <Magnify className={classNames(classes.searchIcon, classes.magnifyIcon)}/>
                )}
                &nbsp;-&nbsp;
                {p.t('search_activity.num_results', {smart_count: searchRecord.nbResults}) + ' - ' + moment(searchRecord['createdAt']).format('LT')}
            </span>
        );*/

        let accountDisplay = buildAccountDisplayPropsFromEntities(
            p,
            searchRecord.selectedAccountIdList,
            accountEntities,
            businessEntities,
            0,
            false
        );

        const iconQuery = (
            <React.Fragment>
                <ListItemIcon>
                    {hover ? (
                        <CloseCircle
                            onClick={deleteItem(searchRecord)}
                            title={p.t("button.remove")}
                        />
                    ) : (
                        <History />
                    )}
                </ListItemIcon>
                <ListItemText
                    classes={{ root: classes.listItemNotHover }}
                    primary={decodedQuery}
                    // {...(hover && { secondary: subtitle })}
                />
            </React.Fragment>
        );

        return (
            <ListItem
                button
                key={key}
                onMouseEnter={this.handleHoverEnter}
                onMouseLeave={this.handleHoverLeave}
                onClick={this.handleClick}
                classes={{
                    root: classNames(classes.listItemRoot, {
                        [classes.listItemRootMobile]: isMobile,
                    }),
                }}
            >
                {isMobile ? (
                    <div className={classes.listItemQueryWrapper}>{iconQuery}</div>
                ) : (
                    iconQuery
                )}

                <div
                    className={classNames(classes.rightItem, {
                        [classes.rightItemMobile]: isMobile,
                    })}
                    title={accountDisplay.title}
                >
                    {searchRecord.selectedAccountIdList.length === 1 ? (
                        <HiColoredLabel
                            label={accountDisplay.label}
                            color={accountDisplay.color}
                            fontSize={13}
                        />
                    ) : (
                        accountDisplay.label
                    )}
                    <i className={classes.listItemRightIcon + " fa fa-lg " + accountDisplay.icon} />
                </div>
            </ListItem>
        );
    }
}

export default withStylesAndTranslate(stylesTableItem)(withConfig(TableItem));
