import PropTypes from "prop-types";
import classNames from "classnames";
import makeStyles from "@mui/styles/makeStyles";
import ButtonBase from "@mui/material/ButtonBase";
import Collapse from "@mui/material/Collapse";

export const useStylesKPI = makeStyles((theme) => ({
    root: {
        background: theme.palette.background3,
        height: 104,
        width: 136,
        padding: "0px 8px",
        margin: 8,
        display: "flex",
        textAlign: "center",
        flexDirection: "column",
        alignItems: "center",
        fontFamily: theme.typography.fontFamily,
        transition: "height .5s ease-out",
    },
    rootDisable: { cursor: "default" },
    error: {
        boxShadow: "0px 2px 4px 0px rgba(213, 0, 0, 0.24)",
        "&:hover, &:focus": { boxShadow: "0px 3px 5px 0px rgba(213, 0, 0, 0.32)" },
    },
    warning: {
        boxShadow: "0px 2px 4px 0px rgba(249, 148, 8, 0.32)",
        "&:hover, &:focus": { boxShadow: "0px 3px 5px 0px rgba(249, 148, 8, 0.40)" },
    },
    inherit: {
        boxShadow: "0px 1px 3px 0px rgba(0, 0, 0, 0.16)",
        "&:hover": { boxShadow: "0px 3px 5px 0px rgba(0, 0, 0, 0.24)" },
    },
    success: {
        boxShadow: "0px 1px 3px 0px rgba(0, 175, 1, 0.36)",
        "&:hover, &:focus": { boxShadow: "0px 3px 5px 0px rgba(0, 175, 1, 0.44)" },
    },
    active: {
        background: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        boxShadow: "0px 3px 5px 0px rgba(0, 0, 0, 0.24)",
    },
    body: {
        margin: 0,
        display: "inline-grid",
        alignItems: "center",
        fontSize: 20,
        fontWeight: theme.typography.fontWeightLight,
        flex: "1 1 auto",
    },
    title: {
        maxHeight: 16,
        maxWidth: "100%",
        margin: "7px 0px",
        whiteSpace: "nowrap",
        overflow: "hidden",
        fontSize: 14,
        fontWeight: theme.typography.fontWeightLight,
        flex: "0 1 16px",
    },
    subtitle: {
        maxHeight: 28,
        margin: "2px 0px",
        maxWidth: "100%",
        color: theme.palette.neutral.main,
        fontSize: 11,
        lineHeight: "13px",
        fontWeight: theme.typography.fontWeightMedium,
        flex: "0 1 28px",
    },
    subtitleActive: { color: theme.palette.primary.contrastText },
    minify: { height: 72 },
}));

export function HiKPI(props) {
    const {
        minify,
        bodyMinify,
        active,
        title,
        body,
        subtitle,
        color,
        id,
        tooltip,
        disable,
        onClick,
    } = props;

    const classes = useStylesKPI(props);

    // TODO - color from prop
    const rootclass = classNames(classes.root, {
        [classes.inherit]: color === "inherit",
        [classes.warning]: color === "warning" && !disable,
        [classes.error]: color === "error" && !disable,
        [classes.success]: color === "success" && !disable,
        [classes.active]: active,
        [classes.minify]: minify,
        [classes.rootDisable]: disable,
    });

    return (
        <ButtonBase
            id={`kpi_${id}`}
            className={rootclass}
            onClick={onClick}
            disableRipple={disable}
            disabled={disable}
            title={tooltip}
        >
            {title && <h3 className={classes.title}>{title}</h3>}
            <div className={classes.body}>{minify && bodyMinify ? bodyMinify : body}</div>
            <Collapse in={!minify} timeout="auto">
                <div className={classNames(classes.subtitle, { [classes.subtitleActive]: active })}>
                    {subtitle}
                </div>
            </Collapse>
        </ButtonBase>
    );
}

HiKPI.propTypes = {
    /**
     * Extension du style appliqué au composant
     */
    active: PropTypes.bool,
    /**
     * Corps du KPI
     */
    body: PropTypes.any.isRequired,
    /**
     * Version minifiée du composant
     */
    bodyMinify: PropTypes.any,
    /**
     * Id of the button
     */
    buttonId: PropTypes.string,
    /**
     * Fonction appelée au clic sur le KPI
     */
    classes: PropTypes.object,
    /**
     * KPI est-il actif ?
     */
    color: PropTypes.oneOf(["inherit", "warning", "error", "success"]),
    /**
     * Titre du KPI
     */
    disable: PropTypes.bool,
    /**
     * KPI id
     */
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    /**
     * Corps minifié du KPI si nécessaire
     */
    minify: PropTypes.bool,
    /**
     * Sous-titre du KPI
     */
    onClick: PropTypes.func,
    /**
     * Type du KPI (ancré ou normal : ancré permet action de clic)
     */
    subtitle: PropTypes.any,
    /**
     * Couleur de l'ombre du KPI
     */
    title: PropTypes.string,
    /**
     * Contenu du tooltip (facultatif)
     */
    tooltip: PropTypes.any,
};

HiKPI.defaultProps = {
    active: false,
    minify: false,
    color: "inherit",
};
