import { useEffect, Suspense, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Help } from "./Help";
import { Router } from "../../containers";
import SnackBarContainer from "../../containers/AppLogged/SnackBarContainer";
import { NotAuthorized, PageLoading } from "./Page";
import Alert from "../../containers/AppLogged/AlertContainer";
import { getAccountIdListFromUrl, hasOneValue, parseJwt } from "../../../utils";
import { useHistory } from "react-router-dom";
import { useToggle } from "react-use";
import makeStyles from "@mui/styles/makeStyles";
import { useLocale, useUser, useUserInfo, setConfigTracker, useCan } from "../../../services";
import {
    fetchSelectedAccountList,
    fetchSelectableAccountList,
    setSelectedAccountIdList,
} from "../../actions/appActions";
import { disconnect } from "../../actions";
import { LayoutContainer } from "../common";
import { useOktaAuth } from "@okta/okta-react";
import { useInterval, useHeaderHeight } from "../../../hooks";
import { menuUserItems } from "./AppMenu";

const useStylesAppLogged = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background1,
        textAlign: "center",
        width: "100%",
        height: "100vh",
        paddingTop: ({ headerHeight }) => headerHeight,
        position: "relative",
    },
    fixed: { position: "fixed" },
}));

/**
 * App Logged
 * @param ModuleRoot - if path match module route else render Router (other non module routes)
 * @returns {*}
 * @constructor
 */
export function AppLogged({ ModuleRoot = Router }) {
    // store props
    const display403 = useSelector((state) => state.app.global.display403);
    const storeAuthData = useSelector((state) => state.app && state.app.authentication.data);
    const [accountRequested, setAccountRequested] = useState(false);

    const dispatch = useDispatch();
    const history = useHistory();
    const headerHeight = useHeaderHeight();
    const classes = useStylesAppLogged({ headerHeight });
    const [locale] = useLocale();
    const user = useUser();
    const userProfile = useUserInfo();
    const can = useCan();

    const selectedAccountIdList = getAccountIdListFromUrl(window.location.pathname);
    const accountEntities = useSelector((state) => state.app.global.entities.account);
    const accountFetched = useSelector((state) => state.app.global.entities.accountFetched);
    const [surveyDataSent, toggleSurvey] = useToggle(false);
    const [isScreebInitDone, setScreebInitDone] = useState(false);

    const { authState, oktaAuth } = useOktaAuth();
    const token = authState?.accessToken?.accessToken;

    // Check token expiration every X minutes and disconnect user if expired
    // TODO: Move to Auth or user provider
    useInterval(() => {
        if (token) {
            // If token, check expiration
            const tokenDecoded = parseJwt(token);
            const { exp: tokenExpiration } = tokenDecoded;
            const now = Math.floor(Date.now() / 1000);

            if (tokenExpiration < now) {
                // Call API to revoke token
                oktaAuth.revokeAccessToken();
                // Close Okta session locally
                oktaAuth.closeSession();
                // Remove token from redux store
                dispatch(disconnect());

                history.push("/login");
            }
        }
    }, process.env.NX_CHECK_TOKEN_DELAY || 10000);

    const accounts = useMemo(() => {
        let _accounts = [];
        if (!user?.internalstaff) {
            if (selectedAccountIdList.length && Object.keys(accountEntities).length) {
                for (let i = 0; i < selectedAccountIdList.length; i++) {
                    const accountEntityId = accountEntities[selectedAccountIdList[i]];
                    _accounts.push({
                        publicref: accountEntityId?.publicref,
                        name: accountEntityId?.name,
                        accountId: accountEntityId?.accountId,
                        businessId: accountEntityId?.businessId,
                        activitySector: accountEntityId?.activitySector?.name,
                    });
                }
            } else {
                return Object.keys(accountEntities).map((accountId) => ({
                    publicref: accountEntities[accountId].publicref,
                    name: accountEntities[accountId].name,
                    accountId: accountEntities[accountId].accountId,
                    businessId: accountEntities[accountId].businessId,
                    activitySector: accountEntities[accountId].activitySector?.name,
                }));
            }
        }
        return _accounts;
    }, [selectedAccountIdList, accountEntities]);

    /*
     * Load accounts
     */
    useEffect(() => {
        // Prevent refetch
        // TODO => remove RSAA !!!
        if (accountRequested !== true && !!storeAuthData?.token) {
            // if user can manage all accounts, just load selected accounts
            if (user.allAccountsAccess === true) {
                // do not fetch "every" account
                if (!hasOneValue(selectedAccountIdList, "_every")) {
                    dispatch(fetchSelectedAccountList(selectedAccountIdList));
                }
            } else {
                // load user granted accounts
                dispatch(fetchSelectableAccountList());
            }
            setAccountRequested(true);
        }
    }, [storeAuthData?.token]);

    useEffect(() => {
        if (
            process.env.NX_SCREEB === "true" &&
            userProfile &&
            !surveyDataSent &&
            window.$screeb &&
            !isScreebInitDone
        ) {
            setScreebInitDone(true);

            let industries = [];
            let business = [];
            Object.values(accountEntities).forEach((account) => {
                !industries.includes(account.activitySector?.name) &&
                    industries.push(account.activitySector?.name);
                !business.includes(account.businessId) && business.push(account.businessId);
            });
            toggleSurvey(true);

            window.$screeb("init", "9c224c41-7b8b-4d3b-82b9-9f803790a4cf", {
                identity: {
                    id: userProfile.email,
                    properties: {
                        first_name: userProfile.firstname,
                        last_name: userProfile.surname,
                        user_type: userProfile.type,
                        language: userProfile.settings.locale.substring(0, 2),
                        country: userProfile.country,
                        currency: userProfile.settings.default_currency,
                        role: user.roles[0],
                        merchant_id: Object.values(accountEntities)
                            .map((account) => account.publicref)
                            .join(";"),
                        merchant_name: Object.values(accountEntities)
                            .map((account) => account.name)
                            .join(";"),
                        account_id: Object.values(accountEntities)
                            .map((account) => account.accountId)
                            .join(";"),
                        business_id: business.join(";"),
                        mcc_industry: industries.join(";"),
                        console_version: process.env.NX_VERSION,
                        environment: process.env.NX_ENV,
                        email: userProfile.email,
                        created_at: userProfile.created_at,
                    },
                },
            });
        }
    }, [user, userProfile, accounts]);

    /*
     * Update store account id list from url
     */
    useEffect(() => {
        // TODO - supprimer selectedAccountIdList du STORE pour toujours le récupérer de l'URL (HOC - withSelectedAccount)
        dispatch(setSelectedAccountIdList(selectedAccountIdList));

        // Listen to location changes
        history.listen((location, action) => {
            if (action === "POP") {
                dispatch(setSelectedAccountIdList(getAccountIdListFromUrl(location.pathname)));
            }

            /**
             * track only page
             * remove account id list prefix
             * replace ID (ex: '/transactions/123456' >> '/transactions/id')
             * remove search
             */
            let page = location.pathname;
            page = page.replace(/\/accounts[-\d+]+/g, "");
            if (page.includes("accounts")) {
                page = page.replace(/\/([a-z]+)\/([a-z0-9-]{36})/g, "$1/id");
            } else {
                page = page.replace(/\/\d+/g, "/id");
            }
            setConfigTracker({ page_path: page });
        });
    }, [dispatch, history, setSelectedAccountIdList]);

    // Redirect external user to Onboarding module if no account is accessible
    useEffect(() => {
        const currentPathname = window.location.pathname;

        // Do not redirect to Onboarding if the route is profile or preference
        if (menuUserItems.some((item) => item.target === currentPathname)) {
            return;
        }

        if (
            !user.internalstaff &&
            can("default", "business/verification-details") &&
            !user.allAccountsAccess &&
            accountFetched &&
            accounts.length === 0 &&
            !currentPathname.includes("/verifications")
        ) {
            history.push("/verifications");
        }
    }, [user, accountFetched]);

    /*
     * Throw Error
     * AppLogged should not be accessible by undefined user
     */
    if (!user) {
        history.push("/login");
        // throw new Error("AppLogged - user is undefined.");
    }

    return (
        <div id="app-logged" className={classes.root}>
            <LayoutContainer user={user} />

            {display403 && <NotAuthorized />}

            <Suspense fallback={<PageLoading />}>
                <ModuleRoot />
            </Suspense>

            {process.env.NX_ZENDESK === "true" && <Help lang={locale} accounts={accounts} />}

            <SnackBarContainer />

            <Alert />
        </div>
    );
}
