import { AppIntegration, AppEterminal } from "../App";

/**
 * Config
 *
 * On retrouve ici la configuration du module.
 *  - Ses attributes
 *  - La config du module selector de la quicksearch
 *  - Les default fields presents dans le quickForm & par défaut sur le formulaire de recherche (s'il est activé)
 *    ainsi que leurs valeurs par défaut (ou null)
 *  - La liste des features qui sont activées pour ce module
 *
 */
export const moduleConfigIntegration = {
    id: "integration", // module key
    icon: "hi_hosted_payment_page_menu", // module icon on module selector
    label: "integration", // translation path
    path: "integration", // module uri from search
    route: "[prefix]/integration", // module uri from quicksearch
    features: [], // module features enabled
    acl: {
        module: [
            {
                module: "settings",
                controller: "console",
                action: "hosted-page",
            },
        ],
    },
};

export const moduleConfigEterminal = {
    id: "eterminal", // module key
    icon: "support_agent", // module icon on module selector
    label: "modules.eterminal.title", // translation path
    path: "eterminal", // module uri from search
    route: "[prefix]/eterminal", // module uri from quicksearch
    acl: {
        module: [
            {
                module: "eterminal",
                controller: "index",
            },
        ],
    },
};

export const moduleRouting = [
    {
        key: "integration", // module key
        path: ["/integration", "/accounts-:ids/integration"], // module matching uri
        component: AppIntegration, // module parent component
    },
    {
        key: "eterminal", // module key
        path: ["/eterminal", "/accounts-:ids/eterminal"], // module matching uri
        component: AppEterminal, // module parent component
    },
];
