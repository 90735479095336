import * as actionTypes from "../actions/actionTypes";

export const isFeatureEnabled = (features, featureName) => {
    return features.indexOf(featureName) !== -1;
};

export const getFeatures = (state = [], { type, payload }) => {
    switch (type) {
        case actionTypes.FETCH_FEATURES_REQUEST:
        case actionTypes.FETCH_FEATURES_FAILURE:
            return [];
        case actionTypes.FETCH_FEATURES_SUCCESS:
            return payload.features || [];

        // Get features in connectedUser
        case actionTypes.SET_USER:
            return payload.features.reduce((features, feature) => [...features, feature.name], []);

        case actionTypes.LOGOUT:
            return [];

        default:
            return state;
    }
};
