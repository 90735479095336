import { useRef, useEffect } from "react";
import { createRoot } from "react-dom/client";
import OktaSignIn from "@okta/okta-signin-widget";
import "@okta/okta-signin-widget/css/okta-sign-in.min.css";
import "./stylesheet.css";
import { ExternalSignInButton } from "./ExternalSignInButton/ExternalSignInButton";
import { useP } from "../../../services/i18n";
import { useOktaAuth } from "@okta/okta-react";
import ArrowLeft from "mdi-material-ui/ArrowLeft";

export const OktaWidget = (props) => {
    const { config, onSuccess, onError, setOktaWidgetStep, userType } = props;
    const widgetRef = useRef(null);
    const p = useP();
    const { oktaAuth } = useOktaAuth();

    const removeExternalButtonIfExist = () => {
        const externalButton = document.getElementById("external-button-container");
        if (externalButton) {
            externalButton.remove();
        }
    };

    // if internal, a button give access to the external login process
    const showLogin = () => {
        if (userType === "internal") {
            const signInForm = document.getElementsByClassName("primary-auth-form")[0];
            if (signInForm) {
                const buttonEl = (
                    <ExternalSignInButton
                        label={p.t("modules.authentication.app.login.login_internal")}
                    />
                );
                signInForm.setAttribute("style", "display: none;");

                removeExternalButtonIfExist();

                let externalButtonContainer = document.createElement("div");
                externalButtonContainer.id = "external-button-container";

                // Insert before last element of signInForm parent
                let parent = signInForm.parentElement;
                if (parent) {
                    parent.insertBefore(externalButtonContainer, parent.lastChild);
                }

                const root = createRoot(externalButtonContainer); // createRoot(container!) if you use TypeScript
                root.render(buttonEl);
            }
        }
    };

    // Put Google Auth on top of the list and add a favorite icon upon it
    const MFAListRework = () => {
        const ulElement = document.getElementsByClassName("list-content");
        const mfaList = ulElement[0]?.getElementsByTagName("li");
        const googleAuthElement = ulElement[0]?.querySelectorAll("li[data-se='GOOGLE_AUTH']")[0];
        googleAuthElement.style.position = "relative";

        const favoriteIcon = document.createElement("img");
        favoriteIcon.setAttribute("src", "/assets/authentication/img/Favorite_icon.svg");
        favoriteIcon.setAttribute("alt", "favorite");
        favoriteIcon.setAttribute(
            "style",
            "width: 10%; position: absolute; top: -12px; left: 24px;"
        );

        googleAuthElement.appendChild(favoriteIcon);

        ulElement[0].insertBefore(googleAuthElement, mfaList[0]);
    };

    // moving back button on top left
    const backButtonRework = (target, key) => {
        const backButton = document.querySelectorAll(`[data-se="${target}"]`)[0];
        if (backButton) {
            const newBackButton = (
                <>
                    <ArrowLeft id={"okta-back-button-icon"} />
                    <span id={"okta-back-button-text"}>
                        {p.t(`modules.authentication.app.login.${key}`)}
                    </span>
                </>
            );
            const root = createRoot(backButton); // createRoot(container!) if you use TypeScript
            root.render(newBackButton);
        }
    };

    const showAuthTitle = () => {
        const title = document.querySelector("#okta-sign-in.auth-container h2");
        if (title) {
            title.style.display = "block";
        }
    };
    const clickBtnSend = () => {
        let divBtnElement = document.getElementsByClassName("mfa-verify")[0];
        let sendBtnSMS = divBtnElement?.querySelectorAll("a[data-se='sms-send-code']")[0];
        sendBtnSMS.setAttribute("id", "SendCode");
        sendBtnSMS.click();
    };

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    useEffect(() => {
        if (!widgetRef.current) {
            return false;
        }

        const widget = new OktaSignIn(config);

        widget.showSignInToGetTokens({ el: widgetRef.current }).then(onSuccess).catch(onError);
        // listen event when resend button appears
        let myInterval = setInterval(() => {
            let divBtnElement = document.getElementsByClassName("mfa-verify")[0];
            let reSendCode = divBtnElement?.querySelectorAll("a[data-se='sms-send-code']")[0];
            let disabledBtn = divBtnElement?.querySelector(".disabled");
            let sendBtn = document.getElementById("sendCode");

            if (!sendBtn && !disabledBtn && reSendCode) {
                // modify input element
                let inputContainer = document.querySelectorAll("div[data-se='o-form-fieldset']")[0];
                inputContainer.setAttribute("style", "width : 150px");
                clearInterval(myInterval);
            }
        }, 1000);

        widget.on("afterRender", (context) => {
            if (typeof context?.controller === "string") {
                setOktaWidgetStep(context?.controller);
                switch (context?.controller) {
                    case "primary-auth":
                        showLogin();
                        break;
                    case "enroll-choices":
                        MFAListRework();
                        break;
                    case "barcode-totp":
                    case "barcode-push":
                    case "enroll-totp":
                    case "activate-totp":
                    case "enroll-webauthn":
                        backButtonRework("back-link", "back_factor_button");
                        break;
                    case "account-unlock":
                    case "forgot-password":
                        showAuthTitle();
                        break;
                    case "mfa-verify":
                        clickBtnSend();
                        break;
                    default:
                        backButtonRework("signout-link", "back_signin_button");
                }
            } else {
                backButtonRework("back-link", "back_factor_button");
            }
        });

        return () => widget.remove();
    }, [oktaAuth]);

    return <div ref={widgetRef} />;
};
